import { fixNegativeZero } from "../utilities";
import { Node } from "./Node";

export class FunctionNode extends Node {
    toTexFunc: (...args: any[]) => any;

    constructor(name: string, args: Node[], parent: Node = undefined, toTexFunc: (...args: any[]) => any) {
        super(name, args, parent);
        this.type = "FunctionNode";
        this.toTexFunc = toTexFunc;
    }

    toTex(options?: any) {
        // Clone to prevent changing current object
        let _toReturn = this.toTexFunc(this.cloneDeep(), options);

        if (this.name !== "f_latex") {
            _toReturn = fixNegativeZero(_toReturn);
        }

        return _toReturn;
    }

    toWord(options?: any) {
        if (this.name === "f_div") {
            return this.args.map((val) => val.toWord()).join("~divided~by~");
        }
        return `~${this.name.replace("f_", "")}~`;
    }

    toString(_options?: any) {
        return `${this.name}(${this.args.map((val) => val.toString(_options)).join(", ")})`;
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new FunctionNode(this.name, args, parent, this.toTexFunc);
    }

    getLatexNeighbour(direction: "left" | "right"): Node {
        // the name matches the funtionName(...) format
        const isFunctionwName = this.toTex().match(/[^\{\}]+\(/g) !== null;

        // any functions we want a space for, but dont conform to the functionName(...) format
        const approvedFuncs = [
            "sin",
            "cos",
            "tan",
            "sec",
            "csc",
            "cot",
            "cotan",
            "asin",
            "acos",
            "atan",
            "asec",
            "acsc",
            "cosec",
            "acot",
            "sqrt",
            "log",
            "ln",
            "cis",
            "f_div",
            "f_latex",
            "f_conj",
            "abs",
        ];
        const approvedRight = ["f_percent"];
        const approvedLeft = ["plusMinus"];

        if (
            approvedFuncs.includes(this.name) ||
            (direction === "left" && (isFunctionwName || approvedLeft.includes(this.name))) ||
            (direction === "right" && approvedRight.includes(this.name))
        ) {
            return this;
        } else if (this.name === "f_exp" && direction === "right") {
            return this.args[0];
        } else if (this.name === "f_subscript" && direction === "left") {
            return this;
        } else {
            if (direction === "left" && this.args[0] !== undefined) {
                return this.args[0].getLatexNeighbour(direction);
            } else if (direction === "right" && this.args[this.args.length - 1] !== undefined) {
                return this.args[this.args.length - 1].getLatexNeighbour(direction);
            }
        }

        return this;
    }
}
