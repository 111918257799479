import { WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { Search } from "@mui/icons-material";
import { Autocomplete, InputAdornment, MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export function SearchBar({ pages }: { pages: WikiPage[] }) {
    const nav = useNavigate();
    const [focussed, setFocussed] = useState(false);
    const [searchVal, setSearchVal] = useState("");

    // function ngrams(text: string, n: number) {
    //     var r = [];
    //     for (var j = 1; j <= n; j++) {
    //         for (var i = 0; i <= text.toLowerCase().length - j; i++) {
    //             r.push(text.toLowerCase().substring(i, i + j));
    //         }
    //     }

    //     return r;
    // }

    // function intersect(array1: any[], array2: any[]) {
    //     return array1.filter((value) => array2.includes(value));
    // }

    // const filterOptions = (options: WikiPage[], { inputValue }: { inputValue: string }) => {
    //     if (!inputValue) {
    //         return options.slice(0, 1000);
    //     }
    //     const inputTrigrams = ngrams(inputValue, 3);
    //     return (
    //         options
    //             // iterate over each option and compute intersect(ngram(search), all_color_ngrams)
    //             .map((option) => {
    //                 const titleMatches = intersect(
    //                     inputTrigrams, // ngrams of search input (i.e. "crnflower")
    //                     ngrams(option.name, 3), // ngrams of the option (i.e. "cornflowerblue")
    //                 ).length;

    //                 const descrMatches = intersect(
    //                     inputTrigrams, // ngrams of search input (i.e. "crnflower")
    //                     ngrams(option.description, 3), // ngrams of the option (i.e. "cornflowerblue")
    //                 ).length;

    //                 return {
    //                     ...option,
    //                     nMatches: titleMatches * 4 + descrMatches,
    //                 };
    //             })
    //             // toss out anything that had no matches
    //             .filter(({ nMatches }) => nMatches > 0)
    //             // order by `nMatches`. in the event of a tie the shorter word wins.
    //             //
    //             // i.e. if we're searching for "blue" then "Blue" is #1 and "Green Blue" #2
    //             .sort((a, b) => {
    //                 const diff = b.nMatches - a.nMatches;
    //                 if (diff) {
    //                     return diff;
    //                 }
    //                 // if they have the same number off matching trigrams, shorter one wins
    //                 return a.name.length - b.name.length;
    //             })
    //     );
    // };

    const filterOptions = (options: WikiPage[], { inputValue }: { inputValue: string }) => {
        if (inputValue.trim() === "") {
            return [];
        } else {
            const results = options.filter((page) =>
                (page.name + page.description + page.path).toLowerCase().includes(inputValue.toLowerCase()),
            );
            // sort direct title matches higher
            return results.sort((a, b) => {
                const aMatch = a.name.toLowerCase().startsWith(inputValue.toLowerCase());
                const bMatch = b.name.toLowerCase().startsWith(inputValue.toLowerCase());
                if (aMatch && !bMatch) {
                    return -1;
                } else if (!aMatch && bMatch) {
                    return 1;
                } else {
                    return 0;
                }
            });
        }
    };

    return (
        <Autocomplete
            filterOptions={filterOptions}
            options={pages}
            blurOnSelect
            inputValue={searchVal}
            value={null}
            onInputChange={(e, val) => setSearchVal(val)}
            disableCloseOnSelect={false}
            sx={{ maxWidth: "51ch", width: "100%" }}
            onFocus={() => setFocussed(true)}
            onBlur={() => setFocussed(false)}
            clearOnBlur
            renderInput={(params) => (
                <TextField
                    label=""
                    variant="outlined"
                    {...params}
                    InputProps={{
                        ...params.InputProps,
                        style: { padding: "5px 15px", borderRadius: "10px" },
                        startAdornment: (
                            <InputAdornment position="start" style={{ opacity: focussed ? 1 : 0.4 }}>
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                    placeholder="Search"
                />
            )}
            onChange={(_, option) => {
                if (option !== null) {
                    nav("/#" + option.path.slice(1).toLowerCase());
                    setSearchVal("");
                }
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                    {option.name}
                </MenuItem>
            )}
        />
    );
}
