import { uid } from "@jaipuna/shared-external";
import { NumberParameter, VariableParameter } from "./Archetype";

export type DefinitionTypes = "NumberRange" | "NumberSet" | "Variable" | "VariableSet";

export abstract class AbstractDefinition {
    id = uid();
    abstract type: DefinitionTypes;
    abstract name: string;
}

export class NumberRangeDefinition extends AbstractDefinition {
    type: DefinitionTypes = "NumberRange";
    name = "";
    original = "0";
    start = "0";
    step = "0";
    stop = "0";

    constructor(name: string, original?: string, start?: string, step?: string, stop?: string) {
        super();
        this.name = name;
        if (original) this.original = original;
        if (start) this.start = start;
        if (step) this.step = step;
        if (stop) this.stop = stop;
    }

    toParameter() {
        const ret = new NumberParameter();
        ret.name = this.name;
        ret.value = this.original;
        return ret;
    }
}

export class NumberSetDefinition extends AbstractDefinition {
    type: DefinitionTypes = "NumberSet";
    name: string = "";
    original = "0";
    set: any[] = [];

    constructor(name: string, original?: string, set?: any[]) {
        super();
        this.name = name;
        if (original) this.original = original;
        if (set) this.set = set;
    }

    toParameter() {
        const ret = new NumberParameter();
        ret.name = this.name;
        ret.value = this.original;
        return ret;
    }
}

export class VariableDefinition extends AbstractDefinition {
    type: DefinitionTypes = "Variable";
    name: string = "";
    locked: boolean = false;

    constructor(name: string, locked?: boolean) {
        super();
        this.name = name;
        if (locked) this.locked = locked;
    }

    toParameter() {
        const ret = new VariableParameter();
        ret.name = this.name;
        ret.value = this.name;
        return ret;
    }
}

export class VariableSetDefinition extends AbstractDefinition {
    type: DefinitionTypes = "VariableSet";
    name: string = "";
    original: string = "";
    set: string[] = [];

    constructor(name: string, original?: string, set?: any[]) {
        super();
        this.name = name;
        if (original) this.original = original;
        if (set) this.set = set;
    }

    toParameter() {
        const ret = new VariableParameter();
        ret.name = this.name;
        ret.value = this.original;
        return ret;
    }
}
