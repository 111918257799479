import marked from "marked";
import * as React from "react";
import * as ReactDOMServer from "react-dom/server";
import { AmyDiagram } from "./AmyDiagram";
import "./amymarkdown.css";
import { AmyPlot } from "./AmyPlot";
import { AmyRenderStyle, getInstructionSections, hasSymetricBrackets, InstructionSection } from "./latexUtils";
import Tex from "./ReactKatex";

export interface AmyRenderProps extends AmyRenderStyle {
    text: string; // The text that might include latex indicated with $[]$ signs
    hidePMargin?: boolean;
    rtl?: boolean;
}

export interface AmyRenderProps extends AmyRenderStyle {
    text: string; // The text that might include latex indicated with $[]$ signs
    hidePMargin?: boolean;
    rtl?: boolean;
}

export function AmyRender({
    text,
    color,
    ignoreDiagrams,
    ignoreGraphs,
    useBlockMath,
    fontSize,
    hidePMargin,
    rtl,
}: AmyRenderProps) {
    const dText = React.useDeferredValue(text);
    const dColor = React.useDeferredValue(color);
    const dIgnoreDiagrams = React.useDeferredValue(ignoreDiagrams);
    const dIgnoreGraphs = React.useDeferredValue(ignoreGraphs);
    const dUseBlockMath = React.useDeferredValue(useBlockMath);
    const dFontSize = React.useDeferredValue(fontSize);
    const dHidePMargin = React.useDeferredValue(hidePMargin);
    const dRtl = React.useDeferredValue(rtl);

    return React.useMemo(
        () => (
            <AmyRenderDef
                text={dText}
                color={dColor}
                ignoreDiagrams={dIgnoreDiagrams}
                ignoreGraphs={dIgnoreGraphs}
                useBlockMath={dUseBlockMath}
                fontSize={dFontSize}
                hidePMargin={dHidePMargin}
                rtl={dRtl}
            />
        ),
        [dText, dColor, dIgnoreDiagrams, dIgnoreGraphs, dUseBlockMath, dHidePMargin, dRtl],
    );
}

/**
 * Renders compiled AmySyntax into JSX
 *
 * The second step in our Render Pipeline
 * ```
 * compileAmySyntax()  =>  [ <AmyRender/> ]  =>  User
 * ```
 * @export
 * @param {AmyRenderProps} props
 * @return {*}
 */
export function AmyRenderDef({
    text,
    color,
    ignoreDiagrams,
    ignoreGraphs,
    useBlockMath,
    fontSize,
    hidePMargin,
    rtl,
}: AmyRenderProps) {
    return React.useMemo(() => {
        if (!text) {
            return <React.Fragment />;
        }
        if (!hasSymetricBrackets(text)) {
            // in case not the same amount of openeing and closing exist. Return an error
            return <span>Error: Non-symmetric brackets in {text}</span>;
        }

        const latexifiedText: string = text;
        const outputElems: JSX.Element[] = [];
        let currentLatexTextBlock: string = "";

        // only format to latex if all brackets have the same amount of openings + closings
        const instrSections: InstructionSection[] = getInstructionSections(latexifiedText);

        if (instrSections.length === 0) {
            outputElems.push(<React.Fragment>{latexifiedText}</React.Fragment>);
        }

        for (const [index, value] of instrSections.entries()) {
            // Graph Section
            if (value.type === "graph") {
                if (ignoreGraphs !== true) {
                    outputElems.push(
                        <span key={index}>
                            <br />
                            <AmyPlot data={value.text} />
                            <br />
                            <br />
                        </span>,
                    );
                }
            }
            // Diagram Section
            else if (value.type === "diagram") {
                if (ignoreDiagrams !== true) {
                    try {
                        const svgText = JSON.parse(value.text) as { svgID: string };
                        // get the svgCode from the object

                        outputElems.push(
                            <span key={index}>
                                <AmyDiagram
                                    data={value.text}
                                    svgId={svgText.svgID}
                                    color={color === "white" ? "white" : "black"}
                                />
                            </span>,
                        );
                    } catch (e) {
                        if (e instanceof Error) {
                            outputElems.push(<span key={index}>ERROR {e.message}</span>);
                        } else {
                            outputElems.push(<span key={index}>ERROR {JSON.stringify(e)}</span>);
                        }
                    }
                }
            }
            // Text or Latex Sections
            else {
                if (value.type === "latex") {
                    currentLatexTextBlock += `$[${value.text}]$`;
                } else {
                    currentLatexTextBlock += value.text;
                }
                // if we are looking at a latex or text and the next one isnt one, we should merge and add them to the outputElems
                if (instrSections[index + 1]?.type !== "text" && instrSections[index + 1]?.type !== "latex") {
                    outputElems.push(
                        <MergeLatexTextIPositions
                            key={index}
                            text={currentLatexTextBlock}
                            index={index}
                            color={color}
                            ignoreGraphs={ignoreGraphs}
                            useBlockMath={useBlockMath}
                            fontSize={fontSize}
                            hidePMargin={hidePMargin}
                            rtl={rtl}
                        />,
                    );
                    currentLatexTextBlock = "";
                }
            }
        }

        return <span>{outputElems}</span>;
    }, [text, color, ignoreDiagrams, ignoreGraphs, useBlockMath, fontSize, rtl]);
}

/**
 * Takes all the latex and text textPositions and merges them, returning the JSX.Elements
 * @private
 * @param {InstructionSection[]} text
 * @returns {JSX.Element[]}
 */
function MergeLatexTextIPositions({
    text,
    index,
    color = "inherit",
    ignoreGraphs,
    useBlockMath,
    fontSize = "inherit",
    hidePMargin,
    rtl,
}: {
    text: string;
    index: number;
    color?: "white" | "black" | "inherit"; // The color the latex should be, supports all Latex colors
    ignoreGraphs?: boolean; // Ignores any graph notation so the output only shows plain text, diagrams and latex
    useBlockMath?: boolean; // Displays in blockMath mode, so displays on its own line and regular fonts on fractions
    fontSize: string; // CSS property of define the fontSize. If not given, "inherit" is used
    hidePMargin: boolean;
    rtl: boolean;
}) {
    let mdReadyText = text;
    const primary = "black";
    const secondary = "white";

    // replace $PrimaryColor and $SecondaryColor with black and white (or visa versa)
    mdReadyText = mdReadyText.replace(
        /((?:^|\.|!|\?) *)\$PrimaryColor/g,
        `$1${primary[0].toUpperCase()}${primary.slice(1)}`,
    );
    mdReadyText = mdReadyText.replace(/\$PrimaryColor/g, primary);

    mdReadyText = mdReadyText.replace(
        /((?:^|\.|!|\?) *)\$SecondaryColor/g,
        `$1${secondary[0].toUpperCase()}${secondary.slice(1)}`,
    );
    mdReadyText = mdReadyText.replace(/\$SecondaryColor/g, secondary);

    const instrSections = getInstructionSections(mdReadyText);
    const latexIsBlock: boolean[] = [];

    for (const [index, section] of instrSections.entries()) {
        if (section.type === "latex") {
            mdReadyText = mdReadyText.replace(`$[${section.text}]$`, `$[[nonMarkdown_${index}]]$`);

            const regex = new RegExp(`\\|([^\\|]*?)\\$\\[\\[nonMarkdown_${index}\\]\\]\\$([^\\|]*?)\\|`);
            let isOnlyTex = false;
            if (
                regex.test(mdReadyText) &&
                mdReadyText.includes("<ghost-table>") &&
                mdReadyText.includes("</ghost-table>")
            ) {
                const cellContent = regex.exec(mdReadyText);
                isOnlyTex = !(cellContent[1].trim().length > 0 || cellContent[2].trim().length > 0);
            }
            latexIsBlock.push(isOnlyTex);
        }
    }

    mdReadyText = marked(mdReadyText);

    for (const [index, section] of instrSections.entries()) {
        if (section.type === "latex") {
            const useBlock = latexIsBlock.splice(0, 1)[0] || useBlockMath;
            let thisHTML: string = "";
            const colorCode = color ? `\\color{${color}}` : "";

            const renderFunc = useBlock ? (
                <Tex style={{ whiteSpace: "nowrap" }} math={colorCode + section.text} block />
            ) : (
                <Tex math={colorCode + section.text} />
            );

            thisHTML = ReactDOMServer.renderToString(renderFunc);
            mdReadyText = mdReadyText.replace(`$[[nonMarkdown_${index}]]$`, thisHTML);
        }
    }

    return (
        <span
            key={index}
            className={`amymarkdown ${hidePMargin ? "noParaMargin" : ""} ${
                rtl ? "amymarkdownRight" : "amymarkdownLeft"
            }`}
            style={{
                color,
                margin: useBlockMath || ignoreGraphs ? undefined : 0,
                fontSize,
                direction: "ltr",
            }}
            dangerouslySetInnerHTML={{ __html: mdReadyText }}
        />
    );
}
