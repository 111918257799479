import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, useTheme } from "@mui/material";
import { useState } from "react";
import { ProgressButton } from "./ProgressButton";

export function ConfirmButton({
    buttonName,
    buttonColor,
    buttonStyle,
    dialogTitle,
    dialogContents,
    onClick,
    progressButton,
}: {
    buttonName: string;
    buttonColor: "primary" | "secondary" | "info" | "error" | "warning";
    buttonStyle?: React.CSSProperties;
    dialogTitle: string;
    dialogContents?: string;
    onClick: () => any;
    progressButton?: boolean;
}) {
    const [modalOpen, setModalOpen] = useState(false);
    const theme = useTheme();

    return (
        <>
            <Button style={buttonStyle} onClick={() => setModalOpen(true)} color={buttonColor}>
                {buttonName}
            </Button>
            <Dialog open={modalOpen} PaperProps={{ style: { background: theme.palette.background.default } }}>
                <DialogTitle>{dialogTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{dialogContents}</DialogContentText>
                    <DialogActions style={{ marginTop: "20px" }}>
                        {progressButton ? (
                            <ProgressButton
                                onClick={() => {
                                    setModalOpen(false);
                                    return onClick();
                                }}
                                buttonProps={{ color: buttonColor }}
                            >
                                {buttonName}
                            </ProgressButton>
                        ) : (
                            <Button onClick={onClick} color={buttonColor}>
                                {buttonName}
                            </Button>
                        )}
                        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        </>
    );
}
