import { Button, ButtonProps, CircularProgress } from "@mui/material";
import { useState } from "react";

export function ProgressButton({
    buttonProps,
    onClick,
    children,
}: {
    buttonProps?: ButtonProps;
    onClick: () => Promise<any>;
    children: any;
}) {
    const [loading, setLoading] = useState(false);

    return (
        <Button
            {...buttonProps}
            onClick={async () => {
                setLoading(true);
                await onClick();
                setLoading(false);
            }}
        >
            {loading && (
                <CircularProgress
                    color={buttonProps?.color || "primary"}
                    style={{
                        height: "20px",
                        width: "20px",
                        marginRight: "12px",
                    }}
                />
            )}
            {children}
        </Button>
    );
}
