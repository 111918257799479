import { Node } from "./Node";

export class ParenthesisNode extends Node {
    isGhost: boolean;

    constructor(args: Node[] = [], parent: Node = undefined, isGhost: boolean = false) {
        super(isGhost ? "$()$" : "()", args, parent);
        this.type = "ParenthesisNode";
        this.isGhost = isGhost;
    }

    toTex(options?: any): string {
        if (!this.isGhost) {
            return `\\left(${this.args[0].toTex(options)}\\right)`;
        } else {
            return this.args[0].toTex(options);
        }
    }

    toString(options: any): string {
        if (!this.isGhost) {
            return `(${this.args[0].toString(options)})`;
        } else {
            return `$(${this.args[0].toString(options)})$`;
        }
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new ParenthesisNode(args, parent, this.isGhost);
    }

    getLatexNeighbour(direction: "left" | "right"): Node {
        if (this.isGhost) {
            return this.args[0].getLatexNeighbour(direction);
        } else {
            return this;
        }
    }
}
