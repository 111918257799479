import {
    getProjectSettingsFromHostname,
    getSettings,
    setSettings,
} from "@jaipuna/common-modules/dist/src/ProjectSettings";
import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { Add, Bedtime, ChevronLeft, LightMode, Logout, Menu } from "@mui/icons-material";
import {
    AppBar,
    Box,
    Button,
    Drawer,
    Grid,
    IconButton,
    ThemeProvider,
    Toolbar,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from "@mui/material";
import { getApps, initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { collection, getDocs, getFirestore, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { CenteredProgess } from "./components/CenteredProgress";
import { SearchBar } from "./components/SearchBar";
import { WikiTree } from "./components/WikiTree";
import { useCookie } from "./hooks/useCookie";
import { useUser } from "./hooks/useUser";
import { darkTheme, lightTheme } from "./themes";
import LoginView from "./views/LoginView";
import { NewPageView } from "./views/NewPageView";
import { PageView } from "./views/PageView";

const settings = getProjectSettingsFromHostname(window.location.hostname);
if (settings) {
    setSettings(settings);
}

// init firebase
if (getApps().length === 0) {
    initializeApp(getSettings().firebaseConfig);
}

const auth = getAuth();

export function App() {
    const [wikiFuncs, setWikiFuncs] = useState<WikiFunc[] | null>(null);
    const [wikiPages, setWikiPages] = useState<WikiPage[] | null>(null);
    const [loadingError, setLoadingError] = useState(false);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [themeMode, setThemeMode] = useCookie<"dark" | "light">(
        "themeMode",
        window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light",
    );
    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));
    const mobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        if (smallScreen) {
            setDrawerOpen(false);
        }
    }, [smallScreen]);

    const nav = useNavigate();
    const user = useUser();

    // load the wiki data from db
    useEffect(() => {
        return onSnapshot(
            collection(getFirestore(), "WikiPages"),
            (querySnapshot) => {
                const pages: WikiPage[] = [];
                querySnapshot.forEach((doc) => {
                    pages.push(doc.data() as WikiPage);
                });
                // sort by name alphabetically
                pages.sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
                setWikiPages(pages);
            },
            () => setLoadingError(true),
        );
    }, [user]);

    useEffect(() => {
        getDocs(collection(getFirestore(), "WikiFuncs")).then(
            (querySnapshot) => {
                const funcs: WikiFunc[] = [];
                querySnapshot.forEach((doc) => {
                    funcs.push(doc.data() as WikiFunc);
                });
                // sort by name alphabetically
                setWikiFuncs(funcs);
            },
            () => setLoadingError(true),
        );
    }, [user]);

    if ((wikiFuncs === null || wikiPages === null) && !loadingError) {
        return <CenteredProgess style={{ marginTop: "20vh" }} />;
    }

    return (
        <>
            <Helmet>
                <style>
                    {`html {
                    background: ${themeMode === "light" ? "#FFFFFF" : "#121212"};
                }
                `}
                </style>
            </Helmet>
            <ThemeProvider theme={themeMode === "light" ? lightTheme : darkTheme}>
                <Box sx={{ flexGrow: 1 }}>
                    <AppBar position="fixed" elevation={0}>
                        <Toolbar>
                            {user !== null ? (
                                <>
                                    <div style={{ width: !smallScreen && drawerOpen ? "350px" : undefined }}>
                                        <IconButton
                                            size="large"
                                            edge="start"
                                            aria-label="menu"
                                            sx={{ mr: 2 }}
                                            onClick={() => setDrawerOpen(!drawerOpen)}
                                        >
                                            <Menu />
                                        </IconButton>
                                    </div>
                                    {!smallScreen && !drawerOpen && !mobileScreen && (
                                        <Typography color="textPrimary" variant="h6" style={{ marginRight: "30px" }}>
                                            Amy Content Wiki
                                        </Typography>
                                    )}

                                    <div style={{ flexGrow: 1 }}>
                                        <SearchBar pages={wikiPages || []} />
                                    </div>
                                    <div style={{ marginLeft: "10px", marginTop: "4px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Tooltip title="Change the theme">
                                                    <IconButton
                                                        onClick={() =>
                                                            setThemeMode(themeMode === "dark" ? "light" : "dark")
                                                        }
                                                    >
                                                        {themeMode === "dark" ? <LightMode /> : <Bedtime />}
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Create a new page">
                                                    <Link
                                                        to="/new"
                                                        style={{
                                                            textDecoration: "none",
                                                            color: "inherit",
                                                        }}
                                                    >
                                                        {smallScreen ? (
                                                            <IconButton>
                                                                <Add />
                                                            </IconButton>
                                                        ) : (
                                                            <Button color="info">+ New Page</Button>
                                                        )}
                                                    </Link>
                                                </Tooltip>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Logout">
                                                    <span>
                                                        {smallScreen ? (
                                                            <IconButton>
                                                                <Logout />
                                                            </IconButton>
                                                        ) : (
                                                            <Button color="info" onClick={() => signOut(auth)}>
                                                                Logout
                                                            </Button>
                                                        )}
                                                    </span>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </div>
                                </>
                            ) : (
                                <Typography variant="h6" style={{ marginRight: "30px" }}>
                                    Amy Content Wiki
                                </Typography>
                            )}
                        </Toolbar>
                    </AppBar>
                </Box>

                <Box sx={{ display: "flex" }}>
                    {user !== null && (
                        <Drawer
                            sx={{
                                width: drawerOpen ? "350px" : "0px",
                                flexShrink: 0,
                                "& .MuiDrawer-paper": {
                                    background: "none",
                                    width: drawerOpen ? "350px" : "0px",
                                    boxSizing: "border-box",
                                    bgcolor: "background.default",
                                },
                            }}
                            variant={smallScreen ? "temporary" : "permanent"}
                            anchor="left"
                            open={smallScreen && drawerOpen}
                            onClose={() => setDrawerOpen(false)}
                        >
                            <Toolbar sx={{ justifyContent: "space-between" }}>
                                <Typography variant="h6" style={{ flexGrow: 1, marginTop: "8px" }}>
                                    Amy Content Wiki
                                </Typography>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    onClick={() => setDrawerOpen(!drawerOpen)}
                                >
                                    <ChevronLeft />
                                </IconButton>
                            </Toolbar>
                            <WikiTree
                                pages={wikiPages || []}
                                onClick={(path) => nav("/#" + path.slice(1).toLowerCase())}
                            />
                        </Drawer>
                    )}
                    <Box
                        component="main"
                        sx={{
                            flexGrow: 1,
                            bgcolor: "background.default",
                            p: 3,
                            marginTop: "50px",
                            width: "100%",
                            minHeight: "calc(100vh - 64px)",
                            boxSizing: "border-box",
                        }}
                    >
                        <Routes>
                            <Route path="/login" element={<LoginView auth={auth} />} />
                            <Route
                                path="/new"
                                element={<NewPageView pages={wikiPages || []} funcs={wikiFuncs || []} />}
                            />
                            <Route path="/" element={<PageView pages={wikiPages || []} funcs={wikiFuncs || []} />} />
                        </Routes>
                    </Box>
                </Box>
            </ThemeProvider>
        </>
    );
}
