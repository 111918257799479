import { PDiff, TreePair } from "../modules";
import { Node } from "../parser/Node";
import { parse } from "../parser/parse";
import { UniqueDiff } from "../unCycle/UniqueDiff";
import { getSingularUnit } from "../utilities";
import { substituteCommonSubTrees } from "./DiffFinderBottomPrune";
import { searchAllCases, searchf_strikeCases } from "./DiffFinderSpecialCases";
import { pruneFromTheTop } from "./DiffFinderTopPrune";
/**
 *
 *   Author: Ewan Orr, Henry Seed
 *   Date: Mon 9th April 2018
 *   (c) Jaipuna 2018
 *
 *   Takes MathJS expression trees for given expressions Source and Target.
 *   Simplifies them both using Top-Down pruning and common subtree replacement until simplified.
 *   The simplified expression tree represents the transition between Source and Target.
 *
 */

/**
 *  replaces Z nodes with thier respective sub trees
 * @function
 * @param {Node} topNode
 * @param {Map<string, Node[]>} stepMap
 * @returns
 */
export function legacyReplaceZNodes(topNode: Node, stepMap: Map<string, Node[]>) {
    let _ret = topNode;
    for (const zNode of _ret.getNodes((node) => node.isZNode())) {
        const tree = stepMap.get(zNode.name); //changed

        _ret = zNode.replaceInTree(tree[0], topNode);
    }
    return _ret;
}

/**
 * Returns string version of subtree eg: (3 + 4 * 4)
 * @function
 * @param {Node[]} tree
 * @returns {string}
 */
export function getSubtreeString(tree: Node[]): string {
    if (!tree || tree.length === 0) {
        return undefined;
    }
    if (tree[0].isAssociative()) {
        const root: Node = tree[0];
        let resultStr: string = tree[1].toString();

        for (const node of tree.slice(2)) {
            // console.log(rootKids);
            // console.log(node);
            // console.log("");

            // we cant check roots kids because the substring has likely been replaced with Z1
            // so we have to go through the tree until we find a child of a previously visited node
            // meaning we have progressed to the 3rd level of the bfs.

            let isPrevNodeChild: boolean = false;
            // check if node is the child of a previous node, meaning we have moved past first level nodes
            for (const prevNode of tree.slice(1)) {
                if (node === prevNode) {
                    // reached the current node
                    break;
                } else {
                    //checks if node is kid of prevNode
                    if (prevNode.args.indexOf(node) > -1) {
                        isPrevNodeChild = true;
                        break;
                    }
                }
            }

            if (!isPrevNodeChild) {
                // If the node is not child of a prev node, means its a kid of root
                resultStr += " " + root.name + " " + node.toString();
            }
        }

        return resultStr;
    } else {
        return tree[0].toString();
    }
}
/**
 * Checks if two trees have the same names and children names / structure
 * returns true if both trees share the same structure of same names
 * @function
 * @param {Node[]} tree1
 * @param {Node[]} tree2
 * @returns {boolean}
 */
export function hasSameNodeNames(tree1: Node[], tree2: Node[]): boolean {
    let isEqual = true;
    if (tree1.length !== tree2.length) {
        isEqual = false;
    } else {
        for (let i = tree1.length - 1; i > -1; i--) {
            const node1Name = tree1[i].name;
            const node2Name = tree2[i].name;
            // ghost bracket case
            if ((node1Name === "$()$" && node2Name === "()") || (node1Name === "()" && node2Name === "$()$")) {
                continue;
            } else if (node1Name === "*" && node2Name === "*") {
                isEqual = (tree1[i] as any).mode === (tree2[i] as any).mode;
            } else if (getSingularUnit(node1Name) !== getSingularUnit(node2Name)) {
                isEqual = false;
            }

            if (!isEqual) break;
        }
    }

    return isEqual;
}

/**
 * Traces all nodes back up the tree until we find a node which they both share, then return that.
 * @function
 * @param {Node} tree
 * @param {Node[]} nodes
 * @returns {Node}
 */
export function getNearestCommonAncestor(nodes: Node[]): Node {
    // we need to make an array of each nodes path back to the root of tree
    const traces: Node[][] = [];
    for (const node of nodes) {
        // get the trace
        const trace: Node[] = node.getAncestors();
        // reverse it so we go from leaf -> root
        trace.reverse();
        traces.push(trace);
    }

    // now take the first trace and iterate over it
    for (const node of traces[0]) {
        // now check if node is in all other traces
        let found = true;
        for (const trace of traces.slice(1)) {
            // if we dont find node, then move onto next node up the tree
            if (trace.indexOf(node) < 0) {
                found = false;
                break;
            }
        }
        if (found) {
            return node;
        }
    }

    return null;
}

/**
 * Returns the Diff Pair roots by themselves
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {Node[]} The transition represented as an array of source -> target trees
 */
export function generateDiff(sourceTree: Node, targetTree: Node, ignoreSpecialCases: boolean = false): PDiff {
    const diffAndMap: { diff: PDiff; map: Map<string, Node[]> } = generateDiffAndSubtreeMap(
        sourceTree,
        targetTree,
        ignoreSpecialCases,
    );

    return diffAndMap.diff;
}

/**
 * Returns the Diff Pair with all special cases ignored
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {Node[]} The transition represented as an array of source -> target trees
 */
export function generateDiffwNoSpecial(sourceTree: Node, targetTree: Node): PDiff {
    const diffAndMap: { diff: PDiff; map: Map<string, Node[]> } = generateDiffAndSubtreeMap(
        sourceTree,
        targetTree,
        true,
    );

    return diffAndMap.diff;
}

/**
 * Checks if the given node has a 'relative' (another node, sharing type and name, but not the original node)
 * @function
 * @param {Node} node
 * @param {Node[]} bfs
 * @returns {boolean}
 */
export function nodeHasRelativeInTree(ogNode: Node, bfs: Node[]): boolean {
    const ogNodename: string = ogNode.name;
    const ogNodeisinEval: boolean = ogNode.isInEval();

    for (const node of bfs) {
        if (node !== ogNode) {
            // console.log(`Comparing ${ogNode} to ${node}\nType: ${ogNode.type} to ${node.type}\nName: ${ogNodename} to ${node.name}\ninEval: ${ogNodeisinEval} to ${isInEval(node, bfs[0])}`)
            if (ogNode.type === node.type && ogNodename === node.name && ogNodeisinEval === node.isInEval()) {
                return true;
            }
        }
    }
    return false;
}

/**
 * tree: Z1 + Z2
 * map:  Z1: {a / c}, Z2: {22}
 * output: {a / c} + {22}
 * @param {Node} tree
 * @param {Map<string, Node>} map
 * @returns {Node}
 */
export function replaceZ1wSubtree(tree: Node, map: Map<string, string> | Map<string, Node>): string {
    return tree
        .findAndReplace(
            (node) => node.isZNode(),
            (node) => parse(map.get(node.toString()).toString()),
        )
        .toString();
}

/**
 * Iterates through tree and replaces any {...} with a unique(for tree) ZX node eg: Z1, Z2, Z3...
 * tbhis is used in the comparer in the special case that the subtree replaces doesnt replace some edge cases namely:
 *    {a} - {a} -> {1}   ==>   Z1 - Z1 -> {1}
 *    {a} - {b} -> {1}   ==>   {a} - {b} -> {1}
 * we need these to compare to be the same so we can use to do the following:
 *    {a} - {a} -> {1}   ==>   Z1 - Z1 -> {1}
 *    {a} - {b} -> {1}   ==>   Z1 - Z2 -> Z2
 * @function
 * @param {Node} tree
 * @returns {Node}
 */
export function replaceEvalSubtrees(tree: Node, BFS: Node[], otherBFS: Node[]): Node[] {
    let expString = tree.toString();
    // let oldexpString = tree.toString();

    // first we need to find the current max ZX in the tree
    const ZRegex = /Z(\d+)/g;
    // get the matches
    const matches = expString.match(ZRegex) || [];
    // find max ZX we hit
    let maxZ = 0;
    for (const match of matches) {
        if (parseInt(match.replace("Z", "")) > maxZ) {
            maxZ = parseInt(match.replace("Z", ""));
        }
    }

    // start from max + 1
    maxZ += 1;

    // console.log(`Starting while loop`);
    // iterate over bfs
    for (const node of BFS) {
        if (node.type === "EvalNode") {
            const nodeKid = node.args[0];
            // make sure it has no relatives in the either tree
            if (!nodeHasRelativeInTree(nodeKid, otherBFS)) {
                expString = expString.replace(node.toString(), `Z${maxZ}`);
                maxZ += 1;
            }
        }
    }

    // console.log(`Relabled ${oldexpString} to ${expString}`);

    return parse(expString).getBFS();
}

/**
 *
 * @function
 * @param {Node} tree
 * @param {Node} node
 * @returns {Node}
 */
export function removeNode(removedNode: Node, tree: Node): Node {
    return removedNode.removeFromTree();
}

/**
 * Attemopts to simplify all f_stringOption()s in the given tree so f_stringOption("test" + {2 * 3}) => {2 * 3}
 * @function
 * @param {Node} tree
 * @returns {Node}
 */
export function simplifyf_stringOption(tree: Node): Node {
    let _retTree = tree;

    // go through every f_stringOption node
    for (const node of _retTree.getNodesByName("f_stringOption")) {
        const correctArg = node.args[0];
        const hasStringArg = correctArg.args.some((val) => val.type === "StringNode");

        // if is case: f_stringOption("awdawd" + {333} + "awdwad" + {34}...)
        if (correctArg.name === "+" && hasStringArg) {
            // we only want to look at non-string nodes
            const trees = correctArg.args.filter((val) => val.type !== "StringNode");
            if (trees.length > 0) {
                const lengthMap = new Map(trees.map((val) => [val, val.getBFS().length]));
                // sort by tree size
                trees.sort((node1, node2) => lengthMap.get(node2) - lengthMap.get(node1));
                _retTree = node.replaceInTree(trees[0]);
            }
        }
        // if is case: f_stringOption({34}) or f_stringOption(x) etc...
        else {
            _retTree = node.replaceInTree(correctArg);
        }
    }
    return _retTree;
}

/**
 * Generated a diff, then relabels it returning the diff and a map from original tree nodes => relabeled nodes including subtreed
 * ### eg:
 * - Steps:      {a} + {b * c} => {a + b * c}
 * - Diff:       {a} + {Z1} => {a + Z1}
 * - Relabled:   {X0} + {X1} => {X0 + X1}
 * - Map:        X0: "a"
 *                  X1: "b * c"
 *
 * @function
 * @param {Node} sourceTree
 * @param {Node} targetTree
 */
export function generateRelabeledDiff(sourceTree: Node, targetTree: Node, vars?: string[]) {
    const diffAndMap = generateDiffAndSubtreeMap(sourceTree, targetTree);
    const diffRelabled = relabelerWIthMap([diffAndMap.diff.source.toString(), diffAndMap.diff.target.toString()], vars);

    const fullMap = new Map<string, string>();
    for (const [name, diffVal] of diffRelabled.keyMap) {
        if (diffAndMap.map.has(diffVal)) {
            fullMap.set(name, getSubtreeString(diffAndMap.map.get(diffVal)));
        } else {
            fullMap.set(name, diffVal);
        }
    }

    return {
        diff: { source: parse(diffRelabled.expressions[0]), target: parse(diffRelabled.expressions[1]) },
        map: fullMap,
    };
}

/**
 * Returns Diff Pair roots and the Map of Z1, Z2... to the subtrees they replace
 * @param {} sourceTree
 * @param {} targetTree
 * @returns {[]}
 */
export function generateDiffAndSubtreeMap(
    sourceTree: Node,
    targetTree: Node,
    ignoreSpecialCases: boolean = false,
    keepf_format: boolean = false,
): { diff: PDiff; map: Map<string, Node[]> } {
    if (!sourceTree || !targetTree) {
        return { diff: new PDiff(sourceTree, targetTree), map: new Map() };
    }
    // console.log("Start\n", sourceTree.toAscii(), "\n", targetTree.toAscii());

    let newSource = sourceTree.removeNodeByName("f_underline");
    let newTarget = targetTree.removeNodeByName("f_underline");

    const origSource = newSource.toString();
    const origTarget = newTarget.toString();

    newSource = newSource.removeNodeByName("f_strike");
    newTarget = newTarget.removeNodeByName("f_strike");

    // replace all units with their kids, allows us to evaluate x and f_unit(m) the same for diffs
    newSource = newSource.findAndReplace(
        (node) => node.isUnit(),
        (node) => node.args[0],
    );
    newTarget = newTarget.findAndReplace(
        (node) => node.isUnit(),
        (node) => node.args[0],
    );

    newSource = newSource.removeNodeByName("f_bstrike");
    newTarget = newTarget.removeNodeByName("f_bstrike");

    newSource = newSource.findAndReplace(
        (node) => node.name === "f_boxbox",
        (node) => {
            node.name = "f_box";
            return node;
        },
    );
    newTarget = newTarget.findAndReplace(
        (node) => node.name === "f_boxbox",
        (node) => {
            node.name = "f_box";
            return node;
        },
    );

    if (origSource.includes("f_stringOption") || origTarget.includes("f_stringOption")) {
        newSource = simplifyf_stringOption(newSource);
        newTarget = simplifyf_stringOption(newTarget);
    }

    // identical to TransitionTreeSource and TransitionTreeTarget but still has its f_format() functions
    const treesWf_format: TreePair = { source: newSource.cloneDeep(), target: newTarget.cloneDeep() };

    if (keepf_format === false) {
        newSource = newSource.findAndReplace(
            (node) => node.name === "f_format",
            (node) => node.args[0],
        );
        newTarget = newTarget.findAndReplace(
            (node) => node.name === "f_format",
            (node) => node.args[0],
        );
    }

    // Clones trees so we don/t edit the trees given as params.
    let TransitionTreeSource = newSource.cloneDeep();
    let TransitionTreeTarget = newTarget.cloneDeep();

    // get the transition from the special cases.
    let special: TreePair;
    let map: Map<string, Node[]>;
    if (!ignoreSpecialCases) {
        // console.log("hello");
        const obj = searchAllCases(
            {
                source: TransitionTreeSource,
                target: TransitionTreeTarget,
            },
            treesWf_format,
            keepf_format,
        );
        special = obj.diff;
        map = obj.map;
    }

    const f_strikeObj = searchf_strikeCases({
        sourceString: origSource,
        targetString: origTarget,
    });
    if (f_strikeObj) {
        special = { source: f_strikeObj.source, target: f_strikeObj.target };
        map = f_strikeObj.map;
    }

    let diffAndMap: {
        diff: PDiff;
        map: Map<string, Node[]>;
    } = undefined;

    // if a special case exists use that.
    if (special !== undefined) {
        TransitionTreeSource = special.source;
        TransitionTreeTarget = special.target;

        // we only care about subtrees for f_box, we may change in the future
        diffAndMap = { diff: new PDiff(TransitionTreeSource, TransitionTreeTarget), map: map };
    } else {
        // make all mult ops normal mode
        for (const node of TransitionTreeSource.getBFS().concat(TransitionTreeTarget.getBFS())) {
            if (node.type === "OperatorNode" && node.name === "*") {
                (node as any).mode = "norm";
            }
        }
        TransitionTreeSource.flatten();
        TransitionTreeTarget.flatten();

        // Prunes the tree from the top
        const pruned = pruneFromTheTop(TransitionTreeSource, TransitionTreeTarget);
        TransitionTreeSource = pruned.source;
        TransitionTreeTarget = pruned.target;

        // console.log("Pruned\n", TransitionTreeSource.toAscii(), "\n", TransitionTreeTarget.toAscii());

        // Prunes the tree from the bottom (Replaces all the common sub trees with variables (Z1, Z2, Z3...))
        diffAndMap = substituteCommonSubTrees(TransitionTreeSource, TransitionTreeTarget);
    }

    // console.log(
    //     "new",
    //     Array.from(diffAndMap.map).map(([key, val]) => `${key} => ${val[0].toString()}`),
    // );

    // Returns the transition represented as an array of source -> target trees
    return diffAndMap;
}

/**
 * Takes a array of strings, eg: a diff. Replaces all leaf nodes with X1, X2, ...
 * @param {string[]} exps
 * @returns {string[]}
 */
export function relabeler(exps: string[], vars?: string[]): string[] {
    return relabelerWIthMap(exps, vars).expressions;
}

/**
 * Returns the relabel with map
 * @param {string[]} exps
 * @returns {string[]: Map <string, string>}
 */
export function relabelerWIthMap(
    exps: string[],
    vars?: string[],
): { expressions: string[]; keyMap: Map<string, string> } {
    // Map of Symbol -> X eg: a -> X0
    const reversed_X_map: Map<string, string> = new Map<string, string>();
    let xCount = 0;

    // relabled exps array
    const labeledExpressions: string[] = [];

    // only relabel non empty strings
    for (const exp of exps.filter((exp) => exp.trim() !== "")) {
        let expsTree = parse(exp, vars);
        // make a in-order-tranversal so a + b / c -> [a, +, b, /, c]
        const traversal = expsTree.getIOT();

        // iterate over it, lookign for symbol nodes
        for (const node of traversal.filter((val) => val.type === "SymbolNode")) {
            if (node.getAncestors().find((ancestor) => ancestor.isUnit())) {
                continue;
            }
            const nodeName = node.name;
            // check if we've already seen symbol
            if (reversed_X_map.get(nodeName)) {
                // if we have then just set node value to the name we used for the others
                expsTree = node.findAndRename((node) => node.type === "SymbolNode", reversed_X_map.get(nodeName));
            } else {
                // if we havent then generate a new name and increment the xCount value
                const newName = `X${xCount}`;
                xCount += 1;
                // set name in the x map
                reversed_X_map.set(nodeName, newName);
                // rename the node to its X name
                expsTree = node.findAndRename((node) => node.type === "SymbolNode", newName);
            }
        }

        labeledExpressions.push(expsTree.toString());
    }

    const X_map: Map<string, string> = new Map<string, string>();
    for (const key of reversed_X_map.keys()) {
        X_map.set(reversed_X_map.get(key), key);
    }
    return { expressions: labeledExpressions, keyMap: X_map };
}

/**
 *
 * @function
 * @param {Node} source
 * @param {Node} target
 * @returns {boolean}
 */
export function compareTrees(BFS1: Node[], BFS2: Node[]): boolean {
    const usedBFS1 = BFS1;
    const usedBFS2 = BFS2;

    // A map that records how nodes in the archetype A (keys) are mapped to nodes in the diff pair (values).
    const fromAToDiff: Map<string, string> = new Map<string, string>();
    // A quick check - may be performed elsewhere.
    if (usedBFS1.length !== usedBFS2.length) {
        return false;
    }

    // stepping through each pair of nodes: a node from nodesFromA and the corresponding node from the diff pair.
    for (let i: number = 0; i < usedBFS2.length; i++) {
        const diffNode = usedBFS1[i];
        const archNode = usedBFS2[i];

        // a. If the DiffNode is not a constant or param we need ti check its the same type.
        if (diffNode.type !== "ConstantNode" && diffNode.type !== "SymbolNode") {
            // handles ghost nodes
            if (
                (diffNode.type === "GhostBracketNode" && archNode.type === "ParenthesisNode") ||
                (diffNode.type === "ParenthesisNode" && archNode.type === "GhostBracketNode")
            ) {
                continue;
            } else {
                if (diffNode.type !== archNode.type) {
                    // The nodes must be of the same type. May need new "types of nodes: variables(eg. 'x') and parameters(eg. 'a')"
                    return false;
                }
            }
        }

        // b. if the nodes are either numbers (constants) or operators then they must be exactly the same.
        if (archNode.type === "OperatorNode" || archNode.type === "FunctionNode") {
            let archNodeName = archNode.name;
            let diffNodeName = diffNode.name;

            // some functions are equivalent to our diffFinder , here are the equivalnce
            const equivalences = new Map([["ln", "log"]]);

            // replace the equivalences
            if (equivalences.get(archNodeName)) {
                archNodeName = equivalences.get(archNodeName);
            }
            if (equivalences.get(diffNodeName)) {
                diffNodeName = equivalences.get(diffNodeName);
            }

            // now compare the functions
            if (archNodeName !== diffNodeName) {
                return false;
            }
        }
        // c. Otherwise we are comparing either parameters or variables
        else {
            // First check Unit special case ie units multiple are equal to units singular
            const archNodeName = getSingularUnit(archNode.name);
            const diffNodeName = getSingularUnit(diffNode.name);

            // Checking whether archNode -> diffNode exists on the map.
            if (fromAToDiff.has(archNodeName)) {
                // Checking for conflict: whether earlier in the traversal we mapped archNode to something other than diffNode.
                if (fromAToDiff.get(archNodeName) !== diffNodeName) {
                    return false;
                }
            }
            // otherwise add it to the map.
            else {
                fromAToDiff.set(archNodeName, diffNodeName);
            }
        }
    }
    return true;
}

/**
 * Compares two BFSs from diffs, returns true if and only if both matche
 * @function
 * @param {Node[]} bfs1
 * @param {Node[]} bfs2
 * @returns {boolean}
 */
export function compareDiffTraces(diff1: UniqueDiff, diff2: UniqueDiff): boolean {
    if (!compareTrees(diff1.sourceNodes, diff2.sourceNodes)) {
        return false;
    }
    if (!compareTrees(diff1.targetNodes, diff2.targetNodes)) {
        return false;
    }

    return true;
}

/**
 * function compares a diff pair of source target and trees (S_diff, T_diff) with the first and last steps (S_A, T_A) in an archetype A.
 * function returns true if both pairs are the same. Eg. the diff pair (a + b + c, y) and A's pair (a + b + c, y) will give true.
 * function also returns true if the diff pair describes a special case of the skill in A. Eg. the diff pair (a + b + b, y) and
 * A's pair (a + b + c, y) will give true. However, the diff pair (a + b + c, y) and A's pair (a + b + b, y) will give false.
 *
 * @param {string[]} aDiff          Diff pair of step we are finding a transition for
 * @param {string[]} archSteps      [First, Last] steps of the arch we are testing
 * @returns {boolean}
 */
export function compareDiffs(aDiff: string[], archSteps: string[]): boolean {
    const diff1 = new UniqueDiff(aDiff[0], aDiff[1]);
    const diff2 = new UniqueDiff(archSteps[0], archSteps[1]);

    return compareDiffTraces(diff1, diff2);
}

/**
 * Overlays the urArch over step and return true if it fits
 * @param {Node} step
 * @param {Node} urArch
 * @returns {boolean}
 */
export function treeOverlay(
    step: Node,
    urArch: Node,
    stepTree: Node,
    urArchTree: Node,
): { match: boolean; map: Map<string, Node> } {
    let ap = new Map<string, Node>();
    let failed = false;

    let stepKids = step.args;
    const urArchKids = urArch.args;
    const sameKidCount = stepKids.length === urArchKids.length;

    if (
        step.isLeaf() ||
        (step.type === "EvalNode" && stepKids[0].isLeaf()) ||
        urArch.isLeaf() ||
        (urArch.type === "EvalNode" && urArchKids[0].isLeaf())
    ) {
        ap.set(urArch.toString(), step);
        return { match: true, map: ap };
    }

    for (const urNode of urArchKids) {
        let stepNode = stepKids[0];
        stepKids = stepKids.slice(1);
        if (!stepNode) {
            failed = true;
            break;
        }
        let evalMatch = true;
        if (!sameKidCount) {
            if (stepNode.isInEval() !== urNode.isInEval()) {
                evalMatch = false;
            }
        }
        while (!evalMatch && stepNode !== undefined) {
            stepNode = stepKids.shift();
            evalMatch = true;

            if (stepNode.isInEval() !== urNode.isInEval()) {
                evalMatch = false;
            }
        }

        if (!stepNode) {
            // console.log("StepNode is undefined");
            failed = true;
            break;
        }

        // if either is a leaf, save the match to the map
        if (stepNode.isLeaf() || urNode.isLeaf()) {
            ap.set(urNode.toString(), stepNode);
        } else {
            const { match, map } = treeOverlay(stepNode, urNode, stepTree, urArchTree);
            if (match) {
                ap = new Map([...ap].concat([...map]));
            } else {
                // console.log("Couldnt find a match");
                failed = true;
            }
        }
    }

    if (!failed) {
        return { match: true, map: ap };
    } else {
        return { match: false, map: new Map() };
    }
}
