import { Node } from "../parser/Node";
import { stripQuotes } from "../utilities";

export const customFunctionsText: any = {
    numToText: function (a: number): number {
        return a;
    },
};

export const customFunctionsTextToTex: any = {
    numToText: function (node: Node, options: any) {
        const mode = stripQuotes(node.args[1]);
        let numword = node.args[0].toWord({ fraction: true, over: mode === "over" });

        // 1st, 2nd, 3rd, 4th, ...
        if (mode === "ordinalNumeral") {
            const number = parseFloat(node.args[0].name);
            const numStr = node.args[0].name;
            if (!isNaN(number)) {
                // teens always end in "th"
                if (number > 10 && number < 20) {
                    numword = `${number}th`; // ...nth
                } else {
                    if (numStr.endsWith("1")) {
                        numword = `${number}st`; // ...1st
                    } else if (numStr.endsWith("2")) {
                        numword = `${number}nd`; // ...2nd
                    } else if (numStr.endsWith("3")) {
                        numword = `${number}rd`; // ...3rd
                    } else {
                        numword = `${number}th`; // ...nth
                    }
                }
            }
        }
        // twenty first, eleventh etc
        else if (mode === "ordinalWord") {
            const ordinals = [
                ["one", "first"],
                ["two", "second"],
                ["three", "third"],
                ["five", "fifth"],
                ["eight", "eighth"],
                ["nine", "ninth"],
                ["twelve", "twelfth"],
                ["ty", "tieth"],
            ];

            if (ordinals.some(([val]) => numword.endsWith(val))) {
                for (const [num, ending] of ordinals) {
                    if (numword.endsWith(num)) {
                        numword = numword.slice(0, numword.length - num.length) + ending;
                        break;
                    }
                }
            } else {
                numword = numword + "th";
            }
        }

        if (options?.rawText) {
            return numword.replaceAll(/~/g, " ");
        } else {
            return `\\mathrm{${numword}}`;
        }
    },
};
