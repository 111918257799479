import { getRandomInt } from "@jaipuna/common-modules/dist/src/GeneralUtils";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import * as ohash from "object-hash";
import { mathjs } from "./compiler/customFunctions";
import { ConstantNode } from "./parser/ConstantNode";
import { Node } from "./parser/Node";
import { parse } from "./parser/parse";
import { mistakeFlag, mistakePriorities } from "./unCycle/mistakeUtility";
import { DiffStats } from "./unCycle/sortArchsbyParams";
import { getStepsFlags } from "./unCycle/stepFlags";
import { cleanFloatingPoint, equalBracketCount, fixDecimalPlaces, fixNegativeZero, limitDigits } from "./utilities";

export { Node };
const forceImport = mathjs;

// ============= Unused until we implement our new Evaluation system ============= //
export class AmyNumber {
    precision: number;
    value: number;
    constructor(val: number, inprecision: number = null) {
        this.precision = inprecision !== null ? inprecision : val.toString().length;
        this.value = val;
    }
}

export class AmyComplex {
    value: number;
    constructor(val: number) {
        this.value = val;
    }
}

// ============================================================================== //

// a list of functions we always allow implicit "*" around
export const implicitFuncs = [
    "sin",
    "cos",
    "tan",
    "sec",
    "csc",
    "cot",
    "asin",
    "acos",
    "atan",
    "asec",
    "cotan",
    "cosec",
    "acsc",
    "acot",
    "sqrt",
    "log",
    "ln",
    "f_div",
    "f_latex",
];

// a list of functions we remove 1 in case of 1 * func => func
export const countableFuncs = [
    "sin",
    "cos",
    "tan",
    "sec",
    "csc",
    "cot",
    "cotan",
    "asin",
    "acos",
    "atan",
    "asec",
    "acsc",
    "cosec",
    "acot",
    "sqrt",
    "log",
    "ln",
];

export const precisionFunctions = [
    "ceil",
    "f_closestVal",
    "f_decimalPlaces",
    "f_digits",
    "f_faceValue",
    "f_format",
    "f_placeValue",
    "f_significantFigures",
    "fix",
    "floor",
    "getDigits",
    "getDP",
    "getSigFig",
    "length",
    "round",
    "toDP",
    "toEng",
    "toSci",
    "toSigFig",
    "trimZeros",
    "trunc",
    "evalUsingSigFigRules",
];

export const symbolTexTable = new Map([
    ["Alpha", "\\mathrm{A}"],
    ["alpha", "\\alpha"],
    ["Beta", "\\mathrm{B}"],
    ["beta", "\\beta"],
    ["Gamma", "\\Gamma"],
    ["gamma", "\\gamma"],
    ["Delta", "\\Delta"],
    ["delta", "\\delta"],
    ["Epsilon", "\\mathrm{E}"],
    ["epsilon", "\\epsilon"],
    ["varepsilon", "\\varepsilon"],
    ["Zeta", "\\mathrm{Z}"],
    ["zeta", "\\zeta"],
    ["Eta", "\\mathrm{H}"],
    ["eta", "\\eta"],
    ["Theta", "\\Theta"],
    ["theta", "\\theta"],
    ["Iota", "\\mathrm{I}"],
    ["iota", "\\iota"],
    ["Kappa", "\\mathrm{K}"],
    ["kappa", "\\kappa"],
    ["Lambda", "\\Lambda"],
    ["lambda", "\\lambda"],
    ["Mu", "\\mathrm{M}"],
    ["mu", "\\mu"],
    ["Nu", "\\mathrm{N}"],
    ["nu", "\\nu"],
    ["Xi", "\\Xi"],
    ["xi", "\\xi"],
    ["Omicron", "\\mathrm{O}"],
    ["omicron", "\\omicron"],
    ["Pi", "\\Pi"],
    ["pi", "\\pi"],
    ["Rho", "\\mathrm{P}"],
    ["rho", "\\rho"],
    ["varrho", "\\varrho"],
    ["Sigma", "\\Sigma"],
    ["sigma", "\\sigma"],
    ["Tau", "\\mathrm{T}"],
    ["tau", "\\tau"],
    ["Upsilon", "\\mathrm{Y}"],
    ["upsilon", "\\upsilon"],
    ["Phi", "\\Phi"],
    ["phi", "\\phi"],
    ["Chi", "\\mathrm{X}"],
    ["chi", "\\chi"],
    ["Psi", "\\Psi"],
    ["psi", "\\psi"],
    ["Omega", "\\Omega"],
    ["omega", "\\omega"],
    ["inf", "\\infty"],
    ["Inf", "\\infty"],
    ["infinity", "\\infty"],
    ["Infinity", "\\infty"],
    ["degree", "\\degree"],
    ["degreeC", "\\degree\\!C"],
    ["degreeF", "\\degree\\!F"],
    ["$", "\\$"],
]);

/** ===================== JURGENS MODULES COPY ================== */
// TODO ask Henry

/**
 * Returns an evaluated expression by using the parameters and variable key/values.
 * @param expression
 * @param parameters
 * @param variables
 * @param ignoreRounding
 */
export function evaluateExpression(
    expression: string,
    parameters: ITuple = {},
    variables: ITuple = {},
    ignoreRounding?: boolean,
): string {
    // console.log(`Evaluating ${expression}`);

    if (!expression) {
        return expression;
    }

    const varNames = Object.keys(variables);

    let _p = expression;
    const innerP = JSON.parse(JSON.stringify(parameters));
    const nonEvalSections = _p.split("}").map((val) => val.split("{")[0]);
    nonEvalSections.reverse();
    // an array of strings we need to put back in {} later so we can use the strings in funcs like f_sum etc
    const inProgressEvals = [];
    let nonEvalIndex = 1;
    const stringSections = getAllRegexMatches(_p, /"[^"]+"/g).map((val) => val[0]);
    let i = 0;
    // find all {xxx} pattern and eval them
    while (_p.indexOf("}") !== -1 && _p.indexOf("{") !== -1 && equalBracketCount(_p)) {
        // run as long as a } is still in the string
        const lastB = _p.lastIndexOf("{"); // Find last { string
        const nextB = _p.substring(lastB).indexOf("}"); // Find next } after the last { string
        let evalPattern = _p.substring(lastB, lastB + nextB + 1); // Find a substring between { and }

        let evalNum: any;
        try {
            // removes curlys around exp as mathjs does not recognise them
            const noEval = evalPattern.replace("{", "").replace("}", "");
            // doubles up excape chars so mathjs doesnt break
            const doubleEsc = noEval.replace(/\\(.)/g, "\\\\$1");
            let tree = parse(doubleEsc);

            if (/f_lim|f_leftLim|f_rightLim/.test(_p)) {
                tree = parse(_p).find((node) => node.deepEquals(tree));
            }

            evalNum = tree.solve(innerP).eval(innerP);

            if (evalNum === "undefined") {
                return evalNum;
            }
        } catch (e) {
            // only if the evalpattern is inside "...
            const inString = stringSections.filter((val) => val.includes(evalPattern)).length > 0;
            if (inString) {
                let tree = parse(evalPattern.replace("{", "").replace("}", ""));
                for (const node of tree.getBFS()) {
                    const paramVal = innerP[node.toString()];
                    if (node.type === "SymbolNode" && paramVal) {
                        tree = node.replaceInTree(parse(paramVal));
                    }
                }
                inProgressEvals.push(tree.toString());
                evalNum = tree.toString();
            } else {
                evalNum = "error";
            }
        }

        if (!ignoreRounding && evalNum !== undefined) {
            if (
                !isNaN(+evalNum) && // if a number
                !precisionFunctions.find((func) => nonEvalSections[nonEvalIndex]?.includes(func)) &&
                !precisionFunctions.find((func) => evalPattern.includes(func)) &&
                !evalPattern.includes("round")
            ) {
                try {
                    // the max number of digits after a "." in the tree
                    const precision = Math.max(
                        getMaxPrecision(evalPattern.toString().replace("{", "").replace("}", ""), 0),
                        getMaxPrecision(
                            parse(evalPattern.toString()).solve(parameters).eval(parameters)?.toString() || 0,
                            0,
                        ),
                    );

                    // now round this number to 3 dp
                    evalNum = fixDecimalPlaces(evalNum, precision);
                } catch (e) {
                    console.error(
                        `ERROR: ${e} with string ${evalPattern
                            .toString()
                            .replace("{", "")
                            .replace("}", "")} and evaluated: ${evalNum}`,
                    );
                }
            } else if (!precisionFunctions.find((func) => evalPattern.includes(func))) {
                evalNum = limitDigits(evalNum);
            }
        }
        evalNum = cleanFloatingPoint(evalNum);
        evalNum = fixNegativeZero(evalNum);

        _p = _p.replace(evalPattern, `${evalNum}`); // Eval substring and replace it
        nonEvalIndex++;
    }

    for (const str of getAllRegexMatches(_p, /"[^"]+"/g)) {
        inProgressEvals.forEach((pattern, index) => {
            if (str[0].includes(pattern)) {
                inProgressEvals.splice(index, 1);
                _p = _p.replace(str[0], str[0].replace(pattern, `{${pattern}}`));
            }
        });
    }

    return _p;
}

/**
 * Calculates the maximum precision between Constant Nodes in the expression.
 * The maximum precision is 3.
 * The default minimum return value is 3.
 *
 * ```
 * getMaxPrecision(2.12) -> 3
 * getMaxPrecision(2.123) -> 3
 * getMaxPrecision(2.12, 0) -> 2
 * getMaxPrecision("2.12 / 1", 0) -> 2
 * ```
 *
 * @export
 * @param {string} noEvalExp
 * @param {number} [min] Optional argument for the minimum return value
 * @return {*}  {number}
 */
export function getMaxPrecision(noEvalExp: string, min: number = 3, max: number = 3): number {
    let maxPrecision = 0;
    const maxAllowed = max;
    if (typeof noEvalExp !== "string") {
        noEvalExp = new String(noEvalExp).toString();
    }
    try {
        // we need to iterate over the exp parsed.
        for (const node of parse(noEvalExp).getNodesByType("ConstantNode")) {
            // // if its in the form x.xxxxxxxxe+xx we should just count that as max precision as 14
            // // we should never hit this case as it would mean the content creator has entered a crazy large number
            // if (node.name.includes("e") && node.name !== "e") {
            //     maxPrecision = maxAllowed;
            // } else
            if ((node as ConstantNode).getDecimalPlaces() > maxPrecision) {
                maxPrecision = (node as ConstantNode).getDecimalPlaces();
            }
        }

        if (maxPrecision < min) {
            maxPrecision = min;
        } else if (maxPrecision > maxAllowed) {
            maxPrecision = maxAllowed;
        }
    } catch (e) {
        // Parse cannot handle expression.
        maxPrecision = 0;
    }
    return maxPrecision;
}

/**
 * Returns all matches for a given regex and string as an array
 * @param {string} str
 * @param {RegExp} regex
 * @param {RegExpExecArray[]} [matches=[]]
 * @returns
 */
export function getAllRegexMatches(str: string, regex: RegExp, matches: RegExpExecArray[] = []): RegExpExecArray[] {
    const res = regex.exec(str);
    if (res) {
        matches.push(res);
        getAllRegexMatches(str, regex, matches);
    }
    return matches;
}

/**
 * Converts any expression into a latex expression.
 *
 * @param {string} expressionString
 * @returns {string}
 */
export function toTex(expressionString: string, variables?: string[]): string {
    try {
        return parse(expressionString, variables, "USEIMPLICIT").toTex();
    } catch (e) {
        console.error("toTex", {
            message2: `Error parsing ${expressionString}`,
            errorMessage: e?.message,
            stack: e?.stack,
        });

        return "error";
    }
}

/**
 * Stores two steps     as trees, non-pruned
 * @export
 * @class PSteps
 */
export class PSteps {
    source: Node;
    target: Node;

    constructor(source: Node, target: Node) {
        this.source = source;
        this.target = target;
    }
}

export interface ITuple {
    [key: string]: number | string;
}

/**
 * Recursively adds params to every possible string in a given JSON tree eg:
 * addParamsToJSONObject({data: "{a}x"}, {a: 1})
 *  => {data: "1x"}
 *
 * @param {*} jsonObject
 * @param {*} params
 */
export function convertLabelsToLatex(jsonObject: any, params: ITuple, localParams: ITuple, variables: string[]): any {
    // iterate over roots kids
    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            let val = jsonObject[key];
            // if the kid is an object, parse it
            if (typeof val === "object") {
                val = convertLabelsToLatex(val, params, localParams, variables);
            }
            // if the kid is a string, parse it
            else if (typeof val === "string") {
                // console.log(`Attempting to parse ${val}`);
                // convert the string to a parsed instruction with params anad latex replaced
                const strWParams = evaluateExpression(val, params);
                val = `${toTex(strWParams, variables)}`;
            }
            // if the kid is anythign else, dont edit it
            else {
                continue;
            }
            jsonObject[key] = val;
        }
    }

    return jsonObject;
}

export function extractFstringFromArch(steps: string[]): string[][] {
    const fstringContents: string[][] = [];

    // iterate over steps, looking for f_stringOption
    for (const step of steps) {
        // parse the step
        // console.log(`Attempting to parse step for f_string:`, step);
        const tree = parse(step, null, "USEIMPLICIT");

        // now get all f_string out of the step
        const fstringNodes = tree.getNodesByName("f_stringOption");

        // for each of these, add their params as strings to the f_stringContents
        for (const node of fstringNodes) {
            fstringContents.push(node.args.map((node) => node.toString()));
        }
    }

    return fstringContents;
}

export class PDiff {
    @Exclude()
    get source(): Node {
        if (!this._sourceCache) {
            this._sourceCache = parse(this.sourceString);
        }
        return this._sourceCache;
    }

    @Exclude()
    get target(): Node {
        if (!this._targetCache) {
            this._targetCache = parse(this.targetString);
        }
        return this._targetCache;
    }

    @Exclude()
    private _sourceCache: Node;
    @Exclude()
    private _targetCache: Node;

    sourceString: string = null;
    targetString: string = null;

    constructor(source: Node, target: Node) {
        this.sourceString = source?.toString() || "";
        this.targetString = target?.toString() || "";
    }

    toString(): string {
        return `${this.source.toString()} -> ${this.target.toString()}`;
    }
}

export interface TreePair {
    source: Node;
    target: Node;
}

export function isComplexExpression(exp: string, parameters: ITuple, vars: string[]): boolean {
    const tree = parse(exp);
    for (const node of tree.getBFS()) {
        if (node.name === "i" && !vars.includes(node.name)) {
            return true;
        }
    }
    try {
        const iCount: number = tree.getNodesByName("i").length;
        const evalExp = evaluateExpression(exp, parameters);
        const postEvaliCount = parse(evalExp).getNodesByName("i").length;
        if (postEvaliCount > iCount) {
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.error("isComplexExpression() failed, make sure you've supplied params and vars, error: ", e.message);
        return true;
    }
}

export enum flags {
    bracketDuplicate = "bracketDuplicate", //   This line adds / removes brackets and follows a line that adds / removes brackets
    noLatexChange = "noLatexChange", //         This line makes a change inside curlys which doesnt affect the curlys output
    ghostBrackets = "ghostBrackets", //         This line adds / removes ghost brackets outside curlys, therefore student sees no change
}

export class LineOptions {
    @Type(() => POption)
    line: Map<string, POption> = new Map();

    // array of flags specific to this line, Amy can then decide based on these flags and the students level if this line should be skipped
    lineFlags: flags[] = [];

    updateFlags(arch: PArchetype, position: number): void {
        this.lineFlags = getStepsFlags(arch, position, this.lineFlags);
    }

    getCorrectOption(): POption {
        for (const key of Array.from(this.line.keys())) {
            const bubble: POption = this.line.get(key);
            if (!bubble.isMistake) {
                return bubble;
            }
        }
        return undefined;
    }

    set(key: string, value: POption): void {
        this.line.set(key, value);
    }

    get(key: string): POption {
        return this.line.get(key);
    }

    has(key: string) {
        return this.line.has(key);
    }
}

export class POption {
    expression: string;

    isMistake: boolean = false;
    // the priority of the mistake (the higher, the more relevent it will be to the user, and thus will be shown more often)
    priority: number = 0;
    violatedArchId: string = null;
    transitionArchId: string = null;
    mistakeFlag: mistakeFlag = null;

    mistakeId: string = null;

    constructor(expression: string, isMistake: boolean = false, _mistakeFlag: mistakeFlag = null) {
        this.expression = expression;
        this.isMistake = isMistake;

        if (_mistakeFlag) {
            this.mistakeFlag = _mistakeFlag;
            // sets the priority based on the one set in the mistakePriorities enum in nonDiffMistakes.ts

            if (mistakePriorities.get(_mistakeFlag)) {
                this.priority = mistakePriorities.get(_mistakeFlag);
            } else {
                // if its the corect option we give it a fake priority so its ordered randomly
                this.priority = getRandomInt(1, 9);
            }
        }
    }

    toString(param?: ITuple): string {
        if (param) {
            return `${this.isMistake ? "❌" : "✅"}  ${evaluateExpression(this.expression, param)} (${
                this.isMistake ? `${this.transitionArchId} + ${this.violatedArchId}` : this.transitionArchId
            })`;
        } else {
            return `${this.isMistake ? "❌" : "✅"}  ${this.expression} (${
                this.isMistake ? `${this.transitionArchId} + ${this.violatedArchId}` : this.transitionArchId
            })`;
        }
    }

    hash(): string {
        return ohash(`${this.isMistake}_${this.expression.replace(/ /g, "")}`);
    }
}

export class PArchetype {
    id: string;
    urArchetypeId: string;
    protoArchetypeId: string;
    ghostArchetype: boolean = false;
    masteryCoefficient: number = null;
    lockedTransitions: string[] = []; // for each step we can define a locked transition which is used instead of found transition since found transition are often not correct

    /**
     *
     *
     * @type {boolean}
     * @memberof PArchetype
     * @deprecated [This is handled by the I18N elements]
     */
    useDivisionSymbol: boolean = false;

    ignoreFirstStep: boolean = false;

    /**
     * @type {string}
     * @memberof PArchetype
     * @deprecated [This is handled by the I18N elements]
     */
    Human_Instructions: string = "";

    /**
     *
     * @type {string}
     * @memberof PArchetype
     */

    instructions: string[] = [];
    parameters: ITuple[] = [];
    variables: ITuple[] = [];
    wordQuestionIds: string[] = [];
    lockedVariables: string[] = [];

    @Expose()
    get transIDs(): string[] {
        return Array.from(this.transitions.values());
    }

    @Type(() => String)
    transitions: Map<string, string> = new Map(); // the string object represents the Archetype-Id

    @Type(() => String)
    protoArchetypes: Map<string, string> = new Map(); // the string object represents the Archetype-Id

    @Type(() => String)
    mistakes: Map<string, string[]> = new Map(); // the string object represents the Mistake-Id
    @Type(() => PDiff)
    diffs: Map<string, PDiff> = new Map();

    @Type(() => DiffStats)
    diffsWithStats: Map<string, DiffStats> = new Map();

    @Type(() => DiffStats)
    firstLastDiffStats: DiffStats;

    @Transform(({ key, value, obj, type }) => {
        if (type === 0) {
            const outerMap = new Map();
            for (const outerKey of Object.keys(value)) {
                const innterMap = new Map();

                for (const innerKey of Object.keys(value[outerKey])) {
                    innterMap.set(innerKey, value[outerKey][innerKey]);
                }

                outerMap.set(outerKey, innterMap);
            }
            return outerMap;
        }

        if (type === 1) {
            const outerJson = {};
            for (const [a, b] of value) {
                outerJson[a] = {};
                for (const [c, d] of b) {
                    outerJson[a][c] = d;
                }
            }

            return outerJson;
        }
    })
    transitionScores: Map<string, Map<string, TransitionStats>> = new Map();

    @Transform(({ key, value, obj, type }) => {
        if (type === 0) {
            const outerMap = new Map();
            for (const outerKey of Object.keys(value)) {
                const innterMap = new Map();

                for (const innerKey of Object.keys(value[outerKey])) {
                    innterMap.set(innerKey, value[outerKey][innerKey]);
                }

                outerMap.set(outerKey, innterMap);
            }
            return outerMap;
        }

        if (type === 1) {
            const outerJson = {};
            for (const [a, b] of value) {
                outerJson[a] = {};
                for (const [c, d] of b) {
                    outerJson[a][c] = d;
                }
            }

            return outerJson;
        }
    })
    diffMaps: Map<string, Map<string, string>> = new Map();

    // this is done but the getFirstLastDiff methode
    // @Type(() => PDiff)
    // firstLastDiff: PDiff;

    @Type(() => String)
    parametricSolutions: Map<string, string> = new Map(); // Represents the data of a green bubble
    @Type(() => String)
    parametricSolutionsOriginal: Map<string, string> = new Map(); // Represents the data of a step before it is changed
    @Type(() => LineOptions)
    options: Map<string, LineOptions> = new Map();

    addParameter(parameter: ITuple): void {
        this.parameters.push(parameter);
    }

    addParameters(parameters: ITuple[]): void {
        parameters.forEach((value) => this.addParameter(value));
    }

    addOption(position: number, option: POption): void {
        // create initial array at position position
        if (!this.options.get(String(position))) {
            this.options.set(String(position), new LineOptions());
        }

        // check if added option doesn't overwrite correct options
        const o = this.options.get(String(position)).get(option.hash());
        if (!o) {
            this.options.get(String(position)).set(option.hash(), option);
        } else {
            if (o.isMistake) {
                this.options.get(String(position)).set(option.hash(), option);
            }
        }

        // update the flags with the diffs
        this.options.get(String(position)).updateFlags(this, position);
    }

    addOptions(position: number, options: POption[]): void {
        options.forEach((value) => this.addOption(position, value));
    }

    getFirstLastDiff(): PDiff {
        return this.diffs.get(this.getFirstLastDiffKey());
    }

    getFirstLastDiffKey(): string {
        return new PFromTo(0, this.parametricSolutions.size - 1).hash();
    }

    /**
     * Returns the first question Options
     */
    getFirstQuestion(): POption {
        return this.options.get("0").line.values().next().value;
    }

    /**
     * Returns an options map of a given line number
     * @param lineNumber line number of the exercise
     */
    getLine(lineNumber: number): Map<string, POption> {
        if (!this.options.get(String(lineNumber))) {
            return new Map<string, POption>();
        }
        return this.options.get(String(lineNumber)).line;
    }

    setLine(lineNumber: number, lineMap: Map<string, POption>): void {
        this.options.get(String(lineNumber)).line = lineMap;
    }

    getCorrectOption(lineNumber: number): { hash: string; line: POption } {
        let _ret: { hash: string; line: POption };
        this.getLine(lineNumber).forEach((option, hashKey) => {
            if (!option.isMistake) {
                _ret = { hash: hashKey, line: option };
            }
        });
        return _ret;
    }

    getMistakeOptions(lineNumber: number): Map<string, POption> {
        const _ret: Map<string, POption> = new Map();
        this.getLine(lineNumber).forEach((option, hashKey) => {
            if (option.isMistake) {
                _ret.set(hashKey, option);
            }
        });
        return _ret;
    }

    /**
     * Check if an options in a line is a mistake or not
     * @param lineNumber line number of the exercise
     * @param optionHash hash of the options to which we want to control
     */
    isMistake(lineNumber: number, optionHash: string): boolean {
        if (!this.options.get(String(lineNumber))) {
            return true;
        }

        if (!this.getLine(lineNumber).get(optionHash)) {
            return true;
        }

        return this.getLine(lineNumber).get(optionHash).isMistake;
    }

    /**
     * Prints the parametric form, or a applied example if a parameter number is given.
     * @param paramPos the number of parameters which should be chosen. If non is given, the parametric form is printed
     */
    print(paramPos?: number): void {
        console.log(`Print arch: ${this.id}`);
        this.options.forEach((stepOptions, key) => {
            let line: string = "";
            stepOptions.line.forEach((option) => {
                if (!isNaN(paramPos)) {
                    let _param: ITuple = {};
                    if (this.parameters[paramPos]) {
                        _param = this.parameters[paramPos];
                    }
                    line = `${line} ... ${option.toString(_param)}`;
                } else {
                    line = `${line} ... ${option.toString()}`;
                }
            });
            console.log(key, line);
        });
    }
}

export class PFromTo {
    from: number;
    to: number;
    constructor(from: number, to: number) {
        this.from = from;
        this.to = to;
    }

    hash(): string {
        return `${this.from}->${this.to}`.replace(/ /g, "");
    }
}

export interface TransitionStats {
    transScoreCard: TransitionScoreCard;
    paramScore: number | string;
    transScore: number | string;
}

export interface TransitionScoreCard {
    params: number;
    topNodeDifference: number;
    prunedParent: number;
    subtreeDifference: number;
    fBoxPresence: number;
    variableCount: number;
    archLength: number;
    prunedAmount: number;
    nonMatchingOperators: number;
}

export class Limit {
    name: string;
    values: string[];
    type: string;
    maxPrecision: number = 0;
}

export class PMistake {
    id: string;
    source: string;
    target: string;
    mistake: string;
    archetypesViolatedIds: string[] = [];
    constructor(source: string, target: string, mistake: string) {
        this.source = source;
        this.target = target;
        this.mistake = mistake;
    }
}

export function getNodeIndex(nodes: Node[], node: Node): number {
    if (node && nodes) {
        const nodeStrings: string[] = nodes.map((v) => v.toString());
        const nodeString: string = node.toString();
        return nodeStrings.indexOf(nodeString);
    }
    return -1;
}

/**
 * Takes a map of Z1 -> subtree where subtree is a BFS and returns a map of Z1 -> subtree where subtree is a string representation
 * We cover the case where a node in the tree isnt in the subtree and prune it before toString
 * @param {Map<string, Node[]>} diffMap
 * @returns {Map<string, string>}
 */
export function simplifyDiffMap(diffMap: Map<string, Node[]>): Map<string, string> {
    if (diffMap === undefined) return undefined;
    if (diffMap === null) return null;

    const newMap: Map<string, string> = new Map<string, string>();

    diffMap.forEach((bfs, key) => {
        const tree = bfs[0];

        if (tree.isAssociative() && tree.args.some((node) => !bfs.includes(node))) {
            // here the subtree nodes we are simplifying might be replaced by Z1,
            // we search the bfs for any nodes which are unlinked
            const allArgs = bfs.flatMap((node) => node.args); // all args referenced in bfs
            const unLinkedNodes = bfs.slice(1).filter((node) => !allArgs.includes(node)); // any nodes in bfs, but not references as an arg (ignore root)

            const output = unLinkedNodes.map((node) => node.toString()).join(tree.name);
            newMap.set(key, output);
        } else {
            newMap.set(key, tree.toString());
        }
    });

    return newMap;
}
