import * as mathjs from "mathjs";
import * as pluralize from "pluralize";
import { basicToTexFunctions } from "./basicFunctionsTex";
import { customFunctionsEval } from "./customFunctionsEval";
import { customFunctionsLaTeX, defaultLatex } from "./customFunctionsLatex";
import { customFunctionsText, customFunctionsTextToTex } from "./customFunctionsText";
import { customMistakeFunctions, customMistakeToTexFunctions } from "./customMistakeFunctions";

/**
 * This is used in 2 places
 *  1. Rename functions in `customFunctions.ts` so we can use the regular naming for latex and eval even though we only override eval
 *  2. In `Node.ts` to replace nodes in tree with their overridden functions so mathjs doesnt freak out
 */
export const nodeOverrides = new Map([
    ["^", "f_exp"],
    ["det", "f_det"],
    ["sin", "f_overwriteSin"],
    ["cos", "f_overwriteCos"],
    ["tan", "f_overwriteTan"],
    ["sec", "f_overwriteSec"],
    ["csc", "f_overwriteCsc"],
    ["cot", "f_overwriteCot"],
    ["asin", "f_overwriteAsin"],
    ["acos", "f_overwriteAcos"],
    ["atan", "f_overwriteAtan"],
    ["asec", "f_overwriteAsec"],
    ["acsc", "f_overwriteAcsc"],
    ["acot", "f_overwriteAcot"],
    ["log", "f_overwriteLog"],
    ["round", "f_overwriteRound"],
]);

// from customMathJSFunctions; doesn't seem to be used
export const implicitFuncs: string[] = ["cos", "tan", "sin", "sqrt", "log", "ln", "f_div"];

// ================================== Helper Functions ================================== //

// from customMathJSFunctions; doesn't seem to be used
export function getSingularUnit(unit: string): string {
    try {
        let unitName: string = mathjs.eval(unit).units.name;
        try {
            unitName = mathjs.eval(pluralize.singular(unit)).toString();
        } catch {
            unitName = unit;
        }
        return unitName;
    } catch (e) {
        return unit;
    }
}

export function decomposeFraction(aNum: number, bNum: number, flag: string = ""): string {
    // node.args[0] is a
    // node.args[1] is b
    // node.args[2] is the optional parameter 'strike' or 'divide'
    // const aStr: string = node.args[0].toTex(options);
    // const bStr: string = node.args[1].toTex(options);
    // const regex = /\s/g;
    // const aNum: number = +aStr.replace(regex, "");
    // const bNum: number = +bStr.replace(regex, "");

    let strikeThrough: boolean = false;
    let divide: boolean = false;
    if (flag === `"strike"`) {
        strikeThrough = true;
    } else if (flag === `"divide"`) {
        divide = true;
    } else if (flag === `"showOne"`) {
        // note that showOne is now default. this option is kept to backward compat
    } else if (flag === `"showOneStrike"`) {
        // note that showOne is now default
        strikeThrough = true;
    } else if (flag !== "") {
        return "error";
    }

    if (isNaN(aNum) || isNaN(bNum)) {
        return "error";
    }

    const gcd = mathjs.gcd(aNum, bNum);
    let neg: string = "";
    let numer: number = aNum / gcd;
    let denom: number = bNum / gcd;
    if (aNum < 0 !== bNum < 0) {
        // XOR
        neg = "-";
        numer = Math.abs(aNum / gcd);
        denom = Math.abs(bNum / gcd);
    }
    if (divide) {
        if (gcd === 1) {
            return `\\frac{${neg}${Math.abs(aNum)}}{${Math.abs(bNum)}}`;
        } else if (neg === "-") {
            return `\\frac{${neg}${Math.abs(aNum)} \\div ${gcd}}{${Math.abs(bNum)} \\div ${gcd}}`;
        } else {
            return `\\frac{${aNum} \\div ${gcd}}{${bNum} \\div ${gcd}}`;
        }
    }

    const numerArr = [numer];
    const denomArr = [denom];
    if (gcd !== 1) {
        numerArr.push(gcd);
        denomArr.push(gcd);
    }
    const sortFunc = (a: number, b: number) => a - b;
    const sortedNumerArr = numerArr.sort(sortFunc);
    const sortedDenomArr = denomArr.sort(sortFunc);

    const strikeL = strikeThrough ? "\\cancel{" : "";
    const strikeR = strikeThrough ? "}" : "";

    let striken = false; // only strike the *first* gcd number (there may be multiple)
    const numerStringArr = sortedNumerArr.map((e) => {
        if (e === gcd && !striken) {
            striken = true;
            return `${strikeL}${e}${strikeR}`;
        } else {
            return String(e);
        }
    });
    striken = false;
    const denomStringArr = sortedDenomArr.map((e) => {
        if (e === gcd && !striken) {
            striken = true;
            return `${strikeL}${e}${strikeR}`;
        } else {
            return String(e);
        }
    });

    let numerString = numerStringArr[0];
    let denomString = denomStringArr[0];

    if (numerStringArr.length > 1) {
        numerString = `${numerStringArr[0]} {\\times} ${numerStringArr[1]}`;
    }
    if (denomStringArr.length > 1) {
        denomString = `${denomStringArr[0]} {\\times} ${denomStringArr[1]}`;
    }

    return `\\frac{${neg}${numerString}}{${denomString}}`;
}

function customUnits(math: any): void {
    math.createUnit("millionths", {
        aliases: ["millionth", "millionths"],
    });
    math.createUnit("hundredThousandths", {
        definition: "10 millionths",
        aliases: ["hundredthousandth", "hundredthousandths"],
    });
    math.createUnit("tenthousandths", {
        definition: "10 hundredthousandths",
        aliases: ["tenThousandths", "tenthousandth"],
    });
    math.createUnit("thousandths", {
        definition: "10 tenthousandths",
        aliases: ["thousandth", "thousandths"],
    });
    math.createUnit("hundredths", {
        definition: "10 thousandths",
        aliases: ["hundredth", "hundredths"],
    });
    math.createUnit("tenths", {
        definition: "10 hundredths",
        aliases: ["tenth", "tenths"],
    });
    math.createUnit("one", {
        definition: "10 tenths",
        aliases: ["one", "amyOnes"],
    });

    math.createUnit("tens", {
        definition: "10 one",
        aliases: ["ten", "tens"],
    });
    math.createUnit("hundreds", {
        definition: "10 tens",
        aliases: ["hundred", "hundreds"],
    });
    math.createUnit("thousands", {
        definition: "10 hundreds",
        aliases: ["thousand", "thousands"],
    });
    math.createUnit("tenthousands", {
        definition: "10 thousands",
        aliases: ["tenThousands", "tenthousand"],
    });
    math.createUnit("hundredthousands", {
        definition: "10 tenthousands",
        aliases: ["hundredThousand", "hundredthousands"],
    });
    math.createUnit("millions", {
        definition: "10 hundredthousands",
        aliases: ["million", "millions"],
    });

    math.createUnit("cent", {
        aliases: ["cents", "cent"],
    });
    math.createUnit("dollar", {
        definition: "100 cents",
        aliases: ["dollars", "dollar"],
    });
}

////////////////////////  Exporting Functions ////////////////////////////////

function getToTexMap() {
    const toTexMap = new Map<string, (...args: any[]) => string>();

    const allFunctions = {
        ...customFunctionsEval,
        ...customMistakeFunctions,
        ...customFunctionsText,
    };

    const allToTexFunctions = {
        ...customFunctionsLaTeX,
        ...basicToTexFunctions,
        ...customMistakeToTexFunctions,
        ...customFunctionsTextToTex,
    };

    const allFuncNames = Array.from(new Set([...Object.keys(allFunctions), ...Object.keys(allToTexFunctions)]));

    for (const funcName of allFuncNames) {
        const latexFunc = allToTexFunctions[funcName];
        if (latexFunc) {
            toTexMap.set(funcName, latexFunc);
        } else {
            toTexMap.set(funcName, defaultLatex);
        }
    }
    return toTexMap;
}

// from customMathJSFunctions:
function importEval(math: any): void {
    // rename any overridden functions
    const customFuncsWOverrides = {};
    for (const [funcName, func] of Object.entries(customFunctionsEval)) {
        const newName = nodeOverrides.get(funcName) || funcName;
        customFuncsWOverrides[newName] = func;
    }

    try {
        try {
            math.import(customFuncsWOverrides, { override: true, silent: true });
        } catch (e) {
            console.error(`MathJS Custom Functions importer failed: ${e.message}`);
        }
        try {
            math.import(customMistakeFunctions);
        } catch (e) {
            console.error(`MathJS Custom Functions importer failed: ${e.message}`);
        }
        try {
            math.import(customFunctionsText);
        } catch (e) {
            console.error(`MathJS Custom Functions importer failed: ${e.message}`);
        }
        customUnits(math);
    } catch (e) {
        console.error(`MathJS Custom Functions importer failed: ${e.message}`);
        // console.log("this should only happen when running a test framework in watch mode");
    }
}

importEval(mathjs);
const toTexMap = getToTexMap();
export { mathjs, toTexMap };
