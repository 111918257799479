import { shuffleArray } from "@jaipuna/common-modules/dist/src/GeneralUtils";
import { divide } from "cycle-division";
import * as mathjs from "mathjs";
import * as numToWord from "number-to-words";
import * as pluralize from "pluralize";
import { evaluateExpression } from "../modules"; // probably dont want this
import { Node } from "../parser/Node";
import { parse } from "../parser/parse";
import { fixDecimalPlaces, stripQuotes } from "../utilities";
import { decomposeFraction } from "./customFunctions";

// the default latex we use for functions with no latex
export const defaultLatex = function (node: Node, options: any) {
    const args = node.args.map((arg) => arg.toTex(options)).join(", ");
    if (node.name.length === 1) {
        return `${node.name}\\left(${args}\\right)`;
    }
    return `\\mathrm{${node.name}}\\left(${args}\\right)`;
};

/**
 * Decides if the parameters of a math function should be between brackets
 *
 * @param {string} childTex
 * @param {string} funcName
 * @return {*}
 */
function handleBracketsLatex(child: Node, funcName: string, options?) {
    const childTex = child.toTex(options);
    let cloneChild = child.cloneDeep();

    // Removes power elements, as they don't matter for adding brackets
    const powerNodes = cloneChild.getNodesByName("^");
    powerNodes.map((node) => {
        node.removeArg(node.args[1]);
        cloneChild = node.replaceInTree(node.args[0], cloneChild);
    });

    // Match any amount of \left( at the start of the string followed by \left|
    // And ending with \right| followed by any amount of \right)
    const regex = new RegExp(/^(\\left\()*(\\left\|).*?(\\right\|(\\right\))*)$/);
    const noSpaceChild = cloneChild.toTex(options).replace(/" "/g, "");

    const needsBracketsLatex = [
        "\\times",
        "+",
        "-",
        "\\div",
        "\\dot",
        "cis",
        "sin",
        "asin",
        "cos",
        "acos",
        "cotan",
        "cosec",
        "tan",
        "atan",
        "f_div",
        "f_latex",
        "f_conj",
        "log",
        "ln",
    ];

    if (needsBracketsLatex.some((val) => noSpaceChild.includes(val)) && !regex.test(noSpaceChild)) {
        return `${funcName} \\left( ${childTex}\\right)`;
    } else {
        return `${funcName}~${childTex}`;
    }
}

export const customFunctionsLaTeX: any = {
    f_div: function (node: Node, options: any): string {
        return `${node.args[0].toTex(options)} \{\\div\} ${node.args[1].toTex(options)}`;
    },

    f_percent: function (node: Node, options: any): string {
        return `${node.args[0].toTex(options)} \\%`;
    },

    f_mixedNum: function (node: Node, options: any): string {
        return `${node.args[0].toTex(options)} ${node.args[1].toTex(options)}`;
    },

    f_bArray: function (node: Node, options: any): string {
        const array = node.args[0].args;
        if (array.length < 1) return "error";

        let ret_str: string = "\\begin{Bmatrix} ";
        for (const param of array) {
            ret_str += `${param.toTex(options)}, `;
        }
        return `${ret_str.slice(0, ret_str.length - 2)} \\end{Bmatrix}`;
    },

    f_pArray: function (node: Node, options: any): string {
        const array = node.args[0].args;
        if (array.length < 1) return "error";

        let ret_str: string = "\\begin{pmatrix} ";
        for (const param of array) {
            ret_str += `${param.toTex(options)}, `;
        }
        return `${ret_str.slice(0, ret_str.length - 2)} \\end{pmatrix}`;
    },

    f_Array: function (node: Node, options: any): string {
        // console.log("f_Array latex");
        // console.log("node.args[0]", node.args[0]);
        // console.log("node.args[0].toTex(options)", node.args[0].toTex(options));
        // return "456";
        const array: string[] = [];
        for (const arg of node.args[0].args) {
            array.push(arg.toTex(options));
            // console.log("arg.toTex(options)", arg.toTex(options));
        }
        // for (const arg of node.args) {
        //     console.log("arg", arg);
        //     array.push(arg.toTex(options));
        //     console.log("arg.toTex(options)", arg.toTex(options));
        // }
        // console.log("array", array);
        return `\\begin{matrix} ${array.join(",~ ")} \\end{matrix}`;
    },

    f_sArray: function (node: Node, options: any): string {
        const array = node.args[0].args;
        if (array.length < 1) return "error";

        let ret_str: string = "\\begin{bmatrix} ";
        for (const param of array) {
            ret_str += `${param.toTex(options)}, `;
        }
        return `${ret_str.slice(0, ret_str.length - 2)} \\end{bmatrix}`;
    },

    f_gArray: function (node: Node, options: any): string {
        const array = node.args[0].args;
        if (array.length < 1) return "error";

        let ret_str: string = "";
        for (const param of array) {
            ret_str += `${param.toTex(options)}`;
        }
        return ret_str;
    },

    f_sort: function (node: Node, _options: any): string {
        // f_sort([...], ?option1, ?option2)
        const option1 = node.args[1]?.eval().toString() || ""; // true or false for ascending or descending
        const option2 = node.args[2]?.eval().toString() || ""; // bracket type

        const bracketTypes = [
            { type: "none", latex: "matrix" }, // no brackets
            { type: "square", latex: "bmatrix" }, // "[...]"
            { type: "curly", latex: "Bmatrix" }, // "{...}"
            { type: "parens", latex: "pmatrix" }, // "(...)"
            { type: "ghost", latex: "matrix" }, // this is different. concat all items together
        ];

        const ascending = ["true", "false"].includes(option1) ? option1 === "true" : true;
        let bracketType = bracketTypes.find((type) => type.type === "none"); // none, square, curly, ghost

        const bracketTypeNames = bracketTypes.map((type) => type.type);
        if (option2 !== "" && bracketTypeNames.includes(option2)) {
            bracketType = bracketTypes.find((type) => type.type === option2);
        } else if (bracketTypeNames.includes(option1)) {
            bracketType = bracketTypes.find((type) => type.type === option1);
        }

        let array: any[] = node.args[0].args.map((arg) => {
            let num = +parse(`{${parse(`{${arg.toString().replace(/\$/g, "")}}`).solve()}}`)
                .solve()
                .toString();
            num = isNaN(num) ? parse(arg.toString().replace(/\$/g, "")).eval() : num;
            return {
                original: arg,
                evaled: num,
            };
        });
        array = array.sort((a, b) => {
            return a.evaled - b.evaled;
        });
        if (ascending === false) {
            array = array.reverse();
        }
        if (bracketType.type === "ghost") {
            return array.map((val) => val.original.toTex()).join("");
        }
        const output = array.map((val) => val.original.toTex()).join(",~ ");
        return `\\begin{${bracketType.latex}} ${output} \\end{${bracketType.latex}}`;
    },

    f_dotplotHeights: function (node: Node, _options: any): string {
        const separation: number = node.args[1] ? Number(node.args[1].eval()) : 1;
        if (separation < 0) {
            throw new Error("separation must be nonnegative");
        }
        const offset: number = node.args[2] ? Number(node.args[2].eval()) : 1;
        const xValues: number[] = node.args[0].args.map((x) => Number(x.eval()));

        const nums: number[] = [];
        const values = new Map();
        for (const x of xValues) {
            values.has(x) ? values.set(x, values.get(x) + separation) : values.set(x, offset);
            nums.push(values.get(x));
        }
        const output = nums
            .map((val) => {
                return parse(val.toString()).toTex(_options);
            })
            .join(",~ ");
        return `\\begin{matrix} ${output} \\end{matrix}`;
    },

    f_factorial: function (node: Node, _options: any): string {
        if (node.args[0] && node.args[0]?.name === "0") {
            return "1";
        }

        let output: string = node.args[0].toString();

        for (let index: number = parseFloat(node.args[0].name) - 1; index > 0; index--) {
            output = output + "{\\times}" + index;
        }
        return output;
    },

    f_format: function (node: Node, _options: any): string {
        const num = stripQuotes(node.args[0]);
        const modeParam = stripQuotes(node.args[1]);
        const precisionParam = stripQuotes(node.args[2]);
        const truncate = stripQuotes(node.args[3]);
        // if mode is undefined or auto ignore it
        let formattedNum: string;
        try {
            formattedNum = (mathjs as any).f_format(num, modeParam, precisionParam, truncate === "truncate", true);
        } catch {
            formattedNum = `Error`;
        }

        return formattedNum;
    },

    plusMinus: function (node: Node, options: any): string {
        return `\\pm${node.args[0].toTex(options)}`;
    },

    f_plusMinus: function (node: Node, options: any): string {
        return `${node.args[0].toTex(options)} \\pm${node.args[1].toTex(options)}`;
    },

    f_Ghost: function (node: Node, options: any): string {
        if (node.args[0]) {
            return node.args[0].toTex(options);
        } else {
            return "";
        }
    },

    f_simul: function (node: Node, options: any): string {
        const lines = node.args[0].args;
        // first we have to check for a pattern so we can line columns up
        const lineVars = lines.map((line) =>
            line
                .getIOT()
                .filter((val) => val.isVar())
                .map((node) => node.name),
        );
        const linesTexIn = lines.map((val) => val.toTex(options));

        const order = Array.from(new Set(lineVars.flat())).sort();
        const isOrdered = lineVars.every(
            (vars) =>
                vars.join(",") ===
                vars
                    .slice(0)
                    .sort((a, b) => order.indexOf(a) - order.indexOf(b))
                    .join(","),
        );

        const canBePretty = linesTexIn.every((lineTexIn) => {
            if (lineTexIn.includes("\\left(")) {
                return false;
            }

            const ops = ["+", "\\times", "-"];
            // make sure each token is either an op or has a var
            const tokens = lineTexIn.split("=")[0].split(/(\+|-|\\times)/);
            const validTokens = tokens.every(
                (val, i) => ops.includes(val) || order.some((varName) => val.includes(varName)),
            );
            return validTokens;
        });

        if (isOrdered && canBePretty) {
            const linesOutput = [];
            for (const lineTexIn of linesTexIn) {
                let lineTex: string[] = [];
                const tokens = lineTexIn.split("=")[0].split(/(\+|-|\\times)/);

                for (const varName of order) {
                    if (tokens.findIndex((val) => val.includes(varName)) === -1) {
                        lineTex.push("", "");
                        continue;
                    }
                    let tokenIndex = 0;
                    while (tokens.slice(tokenIndex).findIndex((val) => val.includes(varName)) !== -1) {
                        tokenIndex = tokenIndex + tokens.slice(tokenIndex).findIndex((val) => val.includes(varName));
                        const token = tokens.at(tokenIndex);
                        const operator = tokens[tokenIndex - 1] || "";
                        lineTex.push(operator.replace(/\s/g, ""), token.replace(/\s/g, ""));

                        if (tokenIndex < tokens.length - 1) {
                            tokenIndex++;
                        } else {
                            break;
                        }
                    }
                }
                lineTex.push("~= &~" + lineTexIn.split("=")[1]);
                linesOutput.push(lineTex.join(" & "));
            }

            return `\\begin{alignedat}{60}${linesOutput.join("\\\\[1.25em]")}\\end{alignedat}`;
        } else {
            return `\\begin{alignedat}{60} ${lines
                .map((line) => line.toTex(options).replace("=", "~= &~"))
                .join("\\\\[1.25em]")} \\end{alignedat}`;
        }
    },

    f_closestVal: function (node: Node, options: any): string {
        let ret_str: string = "\\begin{bmatrix} ";
        for (const param of node.args[1].args) {
            ret_str += `${param.toTex(options)}, `;
        }
        ret_str = `${ret_str.slice(0, ret_str.length - 2)} \\end{bmatrix}`;
        return `\\mathrm{closestValue}\\left(${node.args[0].toTex(options)}, ${ret_str}\\right)`;
    },

    f_quadSolve: function (node: Node, _options: any): string {
        const x: string = stripQuotes(node.args[0]);
        const a: number = node.args[1].getNumberVal();
        const b: number = node.args[2].getNumberVal();
        const c: number = node.args[3].getNumberVal();
        // default precision to 3
        const precision: number = node.args[4] !== undefined ? parseFloat(node.args[4].name) : 3;
        const left: number = mathjs.eval(
            `round((-1 * ${b} + sqrt(${b}^2 - (4 * ${a} * ${c}))) / (2 * ${a}), ${precision})`,
        );
        const right: number = mathjs.eval(
            `round((-1 * ${b} - sqrt(${b}^2 - (4 * ${a} * ${c}))) / (2 * ${a}), ${precision})`,
        );
        return `\\begin{matrix}${x}=${left}, ~${x}=${right}\\end{matrix}`;
    },

    f_ratio: function (node: Node, options: any): string {
        return `${node.args[0].toTex(options)}~:~${node.args[1].toTex(options)}`;
    },

    f_pow: function (node: Node, options: any): string {
        if (node.args[1].getNumberVal() > 0 && node.args[1].getNumberVal() % 1 === 0) {
            let output: string = `${node.args[0].toTex(options)}`;
            for (let num: number = 0; num < node.args[1].getNumberVal() - 1; num++) {
                output += ` {\\times} ${node.args[0].toTex(options)}`;
            }
            return output;
        } else {
            return parse(`$(${node.args[0].toString()})$^$(${node.args[1].toString()})$`).toTex(options);
        }
    },

    f_factorPairs: function (node: Node, _options: any): string {
        let factorPairs: string = "";
        let n: number;
        if (node.args[0].name === "-") {
            n = node.args[0].args[0].getNumberVal() * -1;
        } else {
            n = node.args[0].getNumberVal();
        }

        if (n > 0) {
            // If n is positive
            for (let f1 = 1; f1 < Math.floor(Math.sqrt(n)) + 1; f1++) {
                if (n % f1 === 0) {
                    const f2 = n / f1;
                    factorPairs += String(f1) + " \\!\\times\\! " + String(f2) + ", ";
                    factorPairs += -1 * f1 + " \\!\\times\\! " + -1 * f2 + ", ";
                }
            }
        } else if (n < 0) {
            // If n is negative
            for (let f1 = 1; f1 < Math.floor(Math.sqrt(-1 * n)) + 1; f1++) {
                if (n % f1 === 0) {
                    const f2 = n / f1;
                    factorPairs += String(f1) + " \\!\\times\\! " + String(f2) + ", ";
                    if (f1 !== -1 * f2) {
                        factorPairs += -1 * f1 + " \\!\\times\\! " + -1 * f2 + ", ";
                    }
                }
            }
        } else if (n === 0) {
            // If n is zero
            factorPairs += "0 \\!\\times\\! 0, ";
        }
        factorPairs = factorPairs.slice(0, factorPairs.length - 2);

        const isPositive = node.args[1] && node.args[1].name.toLowerCase() === "true" ? true : false;
        if (isPositive) {
            factorPairs = factorPairs
                .split(",")
                .filter((val) => !val.includes("-"))
                .join(",");
        }
        return factorPairs;
    },

    f_dotMulti: function (node: Node, options: any): string {
        let output: string = node.args[0].toTex(options);
        for (const num of node.args.slice(1)) {
            output += " \\cdot " + num.toTex(options);
        }
        return output;
    },

    f_explicitPlus: function (node: Node, options: any): string {
        let output: string = node.args[0].toTex(options);
        for (const num of node.args.slice(1)) {
            output += " + " + num.toTex(options);
        }
        return output;
    },

    f_explicitMinus: function (node: Node, options: any): string {
        let output: string = node.args[0].toTex(options);
        for (const num of node.args.slice(1)) {
            output += " - " + num.toTex(options);
        }
        return output;
    },

    f_intDef: function (node: Node, options: any): string {
        let displaystyle = "\\displaystyle";
        if (node.args[2] && (node.args[2].toTex(options) === `\\mathrm{small}` || node.args[2].name === `"small"`)) {
            displaystyle = "";
        }
        return `${displaystyle}\\large \\int^{${node.args[0].toTex(options)}}_{${node.args[1].toTex(
            options,
        )}} \\normalsize `;
    },

    f_intIndef: function (_node: Node, _options: any): string {
        let displaystyle = "\\displaystyle";
        if (
            _node.args[0] &&
            (_node.args[0].toTex(_options) === `\\mathrm{small}` || _node.args[0].name === `"small"`)
        ) {
            displaystyle = "";
        }
        return `${displaystyle}\\large \\int \\normalsize `;
    },

    f_nextPrime: function (node: Node, options: any): string {
        return `prime~ ${node.args[1].name === `"down"` ? "before" : "after"}~ ${node.args[0].toTex(options)}`;
    },

    f_shuffleArray: function (node: Node, _options: any): string {
        const bracketOption = node.args[1]?.eval().toString() || "none";
        const bracketTypes = [
            { type: "none", latex: "matrix" }, // no brackets
            { type: "square", latex: "bmatrix" }, // "[...]"
            { type: "curly", latex: "Bmatrix" }, // "{...}"
            { type: "parens", latex: "pmatrix" }, // "(...)"
            { type: "ghost", latex: "matrix" }, // this is different. concat all items together
        ];
        const bracketType = bracketTypes.find((type) => type.type === bracketOption) || bracketTypes[0]; // none, square, curly, ghost

        const array = node.args[0].args.map((arg) => arg.toTex(_options));
        const shuffledArr = shuffleArray(array);
        return `\\begin{${bracketType.latex}} ${shuffledArr} \\end{${bracketType.latex}}`;
    },

    f_numToText: function (node: Node, _options: any): string {
        const over = node.args[1] ? stripQuotes(node.args[1]) === "over" : false;

        return `\\mathrm{${node.args[0].toWord({ fraction: true, over })}}`;
    },

    f_numToPlaceVal: function (node: Node, _options: any): string {
        let num: string = mathjs.eval(node.args[0].toString()).toString();
        // remove any "-" so it doesnt confuse the func
        num = num.replace("-", "");
        // only grab the non-decimal
        num = num.split(".")[0];
        // make array of counts eg: 123 = ["1 hundered", "2 tens", "1 one"]
        const places = [];
        for (let place = 0; place < num.length; place++) {
            // make string of the place eg: 1 in 123  becomes "100" and 2 becomes "10",
            const thisString: string = "1" + "0".repeat(num.length - (place + 1));
            // generate word from thisString eg: "100" becomes "1 hundred",
            const words: string[] = numToWord.toWords(parseFloat(thisString)).split(" ");
            // we have to split the "hundred" from the "1" ("ten" is a special case in 2 ten thousands)
            const placeWords = words[0] === "ten" || words.length === 1 ? words.join(" ") : words.slice(1).join(" ");
            // console.log(words, placeWords);
            // and pluralise the word based on the digit so "2 hundered" becomes "hundereds"
            const placeName = pluralize(placeWords, parseInt(num[place]));
            // add the string to the array eg: "2 hundreds"
            places.push(`${num[place]}~\\mathrm{${placeName}}`);
        }
        return places.join(",~");
    },

    f_stringOption: function (node: Node, options: any): string {
        let correctTree = node.args[0];
        // 1) f_stringOption("Option 1", "Option2"...)          -> Option 1
        if (correctTree.type === "StringNode") {
            return correctTree.toTex({ renderMode: "plain" });
        }
        // 2) f_stringOption("Option 1 is: " + {a}, "Option 2") -> Option 1 is: 42
        else if (correctTree.type === "OperatorNode") {
            if (correctTree.name === "+" && correctTree.toString().includes(`"`)) {
                const kids = correctTree.args;
                // now check one of its kids is a string eg: "aaa" + {a}
                const hasStringKid: boolean = kids.some((val) => val.type === "StringNode");

                if (hasStringKid) {
                    return kids.map((val) => val.toTex({ ...options, renderMode: "plain" })).join("");
                }
            }
        }

        correctTree = parse(correctTree.toString().replace(/([^\\]|^)\\([^\\]|$)/g, "$1\\\\$2"), null, "USEIMPLICIT");
        return correctTree.toTex({ ...options, renderMode: "plain" });
    },
    f_string: function (node: Node, options: any): string {
        let correctTree = node.args[0];
        // 1) f_stringOption("Option 1", "Option2"...)          -> Option 1
        if (correctTree.type === "StringNode") {
            return correctTree.toTex({ renderMode: "plain" });
        }
        // 2) f_stringOption("Option 1 is: " + {a}, "Option 2") -> Option 1 is: 42
        else if (correctTree.type === "OperatorNode") {
            if (correctTree.name === "+" && correctTree.toString().includes(`"`)) {
                const kids = correctTree.args;
                // now check one of its kids is a string eg: "aaa" + {a}
                const hasStringKid: boolean = kids.some((val) => val.type === "StringNode");

                if (hasStringKid) {
                    return kids.map((val) => val.toTex({ ...options, renderMode: "plain" })).join("");
                }
            }
        }

        correctTree = parse(correctTree.toString().replace(/([^\\]|^)\\([^\\]|$)/g, "$1\\\\$2"), null, "USEIMPLICIT");
        return correctTree.toTex({ ...options, renderMode: "plain" });
    },

    ln: function (node: Node, options: any): string {
        if (["()", "abs"].includes(node.args[0].name)) {
            // in these cases reduce the spacing after "ln"
            return handleBracketsLatex(node.args[0], `\\mathrm{ln}\\!`, options);
        }
        return handleBracketsLatex(node.args[0], `\\mathrm{ln}`, options);
    },

    log: function (node: Node, options: any): string {
        // console.log("LOG LATEX");
        const a = node.args[0].toTex(options);
        let b: string;
        if (node.args.length > 1) {
            b = node.args[1].toTex(options);
        }

        if (b) {
            return handleBracketsLatex(node.args[0], `\\mathrm{log}_{${b}}`, options);
        } else {
            return handleBracketsLatex(node.args[0], `\\mathrm{log}`, options);
        }
    },

    f_latex: function (node: Node, _options: any): string {
        let val = node.args[0].toTex({ renderMode: "latex" });
        // if "" are used we need to trim the fiorst set
        if (val[0] === `"` && val[val.length - 1] === `"`) {
            val = val.slice(1, val.length - 1);
        }
        return val;
    },

    f_underline: function (node: Node, _options: any): string {
        const num = node.args[0].eval().toString();
        if (+node.args[1]?.eval() === 0 || +node.args[2]?.eval() === 0) {
            return num;
        }

        const start = +node.args[1]?.eval() || null;
        const end = +node.args[2]?.eval() || null;

        const decPos = num.split(".")[0].length;
        const digits = num.split("");

        if (start === null) {
            return `\\underline{${node.args[0].toString()}}`;
        } else if (start !== null && end === null) {
            const startidx = start >= 0 ? decPos - start : Math.abs(start) + decPos;
            digits.splice(startidx, 1, `\\underline{${digits[startidx]}}`);
            if (digits[startidx] === undefined) {
                return num;
            }
            return digits.join("");
        } else if (start !== null && end !== null) {
            const startidx = start >= 0 ? decPos - start : Math.abs(start) + decPos;
            const endidx = end >= 0 ? decPos - end : Math.abs(end) + decPos;
            if (digits[startidx] === undefined || digits[endidx] === undefined) {
                return num;
            }
            if (endidx < startidx) {
                return num;
            }
            return [
                ...digits.slice(0, startidx),
                `\\underline{${digits.slice(startidx, endidx + 1).join("")}}`,
                ...digits.slice(endidx + 1),
            ].join("");
        }
    },

    f_overline: function (node: Node, _options: any): string {
        const num = node.args[0].eval().toString();
        if (+node.args[1]?.eval() === 0 || +node.args[2]?.eval() === 0) {
            return num;
        }

        const start = +node.args[1]?.eval() || null;
        const end = +node.args[2]?.eval() || null;

        const decPos = num.split(".")[0].length;
        const digits = num.split("");

        if (start === null) {
            return `\\overline{${node.args[0].toString()}}`;
        } else if (start !== null && end === null) {
            const startidx = start >= 0 ? decPos - start : Math.abs(start) + decPos;
            digits.splice(startidx, 1, `\\overline{${digits[startidx]}}`);
            if (digits[startidx] === undefined) {
                return num;
            }
            return digits.join("");
        } else if (start !== null && end !== null) {
            const startidx = start >= 0 ? decPos - start : Math.abs(start) + decPos;
            const endidx = end >= 0 ? decPos - end : Math.abs(end) + decPos;
            if (digits[startidx] === undefined || digits[endidx] === undefined) {
                return num;
            }
            if (endidx < startidx) {
                return num;
            }
            return [
                ...digits.slice(0, startidx),
                `\\overline{${digits.slice(startidx, endidx + 1).join("")}}`,
                ...digits.slice(endidx + 1),
            ].join("");
        }
    },

    f_conj: function (node: Node, options: any): string {
        let notation = "bar";
        if (node.args.length > 1) {
            if (node.args[1].toString() === '"star"') {
                notation = "star";
            }
        }
        if (notation === "bar") {
            return `\\overline{${node.args[0].toTex(options)}}`;
        } else if (notation === "star") {
            return `(${node.args[0].toTex(options)})^*`;
        }
    },

    f_factors: function (node: Node, options: any): string {
        const n = node.args[0];
        // by default nMin and nMax are 1 and n respectivley
        const min: string = node.args[1] !== undefined ? node.args[1].toTex(options) : "1";
        const max: string = node.args[2] !== undefined ? node.args[2].toTex(options) : n.toTex(options);
        return `\\mathrm{factors(${n}, ${min}, ${max})}`;
    },

    f_commonFactors: function (node: Node, options: any): string {
        const n1 = node.args[0].toTex(options);
        const n2 = node.args[1].toTex(options);
        return `\\mathrm{commonFactors(${n1}, ${n2})}`;
    },

    f_multiples: function (node: Node, options: any): string {
        const args = node.args.map((arg) => arg.toTex(options)).join(", ");
        return `\\mathrm{multiples(${args})}`;
    },

    f_commonMultiples: function (node: Node, options: any): string {
        const args = node.args.map((arg) => arg.toTex(options)).join(", ");
        return `\\mathrm{commonMultiples(${args})}`;
    },

    f_strike: function (node: Node, options: any): string {
        return `\\cancel{${node.args[0].toTex(options)}}`;
    },

    f_bstrike: function (node: Node, options: any): string {
        return `\\bcancel{${node.args[0].toTex(options)}}`;
    },

    it: function (node: Node, options: any): string {
        if (node.args[0].name === "Unit") {
            return `\\mathit{${node.args[0].args[0].name}}`;
        }
        return `\\mathit{${node.args[0].name}}`;
    },

    rm: function (node: Node, options: any): string {
        return `\\mathrm{${node.args[0].toTex(options)}}`;
    },

    f_divR: function (node: Node, _options: any): string {
        const a: number = node.args[0].getNumberVal();
        const b: number = node.args[1].getNumberVal();
        // get the division
        const wholeNum = (a - (a % b)) / b;
        const rem = a % b;
        if (!wholeNum && wholeNum !== 0) {
            return `t[Undefined]t`;
        } else {
            return `${wholeNum} ~R~ ${rem}`;
        }
    },

    f_exp: function (node: Node, options: any): string {
        const a = node.args[0].toTex(options);
        const exp = node.args[1].toTex(options);
        return `{${a}}^{${exp}}`;
    },

    f_box: function (_node: Node, _options: any): string {
        return "\\fbox{\\phantom{1}}";
    },

    f_boxbox: function (_node: Node, _options: any): string {
        return "\\fbox{\\fbox{\\phantom{1}}}";
    },

    f_boxOp: function (node: Node, options: any): string {
        const left: any = node.args[0].toTex(options);
        const right: any = node.args[1].toTex(options);
        return `${left}~\\fbox{\\phantom{1}}~${right}`;
    },

    f_boxplus: function (_node: Node, _options: any): string {
        return "\\boxplus";
    },

    f_boxtimes: function (_node: Node, _options: any): string {
        return "\\boxtimes";
    },

    f_subscript: function (node: Node, options: any): string {
        const val = node.args[0].toTex(options);
        const mode = node.args[2]?.name.replace(/\"/g, "");
        let subVals = [node.args[1].toTex(options)];
        if (node.args[1].type === "ArrayNode") {
            subVals = node.args[1].args.map((val) => val.toTex(options));
        }
        return `${val}_{${subVals.join(mode === "commas" ? "," : "")}}`;
    },

    f_superscript: function (node: Node, options: any): string {
        const val = node.args[0].toTex(options);
        const superVal = node.args[1].toTex(options);
        return `${val}^{${superVal}}`;
    },

    f_evalWSymbol: function (node: Node, options: any): string {
        const a: any = node.args[0].toString();
        let symbol: any = node.args[1].toString();
        const b: any = node.args[2].toString();

        if (symbol[0] === `"` && symbol[symbol.length - 1] === `"`) {
            symbol = symbol.slice(1, symbol.length - 1);
        }

        return parse(`${a} ${symbol} ${b}`).toTex(options);
    },

    f_deriv: function (node: Node, options: any): string {
        const args = node.args;

        // if we have an expression input ...
        if (args[0] && args[0].name === "()") {
            let ans = "";
            const func = args[0].toTex(options);
            const braceslessFunc = args[0].args[0].toTex(options);
            const order = findOrder(args);
            // check if we have an input flag
            if (args[3] && ['"PRIME"', '"LIE"', '"LIER"', '"LIES"', '"EUL"'].includes(args[3].name.toUpperCase())) {
                // this is the f_deriv(y,x,1,"FLAG") case
                const flag = args[3].name.toUpperCase() || '"PRIME"';
                if (flag === `"PRIME"`) {
                    const superscript = makeSuperscript(order, '"PRIME"');
                    ans = `${func}${superscript}`;
                } else if (flag === `"LIE"`) {
                    const superscript = makeSuperscript(order, '"LIE"');
                    ans = `\\frac{\\mathrm{d}${superscript}${braceslessFunc}}{\\mathrm{d}${args[1]}${superscript}}`;
                } else if (flag === '"LIER"') {
                    const superscript = makeSuperscript(order, '"LIER"');
                    ans = `\\frac{\\mathrm{d}${superscript}}{\\mathrm{d}${args[1]}${superscript}}\\left(${braceslessFunc}\\right)`;
                } else if (flag === '"LIES"') {
                    const superscript = makeSuperscript(order, '"LIES"');
                    ans = `\\frac{\\mathrm{d}${superscript}}{\\mathrm{d}${args[1]}${superscript}}\\left[${braceslessFunc}\\right]`;
                } else if (flag === '"EUL"') {
                    const superscript = makeSuperscript(order, '"EUL"');
                    ans = `D${superscript}${func}`;
                }
                return ans;
            } else if (args[2]) {
                // this is the f_deriv(y,x,1) case
                const superscript = makeSuperscript(order, '"PRIME"');
                return `\\left(${braceslessFunc}\\right)${superscript}`;
            } else if (args[1]) {
                // this is the f_deriv(y,x) case
                return `\\left(${braceslessFunc}\\right)'`;
            } else {
                // this is the super simple f_deriv(y,x) case
                return `\\left(${braceslessFunc}\\right)'`;
            }
            return ans;
        }

        // we have a function name input
        try {
            let independentVars: string[] = [];
            if (node.args[0]?.type === "FunctionNode") {
                independentVars = node.args[0].args.map((val) => val.toString());
            }

            let varString = independentVars.length > 0 ? `(${independentVars.join(",")})` : "";
            let func: string = node.args[0].name;
            if (func.length > 1) {
                func = `\\mathrm{${func}}`; // func name should be mathrmed if two chars or more
            }

            // stuff like cos x doesn't want () around the independent vars
            if (node.args[0].name.length > 1 && independentVars.length === 1 && independentVars[0].length === 1) {
                varString = independentVars.length > 0 ? `~${independentVars[0]}` : "";
            }

            let order: number | string;
            if (node.args.length < 3) {
                order = 1;
            } else {
                order = findOrder(node.args);
            }

            const flag: string = node.args[3]?.name.toUpperCase() ?? '"PRIME"';
            const wrt: string = node.args[1]?.name; // required

            let ans = "";
            const superscript = makeSuperscript(order, flag);
            if (flag === '"PRIME"') {
                ans = func + superscript + varString;
            } else if (flag === '"LIE"') {
                ans = `\\frac{\\mathrm{d}${superscript}${func}${varString}}{\\mathrm{d}${wrt}${superscript}}`;
            } else if (flag === '"LIER"') {
                ans = `\\frac{\\mathrm{d}${superscript}}{\\mathrm{d}${wrt}${superscript}}\\left(${func}${varString}\\right)`;
            } else if (flag === '"LIES"') {
                ans = `\\frac{\\mathrm{d}${superscript}}{\\mathrm{d}${wrt}${superscript}}\\left[${func}${varString}\\right]`;
            } else if (flag === '"EUL"') {
                ans = `D${superscript}${func}${varString}`;
            } else if (flag === '"DOT"' && !isNaN(+order) && +order < 3) {
                // for dots we only have one or two
                let dot = "";
                if (+order === 1) {
                    dot = "\\dot";
                } else if (+order === 2) {
                    dot = "\\ddot";
                } else {
                    return "error";
                }
                ans = `${dot} ${func} ${varString}`;
            } else {
                return "error";
            }
            return ans;
        } catch (e) {
            return "error";
        }

        function makeSuperscript(order: number | string, flag: string) {
            let superscript = "";
            if (flag === '"PRIME"') {
                if (isNaN(+order) || +order > 3) {
                    superscript = `^{(${order})}`;
                } else {
                    for (let i = 0; i < +order; ++i) {
                        superscript += "'";
                    }
                }
            } else if (isNaN(+order) || +order > 1) {
                superscript = `^{${order}}`;
            }
            return superscript;
        }

        function findOrder(args: Node[]): number | string {
            let order: number | string = 1;
            if (["SymbolNode", "ConstantNode"].includes(node.args[2]?.type)) {
                order = args[2]?.name;
            } else if (args[2]?.type === "UnitNode") {
                order = args[2]?.args[0].name;
            }
            return order;
        }
    },

    f_d: function (node: Node, options: any) {
        return `\\mathrm{d}${node.args[0]?.name}`;
    },

    f_sigma: function (node: Node, _options: any): string {
        const eq = node.args[0].name.replace(/\"/g, "");
        const from = node.args[1].toTex();
        const to = node.args[2].toTex();
        const indexSymbol = node.args[3].toString().replace(/"/g, "");

        const eqTex = parse(eq, [indexSymbol], "USEIMPLICIT").solve().toTex();

        const tex = `\\displaystyle\\sum_{${indexSymbol.toString().replace(/"/g, "")}=${from}}^{${to}} ${eqTex}`;
        return tex;
    },

    f_sum: function (node: Node, _options: any): string {
        const eq = node.args[0].name.replace(/\\\\/g, "\\").replace(/"/g, "");
        const from = node.args[1].getNumberVal();
        const to = node.args[2].getNumberVal();
        const indexSymbol = node.args[3].name.replace(/\"/g, "");

        const tex = [];
        for (let i = from; i < to + 1; i++) {
            const paramSet = { [indexSymbol]: i };
            const eqWithN: string = evaluateExpression(eq, paramSet, paramSet);

            tex.push(parse(eqWithN, [indexSymbol], "USEIMPLICIT").toTex());
        }

        return tex.join("+");
    },

    f_recurring: function (node: Node, _options: any): string {
        const mode = node.args[1]?.name.replace(/"/g, "") || "bar";
        const frac = node.args[0];
        const q = divide(+frac.args[0].eval(), +frac.args[1].eval());
        const prefix = `${q.whole}.${q.fraction.join("")}`;

        switch (mode) {
            case "bar":
                return prefix + `\\overline{${q.cycle.join("")}}`;
            case "dot":
                const cyc = q.cycle.map((val) => val.toString());
                cyc.splice(0, 1, `\\dot{${cyc[0]}}`);
                if (cyc.length > 1) {
                    cyc.splice(-1, 1, `\\dot{${cyc[cyc.length - 1]}}`);
                }
                return prefix + cyc.join("");
            case "brackets":
                return prefix + `\\left(${q.cycle.join("")}\\right)`;
            case "arc":
                return prefix + `\\overgroup{${q.cycle.join("")}}`;
            case "ellipsis":
                return prefix + `${q.cycle.join("").repeat(3)}\\ldots`;
        }
        return "0";
    },

    f_deg: function (_node: Node, _options: any): string {
        return "\\degree";
    },

    f_degC: function (_node: Node, _options: any): string {
        return "\\degree\\!\\mathrm{C}";
    },

    f_degF: function (_node: Node, _options: any): string {
        return "\\degree\\!\\mathrm{F}";
    },

    f_unit: function (node: Node, _options: any): string {
        const symbolNodes = node.getBFS().filter((val) => val.type === "SymbolNode");
        const oldSymbolNames = [];
        symbolNodes.forEach((val, index) => {
            oldSymbolNames.push(val.toString());
            val.name = `STRING--${index}--`;
        });
        let innerTex = node.args[0].toTex();
        oldSymbolNames.forEach((val, index) => {
            innerTex = innerTex.replace(`STRING--${index}--`, val);
            symbolNodes[index].name = val;
        });
        return `\\mathrm{${innerTex}}`;
    },

    f_symbol: function (node: Node, _options: any): string {
        return node.args[0].toString();
    },

    f_op: function (node: Node, options: any): string {
        return ` ${node.args[0].toTex(options)} `
            .replace(/\\leq/g, " \\leq ")
            .replace(/\\geq/g, " \\geq ")
            .replace(/\\gt/g, " \\gt ")
            .replace(/\\lt/g, " \\lt ");
    },

    f_hat: function (node: Node, _options: any): string {
        return `\\hat{${node.args[0].toTex()}}`;
    },

    f_widehat: function (node: Node, _options: any): string {
        return `\\widehat{${node.args[0].toTex()}}`;
    },

    f_angle: function (node: Node, _options: any): string {
        return `\\angle{${node.args[0].toTex()}}`;
    },

    f_tally: function (node: Node, _options: any): string {
        let num = parseFloat(node.args[0].toString());
        // limit it at 500 for performance
        if (num > 500) {
            num = 500;
        }
        const remainder = num % 5;
        const sets = (num - remainder) / 5;

        if (num < 1) {
            return " ";
        }

        const setsLatex = [];
        for (let i = 0; i < sets; i++) {
            setsLatex.push("\\cancel{||||}");
        }
        setsLatex.push("|".repeat(remainder));
        return setsLatex.join(" ~~ ");
    },

    f_datasetFromFreqs: function (node: Node, options: any): string {
        return `\\mathrm{f\\_datasetFromFreqs}\\left(${node.args[0].toTex(options)}, ${node.args[1].toTex(
            options,
        )}\\right)`;
    },

    f_filter: function (node: Node, options: any): string {
        return `\\mathrm{f\\_filter}\\left(${node.args[0].toTex(options)}, ${node.args[1].toTex(options)}\\right)`;
    },

    trunc: function (node: Node, _options: any): string {
        let num: any = stripQuotes(node.args[0]);
        const perc = stripQuotes(node.args[1]);

        // Checks for imaginary number
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }

        const output = (mathjs as any).trunc(num, perc ? +perc : 0);

        return `${output}`;
    },

    f_quantileSeq: function (node: Node, options: any): string {
        return `quantileSeq\\left(${node.args.map((node) => node.toTex(options)).join(", ")}\\right)`;
    },

    f_allTuplesDiffer: function (node: Node, _options: any): string {
        return `allTuplesDiffer\\left(${node.args.map((node) => node.toTex(_options)).join(", ")}\\right)`;
    },

    f_simulNumbered: function (node: Node, options: any): string {
        let ret_str: string = "\\begin{matrix} \\begin{aligned}";
        const endLine = "\\\\[1.25em]";

        ret_str += node.args[0].args
            .map((param, index) => `${param.toTex(options)}~~~~~~~~~~~~~~~~~(${index + 1})`)
            .join(`${endLine}`);

        return ret_str.slice(0, ret_str.length) + ` \\end{aligned} \\end{matrix}`;
    },

    f_piecewise: function (node: Node, options: any): string {
        // functionName: string is node.args[0]
        // functionsList: Array<[function: string, conditionText: string, fromTo: string]> is node.args[1]

        const functionsList = node.args[1];
        let fullStop: boolean = true;
        let ret = `${node.args[0].toTex(options)} = \\begin{cases}`;
        functionsList.args.forEach((x) => {
            fullStop = true;
            let conditionText = "";
            if (x.args[1] && x.args[1].args[0] && x.args[1].args[0].name !== "none") {
                conditionText = x.args[1].toTex(options);
            }

            // no condition text, no fromTo
            if (conditionText === "" && !x.args[2]) {
                ret += `${x.args[0].toTex(options)} & \\\\`;
                fullStop = false; // don't want a full stop in this case if last eq
            }
            // no condition text, fromTo
            else if (conditionText === "" && x.args[2]) {
                const fromTo: string = x.args[2].toTex(options);
                ret += `${x.args[0].toTex(options)} & ${fromTo},\\\\`;
            }
            // conditionText, no fromTo
            else if (conditionText !== "" && !x.args[2]) {
                ret += `${x.args[0].toTex(options)} & ${conditionText},\\\\`;
            }
            // conditionText, fromTo
            else if (conditionText !== "" && x.args[2]) {
                const fromTo: string = x.args[2].toTex(options);
                ret += `${x.args[0].toTex(options)} & ${conditionText}\\text{ } ${fromTo},\\\\`;
            }
        });
        if (!fullStop) {
            return ret.substring(0, ret.length - 3) + "\\end{cases}"; // delete the last ,\\\\
        }
        return ret.substring(0, ret.length - 3) + ".\\end{cases}"; // delete the last ,\\\\
    },

    f_lim: function (node: Node, options: any): string {
        // node.args[0] is the expression
        // node.args[1] is the limit value
        // $\lim\limits_{x \to \infty}$
        const indVar: string = node.getNodesByType("SymbolNode")[0].name;
        const limit = node.args[1].toTex(options);
        let ret_str = `\\lim\\limits_{${indVar} \\to ${limit}} ${node.args[0].toTex(options)}`;
        return ret_str;
    },

    f_leftLim: function (node: Node, options: any): string {
        // node.args[0] is the expression
        // node.args[1] is the limit value
        // $\lim\limits_{x \to \infty}$
        const indVar: string = node.getNodesByType("SymbolNode")[0].name;
        const limit = node.args[1].toTex(options);
        let ret_str = `\\lim\\limits_{${indVar} \\to ${limit}^{-}} ${node.args[0].toTex(options)}`;
        return ret_str;
    },

    f_rightLim: function (node: Node, options: any): string {
        // node.args[0] is the expression
        // node.args[1] is the limit value
        // $\lim\limits_{x \to \infty}$
        const indVar: string = node.getNodesByType("SymbolNode")[0].name;
        const limit = node.args[1].toTex(options);
        let ret_str = `\\lim\\limits_{${indVar} \\to ${limit}^{+}} ${node.args[0].toTex(options)}`;
        return ret_str;
    },
    f_simplifyFraction: function (node: Node, options: any): string {
        // node.args[0] is a
        // node.args[1] is b
        // node.args[2] is an optional flag
        const aStr: string = node.args[0].toTex(options);
        const bStr: string = node.args[1].toTex(options);
        const flag: string = node.args[2] ? node.args[2].name : "";
        const regex = /\s/g;
        const aNum: number = +aStr.replace(regex, "");
        const bNum: number = +bStr.replace(regex, "");

        if (isNaN(aNum) || isNaN(bNum)) {
            return "error";
        }

        const gcd = mathjs.gcd(aNum, bNum);

        if (flag === "") {
            const denom = bNum / gcd;
            // if denom is 1 (or -1), don't show it (we have a flag for this below)
            if (denom === 1) {
                return `${aNum / gcd}`;
            } else if (denom === -1) {
                return `${(-1 * aNum) / gcd}`;
            } else {
                if (aNum < 0 !== bNum < 0) {
                    // XOR
                    return `\\frac{-${Math.abs(aNum) / gcd}}{${Math.abs(denom)}}`;
                }
                return `\\frac{${aNum / gcd}}{${denom}}`;
            }
        } else if (flag === '"unityDenominators"') {
            if (aNum < 0 !== bNum < 0) {
                // XOR
                return `\\frac{-${Math.abs(aNum) / gcd}}{${Math.abs(bNum) / gcd}}`;
            }
            return `\\frac{${aNum / gcd}}{${bNum / gcd}}`;
        }
    },
    f_decomposeFraction: function (node: Node, options: any): string {
        // node.args[0] is a
        // node.args[1] is b
        // node.args[2] is the optional parameter 'strike' or 'divide'
        const aStr: string = node.args[0].toTex(options);
        const bStr: string = node.args[1].toTex(options);
        const regex = /\s/g;
        const aNum: number = +aStr.replace(regex, "");
        const bNum: number = +bStr.replace(regex, "");

        return decomposeFraction(aNum, bNum, node.args[2] && node.args[2].name ? node.args[2].name : "");
    },
    f_improperToMixed: function (node: Node, options: any): string {
        // improper fraction: a / b
        // node.args[0] is a
        // node.args[1] is b
        // node.args[2] is the optional flag
        const regex = /\s/g;
        let a: number = +node.args[0].toTex(options).replace(regex, "");
        let b: number = +node.args[1].toTex(options).replace(regex, "");

        if (isNaN(a) || isNaN(b)) {
            return "error";
        }

        if (a % b === 0) {
            return `${a / b}`;
        }

        const neg = a < 0 !== b < 0 ? "-" : "";
        a = mathjs.abs(a);
        b = mathjs.abs(b);
        let flag: string = node.args[2]?.toString() || `"mixedNumber"`; // default
        flag = flag.replace(regex, "");

        const wholeNumber = Math.floor(a / b);
        const numerator = a % b;
        const gcdenom = mathjs.gcd(numerator, b);
        const numeratorSimplified = numerator / gcdenom;
        const denominatorSimplified = b / gcdenom;
        if (gcdenom === 1 && [`"decomposeFraction"`, `"decomposeStrike"`, `"commonDivisor"`].includes(flag)) {
            flag = `"simplifyWholeNumber"`; // default behaviour when gcd = 1
        }

        if (flag === `"splitNumerator"`) {
            return `${neg}\\frac{${wholeNumber * b} + ${numerator}}{${b}}`;
        } else if (flag === `"splitFraction"`) {
            return `${neg}\\frac{${wholeNumber * b}}{${b}} ${neg === "-" ? neg : "+"} \\frac{${numerator}}{${b}}`;
        } else if (flag === `"simplifyWholeNumber"`) {
            return `${neg}${wholeNumber} ${neg === "-" ? neg : "+"} \\frac{${numerator}}{${b}}`;
        } else if (flag === `"decomposeFraction"`) {
            return `${neg}${wholeNumber} ${neg === "-" ? neg : "+"} ${decomposeFraction(
                Math.abs(numerator),
                Math.abs(b),
            )}`;
        } else if (flag === `"decomposeStrike"`) {
            return `${neg}${wholeNumber} ${neg === "-" ? neg : "+"} ${decomposeFraction(
                Math.abs(numerator),
                Math.abs(b),
                `"strike"`,
            )}`;
        } else if (flag === `"simplifyFraction"`) {
            return `${neg}${wholeNumber} ${
                neg === "-" ? neg : "+"
            } \\frac{${numeratorSimplified}}{${denominatorSimplified}}`;
        } else if (flag === `"mixedNumber"`) {
            return `${neg}${wholeNumber} \\frac{${numerator / gcdenom}}{${b / gcdenom}}`;
        } else if (flag === `"wholeNumber"`) {
            return `${neg === "-" ? neg : ""} ${wholeNumber}`;
        } else if (flag === `"numeratorSimplified"`) {
            return `${numeratorSimplified}`;
        } else if (flag === `"denominatorSimplified"`) {
            return `${denominatorSimplified}`;
        } else if (flag === `"commonDivisor"`) {
            return `${neg}${wholeNumber} ${neg === "-" ? neg : "+"} ${decomposeFraction(
                Math.abs(numerator),
                Math.abs(b),
                `"divide"`,
            )}`;
        } else if (flag === `"splitNumeratorSimplified"`) {
            const firstTerm = wholeNumber !== 0 ? `${wholeNumber * denominatorSimplified}` : "";
            const secondTerm = numeratorSimplified !== 0 ? `${numeratorSimplified}` : "";
            const newNumerator =
                firstTerm === "" || secondTerm === "" ? firstTerm + secondTerm : firstTerm + " + " + secondTerm;
            return `\\frac{${newNumerator}}{${denominatorSimplified}}`;
        } else if (flag === `"splitFractionSimplified"`) {
            const firstTerm =
                wholeNumber !== 0 ? `\\frac{${wholeNumber * denominatorSimplified}}{${denominatorSimplified}}` : "";
            const secondTerm =
                numeratorSimplified !== 0 ? `\\frac{${numeratorSimplified}}{${denominatorSimplified}}` : "";
            return firstTerm === "" || secondTerm === "" ? firstTerm + secondTerm : firstTerm + " + " + secondTerm;
        }

        return "error";
    },

    f_trueToYes: function (node: Node, options: any): string {
        if (node.args[0].name === "true") {
            return "t[Yes]t";
        }
        if (node.args[0].name === "false") {
            return "t[No]t";
        }
    },

    f_relation: function (node: Node, options: any): string {
        const ops = ["=", "\\neq", "<", ">", "\\leq", "\\geq"];
        const num = parseFloat(node.args[2].name);
        return `${node.args[0].toTex(options)} ${ops[num - 1]} ${node.args[1].toTex(options)}`;
    },

    f_faceValue: function (node: Node, options: any) {
        const num = node.args[0].eval().toString();
        const index = +node.args[1].eval();

        if (index === 0) {
            return "error";
        }

        const decPos = num.split(".")[0].length;
        const idx = index >= 0 ? decPos - index : Math.abs(index) + decPos;
        return (num[idx] || 0).toString();
    },

    f_placeValue: function (node: Node, options: any) {
        const num = node.args[0].eval().toString();
        const index = +node.args[1].eval();

        if (index === 0) {
            return "error";
        }

        const decPos = num.split(".")[0].length;
        const idx = index > 0 ? decPos - index : Math.abs(index) + decPos;

        if (num[idx] === undefined) {
            return "0";
        } else if (index > 0) {
            return (10 ** (index - 1)).toString();
        } else {
            return `\\frac{1}{${(10 ** Math.abs(index)).toString()}}`;
        }
    },

    f_decimalPlaces: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },
    f_significantFigures: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },

    f_digits: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },

    // Temporary duplicates: gitSigFig, getDP and getDigits
    // Content team will start using these instead, as this syntax is more intuitive
    getDP: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },
    getSigFig: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },

    getDigits: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },
    f_int: function (node: Node, options: any): string {
        // node.args[0] is the integrand
        // node.args[1] is the variable of integration
        // node.args[2] is the optional lower limit (optional) or the optional "small" flag
        // node.args[3] is the optional upper limit (optional) or the optional "small" flag
        // node.args[4] is the optional "small" flag
        if (!node.args[0] || !node.args[1]) {
            return "error";
        }
        const integrand = node.args[0].toTex(options) !== "\\mathrm{none}" ? node.args[0].toTex(options) : "";
        const intVar = node.args[1].toTex(options) !== "\\mathrm{none}" ? node.args[1].toTex(options) : "";

        let isSmall = false; // small int sign or a big one?
        let lowerLim = "";
        if (node.args[2] && node.args[2].toTex(options) !== "\\mathrm{none}") {
            if (node.args[2].name === `"small"` || node.args[2].toTex(options) === `\\mathrm{small}`) {
                isSmall = true;
            } else {
                lowerLim = node.args[2].toTex(options);
            }
        }

        let upperLim = "";
        if (node.args[3] && node.args[3].toTex(options) !== "\\mathrm{none}") {
            if (node.args[3].name === `"small"` || node.args[3].toTex(options) === `\\mathrm{small}`) {
                isSmall = true;
            } else {
                upperLim = node.args[3].toTex(options);
            }
        }

        if (node.args[4] && (node.args[4].toTex(options) === `\\mathrm{small}` || node.args[4].name === `"small"`)) {
            isSmall = true;
        }

        let ans = "\\displaystyle\\large\\int";
        if (isSmall) {
            ans = "\\large\\int";
        }
        if (lowerLim !== "") {
            ans += `_{${lowerLim}}`;
        }
        if (upperLim !== "") {
            ans += `^{${upperLim}}`;
        }
        ans += "\\normalsize";
        if (integrand !== "") {
            ans += `\\!${integrand}`;
        }
        if (intVar !== "") {
            ans += `~\\mathrm{d}${intVar}`;
        }
        return ans;
    },

    f_matrix: function (node: Node, options: any): string {
        const arr = node.args[0];
        if (arr.type === "ArrayNode") {
            const items: string[] = [];
            for (const row of arr.args) {
                if (row.type === "ArrayNode") {
                    items.push(row.args.map((col) => col.toTex(options)).join(" & "));
                } else {
                    items.push(row.toTex(options));
                }
            }

            return `\\begin{bmatrix} ${items.join(" \\\\ ")} \\end{bmatrix}`;
        } else {
            return `\\begin{bmatrix} ${node.args[0].toTex(options)} \\end{bmatrix}`;
        }
    },

    nand: function (node: Node, options: any) {
        if (!node.args[0] && !node.args[1]) {
            return "error";
        }
        let leftInput = node.args[0].toTex(options);
        let rightInput = node.args[1].toTex(options);
        if (node.args[2] && node.args[2].name === `"wedge"`) {
            return `${leftInput} ~\\overline{\\land}~ ${rightInput}`;
        }
        return `\\overline{${leftInput} \\cdot ${rightInput}}`;
    },

    nor: function (node: Node, options: any) {
        if (!node.args[0] && !node.args[1]) {
            return "error";
        }
        let leftInput = node.args[0].toTex(options);
        let rightInput = node.args[1].toTex(options);
        if (node.args[2] && node.args[2].name === `"wedge"`) {
            return `${leftInput} ~\\overline{ \\lor }~ ${rightInput}`;
        }
        return `\\overline{${leftInput} + ${rightInput}}`;
    },

    f_vect: function (node: Node, options: any) {
        return `\\vec{${node.args[0].toTex(options)}}`;
    },

    f_uVect: function (node: Node, options: any) {
        return `\\hat{${node.args[0].toTex(options)}}`;
    },
    f_blank: function (node: Node, options: any) {
        return `~`;
    },

    and: function (node: Node, options: any) {
        if (!node.args[0] && !node.args[1]) {
            return "error";
        }
        let leftInput = node.args[0].toTex(options);
        let rightInput = node.args[1].toTex(options);
        let andSymb = "\\cdot";
        if (node.args[2] && node.args[2].name === `"wedge"`) {
            andSymb = "\\wedge";
        }
        return `${leftInput} ${andSymb} ${rightInput}`;
    },
    or: function (node: Node, options: any) {
        if (!node.args[0] && !node.args[1]) {
            return "error";
        }
        let leftInput = node.args[0].toTex(options);
        let rightInput = node.args[1].toTex(options);
        let orSymb = "+";
        if (node.args[2] && node.args[2].name === `"wedge"`) {
            orSymb = "\\vee";
        }
        return `${leftInput} ${orSymb} ${rightInput}`;
    },
    xor: function (node: Node, options: any) {
        if (!node.args[0] && !node.args[1]) {
            return "error";
        }
        let leftInput = node.args[0].toTex(options);
        let rightInput = node.args[1].toTex(options);
        let orSymb = "\\oplus";
        if (node.args[2] && node.args[2].name === `"wedge"`) {
            orSymb = "\\veebar";
        }
        return `${leftInput} ${orSymb} ${rightInput}`;
    },

    f_not: function (node: Node, options: any) {
        if (!node.args[0]) {
            return "error";
        }
        const input = node.args[0].toTex(options);
        if (node.args[1] && node.args[1].name === `"square"`) {
            return `\\neg${input}`;
        }
        return `\\overline{${input}}`;
    },

    f_expandedForm: function (node: Node, options: any): string {
        const whitespaceRegex = /\s/g;
        let inputNumStr: string = node.args[0].toTex(options).replace(whitespaceRegex, "");
        // remove negatives
        let isNeg = false;
        if (inputNumStr.includes("-")) {
            isNeg = true;
            inputNumStr = inputNumStr.replace(/-/g, "");
        }
        const powersOfTenFlag: string = node.args[1]?.toString() || `"noPowersOfTen"`; // default
        const decOrFracFlag: string = node.args[2]?.toString() || `"decimals"`; // default

        if (![`"noPowersOfTen"`, `"powersOfTen"`].includes(powersOfTenFlag)) {
            return "error";
        }
        if (![`"decimals"`, `"fractions"`].includes(decOrFracFlag)) {
            return "error";
        }

        let posPart: number = +inputNumStr.split(".")[0];
        let negPart: number = +inputNumStr.split(".")[1] || 0;
        // find positive powers of ten
        const posCount = String(posPart).length;
        const posPowers: { power: number; num: number }[] = [];
        for (let count = 0; count < posCount; ++count) {
            // console.log(count, posPart % 10);
            posPowers.push({ power: count, num: posPart % 10 });
            posPart = Math.trunc(posPart / 10);
        }
        // find negative powers of ten
        const negCount = String(negPart).length;
        const negPowers: { power: number; num: number }[] = [];
        for (let count = negCount; count > 0; --count) {
            // console.log(count, negPart % 10);
            negPowers.unshift({ power: count, num: negPart % 10 }); // these powers represent negative powers
            negPart = Math.trunc(negPart / 10);
        }

        let ans = "";
        if (powersOfTenFlag === `"noPowersOfTen"` && decOrFracFlag === `"decimals"`) {
            // 20300.405 => 20000 + 300 + 0.4 + 0.005
            for (const posPower of posPowers) {
                if (posPower.num !== 0) {
                    ans = `${constructNumFromPosPower(posPower.num, posPower.power)} ${ans !== "" ? "+" : ""} ${ans}`;
                }
            }
            for (const negPower of negPowers) {
                if (negPower.num !== 0) {
                    ans = `${ans} ${ans !== "" ? "+" : ""} ${constructNumFromNegPower(negPower.num, negPower.power)}`;
                }
            }
        } else if (powersOfTenFlag === `"powersOfTen"` && decOrFracFlag === `"decimals"`) {
            // 20300.405 => 2×10000 + 3×100 + 4×0.1 + 5×0.001
            for (const posPower of posPowers) {
                if (posPower.num !== 0) {
                    ans = `${posPower.num} \\times ${constructNumFromPosPower(1, posPower.power)} ${
                        ans !== "" ? "+" : ""
                    } ${ans}`;
                }
            }
            for (const negPower of negPowers) {
                if (negPower.num !== 0) {
                    ans = `${ans} ${ans !== "" ? "+" : ""} ${negPower.num}\\times${constructNumFromNegPower(
                        1,
                        negPower.power,
                    )}`;
                }
            }
        } else if (powersOfTenFlag === `"noPowersOfTen"` && decOrFracFlag === `"fractions"`) {
            // 20300.405 => 20000 + 300 + 4/10 + 5/1000
            for (const posPower of posPowers) {
                if (posPower.num !== 0) {
                    ans = `${constructNumFromPosPower(posPower.num, posPower.power)} ${ans !== "" ? "+" : ""} ${ans}`;
                }
            }
            for (const negPower of negPowers) {
                if (negPower.num !== 0) {
                    ans = `${ans} ${ans !== "" ? "+" : ""} \\frac{${negPower.num}}{${constructNumFromPosPower(
                        1,
                        negPower.power,
                    )}}`;
                }
            }
        } else if (powersOfTenFlag === `"powersOfTen"` && decOrFracFlag === `"fractions"`) {
            // 20300.405 => 2×10000 + 3×100 + 4×1/10 + 5×1/1000
            for (const posPower of posPowers) {
                if (posPower.num !== 0) {
                    ans = `${posPower.num} \\times ${constructNumFromPosPower(1, posPower.power)} ${
                        ans !== "" ? "+" : ""
                    } ${ans}`;
                }
            }
            for (const negPower of negPowers) {
                if (negPower.num !== 0) {
                    ans = `${ans} ${ans !== "" ? "+" : ""} ${
                        negPower.num
                    } \\times\\frac{${1}}{${constructNumFromPosPower(1, negPower.power)}}`;
                }
            }
        }
        if (isNeg) {
            return `-(${ans})`;
        }
        return ans;

        // helpers
        function constructNumFromPosPower(num: number, power: number) {
            let ans = num;
            for (let count = 0; count < power; ++count) {
                ans *= 10;
            }
            return ans;
        }
        function constructNumFromNegPower(num: number, power: number) {
            let ans = num;
            for (let count = 0; count < power; ++count) {
                ans /= 10;
            }
            return ans;
        }
    },
    det: function (node: Node, options: any) {
        // we expect:
        // node is det
        // node.args[0] is f_matrix
        // node.args[0].args[0] is the array of values

        let arr: Node = null;
        if (node.args[0]?.type === "ArrayNode") {
            // if f_matrix is used (not the Amy way)
            arr = node.args[0];
        } else if (node.args[0]?.args[0]?.type === "ArrayNode") {
            // the Amy way
            arr = node.args[0].args[0];
        }

        const items: string[] = [];
        for (const row of arr.args) {
            if (row.type === "ArrayNode") {
                items.push(row.args.map((col) => col.toTex(options)).join(" & "));
            } else {
                items.push(row.toTex(options));
            }
        }

        return `\\begin{vmatrix} ${items.join(" \\\\ ")} \\end{vmatrix}`;
    },
    f_evalAt: function (node: Node, options: any) {
        //  f_evalAt: function (exp: number, lowerBound: number, mode?: string) {
        const exp = node.args[0].toTex(options);
        const lowerBound = node.args[1].toTex(options);
        const mode = node.args[2]?.name.replace(/"/g, "") || "bar";

        if (mode === "bar") {
            return `${exp} \\biggr\\rvert _{${lowerBound}}`;
        } else {
            return `\\left[ ${exp} \\right] _{${lowerBound}}`;
        }
    },
    f_evalDiffAt: function (node: Node, options: any) {
        // f_evalDiffAt: function (exp: number, lowerBound: number, upperBound: number, mode?: string) {
        const exp = node.args[0].toTex(options);
        const lowerBound = node.args[1].toTex(options);
        const upperBound = node.args[2].toTex(options);
        const mode = node.args[3]?.name.replace(/"/g, "") || "squareBrackets";

        if (mode === "bar") {
            return `${exp} \\biggr\\rvert _{${lowerBound}} ^{${upperBound}}`;
        } else {
            return `\\left[ ${exp} \\right] _{${lowerBound}} ^{${upperBound}}`;
        }
    },

    sin: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{sin}", options);
    },
    cos: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cos}", options);
    },
    tan: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{tan}", options);
    },
    sec: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{sec}", options);
    },
    csc: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cosec}", options);
    },
    cot: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cotan}", options);
    },
    cotan: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cotan}", options);
    },
    cosec: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cosec}", options);
    },
    asin: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{sin}^{-1}", options);
    },
    acos: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cos}^{-1}", options);
    },
    atan: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{tan}^{-1}", options);
    },
    asec: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{sec}^{-1}", options);
    },
    acsc: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cosec}^{-1}", options);
    },
    acot: function (node: Node, options: any) {
        return handleBracketsLatex(node.args[0], "\\mathrm{cotan}^{-1}", options);
    },
    f_clock: function (node: Node): string {
        let hour: string = "";
        let colon: string = ":";
        let minute: string = node.args[1].toString();
        let post: string = "";

        function fixLeadingZero(value: string): string {
            if (value.length === 2) {
                return value;
            }
            return "0" + value;
        }

        switch (node.args[2]?.name) {
            case '"military"':
                if (+node.args[0] === 24) {
                    hour += "00";
                } else {
                    hour = fixLeadingZero(node.args[0].toString());
                }
                colon = "";
                break;
            case '"24-hour"':
                if (+node.args[0] === 24) {
                    hour += "0";
                } else {
                    hour = node.args[0].toTex();
                }
                break;
            case '"12-hour"':
            default:
                post = " \\mathrm{";
                if (+node.args[0] > 12) {
                    hour = (+node.args[0] - 12).toString();
                    if (+node.args[0] === 24) {
                        post += "am";
                    } else {
                        post += "pm";
                    }
                } else {
                    if (+node.args[0] === 0) {
                        hour = "12";
                    } else {
                        hour = node.args[0].toTex();
                    }
                    post += "am";
                }
                post += "}";
        }
        return hour + colon + fixLeadingZero(minute) + post;
    },
    f_stopwatch: function (node: Node): string {
        let ret_str = `${node.args[0]?.toString() || "0"}':${node.args[1]?.toString() || "0"}''`;

        if (node.args[3]?.name === '"fancy"') {
            ret_str += `~{\\scriptstyle{${node.args[2]?.toString() || "0"}}}`;
        } else {
            ret_str += ":" + (node.args[2]?.toString() || "0");
        }

        return ret_str;
    },

    toDP: function (node: Node): string {
        let num: any = stripQuotes(node.args[0]);
        const places = stripQuotes(node.args[1]);
        const mode = stripQuotes(node.args[2]);
        // Checks for imaginary number
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }
        let formattedNum: string;
        try {
            formattedNum = `${(mathjs as any).toDP(num, +places, mode)}`;
        } catch {
            formattedNum = `Error`;
        }
        if (mode !== "trimZeros") {
            formattedNum = fixDecimalPlaces(formattedNum, +places);
        }
        return formattedNum;
    },

    toSigFig: function (node: Node): string {
        let num: any = stripQuotes(node.args[0]);
        const places = stripQuotes(node.args[1]);
        const mode = stripQuotes(node.args[2]);
        // Checks for imaginary number
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }
        let formattedNum: string;
        try {
            formattedNum = `${(mathjs as any).toSigFig(num, places, mode)}`;
        } catch {
            formattedNum = `Error`;
        }

        return formattedNum;
    },

    toSci: function (node: Node): string {
        let num: any = stripQuotes(node.args[0]);
        const places = stripQuotes(node.args[1]);
        const mode = stripQuotes(node.args[2]);
        // Checks for imaginary number
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }
        let formattedNum: string;
        try {
            formattedNum = `${(mathjs as any).toSci(num, places, mode)}`;
        } catch {
            formattedNum = `Error`;
        }

        return formattedNum;
    },

    toEng: function (node: Node): string {
        let num: any = stripQuotes(node.args[0]);
        const places = stripQuotes(node.args[1]);
        const mode = stripQuotes(node.args[2]);
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }
        let formattedNum: string;
        try {
            formattedNum = `${(mathjs as any).toEng(num, places, mode)}`;
        } catch {
            formattedNum = `Error`;
        }

        return formattedNum;
    },

    trimZeros: function (node: Node): string {
        let num: any = stripQuotes(node.args[0]);
        // Checks for imaginary number
        if (num.match(/\d+(\.\d+)?\s*\*\s*i/)) {
            num = mathjs.complex(0, +num.split("*")[0]);
        }
        return `${(mathjs as any).trimZeros(num)}`;
    },

    del: function (node: Node, options: any): string {
        return `\\nabla`;
    },

    grad: function (node: Node, options: any): string {
        return `\\nabla ${node.args[0].toTex(options)}`;
    },

    divergence: function (node: Node, options: any) {
        return `\\nabla \\cdot ${node.args[0].toTex(options)}`;
    },

    curl: function (node: Node, options: any) {
        return `\\nabla \\times ${node.args[0].toTex(options)}`;
    },

    f_pDeriv: function (node: Node, options: any) {
        // node.args[0] is the function
        // node.args[1] is the array of derivatives
        // node.args[2] is the optional flag
        const args = node.args;
        const flag = stripQuotes(node.args[2]?.name.toUpperCase() ?? '"LIE"');
        if (!["PRIME", "SUBS", "LIE", "LIER", "LIES"].includes(flag)) {
            return "error";
        }

        const wrtArr: { wrt: string; order: number }[] = [];
        for (const wrt of node.args[1].args.map((n) => n.name)) {
            if (wrtArr[wrtArr.length - 1]?.wrt === wrt) {
                wrtArr[wrtArr.length - 1].order += 1;
            } else {
                wrtArr.push({ wrt, order: 1 });
            }
        }

        // if we have an expression input ...
        if (args[0] && args[0].name === "()") {
            let ans = "";
            const func = args[0].toTex(options);
            const braceslessFunc = args[0].args[0].toTex(options);
            // console.log("braceslessFunc", braceslessFunc);
            if (["LIE", "LIES", "LIER"].includes(flag)) {
                // construct denom
                let denom = "";
                for (const { wrt, order } of wrtArr) {
                    if (order === 1) {
                        denom += `\\partial ${wrt}`;
                    } else {
                        denom += `\\partial ${wrt}^{${order}}`;
                    }
                }
                const totalOrder = wrtArr.reduce((total, curr) => (total += curr.order), 0);

                if (flag === "LIE") {
                    let numer = "";
                    if (totalOrder === 1) {
                        numer = `\\partial ${braceslessFunc}`;
                    } else {
                        numer = `\\partial^{${totalOrder}} ${braceslessFunc}`;
                    }
                    return `\\frac{${numer}}{${denom}}`;
                } else if (flag === "LIER") {
                    return `\\frac{${makeNumer(totalOrder)}}{${denom}}\(${braceslessFunc}\)`;
                } else if (flag === "LIES") {
                    return `\\frac{${makeNumer(totalOrder)}}{${denom}}\\left[${braceslessFunc}\\right]`;
                }
            } else if (["PRIME", "SUBS"].includes(flag)) {
                const subscript = node.args[1].args.reduce((total, curr) => (total += curr.name), "");
                const totalOrder = wrtArr.reduce((total, curr) => (total += curr.order), 0);
                if (flag === "PRIME") {
                    let superscript = "";
                    if (totalOrder > 3) {
                        superscript = `^{(${totalOrder})}`;
                    } else {
                        superscript = "'".repeat(totalOrder);
                    }
                    return `${func}_{${subscript}}${superscript}`;
                } else if (flag === "SUBS") {
                    return `${func}_{${subscript}}`;
                }
            }
        }

        // we have a function name input ie f(x,y)
        try {
            let independentVars: string[] = [];
            if (node.args[0]?.type === "FunctionNode") {
                independentVars = node.args[0].args.map((val) => val.toString());
            }
            let varString = independentVars.length > 0 ? `(${independentVars.join(",")})` : "";
            let func: string = node.args[0].name;

            if (node.args[0].type === "UnitNode" && node.args[0].args[0].name === "_") {
                // this is the case where the user enters "_" to just have the partial deriv operator only
                func = "empty";
            } else if (func === "Unit") {
                // if func is simply "f" (or similar), without "f" being defined as a variable, this will be a Unit
                func = node.args[0].args[0].name;
            } else if (func.length > 1) {
                func = `\\mathrm{${func}}`; // func name should be mathrmed if two chars or more
            }

            // stuff like cos x doesn't want () around the independent vars
            if (node.args[0].name.length > 1 && independentVars.length === 1 && independentVars[0].length === 1) {
                varString = independentVars.length > 0 ? `~${independentVars[0]}` : "";
            }

            let ans = "error";
            if (["LIE", "LIER", "LIES"].includes(flag)) {
                // construct denom
                let denom = "";
                for (const { wrt, order } of wrtArr) {
                    if (order === 1) {
                        denom += `\\partial ${wrt}`;
                    } else {
                        denom += `\\partial ${wrt}^{${order}}`;
                    }
                }
                const totalOrder = wrtArr.reduce((total, curr) => (total += curr.order), 0);

                if (flag === "LIE") {
                    let numer = "";
                    if (func === "empty") {
                        numer = makeNumer(totalOrder);
                    } else {
                        if (totalOrder === 1) {
                            numer = `\\partial ${func}${varString}`;
                        } else {
                            numer = `\\partial^{${totalOrder}} ${func}${varString}`;
                        }
                    }
                    return `\\frac{${numer}}{${denom}}`;
                } else if (flag === "LIER") {
                    return `\\frac{${makeNumer(totalOrder)}}{${denom}}\\left(${func}${varString}\\right)`;
                } else if (flag === "LIES") {
                    return `\\frac{${makeNumer(totalOrder)}}{${denom}}\\left[${func}${varString}\\right]`;
                }
            } else if (["PRIME", "SUBS"].includes(flag)) {
                const subscript = node.args[1].args.reduce((total, curr) => (total += curr.name), "");
                const totalOrder = wrtArr.reduce((total, curr) => (total += curr.order), 0);
                if (flag === "PRIME") {
                    let superscript = "";
                    if (totalOrder > 3) {
                        superscript = `^{(${totalOrder})}`;
                    } else {
                        superscript = "'".repeat(totalOrder);
                    }
                    return `${func}_{${subscript}}${superscript}${varString}`;
                } else if (flag === "SUBS") {
                    return `${func}_{${subscript}}${varString}`;
                }
            }
            return ans;
        } catch (e) {
            return "error";
        }
        return "error";

        function makeNumer(totalOrder: number) {
            let numer = "";
            if (totalOrder === 1) {
                numer = `\\partial`;
            } else {
                numer = `\\partial^{${totalOrder}}`;
            }
            return numer;
        }
    },

    laplace: function (node: Node, options: any) {
        const func = node.args[0].toTex(options);
        let option = `"curly"`;
        if (node.args[1]) {
            option = node.args[1].name;
        }
        if (option === `"curly"`) {
            return `\\mathscr{L}\\left\\{ ${func} \\right\\}`;
        } else if (option === `"round"`) {
            return `\\mathscr{L}\\left( ${func} \\right)`;
        } else if (option === `"square"`) {
            return `\\mathscr{L}\\left[ ${func} \\right]`;
        } else if (option === `"definition"`) {
            return `\\int_{0}^{\\infty} ${func}\\mathrm{e}^{-st} \\mathrm{d}t`;
        }
    },

    laplaceInv: function (node: Node, options: any) {
        const func = node.args[0].toTex(options);
        let option = `"curly"`;
        if (node.args[1]) {
            option = node.args[1].name;
        }
        if (option === `"curly"`) {
            return `\\mathscr{L}^{-1}\\left\\{ ${func} \\right\\}`;
        } else if (option === `"round"`) {
            return `\\mathscr{L}^{-1}\\left( ${func} \\right)`;
        } else if (option === `"square"`) {
            return `\\mathscr{L}^{-1}\\left[ ${func} \\right]`;
        }
    },

    f_yValueOfLocalMaxima: function (node: Node, options: any) {
        return "error";
    },

    f_yValueOfLocalMinima: function (node: Node, options: any) {
        return "error";
    },
    evalUsingSigFigRules: function (node: Node, options: any) {
        return node.solve().eval().toString();
    },
};
