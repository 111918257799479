import { relabelerWIthMap } from "../DiffFinder/DiffFinder";
import { pruneFromTheTop } from "../DiffFinder/DiffFinderTopPrune";
import { getAllRegexMatches, Node, TreePair } from "../modules";
import { Parameter } from "../modules/Archetype";
import { ParenthesisNode } from "../parser/ParenthesisNode";
import { parse } from "../parser/parse";
import { SymbolNode } from "../parser/SymbolNode";

/**
 * Takes a relabelled diff and returns a simplified diff.
 * See the Wiki: https://gitlab.com/jaipuna/AmI/-/wikis/js-internal/DiffSimplifier
 *
 * @export
 * @param {{ source: Node; target: Node }} trees
 * @param {Map<string, string>} diffMap eg: X0 => {a+b}, X1 => y
 * @param {Parameter[]} params
 * @param {boolean} [debug]
 * @return {*}
 */
export function simplifyDiff(
    trees: { source: Node; target: Node },
    diffMap: Map<string, string>,
    vars?: string[],
    debug?: boolean,
) {
    const simplifyFuncs = [
        generifyConstantsSimplifier, //   eg: {a} + {n} => {a + n} ----> {a} + {X0} => {a + X0}
        generifyBracketsSimplifier, //    eg: $({a})$ + {b} => ({a + b}) ----> ({a}) + {b} => ({a + b})
        dupeNodeSimplifier, //            eg: {a} + {a} => {a + a} ----> {a} + {b} => {a + b}
        irregularSubtreeReplacer, //      eg: -{a} + {b - c} => {-a + b - c} ----> -{a} + {X0} => {-a + X0}
        negativeOutsideEvalSimplifier, // eg: {a} + -{b} => {a + -b} ----> {a} + {-b} => {a + -b}
        sameOpMultTimesSimplifier, //     eg: {a} * {b} - {c} * {d} => {a * b} - {c * d} ----> {a} * {b} => {a * b}
        replaceFDivInEvalSimplifier, //   eg: f_div({b}, {c}) => {f_div(b, c)} ----> f_div({b}, {c}) => {b / c}
        removeBracketsSimplifier, //      eg: {a} + {b} => ({a + b}) ----> {a} + {b} => {a + b}
        multipleSameOpsSimplifier, //     eg: {a} * {b} * {c} => {a * b * c} ----> {a} * {b} => {a * b}
    ];

    // make a copy of diffmap we can edit
    // map of relabled node => original subree / node
    const simpMap = diffMap;

    for (const func of simplifyFuncs) {
        const { diff: newTrees, map } = func(trees);

        if (debug) {
            if (newTrees.source !== trees.source) {
                console.log(`${func.name} changed ${trees.source} to ${newTrees.source}`);
            }
            if (newTrees.target !== trees.target) {
                console.log(`${func.name} changed ${trees.target} to ${newTrees.target}`);
            }
        }

        // update the node map
        for (const [key, val] of map) {
            let subtree = parse(val, vars);
            subtree = subtree.findAndReplace(
                (node) => simpMap.has(node.name),
                (node) => parse(simpMap.get(node.name)),
            );

            if (simpMap.has(key)) {
                simpMap.set(key, subtree.toString());
            } else {
                simpMap.set(key, subtree.toString());
            }
        }

        trees = newTrees;
    }

    const { expressions, keyMap } = relabelerWIthMap([trees.source.toString(), trees.target.toString()]);

    // update the simpMap with the new relabling
    const relabeledMap = new Map();
    for (const [key, val] of keyMap) {
        relabeledMap.set(key, simpMap.get(val));
    }

    return {
        diff: {
            source: parse(expressions[0], vars),
            target: parse(expressions[1], vars),
        },
        map: relabeledMap,
    };
}

// ===========================================  Simplifiers  =========================================== //

export function irregularTopPruning(trees: TreePair) {
    const { source, target } = trees;

    function reverseTree(tree: Node) {
        let ret = tree.cloneDeep();
        for (const node of ret.getBFS()) {
            node.args = node.args.reverse();
        }
        return ret;
    }

    // revers the tree and generate another diff
    const revdiff = pruneFromTheTop(parse(reverseTree(source).toString()), parse(reverseTree(target).toString()));

    // unreverse the tree.
    return {
        diff: { source: reverseTree(revdiff.source), target: reverseTree(revdiff.target) },
        map: new Map<string, string>(),
    };
}

/**
 * Simplifies multiple operatos solvinf
 * eg: {a} * {b} * {c} => {a * b * c} ----> {a} * {b} => {a * b}
 * Note: This applies to all associative operators, not just multiplication
 * @export
 * @param {TreePair} trees
 * @return {*}
 */
export function multipleSameOpsSimplifier(trees: TreePair) {
    const { source, target } = trees;

    // this is a map from original node => simplification node
    const nodeMap = new Map<string, string>();

    // check that source is an op and that is the same op inside {...} in target
    if (
        source.type === "OperatorNode" &&
        source.args.length > 2 &&
        source.args.every((node) => node.type === "EvalNode") &&
        target.type === "EvalNode" &&
        source.toString().replace(/\{|\}|\s/g, "") === target.args[0].toString().replace(/\s/g, "")
    ) {
        const newXNode = `X${getNextXNode(source)}`;

        nodeMap.set(
            newXNode,
            target.args[0].args
                .slice(-2)
                .map((val) => val.toString())
                .join(source.name),
        );

        // take the first n-1 nodes and marge them under a Z1
        source.args = [...source.args.slice(0, -2), parse(`{${newXNode}}`)];
        target.args[0].args = [...target.args[0].args.slice(0, -2), new SymbolNode(newXNode)];
    }

    return { diff: trees, map: nodeMap };
}

/**
 * Removes unnecessary brackets
 * eg: {a} + {b} => ({a + b}) ----> {a} + {b} => {a + b}
 * @export
 * @param {TreePair} trees
 * @return {*}
 */
export function removeBracketsSimplifier(trees: TreePair) {
    let testSource = trees.source.cloneDeep();
    let testTarget = trees.target.cloneDeep();

    for (const tree of [testSource, testTarget]) {
        for (const node of tree.getBFS()) {
            if (node.type === "ParenthesisNode") {
                if (
                    !node.parent || // root is a parens nodes
                    node.args[0].isLeaf() || // only child is a leaf
                    (node.args[0].type === "EvalNode" && node.args[0].args[0].isLeaf()) || //TODO iseval
                    (node.parent && node.parent.isFunction()) || // inputs in functions
                    (node.parent && node.parent.isEvalNode()) || // (...) directly below {...}
                    node.args[0].isFunction() // arg is a function
                ) {
                    if (tree === testSource) testSource = node.replaceInTree(node.args[0]);
                    if (tree === testTarget) testTarget = node.replaceInTree(node.args[0]);
                }
            }
        }
    }
    const nodeMap = new Map<string, string>(); // nothing to remap
    if (testSource.toString() !== testTarget.toString()) {
        return { diff: { source: testSource, target: testTarget }, map: nodeMap };
    } else {
        return { diff: trees, map: nodeMap };
    }
}

/**
 * Replace duplicate nodes with a X-node
 * For example: {a} + {a} => {a + a} ----> {a} + {b} => {a + b}
 * or -{a} + -{a} => {-a + -a} ----> -{a} + -{b} => {-a + -b}
 * @param trees
 * @returns
 */
export function dupeNodeSimplifier(trees: TreePair) {
    return sourceTargetRenamer(
        trees,
        (val) => val.type === "SymbolNode" && !val.getAncestors().find((anc) => anc.isUnit()),
    );
}

/**
 * Any diff where the same operation is evaluated multiple times is equivalent
 * For example: {a} * {b} - {c} * {d} => {a * b} - {c * d} ----> {a} * {b} => {a * b}
 * Note: This should run *after* "dupeNodeSimplifier"
 * @param trees
 * @returns
 */
export function sameOpMultTimesSimplifier(trees: TreePair) {
    const nodeMap = new Map<string, string>();

    // look at target, as associative nodes in source make things difficult.
    // then compare these with source
    /**
     * Maps a generic subtree (string) to an array of specific subtrees that match
     * that pattern (also strings), for example: "{X + X}" -> ["{X0 + X1}", "{X2 + X3}"]
     */
    const patternMap = new Map<string, string[]>();
    for (const node of trees.target.getBFS()) {
        // make sure subtree has an operator (but more than a single neg) or a custom func
        if (
            node.getNodes((n) => ["+", "-", "*", "/", "f_div", "^"].includes(n.name) && n.args.length > 1).length ===
                0 &&
            node.getNodes((n) => n.isFunction()).length === 0
        ) {
            continue;
        }
        addKey(patternMap, node);
        // if ass node has more than two args, add combinations of two (note: add three+)
        // add pairs of two
        if (node.isAssociative() && node.args.length > 2) {
            for (let i = 0; i < node.args.length; i += 2) {
                const assNode = parse(node.toString());
                assNode.args = assNode.args.slice(i, i + 2);
                addKey(patternMap, assNode);
            }
        }
    }

    // filter out any nonrepeated subtrees, and sort by how often they appear (most to least)
    let duplicatedPatterns = Array.from(patternMap, ([key, val]) => ({ key, val }))
        .filter((obj) => obj.val.length > 1) // only duplicates
        .filter((obj) => obj.val[0].match(/X([0-9]+)/)) // fixes error with subtrees not containing X-Node
        .sort((a, b) => b.val[0].length - a.val[0].length) // longest bits
        .sort((a, b) => b.val.length - a.val.length); // most common
    // console.log("duplicatedPatterns", duplicatedPatterns);

    if (duplicatedPatterns.length === 0) {
        return { diff: trees, map: nodeMap };
    }

    // get the first entry in order (that is, "{X0+X1}" before "{X4+X5}")
    const longestDupeSubtree = duplicatedPatterns[0].val.sort((a, b) => {
        const aFirstNum = +getAllRegexMatches(a, /X([0-9]+)/g)[0][1]; // get number n from first X node Xn
        const bFirstNum = +getAllRegexMatches(b, /X([0-9]+)/g)[0][1];
        if (!isNaN(aFirstNum) && !isNaN(bFirstNum)) {
            return aFirstNum - bFirstNum;
        }
        return 0;
    });
    // console.log("longestDupeSubtree", longestDupeSubtree);

    // check each of these subtrees exist in the source
    let sourceString: string = "";
    // try to remove the {}s and put {} around each X node, and see if we find something
    for (const dupe of longestDupeSubtree) {
        const modifiedDupe = dupe.replace(/[\{\}]/g, "").replace(/(X[0-9]+)/g, "{$1}");
        if (trees.source.toString().includes(modifiedDupe)) {
            sourceString = modifiedDupe;
            break; // we only need one
        }
    }

    // if this failed, put {} around the whole thing, and see if we find something
    if (sourceString === "") {
        for (const dupe of longestDupeSubtree) {
            const modifiedDupe = `{${dupe.replace(/[\{\}]/g, "")}}`;
            if (trees.source.toString().includes(modifiedDupe)) {
                sourceString = modifiedDupe;
                break; // we only need one
            }
        }
    }

    // if this failed, try put {} around each X node
    if (sourceString === "") {
        for (const dupe of longestDupeSubtree) {
            const modifiedDupe = putCurliesAroundXNodes(`${dupe.replace(/[\{\}]/g, "")}`);
            if (trees.source.toString().includes(modifiedDupe.toString())) {
                sourceString = modifiedDupe.toString();
                break; // we only need one
            }
        }
    }

    // try replace {} with () and put {} around X nodes
    if (sourceString === "") {
        for (const dupe of longestDupeSubtree) {
            const modifiedDupe = putCurliesAroundXNodes(`${dupe.replace(/[\{]/g, "(").replace(/[\}]/g, ")")}`);
            if (trees.source.toString().includes(modifiedDupe.toString())) {
                sourceString = modifiedDupe.toString();
                break; // we only need one
            }
        }
    }

    // didn't find anything
    if (sourceString === "") {
        return { diff: trees, map: nodeMap };
    }

    // construct the node map
    const newKeys = getSymbols(sourceString);
    const symbolNames = longestDupeSubtree.map((tree) => getSymbols(tree));
    for (const [index, key] of newKeys.entries()) {
        const newValsArr = symbolNames.map((n) => n[index]);
        nodeMap.set(key, `(${newValsArr.join("+")})/${newValsArr.length}`);
    }

    // we know these array have length >= 1
    return { diff: { source: parse(sourceString), target: parse(longestDupeSubtree[0]) }, map: nodeMap };

    function getSymbols(tree: string) {
        return parse(tree)
            .getNodes((n) => n.type === "SymbolNode")
            .map((n) => n.name);
    }

    function addKey(patternMap: Map<string, string[]>, node: Node) {
        const key = node.toString().replace(/X[0-9]+/g, "X");
        if (patternMap.has(key)) {
            patternMap.set(key, [...patternMap.get(key), node.toString()]);
        } else {
            patternMap.set(key, [node.toString()]);
        }
    }

    function getXNames(expression: string) {
        return parse(expression)
            .getNodes((n) => n.name.includes("X"))
            .map((n) => n.name);
    }

    function putCurliesAroundXNodes(expression: string) {
        return parse(expression).findAndReplace(
            (n) =>
                (n.name.includes("X") && n.parent && n.parent.name !== "-") || // is an XNode without a single neg parent
                (n.name === "-" && n.args.length === 1 && n.args[0].name.includes("X")), // single neg with X node arg
            (n) => {
                if (n.parent.name === "-" && n.parent.args.length === 1) {
                    // if a single neg, enclose it
                    return parse(`{${n.parent}}`);
                }
                return parse(`{${n}}`);
            },
        );
    }
}

/**
 * Replaces any f_div inside {...} with "/"
 * For example: f_div({b}, {c}) => {f_div(b, c)} ----> f_div({b}, {c}) => {b / c}
 * @param trees
 * @returns
 */
export function replaceFDivInEvalSimplifier(trees: TreePair) {
    for (const node of trees.target.getBFS()) {
        if (node.name === "f_div" && node.isInEval()) {
            const slashNode = parse("1/1");
            slashNode.args = node.args;
            trees.target = node.replaceInTree(slashNode, trees.target);
        }
    }
    const nodeMap = new Map<string, string>(); // nothing to remap
    return { diff: trees, map: nodeMap };
}

/**
 * Replace (...) and $(...)$ with (...)
 * For example: $({a})$ + {b} => ({a + b}) ----> ({a}) + {b} => ({a + b})
 * @param trees
 * @returns
 */
export function generifyBracketsSimplifier(trees: TreePair) {
    const findFunc = (node: Node) => node.type === "ParenthesisNode" && (node as ParenthesisNode).isGhost === true;
    const replaceFunc = (node: Node) => {
        const newNode = parse("(1)");
        newNode.args = node.args;
        return newNode;
    };
    trees.source = trees.source.findAndReplace(findFunc, replaceFunc);
    trees.target = trees.target.findAndReplace(findFunc, replaceFunc);
    const nodeMap = new Map<string, string>(); // nothing to remap
    return { diff: trees, map: nodeMap };
}

function swapNegative(tree: Node, singleNegToMove: Node) {
    // first, join parent and child
    const child = singleNegToMove.args[0];
    const parent = singleNegToMove.parent;

    // Checks if child is an operation with more then one part. E.g. X1 + X2
    // otherwise, ignore. E.g. +X1
    if (child.args[0].type === "OperatorNode" && child.args[0].args.length !== 1) {
        const newNode = new ParenthesisNode(child.args, child);
        child.args = [newNode];
    }

    if (parent) {
        parent.replaceArg(singleNegToMove, child);
    } else {
        tree = child;
    }
    // second, join singleNeg and grandkids
    const tempArgs = [...child.args];
    singleNegToMove.parent = child;
    child.args = [singleNegToMove];
    singleNegToMove.args = tempArgs;

    return tree;
}
/**
 * Any negative outside a eval with only one child is automatically moved inside
 * For example: {a} + -{b} => {a + -b} ----> {a} + {-b} => {a + -b}
 * @param trees
 * @returns
 */
export function negativeOutsideEvalSimplifier(trees: TreePair) {
    const pred = (node) =>
        !node.isInEval() && node.name === "-" && node.args.length === 1 && node.args[0].type === "EvalNode";
    let singleNegToMove = trees.source.find(pred);

    let { source, target } = trees;
    while (singleNegToMove) {
        // swap singleNegToMove and it's (single) arg, the eval node
        source = swapNegative(source, singleNegToMove);
        singleNegToMove = source.find(pred);
    }

    singleNegToMove = target.find(pred);
    while (singleNegToMove) {
        // swap singleNegToMove and it's (single) arg, the eval node
        target = swapNegative(target, singleNegToMove);
        singleNegToMove = target.find(pred);
    }
    const nodeMap = new Map<string, string>(); // nothing to remap
    return { diff: { source, target }, map: nodeMap };
}

/**
 * Any ConstantNode with a value not equal to 0, 1 or a multiple of 10 is replaced with a X-nodes
 * For example: {a} + {n} => {a + n} ----> {a} + {X0} => {a + X0}
 * where  n is any number, aside from 0, 1 and multiples of 10
 * @param trees
 * @returns
 */
export function generifyConstantsSimplifier(trees: TreePair) {
    const searchFunc = (val: Node) =>
        // val.type === "SymbolNode" ||
        val.type === "ConstantNode" && val.name !== "0" && val.name !== "1" && !isPowerOfTen(+val.name);

    return sourceTargetRenamer(trees, searchFunc);
}

/**
 * Any bedmas DiffFinder irregular Subtrees not replaced should be replaces by and X-Node
 * For example: -{a} + {b - c} => {-a + b - c} ----> -{a} + {X0} => {-a + X0}
 * Note that this should go after the dupeNodeSimplifier and before the negativeOutsideEval.
 * @param trees
 */
export function irregularSubtreeReplacer(trees: TreePair) {
    const nodeMap = new Map<string, string>();
    const sourceTree = trees.source;
    // find the highest X node and start from that +1
    let counter = getNextXNode(trees.source);

    let relabelledSource = trees.source.toString().replace(/\s/g, "");
    let relabelledTarget = trees.target.toString().replace(/\s/g, "");
    for (const node of sourceTree.getBFS()) {
        if (node.isEvalNode() && node.find((n) => n.type === "OperatorNode")) {
            const subtree = node.args[0].toString().replace(/\s/g, "");
            // check if exists in both
            if (relabelledSource.includes(subtree) && relabelledTarget.includes(subtree)) {
                const newName = `X${counter}`;
                relabelledSource = replaceAll(relabelledSource, subtree, newName);
                relabelledTarget = replaceAll(relabelledTarget, subtree, newName);
                nodeMap.set(newName, subtree);
            }
        }
    }
    return { diff: { source: parse(relabelledSource), target: parse(relabelledTarget) }, map: nodeMap };
}

// ------------------------------------ helper functions --------------------------------------------

/**
 * Filter nodes in the source and target by the searchFunc function, and replace with a new X node
 * @param trees
 * @param searchFunc
 * @returns
 */
function sourceTargetRenamer(trees: TreePair, searchFunc: (node: Node) => boolean) {
    const nodeMap = new Map<string, string>();
    let { source, target } = trees;
    // console.log(source.toAscii(), "\n\n\n", target.toAscii());

    let sLeaves = source.getIOT().filter(searchFunc);
    let tLeaves = target.getIOT().filter(searchFunc);
    let renameCounter = Math.max(getNextXNode(source), getNextXNode(target));

    const needExistingX = ["gcd"];
    const tNeedExistingXChildren = [...tLeaves].filter((node) =>
        node.getAncestors().find((anc) => needExistingX.includes(anc.name)),
    );
    let postponed = 0;

    while (sLeaves.length > 0 || tLeaves.length > 0) {
        let sPointer = 0;
        let tPointer = 0;
        let foundMatch = false;

        // Postpone leaves that were created based on leaves from source
        if (postponed < tNeedExistingXChildren.length && tNeedExistingXChildren.includes(tLeaves[tPointer])) {
            tLeaves.push(tLeaves.splice(tPointer, 1)[0]);
            postponed++;
            continue;
        }

        if (sLeaves.length > 0) {
            // Look for match of source in targetNodes
            for (tPointer; tPointer < tLeaves.length; tPointer++) {
                if (sLeaves[sPointer].name === tLeaves[tPointer].name) {
                    const newName = `X${renameCounter}`;
                    renameCounter += 1;

                    // Match leaves that wont pair with another from source, but were created based on leaves from source
                    // E.g.: {a} / {b} -> {a / gcd(a, b)} / {b}
                    const matchX = tNeedExistingXChildren.filter((node) => node.name === tLeaves[tPointer].name);
                    if (matchX.length > 0) {
                        matchX.map((node) => {
                            if (node !== tLeaves[tPointer]) {
                                target = replaceWithNewNode(node, target, newName);
                                tLeaves = tLeaves.filter((val) => val !== node);
                            }
                        });
                    }

                    // Match all occurrences of multiplication distributive property
                    // Search only for the case where the distribution happens in the target
                    if (isDistributive(sLeaves[sPointer]) && !isDistributive(tLeaves[tPointer])) {
                        if (tLeaves[tPointer].parent && tLeaves[tPointer].parent.name === "*") {
                            const distributed = tLeaves.filter(
                                (node) =>
                                    node !== tLeaves[tPointer] &&
                                    node.name === tLeaves[tPointer].name &&
                                    node.parent.name === "*",
                            );
                            distributed.map((node) => {
                                target = replaceWithNewNode(node, target, newName);
                                tLeaves = tLeaves.filter((val) => val !== node);
                            });
                        }
                    }

                    // Match all occurrences of sum/subtraction of fractions with the same denominator
                    const division = sLeaves[sPointer].getAncestors().find((node) => node.name === "/");
                    if (division) {
                        const ops = division.getAncestors().filter((node) => node.type === "OperatorNode");

                        // Usage of position ops.length - 2 is done because position ops.length - 1 is the division sign
                        const opNode =
                            ops.length > 1 && (ops[ops.length - 2].name === "-" || ops[ops.length - 2].name === "+")
                                ? ops[ops.length - 2]
                                : undefined;

                        if (opNode) {
                            // Find all denominators with the same name as sLeaves[sPointer] and replace for the same X node
                            const sameDenominator = sLeaves.filter((node) => {
                                const opsNode = node.getAncestors().filter((node) => node.type === "OperatorNode");
                                if (opsNode.length > 1) {
                                    return (
                                        node !== sLeaves[sPointer] &&
                                        node.name === sLeaves[sPointer].name &&
                                        opsNode[opsNode.length - 1].name === "/" &&
                                        opsNode[opsNode.length - 2] === opNode
                                    );
                                }
                            });
                            sameDenominator.map((node) => {
                                source = replaceWithNewNode(node, source, newName);
                                sLeaves = sLeaves.filter((val) => val !== node);
                            });
                        }
                    }

                    source = replaceWithNewNode(sLeaves[sPointer], source, newName);
                    target = replaceWithNewNode(tLeaves[tPointer], target, newName);
                    nodeMap.set(newName, sLeaves[sPointer].name);

                    foundMatch = true;
                    break;
                }
            }
        }
        if (tLeaves.length > 0) {
            // Look for match of target in sourceNodes
            if (!foundMatch) {
                tPointer = 0;
                for (sPointer; sPointer < sLeaves.length; sPointer++) {
                    if (sLeaves[sPointer].name === tLeaves[tPointer].name) {
                        const newName = `X${renameCounter}`;
                        renameCounter += 1;

                        source = replaceWithNewNode(sLeaves[sPointer], source, newName);
                        target = replaceWithNewNode(tLeaves[tPointer], target, newName);
                        nodeMap.set(newName, sLeaves[sPointer].name);

                        foundMatch = true;
                        break;
                    }
                }
            }
        }

        // if we find a match, update leaf arrays
        if (foundMatch) {
            sLeaves.splice(sPointer, 1);
            tLeaves.splice(tPointer, 1);
        }
        // if we couldnt find a match for either, rename serperately
        else {
            sPointer = 0;
            tPointer = 0;

            if (sLeaves.length > 0) {
                // Replace all instances of unmatched nodes in their own trees, as we want to maintain duplicates which are only in one tree
                const newName = `X${renameCounter}`;
                renameCounter += 1;

                source = source.findAndReplace(
                    (node) => node.name === sLeaves[sPointer].name,
                    () => new SymbolNode(newName),
                );
                nodeMap.set(newName, sLeaves[sPointer].name);
                sLeaves = sLeaves.filter((val) => val.name !== sLeaves[sPointer].name);
            }

            if (tLeaves.length > 0) {
                const newName = `X${renameCounter}`;
                renameCounter += 1;

                target = target.findAndReplace(
                    (node) => node.name === tLeaves[tPointer].name,
                    () => new SymbolNode(newName),
                );
                nodeMap.set(newName, tLeaves[tPointer].name);
                tLeaves = tLeaves.filter((val) => val.name !== tLeaves[tPointer].name);
            }
        }
    }
    // console.log(source.toAscii(), "\n\n\n", target.toAscii());
    return { diff: { source, target }, map: nodeMap };
}

/**
 * Checks if there is a multiplication ancestor and:
 *  - if leaf is a direct child of a sum/subtraction node with more then one leaf
 *  - if there is a sum/subtraction sibling
 *
 * @param {Node} leaf
 * @return {*}  {boolean}
 */
function isDistributive(leaf: Node) {
    const ancestors = leaf.getAncestors();
    const ops = ancestors.filter((node) => node.type === "OperatorNode");
    ops.reverse();
    const multiplicationNode = ops.find((node) => node.name === "*");
    if (multiplicationNode) {
        const siblings = multiplicationNode.args.filter((node) => !node.getPathToNode(leaf));
        const siblingsOps: Node[] = [];
        siblings.map((sibling) =>
            siblingsOps.push(...sibling.getNodes((node) => node.name === "-" || node.name === "+")),
        );

        if (
            (ops[0].getLeaves().length > 1 && (ops[0].name === "-" || ops[0].name === "+")) ||
            (multiplicationNode === ops[0] && siblingsOps.filter((node) => node.getLeaves().length > 1).length)
        ) {
            return true;
        }
    }
    return false;
}

function replaceWithNewNode(node: Node, root: Node, newName: string) {
    const newNode = new SymbolNode(newName);
    newNode.args = node.args;
    return node.replaceInTree(newNode, root);
}

function isPowerOfTen(num: number) {
    while (num >= 10 && num % 10 == 0) num /= 10;
    return num === 1;
}

/**
 * Inserts \\ before regex symbols
 * @param string
 * @returns
 */
function escapeRegExp(str: string) {
    return str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

/**
 * Replaces all occurances of the string "find" with "replace" in the string "str"
 * @param str
 * @param find
 * @param replace
 * @returns
 */
function replaceAll(str: string, find: string, replace: string) {
    return str.replace(new RegExp(escapeRegExp(find), "g"), replace);
}

function getNextXNode(tree: Node) {
    const maxXNode = tree
        .getNodes((node) => node.type === "SymbolNode" && node.name.startsWith("X"))
        .map((val) => parseFloat(val.name.slice(1)))
        .reduce((prev, curr) => (curr > prev ? curr : prev), -1);
    return maxXNode + 1;
}
