import { countableFuncs, implicitFuncs } from "../modules";
import { fixNegativeZero, reverseString } from "../utilities";
import { ConstantNode } from "./ConstantNode";
import { Node } from "./Node";
import { parse } from "./parse";

const operatorSpecialTex = new Map([
    ["mod", "\\mod"],
    ["and", "\\wedge"],
    ["xor", "\\veebar"],
    ["or", "\\vee"],
]);
type opSymbolType =
    | "%"
    | "+"
    | "-"
    | "/"
    | "*"
    | "!"
    | ">"
    | "<"
    | ">="
    | "<="
    | "=="
    | "!="
    | "^"
    | "and"
    | "xor"
    | "or";

type Modes = "norm" | "implicit" | "explicit" | "dot";
export class OperatorNode extends Node {
    mode: Modes;
    private _OpName: opSymbolType;

    constructor(name: string, args: Node[] = [], parent: Node = undefined, mode: Modes = "norm") {
        super(name, args, parent);
        this.type = "OperatorNode";
        this.mode = mode;
    }

    get name(): opSymbolType {
        return this._OpName;
    }

    set name(newName: opSymbolType) {
        this._OpName = newName;
    }

    toString(options?: any): string {
        const argsString = this.args.map((val) => val.toString(options));
        let output: string;

        if (this.name === "*") {
            if (this.mode === "implicit") {
                return `f_implicitMulti(${argsString.join(", ")})`;
            } else if (this.mode === "explicit") {
                return `f_explicitMulti(${argsString.join(", ")})`;
            } else if (this.mode === "dot") {
                return `f_dotMulti(${argsString.join(", ")})`;
            } else {
                output = `${argsString.join(` * `)}`;
            }
        } else if (this.name === "==") {
            output = `${argsString[0]} == ${argsString[1]}`;
        } else if (this.name === "-" && argsString.length === 1) {
            output = `-${argsString[0]}`;
            // } else if (this.name === "^") {
            //     output = `f_exp(${argsString.join(", ")})`;
        } else if (this.name === "!" && this.args.length === 1) {
            output = `${argsString[0]}!`;
        } else if (this.name === "^" && this.args.length === 2) {
            const [lhs, rhs] = this.args.map((val) => val.toString());
            // if the LHS is a unary minus it needs (...)
            if (this.args[0].name === "-" && this.args[0].args.length === 1) {
                output = `(${lhs}) ^ ${rhs} `;
            } else {
                output = `${lhs} ^ ${rhs} `;
            }
        } else {
            output = `${argsString.join(` ${this.name} `)}`;
        }

        return output;
    }

    toWord(options?: any): string {
        if (this.name === "-" && this.args.length === 1) {
            return `negative~${this.args[0].toWord()}`;
        } else if (this.name === "/") {
            if (this.args.every((val) => val.type === "ConstantNode")) {
                const [top, bottom] = this.args.map((val) => eval(val.name));
                const fraction = [
                    { single: "half", plural: "halves" },
                    { single: "third", plural: "thirds" },
                    { single: "quarter", plural: "quarters" },
                    { single: "fifth", plural: "fifths" },
                    { single: "sixth", plural: "sixths" },
                    { single: "seventh", plural: "sevenths" },
                    { single: "eighth", plural: "eighths" },
                    { single: "ninth", plural: "ninths" },
                    { single: "tenth", plural: "tenths" },
                ][bottom - 2];

                if (options?.fraction && fraction && options?.over !== true) {
                    if (top > 1 || top === 0) {
                        return `${this.args[0].toWord(options)}~${fraction.plural}`;
                    } else {
                        return `${this.args[0].toWord(options)}~${fraction.single}`;
                    }
                } else {
                    return `${this.args[0].toWord(options)}~over~${this.args[1].toWord(options)}`;
                }
            }
        } else if (this.name === "*") {
            return this.args.map((val) => val.toWord()).join("~times~");
        } else if (this.name === "+") {
            return this.args.map((val) => val.toWord()).join("~plus~");
        } else if (this.name === "-") {
            return this.args.map((val) => val.toWord()).join("~minus~");
        }

        return "NaN";
    }

    getMultLatex(node: Node, options: any): string {
        const validTypes: string[] = [
            "ParenthesisNode",
            "SymbolNode",
            "ConstantNode",
            "FunctionNode",
            "GhostBracketNode",
            "OperatorNode",
            "UnitNode",
        ];

        const validOps: string[] = ["-", "^", "/"];

        // a list of funcs which should not be prefaces by a "~"
        const noSpaceNames = [
            "f_multi",
            "f_Ghost",
            "sqrt",
            "f_latex",
            "degree",
            "degreeC",
            "degreeF",
            "plusMinus",
            "f_conj",
        ];
        const leftExplicit = ["cis", "log"];
        const rightExplicit = ["/", "$"]; // nodes when on the right should be preceeded by an explicit multi
        const eitherExplicit = ["f_percent", "f_div", "abs"]; // nodes when on either side should make the multi explicit

        let output: string = "";
        const opKids = node.args;

        // iterate through the multiplications kids eg: 2 * 3 * 4 => [2,3,4]
        for (const num of opKids.slice(0, opKids.length - 1)) {
            const kidNum = opKids.indexOf(num);
            // find what actual node is printed to the left and right of the * op
            const leftNeighbour = num.getLatexNeighbour("right");
            const rightNeighbour = opKids[kidNum + 1].getLatexNeighbour("left");

            const L: { type: string; name: string } = {
                type: leftNeighbour.type,
                name: leftNeighbour.name.replace(/\\mathrm\{([^\{\}]+)\}/g, "$1"),
            };
            const R: { type: string; name: string } = {
                type: rightNeighbour.type,
                name: rightNeighbour.name.replace(/\\mathrm\{([^\{\}]+)\}/g, "$1"),
            };

            // if either L or R are SYmbolic Constants, we treat those as Symbolnodes
            if (
                (leftNeighbour.type === "ConstantNode" && (leftNeighbour as ConstantNode).mode === "SymbolicNum") ||
                leftNeighbour.name === "f_latex"
            ) {
                L.type = "SymbolNode";
            }
            if (
                (rightNeighbour.type === "ConstantNode" && (rightNeighbour as ConstantNode).mode === "SymbolicNum") ||
                rightNeighbour.name === "f_latex"
            ) {
                R.type = "SymbolNode";
            }

            // Main cases
            // Removes * if a function with trimZeros is followed by a ConstantNode
            const trimZerosNConstant = L.name === '"trimZeros"' && R.type === "ConstantNode";
            const isNotImplicitType =
                (!validTypes.includes(L.type) || !validTypes.includes(R.type)) && trimZerosNConstant;
            const isGeneralExplicit = eitherExplicit.includes(L.name) || eitherExplicit.includes(R.name);
            const isSideBasedExplicit = leftExplicit.includes(L.name) || rightExplicit.includes(R.name);
            const isFractionExplicit =
                L.name === "/" &&
                R.type !== "SymbolNode" &&
                R.type !== "UnitNode" &&
                R.name !== "f_latex" &&
                R.type !== "ParenthesisNode" &&
                !implicitFuncs.includes(R.name);

            // check and see if it satisfies one of the cases
            let shouldBeImplicit = true;
            if (this.mode === "explicit") {
                shouldBeImplicit = false;
            } else if (this.mode === "norm") {
                if (
                    isNotImplicitType || //                                         If either are not valid then it cant be implicit
                    isGeneralExplicit || //                                         If either kid is on the list of invalid kids then fail.
                    isSideBasedExplicit || //                                       If either side is its respective explict node
                    isFractionExplicit || //                                        The Left is a fraction and the right isnt a valid "explicit" node
                    (L.type === "ConstantNode" && R.type === "ConstantNode") || //  The Left and Right cant both be numbers eg: 2 * 2 -> 22
                    ((L.type === "SymbolNode" || L.type === "UnitNode") &&
                        L.name !== "$" &&
                        R.type === "ConstantNode") || //                            The Left cant be a variable if the right is a number eg: a * 3 -> a3
                    (R.type === "OperatorNode" && R.name !== "^") || //             Used to handle the unary minus as any other op will be above * in the tree
                    (L.type === "OperatorNode" && !validOps.includes(L.name)) //    Handles unary minus
                ) {
                    shouldBeImplicit = false;
                }
            }

            // console.log(
            //     `${leftNeighbour}     ${rightNeighbour}   (${L.type}  ${R.type}) ${shouldBeImplicit} ${this.mode}`,
            // );
            // console.log(isNotImplicitType, isGeneralExplicit, isSideBasedExplicit, isFractionExplicit);
            // handle the unity case eg: 1 * x => x
            if (
                +L.name === 1 &&
                (R.type === "SymbolNode" || R.type === "UnitNode" || countableFuncs.includes(R.name)) &&
                this.mode === "norm"
            ) {
                // remove the first 1 from num
                const numString = reverseString(reverseString(num.toString()).replace(/^\s*0*\.?1/, "raVredlohecalp"));
                const numTEx = parse(numString, null, "USEIMPLICIT")
                    .toTex(options)
                    .replace(/placeholderVar/g, "");
                // if we actually have some tex to add, we shouldnt remove the \\times as it is based on the tex other than the removed 1
                if (numTEx.trim() === "") {
                    // if we decided the last op should be explicit (because of the 1 (which is now gone))
                    if (output.slice(output.length - 10) === " {\\times} ") {
                        output = output.slice(0, output.length - 10);
                        let space = " ";
                        // TODO: Merge this and the IF statement below. maybe make it a utility function?
                        if (
                            (R.type === "SymbolNode" && R.name.length > 1) ||
                            (rightNeighbour.type === "ConstantNode" &&
                                (rightNeighbour as ConstantNode).mode === "SymbolicNum") ||
                            L.type === "UnitNode" ||
                            (R.type === "UnitNode" &&
                                rightNeighbour.args[0] &&
                                !noSpaceNames.includes(rightNeighbour.args[0].name))
                        ) {
                            space = "~";
                        }
                        output += space;
                    }
                }

                output += numTEx;
            } else if (shouldBeImplicit) {
                let space = " ";
                if (
                    (R.type === "SymbolNode" && R.name.length > 1) ||
                    (rightNeighbour.type === "ConstantNode" &&
                        (rightNeighbour as ConstantNode).mode === "SymbolicNum") ||
                    (R.type === "FunctionNode" && noSpaceNames.indexOf(R.name) < 0) ||
                    L.type === "UnitNode" ||
                    (R.type === "UnitNode" &&
                        rightNeighbour.args[0] &&
                        !noSpaceNames.includes(rightNeighbour.args[0].name))
                ) {
                    // use the normal space for it and rm custom func modifiers between
                    // f and (x)
                    if (node.args[0].name === "it" || node.args[0].name === "rm") {
                        space = "\\hspace{.16667em plus .08333em}";
                    } else {
                        space = "~";
                    }
                }

                output += ` ${num.toTex(options)}${space}`;
            } else {
                output += num.toTex(options) + " {\\times} ";
            }
        }

        // now add the last kid onto the end
        output += opKids[opKids.length - 1].toTex(options);
        return output;
    }

    toTex(options?: any): string {
        // console.log("in operator node to tex");
        const argsLatex = this.args.map((val) => val.toTex(options));
        let output: string;

        if (this.name === "*") {
            if (this.mode !== "dot") {
                // make a copy of this node so we dont edit the original tree
                const multNode: OperatorNode = this.cloneDeep();
                // if we have a dollar, we need to check for the $-x case
                if (multNode.args.find((val) => val.toString() === "$")) {
                    multNode.args.forEach((val, index) => {
                        if (val.toString() !== "$") return;
                        // Look for a unary minus node after the $ node
                        let nextKid = multNode.args[index + 1];
                        while (nextKid && nextKid.name === "$()$") {
                            nextKid = nextKid.args[0];
                        }
                        // check for "$-x" case (if we have a $ and a unary minus)
                        if (nextKid && nextKid.name === "-" && nextKid.args.length === 1) {
                            // setup a multi node holding [$,x] and go under the "-"
                            const newMulti = new OperatorNode("*", [val, nextKid.args[0]], undefined, "implicit");
                            nextKid.args = [newMulti];
                            // remove $ from this multi, as we have added it below the -
                            multNode.removeArg(val);
                        }
                    });
                }
                // if f_multi has only one arg (the "-") we skip the latex for the * as it has 1 arg
                if (multNode.args.length === 1) {
                    output = multNode.args[0].toTex();
                } else {
                    output = multNode.getMultLatex(multNode, options);
                }
            } else {
                output = argsLatex.join(" \\cdot ");
            }
        } else if (this.name === "/") {
            output = ` \\frac{${argsLatex[0]}}{${argsLatex[1]}} `;
        } else if (this.name === "==") {
            output = ` ${argsLatex[0]} = ${argsLatex[1]} `;
        } else if (this.name === "!=") {
            output = ` ${argsLatex[0]} \\neq ${argsLatex[1]} `;
        } else if (this.name === "+" && argsLatex.length === 1) {
            output = ` + ${argsLatex[0]}`;
        } else if (this.name === "-" && argsLatex.length === 1) {
            // unary minus case
            // we want to collapse concurrent unary minus' into either a unary minus or nothing at all
            let minusCount = 1;
            let endNode = this.args[0];
            while (endNode.name === "-" && endNode.args.length === 1) {
                endNode = endNode.args[0];
                minusCount += 1;
            }
            output = ` ${minusCount % 2 === 1 ? "-" : ""} ${endNode.toTex(options)} `;
        } else if (this.name === "^") {
            let lhs = `{${this.args[0].toTex(options)}}`;
            // check if lhs arg is unary minus
            if (this.args[0].name === "-" && this.args[0].args.length === 1) {
                lhs = `\\left({${this.args[0].toTex(options)}}\\right)`;
            }
            const rhs = `{${this.args[1].toTex(options)}}`;
            output = ` ${lhs} ^ ${rhs} `;
        } else if (this.name === "<=") {
            output = ` ${argsLatex.join(` \\leq `)} `;
        } else if (this.name === "!") {
            output = ` ${argsLatex[0]}! `;
        } else if (this.name === ">=") {
            output = ` ${argsLatex.join(` \\geq `)} `;
        } else {
            const specialOpTex = operatorSpecialTex.get(this.name);
            const opTex = specialOpTex ? specialOpTex : this.name;
            output = ` ${argsLatex.join(` ${opTex} `)} `;
        }

        // console.log(`Generating tex for \n${this.toAscii()}\n\n${output}`);

        output = fixNegativeZero(output);

        return output;
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        const newNode = new OperatorNode(this.name, args, parent, this.mode);
        return newNode;
    }

    getLatexNeighbour(direction: "left" | "right") {
        if (this.name === "/") {
            return this;
        } else if (this.name === "-" && this.args.length === 1 && direction === "left") {
            return this;
        } else if (this.name === "^" && direction === "right") {
            return this.args[0].getLatexNeighbour(direction);
        } else {
            if (direction === "left") {
                return this.args[0].getLatexNeighbour(direction);
            } else if (direction === "right") {
                return this.args[this.args.length - 1].getLatexNeighbour(direction);
            }
        }
    }
}
