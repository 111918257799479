/**
 *  Compiles any amysyntax to a AmyRender format. is the first part of our rendering pipeline
 *      [ Compiler ] => AmyRender => Users Eyeballs
 *
 *  Author - Henry Seed (2021)
 *
 */

import { getInstructionSections, hasSymetricBrackets } from "@jaipuna/common-modules/dist/src/LatexUtilities";
import { getI18n, Languages } from "@jaipuna/shared-external";
import { ITuple, Node } from "../modules";
import { Parameter, Phrase } from "../modules/Archetype";
import { parse } from "../parser/parse";
import { compileInstrTemplate } from "./questionGenerator";

/**
 *  Compiles any amysyntax to a AmyRender format. is the first part of our rendering pipeline
 * ```
 * [ compileAmySyntax() ]  =>  <AmyRender/>  =>  User
 * ```
 * @export
 * @param {{
 *     text: string;
 *     parameters: Parameter[];
 *     archExps: string[];
 *     stepNum: number;
 *     diagrams?: Map<string, string>;
 * }}
 * @return {*}
 */
export function compileAmySyntax({
    text,
    parameters,
    archExps,
    stepNum,
}: {
    text: string;
    parameters: Parameter[];
    archExps: string[];
    stepNum: number;
}) {
    const { params, varMap, colorMap } = expandParameters(parameters);
    // add parameters to varMap so we can use them as such
    for (const param of parameters.filter((val) => val.type === "NUMBER")) {
        varMap.set(param.name, param.value);
    }

    const varNames = Array.from(varMap.keys());
    const compiledSteps = archExps
        .filter((val) => !!val)
        .map((val) => compileAmySyntax({ text: `${val}`, parameters, archExps: [], stepNum: 0 }));

    try {
        if (!text) {
            return text;
        }
        if (!hasSymetricBrackets(text)) {
            throw new Error("[ compileAmySyntax ] Error, non-symmetric Brackets");
        }

        const textSnippets: string[] = [];

        for (const section of getInstructionSections(text)) {
            // console.log(section);
            // $[...]$ LaTeX and t[...]t Raw Text Section ===================================================
            if ((section.type === "latex" || section.type === "rawText") && section.text) {
                // solve the tree, replaces params and evaluates {...}
                let solvedTree = treeBasedSolver(section.text, params, varNames);

                // reparse the tree to account for params eg:  1 + {a} // {a:-2}  ->  1 + - 2  ->  1 - 2
                solvedTree = parse(solvedTree.toString(), varNames, "USEIMPLICIT");

                // replace vars
                solvedTree = solvedTree.findAndReplace(
                    (node) => node.type === "SymbolNode" && varMap.get(node.name) !== undefined,
                    (node) => parse(varMap.get(node.name), null, "USEIMPLICIT"),
                );

                // replace colors
                solvedTree = solvedTree.findAndReplace(
                    (node) => node.type === "SymbolNode" && colorMap.get(node.name) !== undefined,
                    (node) => parse(colorMap.get(node.name), null, "USEIMPLICIT"),
                );

                const tex = solvedTree.toTex().trim().replace(/  /g, " ");

                if (tex.startsWith("t[") && tex.endsWith("]t")) {
                    textSnippets.push(tex.replace("t[", "").replace("]t", ""));
                } else if (section.type === "latex") {
                    textSnippets.push(`$[${tex}]$`);
                } else if (section.type === "rawText") {
                    textSnippets.push(solvedTree.toTex({ rawText: true }).trim().replace(/  /g, " "));
                }
            }

            // g[...]g Graph Section ========================================================================
            else if (section.type === "graph") {
                // extract the JSON from the graph string, parse into JSON
                let graphJSON = JSON.parse(section.text.slice(section.text.split("(")[0].length + 1, -1));

                // compile the JSON, replacing params etc
                graphJSON = compileInstrJSON(graphJSON, parameters);

                // insert the compiled JSON back into the graph string
                textSnippets.push(`g[${section.text.split("(")[0]}(${JSON.stringify(graphJSON)})]g`);
            }

            // d[...]d Diagram Section ======================================================================
            else if (section.type === "diagram") {
                let diagramJSON = JSON.parse(section.text);

                // replace the params in labels with their values
                diagramJSON.labels = compileInstrJSON(diagramJSON.labels, parameters, true);

                // we now add a new property called SVGCode
                diagramJSON.svgCode = "";
                textSnippets.push(`d[${JSON.stringify(diagramJSON)}]d`);
            }
            // Standard Text Section ========================================================================
            else {
                section.text = section.text.replaceAll("<ghost-table>", "<ghost-table>\n");
                section.text = section.text.replaceAll("</ghost-table>", "\n</ghost-table>");
                textSnippets.push(section.text);
            }
        }

        // now replace all the questionLocals
        const questionText = compileInstrTemplate(textSnippets.join(""), params, compiledSteps, stepNum);

        // console.log("compiledSteps", { text, questionText, compiledSteps });
        return questionText;
    } catch (e) {
        throw new Error(`[ compileAmySyntax ] Text2: ${text} \nError: ${e.message}`);
    }
}

// ==================================================== Compilation Helpers ==================================================== //

/**
 * Applies only parameters and variables to a text (expression). This should mostly be used for title pre-renderings
 * @param param0
 * @returns
 */
export function applyParametersToText({ text, parameters }: { text: string; parameters: Parameter[] }) {
    const { params, varMap, colorMap } = expandParameters(parameters);
    const varNames = Array.from(varMap.keys());

    try {
        if (!text || !parse(text)) {
            return text;
        }
        if (!hasSymetricBrackets(text)) {
            throw new Error("[ compileAmySyntax ] Error, non-symmetric Brackets");
        }

        // solve the tree, replaces params and evaluates {...}
        let solvedTree = treeBasedSolver(text, params, varNames);

        // replace vars
        solvedTree = solvedTree.findAndReplace(
            (node) => node.type === "SymbolNode" && varMap.get(node.name) !== undefined,
            (node) => parse(varMap.get(node.name), null, "USEIMPLICIT"),
        );

        // replace colors
        solvedTree = solvedTree.findAndReplace(
            (node) => node.type === "SymbolNode" && colorMap.get(node.name) !== undefined,
            (node) => parse(colorMap.get(node.name), null, "USEIMPLICIT"),
        );

        return solvedTree.toString();
    } catch (e) {
        throw new Error(`[ compileAmySyntax ] Text1: ${text} \nError: ${e.message}`);
    }
}

/**
 * Parses an expression to a tree and uses the solve function from the Node class, replaces evaluateExpression(...)
 *
 * @param {string} expression
 * @param {ITuple} parameters
 * @param {{ ignoreRounding?: boolean }} [options]
 * @return {*}
 */
function treeBasedSolver(
    expression: string,
    parameters: ITuple,
    varnames: string[],
    options?: { ignoreRounding?: boolean },
): Node {
    const tree = parse(expression, varnames, "USEIMPLICIT");
    // console.log(`[treeBasedSolver] Solving ${expression} ...`);
    // console.log(`[treeBasedSolver] Output: ${tree.solve(parameters)}`);
    return tree.solve(parameters);
}

/**
 * Converts a Parameter[] into an object containing the params and vars as string[]
 *
 * @param {Parameter[]} parameters
 * @return {*}  {{params: ITuple, vars: ITuple}}
 */
export function expandParameters(parameters: Parameter[]) {
    const params: ITuple = {};
    const varMap = new Map<string, string>();
    const colorMap: Map<string, string> = new Map();

    try {
        for (const p of parameters) {
            if (p.type === "NUMBER") {
                // @ts-ignore
                params[p.name] = parse(String(p.value)).eval();
            }
            if (p.type === "VARIABLE") {
                varMap.set(String(p.name), String(p.value));
            }
            if (p.type === "COLOR") {
                colorMap.set(p.name, String(p.value));
            }
        }
    } catch (e) {
        console.error(`[ expandParameters ] Error: `, e);
    }

    // TODO This hack creates Capitalized colors
    for (const [key, val] of colorMap) {
        colorMap.set(key.toUpperCase(), val.toUpperCase());

        let upKey = `${key[0].toUpperCase()}${key.substring(1)}`;
        let upVal = `${val[0].toUpperCase()}${val.substring(1)}`;
        colorMap.set(upKey, upVal);
    }

    // console.log("colorMap", colorMap);
    return { params, varMap, colorMap };
}

/**
 * Recursively adds params to every possible string in a given JSON tree eg:
 * addParamsToJSONObject({data: "{a}x"}, {a: 1})
 *  => {data: "1x"}
 *
 * @param {*} jsonObject
 * @param {*} params
 */
function compileInstrJSON(jsonObject: any, parameters: Parameter[], useTex?: boolean): any {
    // console.log(`[compileInstrJSON] Compiling`, jsonObject);

    const { params, varMap, colorMap } = expandParameters(parameters);

    // a mode for diagram labels to use "instruction" formatting with $[]$ eg
    const rawTextMode = jsonObject["rawText"] == true;

    // iterate over roots kids
    for (const key in jsonObject) {
        if (jsonObject.hasOwnProperty(key)) {
            let val = jsonObject[key];
            // console.log(`[compileInstrJSON] Looking at`, val);

            // if the kid is an object, parse it
            if (typeof val === "object") {
                val = compileInstrJSON(val, parameters, useTex);
                jsonObject[key] = val;
            }
            // if the kid is a string, parse it
            else if (typeof val === "string") {
                // if this is an eq string, we ignore the y= as that is implicit
                if (key === "eq") {
                    val = compileGraphEq(val, params);
                }
                if (key === "color") {
                    val = colorMap.get(val) || val;
                }
                // attempt to parse it and solve evals
                try {
                    if (rawTextMode) {
                        val = compileAmySyntax({ text: val, parameters, archExps: [], stepNum: 0 });
                    } else if (val.includes("{")) {
                        val = treeBasedSolver(val, params, Array.from(varMap.keys())).toString().replace(/\{|\}/g, "");
                        if (useTex) {
                            val = parse(val, null, "USEIMPLICIT").toTex();
                        }
                    } else {
                        val = val;
                    }
                } catch (e) {
                    // console.error(`[compileInstrJSON] Error: val: ${val}\n`, e);
                    val = val.replace(/\{|\}/g, "");
                }
                jsonObject[key] = val;
            }
        }
    }

    return jsonObject;
}

function compileGraphEq(eq: string, params: ITuple) {
    const pieces = eq
        .split("&")
        .map((itm: string) => itm.trim())
        .map((itm) => itm.replace(/  +/g, " ")); // replace multiple spaces with a single space
    // this will be length === 1 for std funcs, and length > 1 for piecewise (separated by '&'s)
    let _ret = [];

    for (const piece of pieces) {
        if (piece.trim() === "NODOTS") {
            _ret.push("NODOTS");
            continue;
        }
        const pieceArr = piece.replace(/^y ?=/g, "").split(", for"); // separate piecewise pieces
        let evaledPiece = parse(pieceArr[0]).solve(params).toString();

        if (pieceArr[1]) {
            evaledPiece += ", for ";
            // parse the tree and solve {...}
            const tree = parse(pieceArr[1]);
            evaledPiece += tree.solve(params).toString();
        }
        _ret.push(evaledPiece);
    }

    return _ret.join(" & ");
}

/**
 *
 * The function checks whether the expression starts with the string "PHR" or if any parameter in the
 * parameters array has a name property that matches the expression string and a value
 * property that starts with the string "PHR". If either of these conditions are met, the function returns
 * false. Otherwise, it returns true
 * @param expression
 * @param parameters
 * @returns
 */
export function applyMathBrackets(expression: string, parameters: Parameter[], isMath: undefined | boolean) {
    if (isMath === false) {
        // Do Nothing
        return expression;
    } else if (isMath === true) {
        // apply curlies
        return `$[${expression}]$`;
    } else {
        // isMath is not defined
        if (
            expression.startsWith("PHR") ||
            (
                parameters.find((e) => e.name === expression && e.type === "VARIABLE")?.value.trim() as string
            )?.startsWith("PHR") ||
            expression.includes("$[")
        ) {
            return expression;
        }
    }

    return `$[${expression}]$`;
}

/**
 * This function will find all PHR string (inside parameters or expression) and replaces them with the actual phrase text
 * @param expression
 * @param parameters
 * @param getPhrase A function that return a phrase object given an phraseId
 * @returns
 */
export async function preCompile(
    expression: string,
    parameters: Parameter[],
    language: Languages,
    getPhrase: (phraseId: string) => Promise<Phrase | undefined | null>,
) {
    // Definitions according to https://github.com/amy-app/amy/issues/4200
    // - if a var with phrase is used in expression it must be used alone. Only the word clock is allowed. no other words -> "clock" is allowed, "clock time" is NOT allowed. And this is only allowed if clock is defined as a variable
    // - if a expression starts with PHR... it's treaded as phrase and the phrase is loaded.
    // - if a phrase references another Phrase. it has to be within $[]$ -> "Hello $[PHR...]$ you" is correct. "Hello PHR... you" is NOT correct.
    // - to reference a variable in a phrase it MUST be inside $[]$ -> "Hello $[clock]$ you". It must NOT be "Hello clock you"

    // check if expression start with PHR or any parameter stars with PHR

    // first we need to establish if we are dealing with pure math or not
    // we do that by looking at the beginning of the expression
    // if it start with PHR it's NOT pure math
    // if it starts with a text that is a varialbe which start with PHR it's NOT pure math

    // at this point we want to pull all phrases and combine them

    let start = `${
        (parameters.find((e) => e.name === expression && e.type === "VARIABLE")?.value.trim() as string) ?? expression
    }`;

    let old = `${start}`;

    // let's replace all phrases
    for (let i = 0; i < 100; i = i + 1) {
        for (const phrVar of parameters.filter(
            (e) => e.type === "VARIABLE" && (e.value.trim() as string).startsWith("PHR"),
        )) {
            start = start.replace(
                new RegExp(`\\$\\[${phrVar.name}\\s*\\]\\$`, "g"),
                () => phrVar.value.trim() as string,
            );
        }

        // as long as the text PHR exists, we need to find more
        const foundInSwaredPhraseIds = start.match(/(\$\[PHR)\w+\]\$/g);

        // in case we find $[PHR123]$ patters we repalce it with PHR123
        if (foundInSwaredPhraseIds && foundInSwaredPhraseIds.length > 0) {
            for (const o of foundInSwaredPhraseIds) {
                const pId = o.replace("$[", "").replace("]$", "");
                start = start.replaceAll(o, () => pId);
            }
        }

        const foundPhraseIds = start.match(/(PHR)\w+/g);

        if (foundPhraseIds) {
            for (const o of foundPhraseIds) {
                const phrase = await getPhrase(o);
                if (phrase) {
                    start = start.replaceAll(o, () => getI18n(phrase.phrase, language));
                }
            }
        }

        // in case nothing changes any more. we can break
        if (old === start) {
            break;
        } else {
            old = `${start}`;
        }
    }

    // console.log("preCompleEnd", { expression, start });

    return start;
}
