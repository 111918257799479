import { getMaxPrecision, ITuple, precisionFunctions } from "../modules";
import { cleanFloatingPoint, fixDecimalPlaces, fixNegativeZero, keepTrailingZeros, limitDigits } from "../utilities";
import { ConstantNode } from "./ConstantNode";
import { Node } from "./Node";
import { parse } from "./parse";
import { StringNode } from "./StringNode";

export class EvalNode extends Node {
    constructor(args: Node[] = [], parent: Node = undefined) {
        super("{}", args, parent);
        this.type = "EvalNode";
    }

    toTex(options?: any) {
        return this.args[0].toTex(options);
    }

    toString(options?: any) {
        return `{${this.args[0].toString(options)}}`;
    }

    solve(parameters: ITuple = {}, ignoreRounding?: boolean): Node {
        // solve subtrees first
        const newargs = this.args.map((arg) => arg.solve(parameters));
        this.args = newargs;

        let solvedNum: string;
        try {
            // .eval() will fail if there are eval nodes in the subtree, which
            // is why we solve subtrees first above ^^
            solvedNum = this.args[0].eval(parameters).toString();
        } catch {
            // something went wrong. see if there is a parameter
            // node that we can replace with a value
            try {
                const subtreeNodes = this.getNodesByType("SymbolNode");
                for (const node of subtreeNodes) {
                    // check if parameter is given, OR if parameter val is zero
                    if (parameters[node.name] || parameters[node.name] === 0) {
                        node.replaceInTree(new ConstantNode(`${parameters[node.name]}`));
                    } else {
                        throw Error;
                    }
                }
                solvedNum = this.args[0].eval().toString();
            } catch {
                return new StringNode(`"error"`);
            }
        }

        // ignoreRounding from modules.ts
        if (!ignoreRounding && solvedNum !== undefined) {
            // Checks if there is any precision function that is not under any operator
            // round(12.4) => skipPrecision = true
            // round(12.4) + 1.01234 => skipPrecision = false
            const skipPrecision = this.getNodes().find((node) => {
                const func = precisionFunctions.find((func) => node.name === func);
                if (!func) {
                    return false;
                }
                for (let current = node.parent; current !== this; current = current.parent) {
                    if (current.type === "OperatorNode") {
                        return false;
                    }
                }
                return true;
            });

            if (
                !isNaN(+solvedNum) &&
                !skipPrecision &&
                !this.getAncestors().find((anc) => precisionFunctions.includes(anc.name))
            ) {
                try {
                    // Needed to prevent against floating point error comming from other evaluations
                    // e.g. 27 ^ (1/3) evaluates to 2.9999999999996
                    solvedNum = cleanFloatingPoint(+solvedNum, 13);
                    // the max number of digits after a "." in the tree
                    const precision = Math.max(
                        getMaxPrecision(this.args[0].toString().replace("{", "").replace("}", ""), 0),
                        getMaxPrecision(solvedNum, 0),
                    );
                    // now round this number to 3 dp max
                    solvedNum = fixDecimalPlaces(solvedNum, precision);
                    solvedNum = limitDigits(solvedNum);
                } catch (e) {
                    console.error(
                        `ERROR: ${e} with string ${this.args[0]
                            .toString()
                            .replace("{", "")
                            .replace("}", "")} and evaluated: ${solvedNum}`,
                    );
                }
            }
        }

        solvedNum = fixNegativeZero(solvedNum);
        solvedNum = keepTrailingZeros(this.args[0].toString(), solvedNum);

        const _toReturn = parse(solvedNum);
        return _toReturn;
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new EvalNode(args, parent);
    }

    getLatexNeighbour(direction: "left" | "right"): Node {
        // return this.args[0].getLatexNeighbour(direction);
        return this;
    }
}
