import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { Edit, History } from "@mui/icons-material";
import { Button, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FourOhFour } from "../components/404Page";
import { CenteredProgess } from "../components/CenteredProgress";
import { HistoryModal } from "../components/HistoryModal";
import { PageEditor } from "../components/PageEditor";
import { PageRenderer } from "../components/PageRenderer";
import { timeAgo } from "../utils";

export function PageView({ pages, funcs }: { pages: WikiPage[]; funcs: WikiFunc[] }) {
    const [isEditing, setIsEditing] = useState(false);
    const [historyOpen, setHistoryOpen] = useState(false);

    const [page, setPage] = useState<WikiPage | null | undefined>(null);
    const [funcDef, setFuncDef] = useState<WikiFunc | null>(null);

    const theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.down("md"));

    let location = useLocation();
    const nav = useNavigate();

    useEffect(() => {
        if (location && pages && funcs && !isEditing) {
            const path = "/" + location.hash.replace("#", "");
            const page = pages.find((val) => val.path.toLowerCase() === path.toLowerCase());
            if (page) {
                setPage(page);
                setFuncDef(funcs.find((val) => val.name.toLowerCase() === page?.name.toLowerCase()) || null);
            } else {
                setPage(undefined);
            }

            if (path === "/") {
                nav("/#home");
            }
        }
    }, [location, pages, funcs, nav, isEditing]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    if (page === null || pages.length === 0) {
        return <CenteredProgess style={{ marginTop: "50px" }} />;
    }

    if (page === undefined && pages.length > 0) {
        return <FourOhFour path={location.hash.replace("#", "")} />;
    }

    if (!page) {
        return <></>;
    }

    return (
        <div style={{ width: "90%", margin: "20px auto 100px auto" }}>
            {page && (
                <HistoryModal page={page} open={historyOpen} onClose={() => setHistoryOpen(false)} funcDef={funcDef} />
            )}
            <Grid container style={{ padding: "1vw", width: "100%" }} spacing={0}>
                {isEditing && (
                    <PageEditor pages={pages} page={page} funcDef={funcDef} onCloseEditor={() => setIsEditing(false)} />
                )}
                {!isEditing && (
                    <>
                        <Grid xs={12} item container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <Typography
                                    color="textPrimary"
                                    style={{ display: "inline-block", marginRight: "20px" }}
                                    variant="subtitle1"
                                >
                                    (Edited {timeAgo(new Date(page.lastUpdated))})
                                </Typography>
                                <Button
                                    color="primary"
                                    onClick={async () => {
                                        setHistoryOpen(true);
                                    }}
                                    startIcon={<History />}
                                    style={{ marginRight: "20px" }}
                                >
                                    History
                                </Button>
                                <Button
                                    color="primary"
                                    onClick={async () => {
                                        setIsEditing(!isEditing);
                                    }}
                                    startIcon={<Edit />}
                                >
                                    Edit
                                </Button>
                            </Grid>
                        </Grid>
                        <span style={{ marginTop: smallScreen ? "0" : "-38px" }}>
                            <PageRenderer page={page} funcDef={funcDef} />
                        </span>
                    </>
                )}
            </Grid>
        </div>
    );
}
