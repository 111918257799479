import { PDiff } from "../modules";
import { Node } from "../parser/Node";

/**
 *   Author: Ewan Orr, Henry Seed
 *   Date: Fri 1st June 2018
 *   (c) Jaipuna 2018
 *
 *   This contains the Top Pruning algorithm for the Amy Diff Finder.
 *
 *   To be used in conjunction with the DiffFinder module for parsing and displaying.
 *
 **/
/**
 *   The cases for tree pruning:
 *   1) Root Differ                                                 |  No change
 *   2) Root are the same,   Left is same,      Right is Different  |  Return right as root (pruning left and root)
 *   3) Root are the same,   Left is Different, Right is same       |  Return left as root (pruning right and root)
 *   4) Roots are the same,  Left is same,      Right is same       |  Returns root with value z with no children
 *
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {Node[]}
 */
export function pruneFromTheTop(sourceTree: Node, targetTree: Node): PDiff {
    let changed: boolean = true;

    let _retSourceTree = sourceTree;
    let _retTargetTree = targetTree;

    while (changed) {
        changed = false;

        const sourceKids = _retSourceTree.args;
        const targetKids = _retTargetTree.args;

        // Case 1: The root nodes are different
        if (!_retSourceTree.shallowEquals(_retTargetTree)) {
            // console.log(`${_retSourceTree.toString()} -> ${_retTargetTree.toString()}   |   Roots not equal`);
            break;
        }
        // Case 2: both trees have 0 kids
        else if (sourceKids.length === 0 && targetKids.length === 0) {
            // console.log(`${_retSourceTree.toString()} -> ${_retTargetTree.toString()}   |   Both have 0 kids`);
            break;
        }
        // Case 3: both trees have 1 kid
        else if (sourceKids.length === 1 && targetKids.length === 1) {
            // console.log(`${_retSourceTree.toString()} -> ${_retTargetTree.toString()}   |   Both have 1 kid`);
            // sets both roots to the only shild they have
            _retSourceTree = sourceKids[0];
            _retTargetTree = targetKids[0];
            // sets changed to true so the while loop will continue to prune
            changed = true;
        }
        // Case 4: both trees have 2 kids
        else if (sourceKids.length === 2 && targetKids.length === 2) {
            // console.log(`${_retSourceTree.toString()} -> ${_retTargetTree.toString()}   |   Both have 2 kids`);
            // checks if the left branches are equal
            if (sourceKids[0].deepEquals(targetKids[0])) {
                _retSourceTree = sourceKids[1];
                _retTargetTree = targetKids[1];
                // sets changed to true so the while loop will continue to prune
                changed = true;
            }
            // checks if the right branches are equal
            else if (sourceKids[1].deepEquals(targetKids[1])) {
                _retSourceTree = sourceKids[0];
                _retTargetTree = targetKids[0];
                // sets changed to true so the while loop will continue to prune
                changed = true;
            }
        }
        // Case 5: one has >= 2 kids, other has >2 kids
        else if (
            (sourceKids.length >= 2 && targetKids.length > 2) ||
            (targetKids.length >= 2 && sourceKids.length > 2)
        ) {
            // generates all common kids of the roots
            const commonTargetKids: Node[] = [];
            const commonSourceKids: Node[] = [];
            for (const s_node of sourceKids) {
                for (const t_node of targetKids) {
                    // we make sure the sNode we match tNode to hasnt already been spoken for
                    if (s_node.deepEquals(t_node)) {
                        commonSourceKids.push(s_node);
                        commonTargetKids.push(t_node);
                    }
                }
            }

            // const rejects: Node[] = [];
            const toPruneSource: Node[] = [];
            const toPruneTarget: Node[] = [];

            // console.log("commonSourceKids\n", commonSourceKids.map((val) => val.toString()));
            // console.log("commonTargetKids\n", commonTargetKids.map((val) => val.toString()));

            let sNodeIndex = 0;
            let tNodeIndex = 0;

            // we use these two for loops with pointers so we can skip nodes we cant prune
            while (sNodeIndex < sourceKids.length && tNodeIndex < targetKids.length) {
                const sNode = sourceKids[sNodeIndex];
                const tNode = targetKids[tNodeIndex];
                const sCommon: boolean = commonSourceKids.indexOf(sNode) > -1;
                const tCommon: boolean = commonTargetKids.indexOf(tNode) > -1;

                // console.log(
                //     `Looking at \nSource: ${sNode}, common? ${sCommon}\nTarget: ${tNode}, common? ${tCommon}`,
                // );

                if (sNode === undefined || tNode === undefined) {
                    break;
                }

                const foundReject = false;

                if (!foundReject) {
                    // if the nodes match
                    if (sNode.deepEquals(tNode)) {
                        toPruneSource.push(sNode);
                        toPruneTarget.push(tNode);
                        tNodeIndex++;
                        sNodeIndex++;
                    } else {
                        // if both are common, skip both because we know they dont match
                        if (sCommon && tCommon) {
                            tNodeIndex++;
                            sNodeIndex++;
                            // rejects.push(tNode);
                            // rejects.push(sNode);
                        }
                        // if one node is common, one not. skip the non-common one
                        else if (sCommon && !tCommon) {
                            tNodeIndex++;
                            // rejects.push(tNode);
                        } else if (!sCommon && tCommon) {
                            sNodeIndex++;
                            // rejects.push(sNode);
                        } else {
                            sNodeIndex++;
                            tNodeIndex++;
                        }
                    }
                }
            }

            for (let i = 0; i < toPruneSource.length; i++) {
                if (toPruneSource[i].deepEquals(toPruneTarget[i])) {
                    // If the source is the tree with 2 kids
                    if (sourceKids.length === 2) {
                        // console.log("Source is a small tree");
                        _retSourceTree = sourceKids[(sourceKids.indexOf(toPruneSource[i]) + 1) % 2];

                        targetKids.splice(targetKids.indexOf(toPruneTarget[i]), 1);
                        _retTargetTree.args = targetKids;
                    }
                    // If the target is the tree with 2 kids
                    else if (targetKids.length === 2) {
                        // console.log("Target is a small tree");
                        _retTargetTree = targetKids[(targetKids.indexOf(toPruneTarget[i]) + 1) % 2];

                        sourceKids.splice(sourceKids.indexOf(toPruneSource[i]), 1);
                        _retSourceTree.args = sourceKids;
                    }
                    // If both trees have > 2 kids
                    else {
                        // console.log("Neither are small trees");
                        sourceKids.splice(sourceKids.indexOf(toPruneSource[i]), 1);
                        _retSourceTree.args = sourceKids;

                        targetKids.splice(targetKids.indexOf(toPruneTarget[i]), 1);
                        _retTargetTree.args = targetKids;
                    }

                    // sets changed to true so the while loop will continue to prune
                    changed = true;
                    break;
                }
            }
            // console.log("Returned: ", _retSourceTree.toString(), _retTargetTree.toString());
        }
    }

    return new PDiff(_retSourceTree, _retTargetTree);
}
