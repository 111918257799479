/**
 * This is a utility file for anything related to Latex.
 */

export interface InstructionSection {
    type: "text" | "latex" | "graph" | "diagram" | "rawText";
    start: number;
    end: number;
    text: string;
}

/**
 *  checks if the text at position index matches the multi-char needle
 *  eg: isBracket("Solve $[2 + 4]$", 6, "$[")  -> True
 *
 * @param {string} text
 * @param {*} index
 * @param {*} needle
 */
function isBracket(text: string, index: number, needle: string): boolean {
    let textCount = index;
    for (const char of needle) {
        if (text[textCount] !== char) {
            return false;
        }
        textCount++;
    }
    return true;
}

/**
 * section boundaries return the open and closing brackets for type of instructions.
 * For example sectionBoundaries.get("latext") return {open:"$[", close: "]$"}
 */
export const sectionBoundaries: Map<
    "text" | "latex" | "graph" | "diagram" | "rawText",
    { open: string; close: string }
> = new Map();
sectionBoundaries.set("text", { open: "", close: "" });
sectionBoundaries.set("latex", { open: "$[", close: "]$" });
sectionBoundaries.set("graph", { open: "g[", close: "]g" });
sectionBoundaries.set("diagram", { open: "d[", close: "]d" });
sectionBoundaries.set("rawText", { open: "t[", close: "]t" });

/**
 * For a given string       eg: "Solve the following $[2 + 3]$ with graph g[y == 2 + 2x]g"
 * it will return an
 * array of the substrings  eg: [
 *                                {type: "text", start: 0, end: 19, text: "Solve the following "},
 *                                {type: "latex", start: 22, end: 26, text: "2 + 3"}
 *                                {type: "text", start: 27, end: 38, text: " with graph "},
 *                                {type: "graph", start: 41, end: 51, text: "y == 2 + 2x"}
 *                              ]
 *
 * @private
 * @param {string} text search string
 * @returns {InstructionSection[]}
 * @memberof AmyLatex
 */
export function getInstructionSections(text: string): InstructionSection[] {
    const latexOpen = "$[";
    const latexClose = "]$";
    const graphOpen = "g[";
    const graphClose = "]g";
    const diagramOpen = "d[";
    const diagramClose = "]d";
    const rawTextOpen = "t[";
    const rawTextClose = "]t";

    const openClosing: InstructionSection[] = [];
    let inProgress: InstructionSection = { type: "text", start: 0, end: -1, text: "" };

    for (let i = 0; i < text.length; i++) {
        // if we are in text mode, we can check for a start of brackets
        if (inProgress.type === "text") {
            // Check if the current position is the beginning of a bracket set
            if (
                isBracket(text, i, latexOpen) ||
                isBracket(text, i, graphOpen) ||
                isBracket(text, i, diagramOpen) ||
                isBracket(text, i, rawTextOpen)
            ) {
                // if the current inProgress isnt empty, save it
                if (inProgress.text !== "") {
                    inProgress.end = i; // because we've hit a bracker we must ignore i
                    openClosing.push(inProgress);
                    inProgress = { type: "text", start: i, end: -1, text: "" };
                }
                inProgress.type = isBracket(text, i, latexOpen)
                    ? "latex"
                    : isBracket(text, i, graphOpen)
                    ? "graph"
                    : isBracket(text, i, diagramOpen)
                    ? "diagram"
                    : "rawText";
                inProgress.start = i + 2; // we have to skip the start brackers for the index
                i++;
            } else {
                inProgress.text += text[i];
            }
        }
        // if the mode isnt normal text, then we should look for the end bracket
        else {
            // We need to check for the end of whatever section we are in

            if (
                (inProgress.type === "latex" && isBracket(text, i, latexClose)) ||
                (inProgress.type === "graph" && isBracket(text, i, graphClose)) ||
                (inProgress.type === "diagram" && isBracket(text, i, diagramClose)) ||
                (inProgress.type === "rawText" && isBracket(text, i, rawTextClose))
            ) {
                inProgress.end = i;
                openClosing.push(inProgress);
                i++;
                // reset inProgress;
                inProgress = { type: "text", start: i + 1, end: -1, text: "" };
            } else {
                inProgress.text += text[i];
            }
        }
    }
    // check if we have an unpushed text substring at the end
    if (inProgress.text !== "") {
        inProgress.end = text.length;
        openClosing.push(inProgress);
    }

    return openClosing
        .map((value) => {
            return {
                ...value,
                text: text.substr(value.start, value.end - value.start),
            };
        })
        .sort((a, b) => a.start - b.start);
}

/**
 * Tests if a given string has the same amount of brackets
 * That also includes brackets like $[ ]$
 * @param latexifiedText string with brackets
 */
export function hasSymetricBrackets(latexifiedText: string) {
    if (typeof latexifiedText !== "string") {
        return false;
    }

    const c2o = {
        "]$": "$[",
        "}": "{",
        ")": "(",
        "]": "[",
    };

    const openBrackets = Object.values(c2o);
    const closingBrackets = Object.keys(c2o);
    const allBrackets = [...openBrackets, ...closingBrackets];

    let stack = [];

    // window function
    for (let i = 0; i < latexifiedText.length; i++) {
        for (const b of allBrackets) {
            const sub = latexifiedText.substring(i, i + b.length);
            if (sub === b) {
                stack.push(sub);
                if (b.length > 1) {
                    i = i + b.length - 1;
                }
                break;
            }
        }
    }

    // if we have unqual number it's for sure wrong
    if (stack.length % 2 !== 0) {
        return false;
    }

    // each type of bracket must have equal numbers
    for (const [op, cl] of Object.entries(c2o)) {
        if (stack.filter((e) => e === op).length !== stack.filter((e) => e === cl).length) {
            return false;
        }
    }

    function indexOfFirstClose() {
        for (const [key, val] of Object.entries(stack)) {
            if (closingBrackets.includes(val)) {
                return Number(key);
            }
        }
        return -1;
    }

    while (stack.length > 1) {
        const firstCloseIndex = indexOfFirstClose();
        const lastOpenIndex = firstCloseIndex - 1;

        const firstClose = stack[firstCloseIndex];
        const lastOpen = stack[lastOpenIndex];

        if (c2o[firstClose] === lastOpen) {
            delete stack[firstCloseIndex];
            delete stack[lastOpenIndex];
            stack = stack.filter((e) => !!e);
        } else {
            return false;
        }
    }

    return true;
}

// TESTS for hasSymetricBrackets
// for(const t of ["(())", "$[]$", "}^{", "$[{}]$", "$[{", "((()())()))(][)"]){
//     for(let i=0;i<=t.length;i++){
//          console.log(t.substring(0,i), hasSymetricBrackets(t.substring(0,i)))
//     }
// }

export function convertToLatex(textWithAmyLatex: string, toLax: (latexString: string) => string): string | null {
    // only format to latex is all brackets have the same amount of opening a closings
    if (hasSymetricBrackets(textWithAmyLatex)) {
        const openClosing: InstructionSection[] = getInstructionSections(textWithAmyLatex);

        const textSnippets: string[] = [];
        openClosing.forEach((value, index) => {
            if (value.type === "latex" && value.text !== "") {
                // this part is latex and needs to be converted
                textSnippets.push(`$[${toLax(value.text)}]$`);
            } else if (value.type === "graph") {
                textSnippets.push(`g[${value.text}]g`);
            } else if (value.type === "diagram") {
                textSnippets.push(`d[${value.text}]d`);
            } else if (value.type === "rawText") {
                textSnippets.push(`t[${value.text}]t`);
            } else {
                textSnippets.push(value.text);
            }
        });

        // if no latex is found at all
        if (openClosing.length === 0) {
            return textWithAmyLatex;
        } else {
            return textSnippets.join(" ");
        }
    } else {
        // in case not the same amount of openeing and closing exist. Return an error
        return "";
    }
}
