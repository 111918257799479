import { Node } from "../modules";

// A helper file of functions used in the mistakes
// Author: Henry Seed
// Date:   14/03/2019

/**
 * An enum of each mistake Flag
 * @export
 * @enum {number}
 */
export enum mistakeFlag {
    shuffleParams = "shuffleParams",
    shuffle = "shuffle",
    shuffleOps = "shuffleOps",
    questionOpOrder = "questionOpOrder",
    bedmas = "bedmas",
    boolean = "boolean",
    f_box = "f_box",
    f_stringOption = "f_stringOption",
    reading = "reading",
    generalReading = "generalReading",
    divTomult = "divTomult",
    divToPlus = "divToplus",
    divToMinus = "divTominus",
    multTodiv = "multTodiv",
    multToplus = "multToplus",
    multTominus = "multTominus",
    plusTodiv = "plusTodiv",
    plusTomult = "plusTomult",
    plusTominus = "plusTominus",
    minusTodiv = "minusTodiv",
    minusTomult = "minusTomult",
    minusToplus = "minusToplus",
    lessTogreat = "lessTogreat",
    greatToless = "greatToless",
    bracketRemoval = "bracketRemoval",
    negative = "negative",
    bracketReading = "bracketReading",
    carryTheTen = "carryTheTen",
    offByOne = "offByOne",
    offByMagnitude = "offByMagnitude",
    wrongOperator = "wrongOperator",

    consistentOff = "consistentOff",
    f_format = "f_format",

    subtractNumerator = "subtractNumerator",
    addNumerator = "addNumerator",
    multiplyNumerator = "multiplyNumerator",

    subtractDenominator = "subtractDenominator",
    addDenominator = "addDenominator",
    multiplyDenominator = "multiplyDenominator",

    wrongNumerator = "wrongNumerator",
    wrongDenominator = "wrongDenominator",

    nothingChanged = "nothingChanged",

    commutative = "commutative",
    swapNumeratorDenominator = "swapNumeratorDenominator",
    missplaceBrackets = "missplaceBrackets",

    exponent = "exponent",
    swapExponent = "swapExponent",

    strike = "strike",
    strikeFraction = "strikeFraction",
    strikeMultiple = "strikeMultiple",
    strikeNonProduct = "strikeNonProduct",
    concatParams = "concatParams",

    doubleLast = "doubleLast",
}

// A priority for each mistakeFlag
export const mistakePriorities = new Map<mistakeFlag, number>([
    [mistakeFlag.offByOne, 6],
    [mistakeFlag.offByMagnitude, 6],

    [mistakeFlag.shuffle, 9],

    [mistakeFlag.subtractNumerator, 8],
    [mistakeFlag.addNumerator, 8],
    [mistakeFlag.multiplyNumerator, 8],
    [mistakeFlag.missplaceBrackets, 8],
    [mistakeFlag.strikeNonProduct, 7],
    [mistakeFlag.strikeMultiple, 7],
    [mistakeFlag.strikeFraction, 8],
    [mistakeFlag.strike, 7],

    [mistakeFlag.exponent, 8],
    [mistakeFlag.swapExponent, 8],
    [mistakeFlag.doubleLast, 8],

    [mistakeFlag.subtractDenominator, 8],
    [mistakeFlag.addDenominator, 8],
    [mistakeFlag.multiplyDenominator, 8],

    [mistakeFlag.consistentOff, 8],
    [mistakeFlag.f_format, 8],

    [mistakeFlag.wrongNumerator, 8],
    [mistakeFlag.wrongDenominator, 8],

    [mistakeFlag.f_stringOption, 9],
    [mistakeFlag.bedmas, 10],
    [mistakeFlag.f_box, 9],
    [mistakeFlag.lessTogreat, 9],
    [mistakeFlag.greatToless, 9],

    [mistakeFlag.questionOpOrder, 7],
    [mistakeFlag.bracketReading, 7],
    [mistakeFlag.carryTheTen, 7],

    [mistakeFlag.divTomult, 7],
    [mistakeFlag.divToPlus, 5],
    [mistakeFlag.divToMinus, 5],
    [mistakeFlag.wrongOperator, 5],

    [mistakeFlag.multTodiv, 2],
    [mistakeFlag.multToplus, 5],
    [mistakeFlag.multTominus, 5],

    [mistakeFlag.plusTodiv, 2],
    [mistakeFlag.plusTomult, 5],
    [mistakeFlag.plusTominus, 7],

    [mistakeFlag.minusTodiv, 2],
    [mistakeFlag.minusTomult, 5],
    [mistakeFlag.minusToplus, 7],

    [mistakeFlag.reading, 5],
    [mistakeFlag.concatParams, 6],

    [mistakeFlag.nothingChanged, 7],

    [mistakeFlag.boolean, 7],

    [mistakeFlag.bracketRemoval, 5],
    [mistakeFlag.commutative, 7],

    [mistakeFlag.shuffleParams, 4],

    [mistakeFlag.shuffleOps, 3],
    [mistakeFlag.swapNumeratorDenominator, 3],

    [mistakeFlag.generalReading, 2],
    [mistakeFlag.multTodiv, 2],
    [mistakeFlag.negative, 1],
]);

// An array of functions in the order a student would learn them
export const funcLearnOrder = ["add", "subtract", "f_multi", "f_div", "f_pow", "f_ratio", "log", "ln"];

export interface MistakeObj {
    expression: string;
    flags: mistakeFlag[];
    mistakeId?: string;
}

/**
 * Compares the diffTree (including Z-Nodes) to a topPruneTree (no Z-Nodes)
 * @export
 * @param {Node} diffTree
 * @param {Node} topPruneTree
 * @return {*}  {boolean}
 */
export function compareDiffToTopPrune(diffTree: Node, topPruneTree: Node): boolean {
    // if DiffTree is a subtree, allow it to match to anything
    if (diffTree.name.startsWith("Z")) {
        return true;
    }
    if (diffTree.type !== topPruneTree.type) {
        return false;
    }
    // for functions and operators, the names have to match
    const specificTypes = ["OperatorNode", "FunctionNode"];
    if (specificTypes.includes(diffTree.type)) {
        if (diffTree.name !== topPruneTree.name) {
            return false;
        }
    }

    // if the roots match, check their args
    let argIndex = 0;
    for (const diffArg of diffTree.args) {
        const topPruneArg = topPruneTree.args[argIndex];
        if (!compareDiffToTopPrune(diffArg, topPruneArg)) {
            return false;
        }
        argIndex += 1;
    }

    return true;
}
