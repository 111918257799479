import { ITuple } from "../modules";
import { Node } from "./Node";
import { parse } from "./parse";

export class StringNode extends Node {
    constructor(name: string, parent: Node = undefined) {
        super(name, [], parent);
        this.type = "StringNode";
    }

    toTex(options: any = {}) {
        const rawString = this.name.slice(1, this.name.length - 1);
        if (options.renderMode === "latex") {
            const specialChars: Array<[RegExp, string]> = [[/\\n/g, "\n"]];
            let latex = rawString;
            for (const replacement of specialChars) {
                latex = latex.replace(replacement[0], replacement[1]);
            }
            return latex.replace(/\\\\/g, "\\");
        } else if (options.renderMode === "plain") {
            return `\\mathrm{${rawString.replace(/ /g, "~").replace(/-/g, "\\text-")}}`;
        } else {
            return `\\mathrm{"${rawString.replace(/ /g, "~").replace(/-/g, "\\text-")}"}`;
        }
    }

    solve(parameters: ITuple = {}, ignoreRounding?: boolean): Node {
        // f_string and f_stringOption are off limits
        const ancestorFuncs = this.getAncestors().filter((node) => node.type === "FunctionNode");
        ancestorFuncs.reverse();
        if (["f_string", "f_stringOption"].includes(ancestorFuncs[0]?.name || "")) {
            return this;
        }

        const quotelessName = this.name.slice(1, -1);
        if (!isNaN(parseFloat(quotelessName))) {
            // eg "0.00"
            return this;
        }

        let tree: Node = null;
        try {
            tree = parse(quotelessName); // remove the "..." and make tree
        } catch {
            // eg "/theta"
            return this;
        }

        if (tree.type === "SymbolNode" || tree.type === "ConstantNode") {
            return this;
        }

        // replace all params in the tree with their numerical value
        tree = tree.findAndReplace(
            (node) => Object.keys(parameters).includes(node.name) && node.isInEval(),
            (node) => parse(parameters[node.name].toString()),
        );
        // try to evaluate the expression (will fail if vars etc)
        try {
            const evaluated = tree.eval();
            this.name = `"${evaluated.toString()}"`;
        } catch {
            this.name = `"${tree.toString()}"`;
        }

        return this;
    }

    toString(_options?: any) {
        return this.name;
    }

    cloneDeep(parent?: Node) {
        return new StringNode(this.name, parent);
    }
}
