import { precisionFunctions } from "../modules";
import { Node } from "./Node";

// The ConstantNode is used to define any leaf node which can be evaluated, These fall into multiple types
//  - Real Number           1, 0.2, -3 ...
//  - Imaginary Number      2i, 3i ...
//  - Boolean               True, False
//  - SymbolicConstants     pi, e ...

// a map of symbol names to the exp they represent, this is evaluated when they are evaluated

export const symbolicConstants = new Map([
    ["pi", { eval: Math.PI, tex: "\\pi" }],
    ["e", { eval: Math.E, tex: "\\mathrm{e}" }],
    ["phi", { eval: 1.61803398874989484820458683436563811772030917980576286213545, tex: "\\phi" }],
    ["tau", { eval: Math.PI * 2, tex: "\\tau" }],
    ["NaN", { eval: NaN, tex: "Undefined" }],
    ["Infinity", { eval: Infinity, tex: "\\infty" }],
]);

export class ConstantNode extends Node {
    precision: number;
    mode: "RealNum" | "ImaginaryNum" | "SymbolicNum";

    constructor(name: string, parent: Node = undefined, mode: "RealNum" | "ImaginaryNum" | "SymbolicNum" = "RealNum") {
        super(name, [], parent);
        this.type = "ConstantNode";
        this.precision = name.length;
        this.mode = mode;
    }

    /**
     * Converts a number into words, detects negatives etc
     * @param {*} options
     * @return {*}
     * @memberof ConstantNode
     */
    toWord(options) {
        const numMap = [
            "zero",
            "one",
            "two",
            "three",
            "four",
            "five",
            "six",
            "seven",
            "eight",
            "nine",
            "ten",
            "eleven",
            "twelve",
            "thirteen",
            "fourteen",
            "fifteen",
            "sixteen",
            "seventeen",
            "eighteen",
            "nineteen",
        ];
        const tensMap = ["ten", "twenty", "thirty", "forty", "fifty", "sixty", "seventy", "eighty", "ninety"];
        const placeSuffix = [
            "",
            "thousand",
            "million",
            "billion",
            "trillion",
            "quadrillion",
            "quintillion",
            "sextillion",
        ];

        if (isNaN(parseFloat(this.name))) {
            return this.name;
        } else {
            // split decimal
            const [wholeNum, decimal] = this.name.split(".");

            // an array of words, reversed
            const revWords: string[] = [];

            // 1. Whole Numbers ==========================================
            // first we reverse it because places are easier that way
            const revWhole = wholeNum
                .split("")
                .map((val) => parseFloat(val))
                .reverse();

            // special cases like twelve, etc
            if (numMap[parseFloat(wholeNum)]) {
                revWords.push(numMap[parseFloat(wholeNum)]);
            }
            // computational generation
            else {
                for (let i = 0; i < revWhole.length; i += 3) {
                    const [ones, tens, hundos] = [revWhole[i], revWhole[i + 1], revWhole[i + 2]];

                    // if we havent added an "and" yet and we have a tens or ones, add one now
                    if ((revWhole[0] || revWhole[1]) && !revWords.includes("and") && i > 2) {
                        revWords.push("and");
                    }

                    // add the prefix first as we are reversed
                    if ((ones || tens || hundos) && i > 2) {
                        revWords.push(placeSuffix[i / 3]);
                    }

                    // check for special teens case
                    if (tens === 1 && ones > 0) {
                        revWords.push(numMap[tens * 10 + ones]);
                    } else {
                        if (ones > 0) {
                            revWords.push(numMap[ones]);
                        }
                        if (tens > 0) {
                            revWords.push(tensMap[tens - 1]);
                        }
                    }

                    if (hundos > 0) {
                        // if we need to add an "and"
                        if (ones || tens) {
                            revWords.push("and");
                        }
                        revWords.push("hundred", numMap[hundos]);
                    }
                }
            }

            const words = revWords.reverse();

            // 2. Decimals ================================================
            if (decimal) {
                words.push("point");
                for (const char of decimal) {
                    words.push(numMap[parseFloat(char)]);
                }
            }

            return words.join("~");
        }
    }

    toTex(_options?: any) {
        if (this.name === "undefined") {
            return "Undefined";
        }
        const symbolEval = symbolicConstants.get(this.name);
        if (this.mode === "SymbolicNum" && symbolEval) {
            return symbolEval.tex;
        } else if (this.mode === "RealNum") {
            if (this.name === "true") return "True";
            if (this.name === "false") return "False";
        }
        let solvedNum = this.name;

        if (!this.getAncestors().find((anc) => precisionFunctions.includes(anc.name) || anc.type === "EvalNode")) {
            if (solvedNum.split(".")[0].length > 15) {
                solvedNum = (+solvedNum).toExponential();
                solvedNum = `${solvedNum.split("e+")[0]}\\times10^{${solvedNum.split("e+")[1]}}`;
            }
        }
        return solvedNum;
    }

    getNumberVal(): number {
        if (this.name === "true") return 1;
        if (this.name === "false") return 0;

        return parseFloat(this.name);
    }

    getDecimalPlaces() {
        return this.name.includes(".") ? this.name.split(".")[1].length : 0;
    }

    cloneDeep(parent?: Node) {
        return new ConstantNode(this.name, parent, this.mode);
    }
}
