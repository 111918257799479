/**
 * Languages should be implemented as new class for a name.
 * for example
 * title: new Languages();
 */

export type Languages = "en" | "en-NZ" | "de" | "ar";

export class Language {
    en: string = "";
    "en-NZ": string = "";
    de: string = "";
    ar: string = "";
}

const languages = Object.keys(new Language());
export { languages };

export function getI18n(lngObj: Language, language: Languages) {
    if (language === "en-NZ") {
        return lngObj[language] || lngObj["en"];
    }

    if (language === "en") {
        return lngObj[language];
    }

    if (language === "de") {
        return lngObj[language] || lngObj["en"];
    }

    if (language === "ar") {
        return lngObj[language] || lngObj["en"];
    }

    return "";
}
