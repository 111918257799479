const itemsArray: [string, string[]][] = [
    ["2DShape", ["circle", "rectangle", "diamond", "square", "triangle"]],
    ["3DShape", ["box", "cone", "cube", "cuboid", "cylinder", "prism", "sphere"]],
    [
        "BakingIngredient",
        [
            "baking powder",
            "baking soda",
            "butter",
            "chocolate",
            "cocoa",
            "eggs",
            "flour",
            "golden syrup",
            "sugar",
            "spices",
            "vanilla essence",
        ],
    ],
    [
        "BreakfastFood",
        ["bacon", "bagels", "cereal", "cornflakes", "eggs", "omelette", "pancakes", "sausage", "toast", "waffles"],
    ],
    [
        "ChemicalElement",
        [
            "Helium",
            "Lithium",
            "Hydrogen",
            "Sodium",
            "Boron",
            "Carbon",
            "Silicon",
            "Calcium",
            "Beryllium",
            "Fluorine",
            "Neon",
            "Sulfur",
            "Phosphorus",
            "Nitrogen",
            "Aluminum",
            "Potassium",
            "Chlorine",
            "Argon",
            "Magnesium",
            "Iron",
            "Bromine",
            "Oxygen",
            "Manganese",
            "Copper",
            "Cobalt",
            "Zinc",
            "Krypton",
            "Silver",
            "Iodine",
            "Platinum",
            "Cadmium",
            "Tin",
            "Cesium",
            "Barium",
            "Lead",
            "Nickel",
            "Chromium",
            "Bismuth",
            "Radon",
            "Radium",
            "Arsenic",
            "Xenon",
            "Uranium",
            "Strontium",
            "Polonium",
            "Mercury",
            "Gold",
            "Tungsten",
        ],
    ],
    [
        "Colour",
        [
            "black",
            "blue",
            "brown",
            "green",
            "gold",
            "gray",
            "indigo",
            "orange",
            "pink",
            "purple",
            "red",
            "silver",
            "yellow",
        ],
    ],
    ["Container", ["bag", "pot", "box", "container", "backpack", "sack"]],
    [
        "Country",
        [
            "Australia",
            "Belgium",
            "Bangladesh",
            "Brazil",
            "China",
            "Egypt",
            "Ethiopia",
            "France",
            "Germany",
            "India",
            "Indonesia",
            "Iran",
            "Italy",
            "Japan",
            "Mexico",
            "Myanmar",
            "New Zealand",
            "Nigeria",
            "Pakistan",
            "Philippines",
            "Russia",
            "South Africa",
            "South Korea",
            "Tanzania",
            "Thailand",
            "Turkey",
            "United Kingdom",
            "United States",
            "Vietnam",
        ],
    ],
    ["Day", ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]],
    ["DistanceSport", ["running", "driving", "cycling", "swimming", "kayaking", "sailing", "free diving"]],
    ["DivisibleFood", ["apple", "pizza", "cake", "chips"]],
    ["Drink", ["fruit juice", "lime water", "milk", "orange juice", "water"]],
    ["FarmAnimal", ["chicken", "cow", "horse", "pig"]],
    ["Food", ["banana", "apple", "pineapple", "orange", "bread", "tomato", "leeks", "potato", "pizza"]],
    ["FoodLocation", ["cafe", "restaurant", "park"]],
    ["Fruit", ["banana", "apple", "pineapple", "orange", "tomato"]],
    ["GoalSport", ["soccer", "football", "netball", "hockey"]],
    ["LandDistanceVerb", ["walk", "drive", "cycle"]],
    ["LiquidContainer", ["bucket", "bottle", "container", "urn", "beaker", "glass", "mug", "cup"]],
    [
        "Month",
        [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
        ],
    ],
    ["PartTimeJob", ["baby sitting", "cleaning", "dog walking", "lawn mowing", "a paper run", "yard work"]],
    ["PointSport", ["golf", "basketball", "volleyball", "darts"]],
    ["PublicTransportation", ["bus", "ferry", "train", "tram"]],
    ["PrivateTransportation", ["bike", "car", "motorcycle"]],
    [
        "ShopItem",
        [
            "bus pass",
            "book",
            "apple",
            "computer",
            "bottle of milk",
            "stapler",
            "textbook",
            "desk",
            "table",
            "work book",
            "skirt",
            "shirt",
            "hat",
            "bucket",
        ],
    ],
    ["SchoolItem", ["stapler", "textbook", "desk", "table", "workbook"]],
    ["ScoreSport", ["diving", "gymnastics", "gymnastics", "figureSkating", "car drifting", "weightLifting"]],
    [
        "Subject",
        ["maths", "english", "chemistry", "physics", "science", "biology", "geometry", "algebra", "trigonometry"],
    ],
    ["Tradesman", ["builder", "plumber", "electrician", "labourer", "painter", "mechanic"]],
    ["Vegetable", ["broccoli", "corn", "carrot", "cauliflower", "green bean", "pea", "potato", "spinach"]],
    ["ZooAnimal", ["zebra", "lion", "giraffe", "leopard", "rhino", "panda"]],
    ["Workplace", ["office", "factory", "bank", "gym", "lab", "department store", "petrol station", "mechanics"]],
    ["Pet", ["cat", "dog", "rabbit", "turtle", "guinea pig", "chicken"]],
    ["CutePet", ["kitten", "puppy", "bunny"]],
    ["GroupPeople", ["kids", "children", "friends", "siblings", "classmates"]],
];

export const items: Map<string, string[]> = new Map(itemsArray);

export function getNewItem(identifier: string): string | undefined {
    if (items.has(identifier)) {
        const options = items.get(identifier);
        const choice = options[Math.floor(Math.random() * options.length)];
        return choice;
    } else {
        return undefined;
    }
}
