import { Type } from "class-transformer";
import "reflect-metadata";
import { getSubtreeString } from "../DiffFinder/DiffFinder";
import { evaluateExpression, ITuple, Node, PDiff } from "../modules";

export class StepStats {
    mean: number;
    spread: number;
    median: number;
    canBeDecimal: boolean;
    isVar: boolean;

    constructor(mean: number, spread: number, median: number, canBeDecimal: boolean, isVar?: boolean) {
        this.mean = mean;
        this.spread = spread;
        this.median = median;
        this.canBeDecimal = canBeDecimal;
        this.isVar = isVar;
    }
}

export class StepStatsWithName {
    step: string;
    @Type(() => StepStats)
    stats: StepStats;

    constructor(name: string, stats: StepStats) {
        this.step = name;
        this.stats = stats;
    }
}

export class DiffStats {
    diff: string;
    @Type(() => StepStatsWithName)
    stepStats: Map<string, StepStatsWithName> = new Map();
}

/**
 * This is apart of the old archetype builder. Please develop new features in the new data builder project.
 * Returns true if the param set given contains only integer params
 * @param {ITuple[]} params
 * @returns {boolean}
 */
function hasOnlyIntParams(params: ITuple[]): boolean {
    for (const paramSet of params) {
        for (const param in paramSet) {
            // check if the params value is an int or a float
            const val = parseFloat(String(paramSet[param]));
            if (val % 1 !== 0) {
                return false;
            }
        }
    }
    return true;
}

/**
 * This is apart of the old archetype builder. Please develop new features in the new data builder project.
 * Given an array of possible numbers this returns the stats object for them
 * @param {number[]} possibleValues
 * @returns {ParamStats}
 */
function getParamStats(possibleValues: number[]): StepStats {
    let sum: number = 0;
    const valueNumbers: number[] = [];
    let canBeDecimal: boolean = false;

    for (const num of possibleValues) {
        // we cant trust for format for the nums, so reparse them
        sum += parseFloat(num.toString());
        valueNumbers.push(parseFloat(num.toString()));
        if (num % 1 > 0) {
            canBeDecimal = true;
        }
    }
    const mean: number = Math.floor(sum / valueNumbers.length);
    const spread = Math.max(...valueNumbers) - Math.min(...valueNumbers);
    const median = valueNumbers[Math.floor(valueNumbers.length / 2)];

    return new StepStats(mean, spread, median, canBeDecimal);
}

/**
 * This is apart of the old archetype builder. Please develop new features in the new data builder project.
 * Returns the stats for the first->last diff of the given arch, a map of X0 -> the stats for that node
 * @param {PArchetype} arch
 * @returns {ParamStats}
 */
export function getDiffStats(
    firstLast: PDiff,
    diffMap: Map<string, Node[]>,
    params: ITuple[],
): Map<string, StepStatsWithName> {
    const thisDiffStats: Map<string, StepStatsWithName> = new Map();

    let x_counter: number = 0;

    // console.log(firstLast.source.getBFS().concat(firstLast.target.getBFS()).toString());

    // iterate over that diff
    for (const node of firstLast.source.getBFS().concat(firstLast.target.getBFS())) {
        const possibleValues: number[] = [];

        let isParam: boolean = false;
        if (params[0] !== undefined) {
            if (params[0][node.name] !== undefined && node.isParam()) {
                isParam = true;
            }
        }

        // console.log(node.toString(), isParam, node.type, node.name, simplifyDiffMap(diffMap));

        // PARAM
        if (isParam) {
            // get array from params
            for (const paramSet of params) {
                possibleValues.push(paramSet[node.name] as number);
            }
        }
        // NUMBER
        else if (node.type === "ConstantNode") {
            // just being cautious that the constant nodes value isnt stored as a string so we can compute the stats easily
            possibleValues.push(parseFloat(node.toString()));
        }
        // Z1 SUBTREE
        else if (node.type === "SymbolNode" && diffMap.get(node.name) !== undefined) {
            // get the tree from the diffMap
            let subtree: string = getSubtreeString(diffMap.get(node.name));
            // check if the given subtree is inside evals
            if (node.isInEval() || node.isInEval()) {
                subtree = `{${subtree.replace(/\{/g, "").replace(/\}/g, "")}}`;
            }
            subtree = subtree.replace(/\$\(/g, "(").replace(/\)\$/g, ")");

            // for each paramSet, generate a subtree value
            for (const paramSet of params) {
                const value = evaluateExpression(subtree, paramSet);
                if (!isNaN(parseFloat(value))) {
                    possibleValues.push(parseFloat(value));
                }
            }
        }

        // VARIABLE CASE
        else if (node.type === "SymbolNode" && !isParam) {
            // becasue the possibleValues will be empty we have to set our own
            thisDiffStats.set(`X${x_counter}`, new StepStatsWithName(node.name, new StepStats(0, 0, 0, false, true)));

            x_counter++;
        }

        // if we actually found any values for this node
        if (possibleValues.length > 0) {
            // add paramStats for this node to the map
            thisDiffStats.set(`X${x_counter}`, new StepStatsWithName(node.toString(), getParamStats(possibleValues)));
            x_counter++;
        }
    }
    return thisDiffStats;
}
