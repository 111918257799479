import { PDiff } from "../modules";
import { Node } from "../parser/Node";
import { parse } from "../parser/parse";
import { hasSameNodeNames } from "./DiffFinder";

/**
 *   Author: Ewan Orr, Henry Seed
 *   Date: Fri 1st June 2018
 *   (c) Jaipuna 2018
 *
 *   This contains the Bottom Pruning algorithm for the Amy Diff Finder.
 *
 *   To be used in conjunction with the DiffFinder module for parsing and displaying.
 *
 *        Node[] => Subtree
 *            [g * e, e, g]
 *
 *        Node[][] => group of common subtrees
 *            [
 *                [g * e, e, g],
 *                [g * e, e, g]
 *            ]
 *
 *       Node[][][] => array of groups of common subtrees
 *            [
 *                [g * e, e, g], [g * e, e, g],
 *                [d * g, d, g], [d * g, d, g], [d * g, d, g]
 *            ]
 *
 **/

// this min length makes sure nodes like {a} are replaced with Z1

/**
 * Finds all subtrees of a nodes children
 * @param {Node} root the root of the parent tree
 * @return {Node[][]} The keys are the nodes in the parent tree, a value is an array containing the
 * subtree with the key node as the root; the result of a breadth-first-search from that node.
 */
export function getAllSubtrees(root: Node) {
    const allSubtrees: Node[][] = [];
    const parentTree = root.getBFS();

    // Iterating through all nodes.
    for (const node of parentTree) {
        // If its not an associative node, skip the multi-child subtree finder
        if (!node.isAssociative()) {
            allSubtrees.push(node.getBFS());
        }
        // If it is an associative node
        else {
            const origionalKids = node.args; // origional kids for node

            // iterate through all the kids
            for (let kidIndex: number = 0; kidIndex < origionalKids.length; kidIndex++) {
                const consecutiveKids = [origionalKids[kidIndex]];
                // iterate through this kids consecutive siblings
                for (let pairingIndex: number = kidIndex + 1; pairingIndex < origionalKids.length; pairingIndex++) {
                    // generate consecutiveKids
                    consecutiveKids.push(origionalKids[pairingIndex]);

                    // set node kids to consecutiveKids
                    node.args = consecutiveKids;

                    // add bfs on node to allSubtrees
                    allSubtrees.push(node.getBFS());

                    // reset node kids to origionalKids
                    node.args = origionalKids;
                }
            }
        }
    }
    return allSubtrees;
}

/**
 * Finds the parents of all trees in treesToReplace, replaces it from its parents children array with a new 'z' node
 * @param {Node[]} sourceTreeNodes
 * @param {Node[]} targetTreeNodes
 * @param {Node[]} treesToReplace
 * @returns {[Node[], Map<string, Node[]>]}
 */
export function replaceNodesAndChildren(
    sourceTreeNodes: Node[],
    targetTreeNodes: Node[],
    treesToReplace: Node[][],
    zIndex: number,
) {
    let nodeName: string = "";
    let found: boolean = false;
    // console.log(`replacing subtree ${treesToReplace[0][0]}`);

    const subtreeMap: Map<string, Node[]> = new Map<string, Node[]>();

    let sourceRootNode = sourceTreeNodes[0];
    let targetRootNode = targetTreeNodes[0];

    // iterates over each instance of the common tree
    // these are all identical, just exist in either source or target.
    for (const tree of treesToReplace) {
        let root = tree[0];
        // find parent
        let parent = root.parent;
        // Ensures that if we have already unlinked tree from its parent it skips doing it again.
        if (!sourceTreeNodes.includes(root) && !targetTreeNodes.includes(root)) {
            continue;
        }

        if (!found) {
            found = true;
            nodeName = "Z" + zIndex.toString();
            zIndex += 1;
            // console.log(`Replacing tree ${tree.map((val) => val.name)} in ${sourceRootNode} with ${nodeName}`);
            subtreeMap.set(nodeName, tree);
        }

        // console.log(tree);

        // 2nd Case: Associative and Subtree is a subset of child nopes
        if (tree[0].isAssociative() && !hasSameNodeNames(tree, tree[0].getBFS())) {
            // console.log("Is Subset");

            root = tree[0];
            const kids = root.args;
            const newKid = parse(nodeName);
            const deleteIndex = kids.indexOf(tree[1]);

            if (deleteIndex < 0) {
                continue;
            }

            for (const node of tree.slice(1)) {
                // check if still first level node
                if (root.args.indexOf(node) > -1) {
                    kids.splice(kids.indexOf(node), 1);
                } else {
                    break;
                }
            }

            kids.splice(deleteIndex, 0, newKid);
            root.args = kids;

            // 3rd Case: Subtree is equal to child nodes
        } else {
            // console.log("Is the same");
            // console.log(sourceRootNode);
            // console.log(tree[0]);

            if (tree[0] === sourceRootNode) {
                // console.log("Source Root is subtree");
                found = true;
                sourceRootNode = parse(nodeName);
                // console.log(sourceRootNode);
            } else if (tree[0] === targetRootNode) {
                // console.log("Target Root is subtree");
                found = true;
                targetRootNode = parse(nodeName);
            } else {
                const replacementKid = parse(nodeName);
                tree[0].replaceInTree(replacementKid); // we know this isn't the root
            }
        }
        // console.log(`${sourceRootNode}\n${targetRootNode}`);
        tree[0].parent = undefined;
    }

    // return [[sourceRootNode, targetRootNode], subtreeMap, zIndex];
    return { trees: { source: sourceRootNode, target: targetRootNode }, subtreeMap, newZIndex: zIndex };
}

/**
 * Returns an array of common sub trees where no param in the subtree features anywhere else in source or target, unless it is in a subtree found by getCommonSubTreesAcrossBothTrees
 * getCommonSubTreesNoExternalParams assumes commonSubTrees is already sorted
 *
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {Node[][][]}
 */
export function getCommonSubTreesNoExternalParams(
    sourceTreeNodes: Node[],
    targetTreeNodes: Node[],
    subtreeNodes: string[],
    commonSubTrees: Node[][][],
) {
    const validCommonSubTrees: Node[][][] = [];
    const allTreeNodes = sourceTreeNodes.concat(targetTreeNodes);

    for (const commonSubtree of commonSubTrees) {
        const uniqueTree = Array.from(new Set(commonSubtree));

        let allSubtreeNodes = commonSubtree.flat();

        // check for this subtree has a parameter features elsewhere
        let paramOutsideSubtree: boolean = false;
        // If any of the params in the subtree are elsewhere.
        // iterate through all nodes in the current subtree
        for (const subtreeNode of allSubtreeNodes) {
            // ignore anything that isnt a param
            // console.log(subtreeNode.toString(), subtreeNode.type);
            if (!(subtreeNode.type === "SymbolNode") && !(subtreeNode.type === "ConstantNode")) {
                continue;
            }

            // Iterate through all nodes in the source and target
            for (const TreeNode of allTreeNodes) {
                // if the current TreeNode is in subtreeNodes, skip it. Its allowed to be in the commonSubTree
                if (subtreeNodes.includes(TreeNode.name)) {
                    continue;
                }

                // If the current subtreeNode is found somewhere else it shouldnt be replaced
                if (!allSubtreeNodes.includes(TreeNode) && subtreeNode.shallowEquals(TreeNode)) {
                    paramOutsideSubtree = true;
                    // console.log(
                    //     `Subtree ${commonSubtree[0][0]} is not allowed as it has external Params ${subtreeNode}`,
                    // );
                    break;
                }
            }
        }

        if (!paramOutsideSubtree) {
            validCommonSubTrees.push(uniqueTree);
        }
    }

    // console.log("validCommonSubTrees\n", displaySubtrees(validCommonSubTrees));

    return validCommonSubTrees;
}

/**
 * Returns an array of common sub trees where each subtree pair has one subtree in source and one subtree in target
 * Assumes allCommonSubtrees is already sorted
 *
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {Node[][][]}
 */
export function getCommonSubTreesAcrossBothTrees(
    sourceTreeNodes: Node[],
    targetTreeNodes: Node[],
    allCommonSubtrees: Node[][][],
): [Node[][][], Node[][][]] {
    const commonSubTrees: Node[][][] = [];
    const commonSubTrees2: Node[][][] = [];

    for (const [subtree1, subtree2] of allCommonSubtrees) {
        // Note: As we are using .includes() for non primitive objects, the comparison is similar
        // to comparing pointers in C.
        // This means that sourceTreeNodes.includes(subtree1[0]) will only be true if
        // sourceTreeNodes has an object that points to the same object as subtree1[0]
        if (
            !(
                (sourceTreeNodes.includes(subtree1[0]) && sourceTreeNodes.includes(subtree2[0])) ||
                (targetTreeNodes.includes(subtree1[0]) && targetTreeNodes.includes(subtree2[0]))
            )
        ) {
            let found = false;
            for (const seenTree of commonSubTrees) {
                if (hasSameNodeNames(seenTree[0], subtree2)) {
                    seenTree.push(subtree1, subtree2);
                    found = true;
                }
            }
            if (!found) {
                // Push only if subtree1 and subtree2 are from different trees
                commonSubTrees.push([subtree1, subtree2]);
            }
        }

        // Previously this logic was inside getCommonSubTreesNoExternalParams
        if (hasSameNodeNames(subtree1, subtree2)) {
            let found = false;

            for (const seenTree of commonSubTrees2) {
                if (hasSameNodeNames(seenTree[0], subtree2)) {
                    seenTree.push(subtree1, subtree2);
                    found = true;
                }
            }
            if (!found) {
                commonSubTrees2.push([subtree1, subtree2]);
            }
        }
    }

    return [commonSubTrees, commonSubTrees2];
}
/**
 * getAllCommonSubTrees assume allSubTrees is sorted from large -> small
 * "Common subtrees" are subtrees that has the same node names and share the
 * same structure. There is no need for them to be in the same order.
 *
 * Example: '{a} + {b}' and '{b} + {a}' are common subtrees
 *
 * @param {Node[][]} allSubTrees
 * @return {*}
 */
function getAllCommonSubTrees(allSubTrees: Node[][]) {
    const commonSubTrees: Node[][][] = [];

    for (let i1: number = 0; i1 < allSubTrees.length; i1++) {
        // If the subtree has less than 2 nodes we ignore it
        if (allSubTrees[i1].length <= 1) {
            break;
        }

        for (let i2: number = i1 + 1; i2 < allSubTrees.length; i2++) {
            // If the subtree has less than 2 nodes we ignore it
            if (allSubTrees[i2].length <= 1) {
                break;
            }

            const subtree1 = allSubTrees[i1];
            const subtree2 = allSubTrees[i2];

            if (hasSameNodeNames(subtree1, subtree2)) {
                commonSubTrees.push([subtree1, subtree2]);
            }
        }
    }
    return commonSubTrees;
}

/**
 * Function which manages the substitution of the subtrees in 5 easy steps!
 *    1) Get all subtrees of sourceTree and targetTree.
 *    2) Concatenates the source and target subtrees into allSubTrees, ordered from large -> small
 *    3) Find common subtrees of allSubTrees, largest first.
 *    4) Sorts commonSubTrees by length
 *    5) Replaces subtrees with variables
 *
 * substitutionMedthod can be 'noExternalParams' or 'AcrossBothTrees'
 *
 * @param {Node} sourceTree
 * @param {Node} targetTree
 * @returns {[Node[], Map<string, Node[]>]}
 */
export function substituteCommonSubTrees(
    sourceTree: Node,
    targetTree: Node,
): { diff: PDiff; map: Map<string, Node[]> } {
    // 1) Get all subtrees of sourceTree and targetTree.
    let returnedSourceTree = sourceTree;
    let returnedTargetTree = targetTree;

    const sourceSubTrees = getAllSubtrees(sourceTree);
    const targetSubTrees = getAllSubtrees(targetTree);

    // 2) get BFS's
    let sourceTreeNodes = sourceTree.getBFS();
    let targetTreeNodes = targetTree.getBFS();

    // 3) Concatenates the source and target subtrees into allSubTrees, ordered from large -> small
    const allSubTrees = sourceSubTrees.concat(targetSubTrees);

    allSubTrees.sort(function (obj1: Node[], obj2: Node[]): number {
        return obj2.length - obj1.length;
    });

    // 3.5) We now get all common subtrees, later this is trimmed in getCommonSubTreesAcrossBothTrees and getCommonSubTreesNoExternalParams
    const allCommonSubtrees = getAllCommonSubTrees(allSubTrees);

    // 4) Find common subtrees of allSubTrees, largest first.
    const [validCommonSubTrees2, commonSubTrees] = getCommonSubTreesAcrossBothTrees(
        sourceTreeNodes,
        targetTreeNodes,
        allCommonSubtrees,
    );

    const subtreeNodes: string[] = []; // an array of symbol nodes that have been already included in a subtree, this is used in getCommonSubTreesAcrossBothTrees() to allow nodes that are equal to others already in a subtree to be included in subtrees
    const commonNodes: Node[] = []; // an array of nodes used in subtrees
    for (const subtreeList of validCommonSubTrees2) {
        for (const subtree of subtreeList) {
            for (const node of subtree) {
                if (node.isParam() || node.type === "SymbolNode" || node.type === "OperatorNode") {
                    if (!subtreeNodes.includes(node.name)) {
                        subtreeNodes.push(node.name);
                    }
                }

                commonNodes.push(node);
            }
        }
    }

    const validCommonSubTrees1 = getCommonSubTreesNoExternalParams(
        sourceTreeNodes,
        targetTreeNodes,
        subtreeNodes,
        commonSubTrees,
    );

    const strippedCommonSubTrees1: Node[][][] = [];

    // console.log(`all ${allSubTrees.map((tree) => tree[0]).join("\n")}`);

    // iterate over the single-side subtrees (validCommonSubTrees1) remove any ones which contain other subtrees
    for (const subtreeList of validCommonSubTrees1) {
        let failed = false;
        for (const subtree of subtreeList) {
            for (const node of subtree) {
                if (commonNodes.indexOf(node) > -1) {
                    failed = true;
                    break;
                }
            }
            if (failed) {
                break;
            }
        }

        if (!failed) {
            strippedCommonSubTrees1.push(subtreeList);
        }
    }

    const allValidCommonSubTrees = strippedCommonSubTrees1.concat(validCommonSubTrees2);

    allValidCommonSubTrees.sort(function (obj1: Node[][], obj2: Node[][]): number {
        const obj1AcrossBothSubtree = validCommonSubTrees2.indexOf(obj1) > -1 ? 0 : 1000;
        const obj2AcrossBothSubtree = validCommonSubTrees2.indexOf(obj2) > -1 ? 0 : 1000;

        return obj2[0].length - obj1[0].length + obj1AcrossBothSubtree - obj2AcrossBothSubtree;
    });

    // console.log("CommonTrees to replace:\n", displaySubtrees(allValidCommonSubTrees));

    let zIndex = 1;
    // 5) Replaces subtrees with ZX
    const dict = new Map<string, Node[]>();
    for (const subTrees of allValidCommonSubTrees) {
        // console.log("Subtree in allValidCommonSubTrees:\n", subTrees);
        // console.log(`Replacing subtree ${subTrees[0][0]}`);

        sourceTreeNodes = returnedSourceTree.getBFS();
        targetTreeNodes = returnedTargetTree.getBFS();
        const {
            trees: roots,
            subtreeMap,
            newZIndex,
        } = replaceNodesAndChildren(sourceTreeNodes, targetTreeNodes, subTrees, zIndex);
        zIndex = newZIndex;

        for (const key of subtreeMap.keys()) {
            dict.set(key, subtreeMap.get(key));
        }
        returnedSourceTree = roots.source;
        returnedTargetTree = roots.target;
    }

    return { diff: new PDiff(returnedSourceTree, returnedTargetTree), map: dict };
}
