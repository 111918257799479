import { getApp } from "firebase/app";
import { getAuth, onAuthStateChanged, User } from "firebase/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export function useUser(): User | null {
    const auth = getAuth(getApp());
    const [user, setUser] = useState<User | null>(null);
    const nav = useNavigate();

    useEffect(() => {
        onAuthStateChanged(auth, (newuser) => {
            if (newuser) {
                setUser(newuser);
            } else {
                setUser(null);
                nav("/login");
            }
        });
    }, [auth, nav]);

    return user;
}
