/**
 * Returns a 8 digit unique id
 */
export function uid() {
    function genId() {
        return "xxxxxxxx".replace(/[x]/g, function (c) {
            let d = new Date().getTime(); //Timestamp
            var r = Math.random() * 36; //random number between 0 and 16
            r = (d + r) % 36 | 0;
            d = Math.floor(d / 36);
            return (c === "x" ? r : (r & 0x3) | 0x8).toString(36);
        });
    }

    function isConfusableFree(str: string) {
        // empty string is always confusion free
        if (!str) {
            return true;
        }

        // if string is unequal long to trimmed string it's always confusing
        if (str.length !== str.trim().length) {
            return false;
        }

        // check if all characters are lower case
        if (str.toLowerCase() !== str) {
            return false;
        }

        // check for 2 consecutive same chracters
        for (let i = 1; i < str.length; i = i + 1) {
            if (str[i] === str[i - 1]) {
                return false;
            }
        }

        // the following caracters are not allowed
        const rejectedStrings = ["l", "I", "L", "0", "O", "o"];
        for (const rejectedString of rejectedStrings) {
            if (str.includes(rejectedString)) {
                return false;
            }
        }

        return true;
    }

    // check generaded Id until it's human readable

    let r = genId();

    for (let i = 0; i < 100; i = i + 1) {
        if (isConfusableFree(r)) {
            return r;
        } else {
            r = genId(); // get a new Id since the old one was bad :)
        }
    }

    return r;
}

/**
 * Creates a simple hash based on a string. perfect to use for unique object ids
 * @param str
 * @returns
 */
export function simpleHash(str: string) {
    if (!str) {
        return "";
    }

    let hash = 0;
    for (let i = 0; i < str.length; i++) {
        const char = str.charCodeAt(i);
        hash = (hash << 5) - hash + char;
        hash &= hash; // Convert to 32bit integer
    }
    return new Uint32Array([hash])[0].toString(36);
}

/**
 * Creates a new id using simple hash
 * @param {string} id
 * @param {string} suffix
 * @returns {string}
 */
export function createNewId(id: string, suffix: string) {
    const prefix = id.slice(0, 3);
    const newHash = simpleHash(`${suffix}${id.slice(3)}`);
    return `${prefix}${newHash}`;
}
