import {
    getProjectSettingsFromHostname,
    getSettings,
    setSettings,
} from "@jaipuna/common-modules/dist/src/ProjectSettings";
import { TextField } from "@mui/material";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { cleanImgPath } from "../utils";

const settings = getProjectSettingsFromHostname(window.location.hostname);
if (settings) {
    setSettings(settings);
}

// init firebase
if (getApps().length === 0) {
    initializeApp(getSettings().firebaseConfig);
}
const storage = getStorage(getApp());

export function DragzoneTextfield({
    value,
    onChange,
    label,
}: {
    value: string;
    onChange: (val: string) => any;
    label: string;
}) {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        noClick: true,

        multiple: true,
    });
    const inputRef = useRef();
    const [selectionStart, setSelectionStart] = useState<any>(value.length);

    const updateSelectionStart = () => setSelectionStart((inputRef?.current as any)?.selectionStart);

    async function uploadImages(files: File[]) {
        const folder = `${Date.now()}`;

        console.log(`Uploading ${files[0].name} to\n${`WikiImages/${folder}/${cleanImgPath(files[0].name)}`}`);
        const res = await Promise.all(
            files.map((file) => uploadBytes(ref(storage, `WikiImages/${folder}/${cleanImgPath(file.name)}`), file)),
        );

        const imgTags = files.map(
            (file) => `![${file.name.split("."[0])}](uploads/${folder}/${cleanImgPath(file.name)})`,
        );

        onChange(value.slice(0, selectionStart) + `\n\n${imgTags.join("\n")}\n\n` + value.slice(selectionStart));
    }

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            uploadImages(acceptedFiles);
        }
    }, [acceptedFiles]);

    return (
        <span {...getRootProps()}>
            <input {...getInputProps()} />
            <TextField
                value={value}
                label={label}
                color={isDragActive ? "success" : undefined}
                inputRef={inputRef}
                onSelect={updateSelectionStart}
                onChange={(e) => onChange(e.target.value)}
                multiline
                fullWidth
                inputProps={{ style: { minHeight: "500px", alignItems: "flex-start" } }}
            />
        </span>
    );
}
