import { Node } from "./Node";

type conditionalNames = "==" | "!=" | "<" | ">" | "<=" | ">=";

export class RelationalNode extends Node {
    conditionals: conditionalNames[];
    constructor(args: Node[], conditionals: conditionalNames[], parent: Node = undefined) {
        super(conditionals.join(","), args, parent);
        this.type = "RelationalNode";
        this.conditionals = conditionals;
    }

    toTex(options: any = {}) {
        const condLatex = {
            "==": "=",
            "!=": "\\neq",
            "<": "<",
            ">": ">",
            "<=": "\\leq",
            ">=": "\\geq",
        };

        let _ret = this.args[0].toTex(options);
        this.conditionals.forEach((cond, index) => {
            _ret += ` ${condLatex[cond]} `;
            _ret += this.args[index + 1].toTex(options);
        });

        return _ret;
    }

    toString(options?: any) {
        let _ret = this.args[0].toString(options);
        this.conditionals.forEach((cond, index) => {
            _ret += ` ${cond} `;
            _ret += this.args[index + 1].toString(options);
        });
        return _ret;
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new RelationalNode(args, this.conditionals, parent);
    }
}
