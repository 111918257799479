import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RenderWikiMarkdown } from "../components/RenderWikiMarkdown";
import { Breadcrumbs } from "./Breadcrumbs";
import { DataCard } from "./DataCard";

export function PageRenderer({ page, funcDef }: { page: WikiPage; funcDef: WikiFunc | null }) {
    const theme = useTheme();

    return (
        <span style={{ width: "100%" }}>
            <Breadcrumbs path={page.path} />
            <Typography variant="h4" style={{ margin: "20px 0" }} color="textPrimary">
                {page.name}
            </Typography>
            {funcDef && <DataCard funcDef={funcDef} page={page} />}
            <RenderWikiMarkdown
                md={page.renderedDescription}
                color={theme.palette.mode === "dark" ? "white" : "black"}
            />
        </span>
    );
}
