// Generates a person object and returns it
export interface Person {
    name: string;
    he: string;
    his: string;
    him: string;
}

const MALENames: string[] = [
    "Jack",
    "Joshua",
    "Daniel",
    "James",
    "William",
    "Samuel",
    "Jacob",
    "Thomas",
    "Benjamin",
    "Ryan",
    "Liam",
    "Oliver",
    "Ethan",
    "Luke",
    "Matthew",
    "Noah",
    "Caleb",
    "Max",
    "Jayden",
    "Logan",
    "Dylan",
    "Connor",
    "Alexander",
    "Blake",
    "Riley",
    "Charlie",
    "Nathan",
    "Tyler",
    "George",
    "Joseph",
    "Lucas",
    "Lachlan",
    "Harrison",
    "Cameron",
    "Jordan",
    "Isaac",
    "Michael",
    "Alex",
    "Finn",
    "Oscar",
    "Jackson",
    "Hunter",
    "Cody",
    "Harry",
    "Levi",
    "Adam",
    "Flynn",
    "Nicholas",
    "Jake",
    "Joel",
    "Toby",
    "Jesse",
    "Callum",
    "Xavier",
    "Zachary",
    "Angus",
    "Seth",
    "Henry",
    "Jamie",
    "Bailey",
    "David",
    "Sam",
    "John",
    "Ashton",
    "Christopher",
    "Hamish",
    "Sean",
    "Mitchell",
    "Sebastian",
    "Charles",
    "Elijah",
    "Hayden",
    "Leo",
    "Aidan",
    "Andrew",
    "Ben",
    "Kaleb",
    "Troy",
    "Cooper",
    "Mason",
    "Luca",
    "Phoenix",
    "Aaron",
    "Dominic",
    "Brooklyn",
    "Taylor",
    "Christian",
    "Quinn",
    "Jonathan",
    "Cole",
    "Robert",
    "Carlos",
    "Kyle",
    "Edward",
    "Carter",
    "Gabriel",
    "Patrick",
    "Reuben",
    "Vincent",
    "Felix",
    "Kingston",
    "Lukas",
    "Isaiah",
    "Louis",
    "Lincoln",
    "Peter",
    "Luka",
    "Austin",
    "Archie",
    "Aiden",
    "Leon",
    "Marcus",
];

const FEMALENames: string[] = [
    "Charlotte",
    "Ella",
    "Sophie",
    "Emma",
    "Olivia",
    "Emily",
    "Grace",
    "Jessica",
    "Hannah",
    "Lily",
    "Isabella",
    "Lucy",
    "Chloe",
    "Ruby",
    "Georgia",
    "Paige",
    "Amelia",
    "Maia",
    "Zoe",
    "Madison",
    "Brooke",
    "Holly",
    "Samantha",
    "Sarah",
    "Mia",
    "Ava",
    "Jasmine",
    "Kate",
    "Hayley",
    "Caitlin",
    "Jade",
    "Sophia",
    "Amber",
    "Eva",
    "Molly",
    "Amy",
    "Katie",
    "Keira",
    "Anna",
    "Sienna",
    "Summer",
    "Zara",
    "Maddison",
    "Kayla",
    "Elizabeth",
    "Abigail",
    "Isla",
    "Brianna",
    "Danielle",
    "Rebecca",
    "Alyssa",
    "Jorja",
    "Leah",
    "Aaliyah",
    "Alexandra",
    "Tayla",
    "Ashley",
    "MacKenzie",
    "Phoebe",
    "Riley",
    "Lauren",
    "Alice",
    "Mya",
    "Stella",
    "Angel",
    "Eden",
    "Laura",
    "Kaitlyn",
    "Mikayla",
    "Poppy",
    "Abby",
    "Isabelle",
    "Nikita",
    "Caitlyn",
    "Harmony",
    "Trinity",
    "Lilly",
    "Ashlee",
    "Gabrielle",
    "Brooklyn",
    "Chelsea",
    "Bella",
    "Nevaeh",
    "Nicole",
    "Scarlett",
    "Libby",
    "Rose",
    "Taylor",
    "Isabel",
    "Maya",
    "Tessa",
    "Aimee",
    "Anahera",
    "Faith",
    "Jessie",
    "Courtney",
    "Maria",
    "Rachel",
    "Skye",
    "Alexis",
    "Savannah",
    "Madeleine",
    "Aria",
    "Victoria",
    "Gemma",
    "Gabriella",
    "Manaia",
    "Millie",
    "Hazel",
    "Natalie",
    "Peyton",
    "Sofia",
    "Lucia",
    "Anika",
    "Ayla",
];

export function getNewPerson(gender?: "MALE" | "FEMALE"): Person {
    let name: string = "";
    let pronouns: string[] = [];

    // check if gender is not undefined
    let genGender: string = gender;
    if (genGender === undefined) {
        genGender = ["MALE", "FEMALE"][Math.floor(Math.random() * 2)];
    }

    if (genGender === "MALE") {
        name = MALENames[Math.floor(Math.random() * MALENames.length)];
        pronouns = ["he", "his", "him"];
    } else {
        name = FEMALENames[Math.floor(Math.random() * FEMALENames.length)];
        pronouns = ["she", "her", "her"];
    }

    return {
        name: name,
        he: pronouns[0],
        his: pronouns[1],
        him: pronouns[2],
    };
}
