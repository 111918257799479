import * as draw_tree from "asciitree";
import { evaluateExpression, getMaxPrecision, Node } from "../src/modules";
import { generateDiffAndSubtreeMap, getSubtreeString, relabelerWIthMap } from "./DiffFinder/DiffFinder";
import { Diff } from "./modules/Archetype";
import { parse } from "./parser/parse";
import { simplifyDiff } from "./TransitionFinder/simplifyDiffs";

import mathjs = require("mathjs");
import pluralize = require("pluralize");
import math = require("mathjs");

/**
 * This class exposes Utility functions often used in the project
 */

/**
 * Generates unique coefficients.
 * If it can't make all values unique for 100 iterations it will return the last
 * iteration.
 * @param coefficients
 * @returns {{}}
 */
export function generateUniqueRandomCoefficients(coefficients: any): any {
    let maxTrials = 100; //Number of trials until the function gives up
    let _c = {};
    let _cArray = [];
    do {
        _c = {};
        _cArray = [];

        for (const key of Object.keys(coefficients)) {
            _c[key] = getRandomNumber(coefficients[key].start, coefficients[key].end);
            _cArray.push(_c[key]);
        }
        maxTrials = maxTrials - 1;
    } while (!hasNoDuplicates(_cArray) && maxTrials > 0);

    return _c;
}

/**
 * Checks if an array consists of only unique values
 * @param array
 * @returns {boolean}
 */
export function hasNoDuplicates(array: Array<number>): boolean {
    let unique = true;
    const _uArray = [];

    for (const v of array) {
        if (_uArray.indexOf(v) === -1) {
            _uArray.push(v);
        } else {
            unique = false;
            return unique;
        }
    }
    return unique;
}

/**
 * Removes duplicated elements from an array and returns a new one without duplicates
 * @param array
 * @returns {Array<T>}
 */
export function removeDuplicates(array: Array<any>): Array<any> {
    return Array.from(new Set(array));
}

/**
 * Returns a random integer or random float, depending on the type of min and max
 * @param min
 * @param max
 * @returns {number}
 */
export function getRandomNumber(min: number, max: number): number {
    if (isInt(min, max)) {
        return getRandomInt(min, max);
    } else {
        return getRandomFloat(min, max);
    }
}

/**
 * Returns a random integer between min-max (inclusive)
 * @param min
 * @param max
 * @returns {number}
 */
export function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Returns a random float between min-max
 * @param min
 * @param max
 * @returns {number}
 */
export function getRandomFloat(min: number, max: number): number {
    return Math.round((Math.random() * (max - min) + min) * 100) / 100.0;
}

/**
 * Checks if min and max are integers
 * @param min
 * @param max
 * @returns {boolean}
 */
export function isInt(min: number, max: number): boolean {
    let _isInt = true;

    if (!Number.isInteger(min)) {
        _isInt = false;
    }

    if (!Number.isInteger(max)) {
        _isInt = false;
    }

    return _isInt;
}

/**
 * This return the UID or null from a logged in user
 * //todo i'm using an experimental feature
 * read: http://stackoverflow.com/questions/42750060/getting-the-user-info-userrecord-from-a-database-trigger-in-the-firebase-cloud
 * @param event
 * @returns {string}
 */
export function uidFromFBEvent(event: any): string {
    let _uid: string = null;

    if (event.auth) {
        if (event.auth.admin === false) {
            if (event.auth.variable) {
                _uid = event.auth.variable.uid;
            }
        }
    }

    return _uid;
}

/**
 * This function turns any object into an array. Element key of the object is added to the array element
 * with a special property name called `key`. The value with a property name called `value`;
 * @param obj Any object
 * @returns {Array<{key:string, value: any}>}
 */
export function objectToArray(obj: any): Array<{ key: string; value: any }> {
    const _ret: Array<{ key: string; value: any }> = [];
    if (!obj) {
        return _ret;
    }

    const keys = Object.keys(obj);
    for (const key of keys) {
        const _obj = {
            key: key,
            value: obj[key],
        };
        _ret.push(_obj);
    }
    return _ret;
}

// just a passthrough temporarily
export { evaluateExpression };

export function randomiseArray(
    array: any[],
    ignoreFirst: boolean = false,
    ignoreLast: boolean = false,
    randomSeed: number = 0,
): mathjs.Matrix {
    const mistakeKids = [];
    // we dopnt edit the first and last value to make them more realistic
    array.forEach((val, index) => {
        if (index === 0 && ignoreFirst) {
            mistakeKids.push(val);
        } else if (index === array.length - 1 && ignoreLast) {
            mistakeKids.push(val);
        } else {
            mistakeKids.push(val + getRandomInt(-3, 3) + randomSeed);
        }
    });
    // return the sorted array
    return mathjs.matrix(
        mistakeKids.sort((a: number, b: number): number => {
            return a - b;
        }),
    );
}

/**
 * Returns all matches for a given regex and string as an array
 * @param {string} str
 * @param {RegExp} regex
 * @param {RegExpExecArray[]} [matches=[]]
 * @returns
 */
export function getAllRegexMatches(str: string, regex: RegExp, matches: RegExpExecArray[] = []): RegExpExecArray[] {
    const res = regex.exec(str);
    res && matches.push(res) && getAllRegexMatches(str, regex, matches);
    return matches;
}

/**
 * Returns all string matches for a given regex and string as an array
 * @param {string} str
 * @param {RegExp} regex
 * @param {RegExpExecArray[]} [matches=[]]
 * @returns
 */
export function getAllRegexMatchStrings(str: string, regex: RegExp) {
    const matches = str.matchAll(regex);
    const ret: string[] = [];
    for (const match of matches) {
        ret.push(match[0]);
    }
    return ret;
}

export function equalBracketCount(equation: string, char?: "(" | "{" | "["): boolean {
    let bracket = "{}";
    if (char === "(") bracket = "()";
    if (char === "[") bracket = "[]";

    let openCount: number = 0;
    let openPos: number = equation.indexOf(bracket[0]);

    while (openPos !== -1) {
        openCount = openCount + 1;
        openPos = equation.indexOf(bracket[0], openPos + 1);
    }

    let closeCount: number = 0;
    let closePos: number = equation.indexOf(bracket[1]);

    while (closePos !== -1) {
        closeCount = closeCount + 1;
        closePos = equation.indexOf(bracket[1], closePos + 1);
    }

    return openCount === closeCount;
}

export function stripQuotes(str: any): string {
    if (str === undefined) {
        return str;
    }

    let numToFormat: string;

    if (str.value !== undefined) {
        numToFormat = str.value.toString();
    } else {
        numToFormat = str.toString();
    }

    // edge case
    if (numToFormat.indexOf('"') > -1) {
        numToFormat = numToFormat.replace(/"/g, "");
    }
    if (numToFormat.indexOf("'") > -1) {
        numToFormat = numToFormat.replace(/'/g, "");
    }

    return numToFormat;
}

export function removeLeadingZeros(str: string): string {
    let _retString: string = "";

    let foundNonZero: boolean = false;
    for (const char of str) {
        if (!foundNonZero) {
            if (char !== "0" && char !== "." && char !== "-") {
                _retString += char;
                foundNonZero = true;
            }
        } else {
            _retString += char;
        }
    }
    return _retString;
}

export function getSingularUnit(unit: string): string {
    try {
        let unitName: string = mathjs.eval(unit).units.name;
        try {
            unitName = mathjs.eval(pluralize.singular(unit)).toString();
        } catch {
            unitName = unit;
        }
        return unitName;
    } catch (e) {
        return unit;
    }
}

/**
 * Reverses the given string and returns a copy of it eg: "abc" => "cba"
 * @export
 * @param {string} str
 * @returns {string}
 */
export function reverseString(str: string): string {
    // console.log(`reversing ${str}`);
    return str.split("").reverse().join("");
}

export function gcd(a: number, b: number): number {
    if (b) {
        return gcd(b, a % b);
    } else {
        return Math.abs(a);
    }
}

export function getTreesSideBySide(tree1: string, tree2: string): string {
    const sourceascii: string[] = tree1.split("\n");
    const targetascii: string[] = tree2.split("\n");

    const maxLines: number = Math.max(sourceascii.length, targetascii.length);

    let maxWidth: number = 0;
    for (const line of sourceascii) {
        if (line.length > maxWidth) {
            maxWidth = line.length;
        }
    }

    let _retString: string = "";
    for (let line: number = 0; line < maxLines; line++) {
        if (sourceascii[line] === undefined) {
            const buffer: string = " ".repeat(maxWidth + 1) + "  ";
            _retString += buffer + targetascii[line] + "\n";
        } else if (targetascii[line] === undefined) {
            _retString += sourceascii[line] + "\n";
        } else {
            const buffer: string = " ".repeat(maxWidth - sourceascii[line].length) + "   ";
            _retString += sourceascii[line] + buffer + targetascii[line] + "\n";
        }
    }

    return _retString;
}

// TEMPORARY FOR TESTING DURING CHANGEOVER
export function getMATHJSTreesSideBySide(source: mathjs.MathNode, target: mathjs.MathNode): string {
    const sourceascii: string[] = draw_tree(parse(source.toString()).getBasicTree()).split("\n");
    const targetascii: string[] = draw_tree(parse(target.toString()).getBasicTree()).split("\n");

    const maxLines: number = Math.max(sourceascii.length, targetascii.length);
    const sourceEquation: string = source.toString().split(" ").join("");
    const targetEquation: string = target.toString().split(" ").join("");
    let maxWidth: number = sourceEquation.length;
    for (const line of sourceascii) {
        if (line.length > maxWidth) {
            maxWidth = line.length;
        }
    }

    let _retString: string = "";

    _retString += "\n" + sourceEquation + " ".repeat(maxWidth - sourceEquation.length) + "   " + targetEquation;
    _retString += "\n";

    for (let line: number = 0; line < maxLines; line++) {
        if (sourceascii[line] === undefined) {
            const buffer: string = " ".repeat(maxWidth + 1) + "  ";
            _retString += buffer + targetascii[line] + "\n";
        } else if (targetascii[line] === undefined) {
            _retString += sourceascii[line] + "\n";
        } else {
            const buffer: string = " ".repeat(maxWidth - sourceascii[line].length) + "   ";
            _retString += sourceascii[line] + buffer + targetascii[line] + "\n";
        }
    }

    return _retString;
}

// ===================  Matric Functions  =================== //

// function dotProduct1D(array1: number[], array2: number[]) {
//     return array1.map((val, index) => val * array2[index]).reduce((acc, curr) => acc + curr);
// }

// function transpose2DMatrix(matrix: number[][]): number[][] {
//     return matrix[0].map((_, index) => matrix.map((val) => val[index]));
// }

/**
 * Performs matrix multiplication on 2 matrices
 * @export
 * @param {number[][]} leftMatrix
 * @param {number[][]} rightMatrix
 * @return {*}  {number[][]}
 */
export function matrix2DProduct(leftMatrix: number[][], rightMatrix: number[][]): number[][] {
    // the number of columns in the leftMatrix need to equal the num of rows on the rightMatrix
    const rows: number[][] = leftMatrix;
    const cols: number[][] = this.transpose2DMatrix(rightMatrix);

    const _ret: number[][] = [];
    rows.forEach((row, _) => {
        const rowOut = [];
        cols.forEach((col, _) => {
            rowOut.push(this.dotProduct1D(row, col));
        });
        _ret.push(rowOut);
    });

    return _ret;
}

/**
 * Returns a diff based on source and target expression
 * @param sourceExpression
 * @param targetExpression
 * @returns
 */
export function genDiffFromExpression(sourceExpression: string, targetExpression: string) {
    if (!sourceExpression && !targetExpression) {
        return new Diff();
    }

    const sourceStep = parse(sourceExpression, [], "DIFF");
    const targetStep = parse(targetExpression, [], "DIFF");
    const { diff, map: subtreeMap } = generateDiffAndSubtreeMap(sourceStep, targetStep);

    const retdiff = new Diff();

    retdiff.sourceExpression = sourceExpression;
    retdiff.targetExpression = targetExpression;

    retdiff.sourceDiff = diff.sourceString;
    retdiff.targetDiff = diff.targetString;

    const { expressions: relabledDiffs, keyMap } = relabelerWIthMap([diff.sourceString, diff.targetString]);

    retdiff.sourceDiffRelabeled = relabledDiffs[0];
    retdiff.targetDiffRelabeled = relabledDiffs[1];

    // merge the two maps toegether to make a map which does Xn => node or subtree from source/target
    const paramMap: Map<string, string> = new Map();
    for (const [Xkey, Xval] of keyMap) {
        const subtreeVal = subtreeMap.get(Xval);
        // if the Xval is a subtree, use that,
        if (subtreeVal) {
            paramMap.set(Xkey, getSubtreeString(subtreeVal));
        }
        // otherwise just use Xval
        else {
            paramMap.set(Xkey, Xval);
        }
    }
    retdiff.paramMap = paramMap;

    // generate the simple diff
    const { diff: simpleDiff, map: simpleMap } = simplifyDiff(
        { source: parse(relabledDiffs[0]), target: parse(relabledDiffs[1]) },
        paramMap,
    );
    retdiff.sourceDiffSimple = simpleDiff.source.toString();
    retdiff.targetDiffSimple = simpleDiff.target.toString();
    retdiff.simpleMap = simpleMap;

    return retdiff;
}

/**
 * Finds next number in the string.
 *
 * Returns a string with the number and its start position
 *
 * @export
 * @param {string} exp
 * @param {number} [start=0] Zero based index to start searching for a number
 * @return {*} {num, position}
 */
export function findNumber(exp: string, start: number = 0): any {
    let _toReturn = { num: "", position: -1 };
    if (exp.length <= start) return _toReturn;

    // Searches numbers remaining on the subExp
    const result = exp.substring(start).match(/(\d)+(\.\d+)?/);
    if (result) {
        _toReturn = { num: result[0], position: result["index"] + start };
    }
    return _toReturn;
}

/**
 * Count the decimal places of every number in the expression.
 * Returns an array with all decimal places counters in order.
 *
 * @export
 * @param {string} exp
 * @return {*}  {number[]}
 */
export function getExpressionDecimalPlaces(exp: string): number[] {
    // - remove any number between quotes
    let subExp = exp.replaceAll(/"([^"])*"/g, "");

    // - remove any number "touching" chars A-Z, a-z or _
    // We don't use [A-z] here, because it includes the square brackets
    subExp = subExp.replaceAll(
        /([A-Za-df-z_]*\d+(\.\d+)?[A-Za-df-z_]+)|([A-Za-df-z_]+\d+(\.\d+)?([A-Za-df-z_])*)/g,
        "",
    );

    const decimalPlaces: number[] = [];
    while (true) {
        let isUpdated = false;
        const { num, position } = findNumber(subExp);
        if (position === -1) {
            break;
        }
        const dot = num.indexOf(".");
        if (dot !== -1) {
            decimalPlaces.push(num.length - dot - 1);
        } else {
            decimalPlaces.push(0);
        }
        // check for scientific notation
        const num2 = findNumber(subExp, position + num.length);
        if (num2.position !== -1) {
            //extract the possible scientific notation
            // +2 means e+ or e-
            const possibleSci = subExp.substring(position, position + num.length + 2 + num2.num.length);
            if (possibleSci === `${num}e-${num2.num}`) {
                decimalPlaces[decimalPlaces.length - 1] += +num2.num;

                subExp = subExp.replace(`${num}e-${num2.num}`, "");
                isUpdated = true;
            } else if (possibleSci === `${num}e+${num2.num}`) {
                if (decimalPlaces[decimalPlaces.length - 1] - +num2.num >= 0) {
                    decimalPlaces[decimalPlaces.length - 1] -= +num2.num;
                } else {
                    decimalPlaces[decimalPlaces.length - 1] = 0;
                }

                subExp = subExp.replace(`${num}e+${num2.num}`, "");
                isUpdated = true;
            }
        }
        if (!isUpdated) subExp = subExp.replace(num, "");
    }
    return decimalPlaces;
}

/**
 * If needed, add missing trailing zeros, or round each number of a given
 * expression to fit the precision.
 * ```
 * fixExpressionPrecision("1 + 12.3", 5) -> "1.00000 + 12.30000"
 * fixExpressionPrecision("1 + 12.345", 2) -> "1.00 + 12.35"
 * fixExpressionPrecision("1 + 12", 1) -> "1.0 + 12.0"
 * ```
 * @export
 * @param {string} expression
 * @param {number} precision
 */
export function fixExpressionDecimalPlaces(
    expression: string,
    precision: number,
    preventRounding: boolean = false,
): string {
    if (typeof expression !== "string") {
        expression = new String(expression).toString();
    }
    let _toReturn = expression;
    try {
        // we need to iterate over the exp parsed.
        const tree = parse(expression);
        for (const node of tree.getNodesByType("ConstantNode")) {
            if (!(preventRounding && node.name.split(".")[1]?.length > precision)) {
                node.name = fixDecimalPlaces(node.name, precision);
            }
        }
        _toReturn = tree.toString();
    } catch (e) {
        // Parse cannot handle expression.
        console.log("fixExpressionDecimalPlaces error!", e);
    }
    return _toReturn;
}
/**
 * If needed, add missing trailing zeros, or round number to fit the precision.
 * ```
 * fixPrecision("12.3", 5) -> "12.30000"
 * fixPrecision("12.345", 2) -> "12.35"
 * fixPrecision("12", 1) -> "12.0"
 * ```
 * @export
 * @param {string} num
 * @param {number} precision
 */
export function fixDecimalPlaces(num: string, precision: number): string {
    // Check for boolean, because +true = 1 and +false = 0
    if (isNaN(+num) || typeof num === "boolean" || precision < 0 || isNaN(precision)) return num;
    const formatOptions: mathjs.FormatOptions = {
        // When notation is set to ‘fixed’, precision defines the number of significant digits after the decimal point.
        notation: "fixed",
        precision: precision,
    };
    return mathjs.format(+num, formatOptions);
}

/**
 * Turns negative zero to unsigned zero, regardless of the decimal places
 *
 * @export
 * @param {*} expression
 * @return {*}
 */
export function fixNegativeZero(expression: any): string {
    const copy = expression.toString().replace(/\s/g, "");
    if (!isNaN(+copy) && copy === `-${fixDecimalPlaces("0", getMaxPrecision(expression, 0))}`) {
        expression = copy.replace(/\-/, "");
    }
    return expression;
}
/**
 * Limits the number of digits in a number. Default max digits is 8.
 *
 * @export
 * @param {*} expression
 * @param {number} [maxDigits=8]
 * @return {*}
 */
export function limitDigits(expression: any, maxDigits: number = 8): any {
    let copy = new String(expression).replace(/\s/g, "").replace(/-/, "");
    if (!isNaN(+copy) && copy.length > maxDigits) {
        if (copy.includes(".")) {
            let digits = copy.split(".")[0].length;
            if (digits >= maxDigits) {
                expression = fixDecimalPlaces(expression, 0);
            } else if (digits + copy.split(".")[1].length > maxDigits) {
                expression = fixDecimalPlaces(expression, maxDigits - digits);
            }
        }
    }
    return expression;
}
export function cleanFloatingPoint(exp, defaultPrecision: number = 15) {
    let _toReturn = exp.toString();

    if (_toReturn.startsWith('"') && _toReturn.endsWith('"') && !isNaN(+_toReturn.slice(1, -1))) {
        _toReturn = _toReturn.slice(1, -1);
    }

    if (
        (typeof exp === "number" || !isNaN(+_toReturn)) &&
        _toReturn.includes(".") &&
        _toReturn.split(".")[1].length > 10 &&
        !_toReturn.includes("e+") &&
        !(+_toReturn).toString().includes("e+")
    ) {
        const num = +_toReturn;
        const precision = defaultPrecision - _toReturn.split(".")[0].length;

        if (precision > 0) {
            // limits the precision and lops off the final digit to fix floating point bug
            _toReturn = parseFloat(num.toFixed(precision).slice(0, -1)).toString();
        } else {
            _toReturn = parseFloat(num.toPrecision(defaultPrecision).slice(0, -1)).toString();
        }

        // round if necessary
        if (Math.abs(Math.ceil(+_toReturn) - +_toReturn) < 1e-13) {
            _toReturn = Math.ceil(+_toReturn).toString();
        } else if (Math.abs(Math.floor(+_toReturn) - +_toReturn) < 1e-13) {
            _toReturn = Math.floor(+_toReturn).toString();
        }

        _toReturn = keepTrailingZeros(exp.toString(), _toReturn);

        if (!isNaN(+_toReturn) && +_toReturn === +exp.toString()) {
            _toReturn = exp.toString();
        }
    }

    return _toReturn;
}

/**
 * Keep trailing zeros, if the outcome is equals to the income
 *
 * @export
 * @param {string} oldNum
 * @param {string} newNum
 * @return {*}  {string}
 */
export function keepTrailingZeros(oldNum: string, newNum: string): string {
    let _toReturn = newNum;

    if (typeof oldNum === "string" && typeof newNum === "string") {
        const longest = newNum.length > oldNum.length ? newNum : oldNum;
        const shortest = newNum.length > oldNum.length ? oldNum : newNum;

        if (
            !isNaN(+oldNum) &&
            !isNaN(+newNum) &&
            +oldNum === +newNum &&
            oldNum.substring(0, shortest.length) === newNum.substring(0, shortest.length)
        ) {
            let i = 0;
            while (oldNum[i] || newNum[i]) {
                if (
                    oldNum[i] !== newNum[i] &&
                    ((oldNum[i] !== "0" && !newNum[i]) || (!oldNum[i] && newNum[i] !== "0")) &&
                    ((oldNum[i] !== "." && !newNum[i]) || (!oldNum[i] && newNum[i] !== "."))
                ) {
                    // console.log("a", oldNum[i], newNum[i]);
                    break;
                }

                i++;
            }
            if (i === longest.length) {
                _toReturn = longest;
            }
            // console.log("b", i, longest.length);
        }
        // console.log("c", oldNum, typeof oldNum, newNum, typeof newNum, "--->", _toReturn);
    }
    return _toReturn;
}

/**
 *  Adds quotes to all constant nodes under one of the white-listed functions.
 *
 * @export
 * @param {Node} exp
 * @return {*}  {string}
 */
export function addQuotesToNums(exp: Node): string {
    const whiteListedFunctions = [
        "trunc",
        "f_decimalPlaces",
        "f_significantFigures",
        "f_digits",
        "toDP",
        "toSigFig",
        "toSci",
        "toEng",
        "getSigFig",
        "getDP",
        "getDigits",
    ];

    let cloneExp = exp.cloneDeep();
    cloneExp.getNodesByType("FunctionNode").forEach((node) => {
        if (whiteListedFunctions.includes(node.name)) {
            // we only need to check for immediate children because this function
            // will be called after its children is evaluated
            node.args.forEach((child) => {
                if (
                    !isNaN(+child.name) &&
                    child.type === "ConstantNode" &&
                    child.name !== "true" &&
                    child.name !== "false"
                ) {
                    child.name = `"${child.name}"`;
                }
            });
        }
    });

    return cloneExp.toString();
}
