import { ITuple } from "../modules";
import { Node } from "./Node";

// console.log("Node", Node);

type bracketType = "[]" | "()" | "{}" | "" | "matrix";

export class ArrayNode extends Node {
    bracket: bracketType;

    constructor(args: Node[] = [], parent: Node = undefined, bracket: bracketType = "matrix") {
        super("[]", args, parent);
        this.type = "ArrayNode";
        this.bracket = bracket;
    }

    setBrackets(bracket: bracketType): void {
        this.bracket = bracket;
    }

    toTex(options?: any): string {
        const bracketLatex = new Map([
            ["[]", "bmatrix"],
            ["()", "pmatrix"],
            ["{}", "Bmatrix"],
            ["", "matrix"],
            ["matrix", "bmatrix"],
        ]);
        const brackType = bracketLatex.get(this.bracket);

        // if we are rednering a matrix
        if (this.args.every((val) => val.type === "ArrayNode")) {
            const arrayLatex = this.args.map((row) => row.args.map((col) => col.toTex(options)));
            return `\\begin{bmatrix} ${arrayLatex.map((val) => val.join("&")).join("\\\\")} \\end{bmatrix}`;
        }
        // if we are rendering a non-matrix
        else {
            const arrayLatex = this.args.map((val) => val.toTex(options));

            if (this.bracket === "matrix") {
                return `\\begin{${brackType}} ${arrayLatex.join("\\\\")} \\end{${brackType}}`;
            } else {
                return `\\begin{${brackType}} ${arrayLatex.join(",")} \\end{${brackType}}`;
            }
        }
    }

    toString(options?: any): string {
        const bracketLatex = new Map([
            ["[]", "f_sArray"],
            ["()", "f_pArray"],
            ["{}", "f_bArray"],
            ["", "f_Array"],
        ]);
        if (this.bracket !== "matrix") {
            const funcName = bracketLatex.get(this.bracket);
            return `${funcName}([${this.args.map((val) => val.toString(options)).join(", ")}])`;
        } else {
            return `[${this.args.map((val) => val.toString(options)).join(", ")}]`;
        }
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new ArrayNode(args, parent, this.bracket);
    }

    getLatexNeighbour(direction: "left" | "right") {
        if (this.bracket !== "") {
            return this;
        } else if (direction === "left") {
            return this.args[0].getLatexNeighbour(direction);
        } else if (direction === "right") {
            return this.args[this.args.length - 1].getLatexNeighbour(direction);
        }
    }

    /**
     * Replaces subtrees inside EvalNodes with the evaluated expression
     * @return {*}  {Node}
     * @memberof Node
     */
    solve(parameters: ITuple = {}): Node {
        // if its child is eval, we have to insert the eval [...] into the args carefully
        let newArgs: Node[] = this.args.map((arg) => arg.solve(parameters));

        if (this.args[0].type === "EvalNode" && newArgs[0].type === "ArrayNode") {
            newArgs = newArgs[0].args;
        }

        this.args = newArgs;
        return this;
    }
}
