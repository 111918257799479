import { getInstructionSections } from "@jaipuna/common-modules/dist/src/LatexUtilities";
import * as articles from "articles";
import * as pluralize from "pluralize";
import { ITuple } from "../modules";
import { NumberParameter, Parameter } from "../modules/Archetype";
import { parse } from "../parser/parse";
import { getAllRegexMatchStrings } from "../utilities";
import { getNewItem, items } from "./itemGenerator";
import { Person, getNewPerson } from "./personGenerator";

type Token = {
    name: string;
    id: string;
    args: string[];
    plural: number;
    suffix: string;
};

type TokenInstance = Person | string;

/**
 * Removes any non-latex sections from the text and stores them for later
 * @param {string} text
 * @return {*}
 */
function storeNonText(text: string) {
    const sections = getInstructionSections(text);

    const nonTextMap: Map<string, string> = new Map<string, string>();
    const nonTextSections = sections.filter((val) => val.type !== "text");
    let noNonTextText = text;

    for (const [index, sect] of nonTextSections.entries()) {
        let replacer: string;
        if (sect.type === "diagram") replacer = `d[${sect.text}]d`;
        if (sect.type === "graph") replacer = `g[${sect.text}]g`;
        if (sect.type === "latex") replacer = `$[${sect.text}]$`;
        noNonTextText = noNonTextText.replace(replacer, `NON_TEXT_${index}`);
        nonTextMap.set(`NON_TEXT_${index}`, replacer);
    }

    return { text: noNonTextText, nonTextMap };
}

/**
 * Inserts back in any non-latex sections we previously removed
 * @param {string} text
 * @param {Map<string, string>} storedLatex
 * @return {*}
 */
function replaceNonText(text: string, storedLatex: Map<string, string>) {
    let tempText: string = text;

    for (const [key, value] of Array.from(storedLatex)) {
        tempText = tempText.replace(key, value);
    }

    return tempText;
}

/**
 * Any article eg: "an" or "a" is replaced with the correct one based on the following word
 * @param {string[]} words
 * @return {*}
 */
function fixArticles(words: string[]) {
    const fixedWords: string[] = [];

    for (const [index, word] of words.entries()) {
        let nextWord = words[index + 1];

        if (["a", "an"].includes(word.trim().toLowerCase()) && nextWord) {
            // remove non alpha chars
            nextWord = nextWord.replace(/\W/g, "");

            let correctArticle = "";
            // handle single letters here as articlize package cant
            if (nextWord.toLowerCase().match(/^[a-z]$/)) {
                const letterArticles = new Map(
                    "a:an,b:a,c:a,d:a,e:an,f:an,g:a,h:an,i:an,j:a,k:a,l:an,m:an,n:an,o:an,p:a,q:a,r:an,s:an,t:a,u:a,v:a,w:a,x:an,y:a,z:a"
                        .split(",")
                        .map((val) => val.split(":") as [string, string]),
                );
                correctArticle = letterArticles.get(nextWord.toLowerCase());
            } else {
                // Pull the lever Kronk
                correctArticle = articles.articlize(nextWord).split(" ")[0];
            }
            // maintain capitalisation
            fixedWords.push(word[0] === "A" ? "A" + correctArticle.slice(1) : correctArticle);
        } else {
            fixedWords.push(word);
        }
    }

    return fixedWords;
}

/**
 * Converts the Parameter[] into an ITuple so we can eval using the tree,
 * @param {string} text
 * @param {Parameter[]} params
 * @return {*}
 */
function evalWithParameter(text: string, params: Parameter[]) {
    let tree = parse(text.replace(/\{|\}/g, ""));
    return parseFloat(tree.eval(Object.fromEntries(params.map((val) => [val.name, parseFloat(val.value.toString())]))));
}

/**
 * Gets a value for the given token
 * @param {Token} token
 * @param {string[]} archsExps // compiled steps surrounded by $[...]$
 * @param {Map<string, TokenInstance>} tokenMap
 * @param {string} stepNum
 * @param {Parameter[]} params
 * @return {*}
 */
function getTokenInstance(
    token: Token,
    archsExps: string[],
    tokenMap: Map<string, TokenInstance>,
    stepNum: number,
    params: Parameter[],
) {
    const name = token.name;

    if (!name.startsWith("$")) {
        return name;
    }

    if (name === "$") {
        return "$";
    }

    // If the selection is for a step
    if (name === "$Step") {
        if (archsExps) {
            let stepCount = 0;
            if (token.args[0]) {
                const current = new NumberParameter();
                current.name = "current";
                current.value = stepNum.toString();
                stepCount = evalWithParameter(token.args[0], [current, ...params]);
            }
            return archsExps[stepCount];
        } else {
            return '"Error: No Steps supplied to QuestionGenerator"';
        }
    }
    // if the selector is a person
    if (name.includes("$Person")) {
        let gender;
        if (name === "PersonMale") gender = "MALE";
        if (name === "PersonFemale") gender = "FEMALE";
        return getNewPerson(gender);
    }
    // if the selector is an item
    else {
        let item: string;
        const tokenName = name.replace("$", "");

        // only attempt to generate a value if we recognise the tokenName
        if (items.has(tokenName)) {
            let watchdog: number = 0;
            while (item === undefined || Array.from(tokenMap.values()).includes(item)) {
                item = getNewItem(name.replace("$", ""));

                if (watchdog === 100) {
                    console.error(`[ questionGenerator ] Failed to find an item for ${name}  Ran out of options`);
                    return `ERROR: Failed to find an item for ${name} : Ran out of options`;
                }

                watchdog++;
            }
        }

        if (item === undefined) {
            return name;
        } else {
            return item;
        }
    }
}

/**
 * Turns a token into a string, applying its args, pronouns etc. It also capitalises words
 *
 * @param {Token} token
 * @param {TokenInstance} tokenInstance
 * @param {boolean} afterSentEnd //  this token appears after the end of a sentence
 * @return {*}  {string}
 */
function stringifyTokenInstance(tokenInstance: TokenInstance, token: Token, afterSentEnd: boolean) {
    let tokenStr = "";
    if (typeof tokenInstance === "string") {
        tokenStr = tokenInstance;
    } else {
        let val = tokenInstance;
        if (val && token && token.args) {
            for (const selector of token.args) {
                val = val[selector];
            }
        }
        tokenStr =
            val?.toString() || `ERROR: "${token.args[token.args.length - 1]}" does not exist on "${tokenInstance}"`;
    }

    // apply the correct pluralisation
    if (token.plural !== 1) {
        tokenStr = pluralize(tokenStr, token.plural);
    }

    // if we are after a sentence end, apply capitalisation
    if (tokenStr && tokenStr.length > 0 && token.name.startsWith("$") && afterSentEnd) {
        tokenStr = tokenStr[0].toUpperCase() + tokenStr.slice(1);
    }

    return tokenStr + token.suffix;
}

/**
 * Splits token into its parts eg:
 * ```
 * "$Person#1.name~{a}"
 *     =>  name:    "$Person"
 *         id:      "$Person#1"
 *         args:    ['name']
 *         plural:  number
 * ```
 * @param {string} tokenStr
 * @param {Parameter[]} params
 * @param {number} stepNum
 * @return {*}  {Token}
 */
function parseToken(tokenStr: string, params: Parameter[], stepNum: number) {
    const tempToken = { NAME: "", ID: "", ARGS: "", PLURAL: "", SUFFIX: "" };

    let mode: "NAME" | "ID" | "ARGS" | "PLURAL" | "SUFFIX" = "NAME";
    let braceLevel = 0;
    const tokenStrArr = [...tokenStr];
    for (const [idx, letter] of tokenStrArr.entries()) {
        const prevChar = idx !== 0 ? tokenStrArr[idx - 1] : "";
        const nextChar = idx !== tokenStrArr.length - 1 ? tokenStrArr[idx + 1] : "";

        if (letter === "{") braceLevel++;
        if (letter === "}") braceLevel--;

        // nextChar has to be a number eg `$Person#1` and not `## Heading2`
        if (letter === "#" && braceLevel === 0 && ![" ", "#", ""].includes(nextChar)) {
            mode = "ID";
        } else if (letter === "." && braceLevel === 0 && tokenStr.startsWith("$")) {
            if (mode === "ARGS") {
                tempToken.ARGS += letter;
            } else {
                mode = "ARGS";
            }
        }
        // Only want to detect ~ for plurals, not markdown strikethrough
        else if (letter === "~" && braceLevel === 0 && prevChar !== "~" && nextChar !== "~") {
            // checking here for ~~ which is valid strikethrough syntax
            mode = "PLURAL";
        } else if (letter === "+" && braceLevel === 0) {
            mode = "SUFFIX";
        } else {
            tempToken[mode] += letter;
        }
    }

    let plural = 1;
    if (tempToken.PLURAL && tempToken.PLURAL.trim() !== "") {
        plural = evalWithParameter(tempToken.PLURAL, params);
    }

    return {
        name: tempToken.NAME,
        id: `${tempToken.NAME}#${tempToken.NAME === "$Step" ? tempToken.ARGS : tempToken.ID}`,
        args: tempToken.ARGS.split(".").filter((val) => val && val.trim() !== ""),
        plural,
        suffix: tempToken.SUFFIX,
    } as Token;
}

/**
 * Compiles an instruction template into a user-ready string
 * - Replaces global tokens like `$Fruit` with strings like "apple"
 * - Replaces people tokens like `$Person.name` with strings like "Sarah"
 * - Applies capitalisation to tokens to match sentence structure
 * - Applies correct articles eg: "a" vs "an" to articles
 *
 * **Does not apply any tokens to latex, graphs or diagrams**
 *
 * @export
 * @param {string} text
 * @param {ITuple} paramsTuple
 * @param {string[]} archsExps
 * @param {number} stepNum
 * @return {*}  {string}
 */
export function compileInstrTemplate(text: string, paramsTuple: ITuple, archsExps: string[], stepNum: number) {
    // Convert params to Parameter[]
    const params: Parameter[] = Array.from(Object.entries(paramsTuple)).map(([key, val]) => {
        const p = new NumberParameter();
        p.name = key;
        p.value = val.toString();
        return p;
    });

    // Temporarily remove  non-text sections, we dont insert template words into latex, graphs etc
    const { text: pureText, nonTextMap } = storeNonText(text);

    /** Map of Tokens ID -> value eg:
     * ```
     * "$Person#1" -> Person{name: "Sarah", he: "she", him: "her", his: "her"}
     * "$Fruit#2" -> "apple"
     * ``` */
    const tokenIdToVal = new Map<string, TokenInstance>();

    // remove 0th char if whitespace, helps with whiteSpace storage and rejoining
    const trimText = pureText.trim();

    // store the whitespace so we can both split by any whitespace and still maintain correct spacing
    // const whiteSpace = getAllRegexMatches(trimText, /\s/g).map((val) => val[0]);
    const whiteSpace = getAllRegexMatchStrings(trimText, /\s/g);

    // replace words matching tokens with the compiled token
    let tokenised: string[] = [];
    const words = trimText.split(/\s/g);
    for (const [index, wordRaw] of words.entries()) {
        // deal with suffix of tokens like punctuation etc
        let suffix = "";
        let word = wordRaw;
        if ([",", ".", "!", "?"].includes(word[word.length - 1])) {
            suffix = word[word.length - 1];
            word = word.slice(0, -1);
        }

        // First we parse the token, this generated Token is not human-ready
        const token = parseToken(word, params, stepNum);

        // We save this token to the map so if the id is used again, we give the user the same word
        let tokenInstance =
            tokenIdToVal.get(token.id) || getTokenInstance(token, archsExps || [], tokenIdToVal, stepNum, params);
        tokenIdToVal.set(token.id, tokenInstance);

        const prevWord = words[index - 1] || "."; // if !prevword, we are at start, default to capital
        const afterSentEnd = [".", "!", "?"].includes(prevWord[prevWord.length - 1]);

        // Stringify the token to make it human readable, applies plural and capitalisation etc
        const tokenString = stringifyTokenInstance(tokenInstance, token, afterSentEnd) + suffix;

        tokenised.push(tokenString);
    }

    // a apple => an apple
    tokenised = fixArticles(tokenised);

    // zip the tokend and whitespace back together
    let toRet = tokenised.map((val, i) => val + (whiteSpace[i] === undefined ? "" : whiteSpace[i])).join("");

    toRet = replaceNonText(toRet, nonTextMap);

    let { text: toRetText, nonTextMap: toRetNonTextMap } = storeNonText(toRet);

    // Fix other capitalisation issues
    toRetText = toRetText.replace(
        /([^ ][\.?!] |^)[a-z]/g,
        (val) => val.slice(0, -1) + val[val.length - 1].toUpperCase(),
    );

    // reapply any latex
    toRet = replaceNonText(toRetText, toRetNonTextMap);

    return toRet;
}
