import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { uid } from "@jaipuna/shared-external";
import { PageEditor } from "../components/PageEditor";
import { useUser } from "../hooks/useUser";

export function NewPageView({ pages, funcs }: { pages: WikiPage[]; funcs: WikiFunc[] }) {
    const user = useUser();
    return (
        <PageEditor
            pages={pages}
            page={{
                id: uid(),
                name: "",
                path: "/",
                description: "",
                renderedDescription: "",
                lastUpdated: Date.now(),
                editedBy: user?.email || "",
                examples: [],
                seeAlso: [],
            }}
            isNewPage
            onCloseEditor={() => {}}
            funcDef={null}
        />
    );
}
