import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import {
    Alert,
    Button,
    Dialog,
    DialogContent,
    Grid,
    Step,
    StepLabel,
    Stepper,
    Typography,
    useTheme,
} from "@mui/material";
import { collection, doc, getDocs, getFirestore, query, setDoc, where } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { timeAgo } from "../utils";
import { PageRenderer } from "./PageRenderer";

export function HistoryModal({
    page,
    open,
    funcDef,
    onClose,
}: {
    page: WikiPage;
    funcDef: WikiFunc | null;
    open: boolean;
    onClose: () => void;
}) {
    const [versions, setVersions] = useState<WikiPage[] | null>(null);
    const theme = useTheme();
    const [previewIndex, setPreviewIndex] = useState(0);
    const nav = useNavigate();

    // load history objects
    useEffect(() => {
        setVersions(null);

        const ref = collection(getFirestore(), "WikiHistory");
        getDocs(query(ref, where("id", "==", page.id))).then((querySnapshot) => {
            const funcs: WikiPage[] = [];
            querySnapshot.forEach((doc) => {
                funcs.push(doc.data() as WikiPage);
            });
            funcs.sort((a, b) => (b.lastUpdated < a.lastUpdated ? -1 : 1));
            setVersions([page, ...funcs]);
        });
    }, [open, page.name]);

    const handleUserKeyPress = useCallback(
        (event: any) => {
            if (event.key === "ArrowDown" && previewIndex < (versions || []).length - 1) {
                setPreviewIndex(previewIndex + 1);
            } else if (event.key === "ArrowUp" && previewIndex > 0) {
                setPreviewIndex(previewIndex - 1);
            }
        },
        [previewIndex, versions],
    );

    useEffect(() => {
        window.addEventListener("keydown", handleUserKeyPress);
        return () => {
            window.removeEventListener("keydown", handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    function restoreVersion(oldPage: WikiPage) {
        // copy the current version to the history collection
        setDoc(doc(getFirestore(), "WikiHistory", `${page.id}-${page.lastUpdated}`), page);

        // grab the version to restore
        setDoc(doc(getFirestore(), "WikiPages", oldPage.id), { ...oldPage, lastUpdated: Date.now() });
        setPreviewIndex(0);
        onClose();
        nav("/#" + oldPage.path.slice(1).toLowerCase());
    }

    const previewVersion = (versions || [])[previewIndex];

    return (
        <Dialog
            onClose={onClose}
            open={open}
            maxWidth="lg"
            fullWidth
            PaperProps={{ style: { background: theme.palette.background.default } }}
        >
            {versions && previewVersion && (
                <DialogContent style={{ paddingTop: "30px" }}>
                    <Grid container>
                        <Grid
                            item
                            xs={6}
                            sm={4}
                            md={3}
                            lg={2}
                            style={{ paddingLeft: "10px", height: "100%", overflow: "scroll" }}
                        >
                            <Stepper activeStep={previewIndex} nonLinear orientation="vertical">
                                {versions.slice(0).map((oldpage, i) => (
                                    <Step key={oldpage.lastUpdated}>
                                        <Button
                                            onClick={() => setPreviewIndex(i)}
                                            variant="text"
                                            style={{
                                                paddingTop: 0,
                                                paddingBottom: 0,
                                                marginLeft: "-7px",
                                                textTransform: "none",
                                                width: "90%",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            <StepLabel>
                                                {oldpage.editedBy.split("@")[0]}
                                                <br />
                                                {timeAgo(new Date(oldpage.lastUpdated))}
                                            </StepLabel>
                                        </Button>
                                    </Step>
                                ))}
                            </Stepper>
                        </Grid>
                        <Grid item xs={6} sm={8} md={9} lg={10} style={{ paddingLeft: "20px" }}>
                            <Grid container spacing={3} justifyContent={"space-between"}>
                                <Grid item>
                                    <Typography variant="subtitle1">
                                        Edited: {new Date(previewVersion.lastUpdated).toLocaleDateString("en-UK")}
                                        {" ("}
                                        {timeAgo(new Date(previewVersion.lastUpdated))}
                                        {")"}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button
                                        disabled={previewVersion.lastUpdated === page.lastUpdated}
                                        color="primary"
                                        style={{ marginRight: "20px" }}
                                        onClick={() => restoreVersion(previewVersion)}
                                    >
                                        Restore this Version
                                    </Button>
                                    <Button onClick={() => onClose()}>Close</Button>
                                </Grid>
                            </Grid>

                            <Typography variant="subtitle1">By: {previewVersion.editedBy}</Typography>

                            {previewVersion.lastUpdated === page.lastUpdated && (
                                <Alert variant="filled" severity="success" style={{ marginTop: "20px" }}>
                                    Current version
                                </Alert>
                            )}
                            <div style={{ minHeight: "500px", marginTop: "30px" }}>
                                <PageRenderer page={previewVersion} funcDef={funcDef} />
                            </div>
                        </Grid>
                    </Grid>
                </DialogContent>
            )}
        </Dialog>
    );
}
