import { AmyRender, hasSymetricBrackets } from "@amy-app/react-syntax-renderer";
import { Alert } from "@mui/material";
import marked from "marked";
import { useNavigate } from "react-router-dom";

export function RenderWikiMarkdown({ md, color }: { md: string; color: "black" | "white" }) {
    const nav = useNavigate();
    const compileError = md.startsWith("COMPILER_ERROR");

    // need to pass <a.../> tags to react-router
    function navigate(event: any) {
        if (event.target?.tagName === "A" && !event.target.getAttribute("href").startsWith("http")) {
            event.preventDefault();
            nav(event.target.getAttribute("href").toLowerCase());
        }
    }

    const cleanMd = md.replace(/^COMPILER_ERROR/, "");

    const fallbackRender = (
        <>
            <Alert severity="warning">
                This Article contains synax errors and cannot be rendered with AmySyntax, some features like $[...]$
                will be missing
            </Alert>

            <span
                style={{ margin: "20px 0", display: "block", color: color }}
                className={`amymarkdown ${color === "white" ? "darkmode" : ""}`}
                dangerouslySetInnerHTML={{ __html: marked(cleanMd) }}
                onClick={(event) => navigate(event)}
            />
        </>
    );

    if (!compileError && hasSymetricBrackets(cleanMd)) {
        try {
            return (
                <span
                    style={{ color: color }}
                    className={`${color === "white" ? "darkmode" : ""}`}
                    onClick={(event) => navigate(event)}
                >
                    <AmyRender text={cleanMd} color={color} />
                </span>
            );
        } catch {
            return fallbackRender;
        }
    } else {
        return fallbackRender;
    }
}
