/**
 * This file will incluse for all project the settings.
 * So we have a single point to config it all
 */

const TIMES = {
    NEVER: "1 of jan 00:00",
    MINUTLY: "every 1 minutes",
    HOURLY: "every 1 hours",
    DAILY: "every 24 hours",
};

interface IProjectSetting {
    projectId: string;
    region: "europe-west1";
    hostnames: string[];
    firebaseConfig: {
        apiKey: string;
        authDomain: string;
        databaseURL: string;
        projectId: string;
        storageBucket: string;
        messagingSenderId: string;
        appId?: string;
    };
    client: {
        useCache: boolean;
        dashboard: {
            showArchetypeSearch: boolean;
            showLanguageSelector: boolean;
            showRoleSelector: boolean;
        };
        assignment: {
            showCopyArchId: boolean;
            showArchetypeInfo: boolean; // show info which is useful for the partners
            showcuiLink: boolean;
        };
        explorer: {
            showExtraDetails: boolean;
            showEmptyTiles: boolean;
        };
        onboarding: {
            inviteCode: boolean;
        };
        i18n: {
            showMissingKey: boolean;
        };
        login: {
            loginProviders: {
                google: boolean;
                facebook: boolean;
                email: boolean;
            };
        };
    };
    server: {
        showAllMistakes: boolean;
        useCache: boolean;
        // each minute: every 1 minutes
        // each hour:   every 1 hours
        // each day:    every 24 hours
        // 1 Jan:       1 of jan 00:00
        minuteScheduler: string;
        hourlyScheduler: string;
        dailyScheduler: string;
        sqlCopy: boolean;
        keepWarm: boolean;
        backup: boolean;
        auth: {
            learnUrl: string;
            apiUrl: string;
            iApiUrl: string;
        };

        newUser: {
            useDefaultSchool: boolean;
        };
    };
}

const settings: IProjectSetting[] = [
    // DEVELOPMENT PROJECTS
    {
        projectId: "dev1-amy-app",
        region: "europe-west1",
        hostnames: [
            "dev1-amy-app.firebaseapp.com",
            "learn-dev1-amy-app.firebaseapp.com",
            "iframe-dev1-amy-app.firebaseapp.com",
            "cui-dev1-amy-app.firebaseapp.com",
            "teach-dev1-amy-app.firebaseapp.com",
            "ana-dev1-amy-app.firebaseapp.com",
            "cui2-dev1-amy-app.firebaseapp.com",
            "cubi-dev1-amy-app.firebaseapp.com",
            "localhost",
            "127.0.0.1",
            "experiment-amy-app.web.app",
            "experiment-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyD4Y4Rb1detiPVFjoHtbhaSxWOo7KiEKjQ",
            authDomain: "dev1-amy-app.firebaseapp.com",
            databaseURL: "https://dev1-amy-app.firebaseio.com",
            projectId: "dev1-amy-app",
            storageBucket: "dev1-amy-app.appspot.com",
            messagingSenderId: "712940000014",
            appId: "1:712940000014:web:cc63c7d4c00e4ab9",
        },
        client: {
            dashboard: {
                showLanguageSelector: true,
                showRoleSelector: true,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: false,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.MINUTLY,
            hourlyScheduler: TIMES.HOURLY,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: true,
            auth: {
                learnUrl: "https://learn-dev1-amy-app.firebaseapp.com",
                apiUrl: "https://api-dev1-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-dev1-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    // TESTING PROJECT
    {
        projectId: "test-amy-app",
        region: "europe-west1",
        hostnames: [
            "test-amy-app.firebaseapp.com",
            "learn-test-amy-app.firebaseapp.com",
            "cui-test-amy-app.firebaseapp.com",
            "teach-test-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyD22hcgaDmpOzZPFY1kPEj-ulIlkOE2M6M",
            authDomain: "test-amy-app.firebaseapp.com",
            databaseURL: "https://test-amy-app.firebaseio.com",
            projectId: "test-amy-app",
            storageBucket: "test-amy-app.appspot.com",
            messagingSenderId: "868408118952",
            appId: "1:868408118952:web:5b7f8cc89d3218ef933c9d",
        },
        client: {
            dashboard: {
                showLanguageSelector: true,
                showRoleSelector: true,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: false,
                    email: false,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: true,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-test-amy-app.firebaseapp.com",
                apiUrl: "https://api-test-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-test-amy-app.firebaseapp.com",
            },
            useCache: true,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    // PRODUCTION PROJECTS
    {
        projectId: "amy--app",
        region: "europe-west1",
        hostnames: [
            "learn.amy.app",
            "learn-amy--app.web.app",
            "learn-amy--app.firebaseapp.com",
            "ana-amy--app.firebaseapp.com",
            "analytics.amy.app",
            "ana-amy--app--newanalytics-8e448dzb.web.app",
            "iframe.amy.app",
            "cui2-amy--app.firebaseapp.com",
            "cui2.amy.app",
            "abi.amy.app",
            "cui2-amy--app",
            "cui-amy--app",
            "learn-amy--app",
            "amy--app",
            "mia.cogx.info",
            "maths.userfriendlyresources.com",
            "wiki.amy.app",
            "cubi-amy--app.firebaseapp.com",
            "curriculum.amy.app",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyC9ttADyEm-cfPb__joYv3MgBGjWOkxwMc",
            authDomain: "learn.amy.app",
            databaseURL: "https://amy--app-eu.europe-west1.firebasedatabase.app",
            projectId: "amy--app",
            storageBucket: "amy--app.appspot.com",
            messagingSenderId: "344535742053",
            appId: "1:344535742053:web:a3298943d3d6171f0af88c",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: false,
            },
            assignment: {
                showCopyArchId: false,
                showArchetypeInfo: false,
                showcuiLink: false,
            },
            explorer: {
                showExtraDetails: false,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: true,
            },
            i18n: {
                showMissingKey: false,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: true,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.MINUTLY,
            hourlyScheduler: TIMES.HOURLY,
            dailyScheduler: TIMES.DAILY,
            keepWarm: true,
            backup: true,
            sqlCopy: true,
            auth: {
                learnUrl: "https://learn.amy.app",
                apiUrl: "https://api.amy.app",
                iApiUrl: "https://europe-west1-amy--app.cloudfunctions.net/iapi",
            },
            useCache: true,
            newUser: {
                useDefaultSchool: false,
            },
        },
    },
    {
        projectId: "amy-demo",
        region: "europe-west1",
        hostnames: [
            "amy-demo.firebaseapp.com",
            "learn-amy-demo.firebaseapp.com",
            "cui-amy-demo.firebaseapp.com",
            "teach-amy-demo.firebaseapp.com",
            "demo.amy.app",
            "learn.demo.amy.app",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyCfufIb_jq67f_HKn72RHpDK9zwMSvzT2g",
            authDomain: "amy-demo.firebaseapp.com",
            databaseURL: "https://amy-demo.firebaseio.com",
            projectId: "amy-demo",
            storageBucket: "amy-demo.appspot.com",
            messagingSenderId: "346207533779",
        },
        client: {
            dashboard: {
                showLanguageSelector: true,
                showRoleSelector: false,
                showArchetypeSearch: false,
            },
            assignment: {
                showCopyArchId: false,
                showArchetypeInfo: false,
                showcuiLink: false,
            },
            explorer: {
                showExtraDetails: false,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: false,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: true,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.MINUTLY,
            hourlyScheduler: TIMES.HOURLY,
            dailyScheduler: TIMES.DAILY,
            keepWarm: true,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://amy-demo.firebaseapp.com",
                apiUrl: "https://api.amy-demo.firebaseapp.com",
                iApiUrl: "https://iapi-amy-demo.firebaseapp.com",
            },
            useCache: true,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "staging-amy-app",
        region: "europe-west1",
        hostnames: [
            "staging-amy-app.firebaseapp.com",
            "learn-staging-amy-app.firebaseapp.com",
            "cui-staging-amy-app.firebaseapp.com",
            "teach-staging-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyCaQFEbCJBadrCzGfXER9BnRs-qYxZCCEA",
            authDomain: "staging-amy-app.firebaseapp.com",
            databaseURL: "https://staging-amy-app.firebaseio.com",
            projectId: "staging-amy-app",
            storageBucket: "staging-amy-app.appspot.com",
            messagingSenderId: "659928660023",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: false,
            },
            assignment: {
                showCopyArchId: false,
                showArchetypeInfo: false,
                showcuiLink: false,
            },
            explorer: {
                showExtraDetails: false,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: true,
            },
            i18n: {
                showMissingKey: false,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-staging-amy-app.firebaseapp.com",
                apiUrl: "https://api-staging-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-staging-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: false,
            },
        },
    },
    {
        projectId: "partners-amy-app",
        region: "europe-west1",
        hostnames: ["partners.amy.app", "learn-partners-amy-app.firebaseapp.com"],
        firebaseConfig: {
            apiKey: "AIzaSyBtIU2o2GkAVUiDyWP6nfRSTKzBhcsJwps",
            authDomain: "partners-amy-app.firebaseapp.com",
            databaseURL: "https://partners-amy-app.firebaseio.com",
            projectId: "partners-amy-app",
            storageBucket: "partners-amy-app.appspot.com",
            messagingSenderId: "208978792839",
            appId: "1:208978792839:web:fda3c36737ba0af7",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: false,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: false,
            },
            onboarding: {
                inviteCode: true,
            },
            i18n: {
                showMissingKey: false,
            },
            login: {
                loginProviders: {
                    google: true, //can only access partners with google, not any other login options
                    facebook: false,
                    email: false,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.MINUTLY,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: true,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://partners.amy.app",
                apiUrl: "https://api.partners.amy.app",
                iApiUrl: "https://iapi-partners-amy-app.firebaseapp.com",
            },
            useCache: true,
            newUser: {
                useDefaultSchool: false,
            },
        },
    },
    {
        projectId: "partners2-amy-app",
        region: "europe-west1",
        hostnames: ["nw.amy.app", "partners2.amy.app", "learn-partners2-amy-app.firebaseapp.com"],
        firebaseConfig: {
            apiKey: "AIzaSyAeG8Z7ACvtGq3PmRVTRIX2sx3GvvYGoDg",
            authDomain: "partners2-amy-app.firebaseapp.com",
            databaseURL: "https://partners2-amy-app.firebaseio.com",
            projectId: "partners2-amy-app",
            storageBucket: "partners2-amy-app.appspot.com",
            messagingSenderId: "1005573501192",
            appId: "1:1005573501192:web:9aef495a3861c12b3bfcbd",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: false,
            },
            explorer: {
                showExtraDetails: false,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: true,
            },
            i18n: {
                showMissingKey: false,
            },
            login: {
                loginProviders: {
                    google: true, //can only sign in with google option, not any other ones
                    facebook: false,
                    email: false,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.MINUTLY,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: true,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://partners2.amy.app",
                apiUrl: "https://api.partners2.amy.app",
                iApiUrl: "https://api-partners2-amy-app.firebaseapp.com",
            },
            useCache: true,
            newUser: {
                useDefaultSchool: false,
            },
        },
    },
    // CONTENT TEAM PROJECTS
    {
        projectId: "content1-amy-app",
        region: "europe-west1",
        hostnames: [
            "content1-amy-app.firebaseapp.com",
            "learn-content1-amy-app.firebaseapp.com",
            "cui-content1-amy-app.firebaseapp.com",
            "teach-content1-amy-app.firebaseapp.com",
            "cui2-content1-amy-app.web.app",
            "cui2-content1-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyDHIlhfAYstfa6GtlgezK6k3jjZljztgMo",
            authDomain: "content1-amy-app.firebaseapp.com",
            databaseURL: "https://content1-amy-app.firebaseio.com",
            projectId: "content1-amy-app",
            storageBucket: "content1-amy-app.appspot.com",
            messagingSenderId: "265338105389",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-content1-amy-app.firebaseapp.com",
                apiUrl: "https://api-content1-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-content1-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "content2-amy-app",
        region: "europe-west1",
        hostnames: [
            "content2-amy-app.firebaseapp.com",
            "learn-content2-amy-app.firebaseapp.com",
            "cui-content2-amy-app.firebaseapp.com",
            "teach-content2-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyCpZXzPKnAh5pDteS2UOIbHMlSrZB_xKHI",
            authDomain: "content2-amy-app.firebaseapp.com",
            databaseURL: "https://content2-amy-app.firebaseio.com",
            projectId: "content2-amy-app",
            storageBucket: "content2-amy-app.appspot.com",
            messagingSenderId: "105429490208",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-content2-amy-app.firebaseapp.com",
                apiUrl: "https://api-content2-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-content2-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "content3-amy-app",
        region: "europe-west1",
        hostnames: [
            "content3-amy-app.firebaseapp.com",
            "learn-content3-amy-app.firebaseapp.com",
            "cui-content3-amy-app.firebaseapp.com",
            "teach-content3-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyA0nwm6yW7Pjcbi6AycwpP0jEuPsQS7uNw",
            authDomain: "content3-amy-app.firebaseapp.com",
            databaseURL: "https://content3-amy-app.firebaseio.com",
            projectId: "content3-amy-app",
            storageBucket: "content3-amy-app.appspot.com",
            messagingSenderId: "1085792808314",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-content3-amy-app.firebaseapp.com",
                apiUrl: "https://api-content3-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-content3-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "content4-amy-app",
        region: "europe-west1",
        hostnames: [
            "content4-amy-app.firebaseapp.com",
            "learn-content4-amy-app.firebaseapp.com",
            "cui-content4-amy-app.firebaseapp.com",
            "teach-content4-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyBZlTX5FF2xTduMin77uH0WC5FqQAjPdbQ",
            authDomain: "content4-amy-app.firebaseapp.com",
            databaseURL: "https://content4-amy-app.firebaseio.com",
            projectId: "content4-amy-app",
            storageBucket: "content4-amy-app.appspot.com",
            messagingSenderId: "304683370006",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-content4-amy-app.firebaseapp.com",
                apiUrl: "https://api-content4-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-content4-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "content5-amy-app",
        region: "europe-west1",
        hostnames: [
            "content5-amy-app.firebaseapp.com",
            "learn-content5-amy-app.firebaseapp.com",
            "cui-content5-amy-app.firebaseapp.com",
            "teach-content5-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyAHmlkYwUR0gBcvBl8u37kR90S5VdJUlWk",
            authDomain: "content5-amy-app.firebaseapp.com",
            databaseURL: "https://content5-amy-app.firebaseio.com",
            projectId: "content5-amy-app",
            storageBucket: "content5-amy-app.appspot.com",
            messagingSenderId: "706457874171",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-content5-amy-app.firebaseapp.com",
                apiUrl: "https://api-content5-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-content5-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
    {
        projectId: "contentall-amy-app",
        region: "europe-west1",
        hostnames: [
            "contentall-amy-app.firebaseapp.com",
            "learn-contentall-amy-app.firebaseapp.com",
            "cui-contentall-amy-app.firebaseapp.com",
            "teach-contentall-amy-app.firebaseapp.com",
        ],
        firebaseConfig: {
            apiKey: "AIzaSyDpOuGk6eutRCTnDtv6_YME7b0jFp4Mr0Q",
            authDomain: "contentall-amy-app.firebaseapp.com",
            databaseURL: "https://contentall-amy-app.firebaseio.com",
            projectId: "contentall-amy-app",
            storageBucket: "contentall-amy-app.appspot.com",
            messagingSenderId: "346323745236",
        },
        client: {
            dashboard: {
                showLanguageSelector: false,
                showRoleSelector: false,
                showArchetypeSearch: true,
            },
            assignment: {
                showCopyArchId: true,
                showArchetypeInfo: true,
                showcuiLink: true,
            },
            explorer: {
                showExtraDetails: true,
                showEmptyTiles: true,
            },
            onboarding: {
                inviteCode: false,
            },
            i18n: {
                showMissingKey: true,
            },
            login: {
                loginProviders: {
                    google: true,
                    facebook: true,
                    email: true,
                },
            },
            useCache: false,
        },
        server: {
            showAllMistakes: false,
            minuteScheduler: TIMES.NEVER,
            hourlyScheduler: TIMES.NEVER,
            dailyScheduler: TIMES.NEVER,
            keepWarm: false,
            backup: false,
            sqlCopy: false,
            auth: {
                learnUrl: "https://learn-contentall-amy-app.firebaseapp.com",
                apiUrl: "https://api-contentall-amy-app.firebaseapp.com",
                iApiUrl: "https://iapi-contentall-amy-app.firebaseapp.com",
            },
            useCache: false,
            newUser: {
                useDefaultSchool: true,
            },
        },
    },
];

/**
 *
 * @param hostname Hostname can be found via: window.location.hostname
 */
export function getProjectSettingsFromHostname(hostname: string) {
    const ret = settings.find((e) => e.hostnames.some((f) => hostname.includes(f)));

    if (!ret) {
        throw new Error(`No Settings found for hostanme: ${hostname}`);
    }

    return ret;
}

/**
 *
 * @param projectId firebase ProjectId e.g.: amy-ac, dev1-amy-app
 */
export function getProjectSettingsFromProjectId(projectId: string) {
    const ret = settings.find((e) => e.projectId === projectId);

    if (!ret) {
        throw new Error(`No Settings found for projectId: ${projectId}`);
    }

    return ret;
}

let projectSingleton: IProjectSetting = null;

/**
 * To make sure all parts of the code use the exact same settings
 * you need to specify the project settings at the beginning or running
 * your code. From that point on you can use `getProject()` to get all
 * settings you need
 * @param project Project-settings used for this project
 */
export function setSettings(project: IProjectSetting) {
    // console.log("Project settings", projectSingleton);
    projectSingleton = project;
}

export function getSettings() {
    if (!projectSingleton) {
        throw new Error("No global project set! Make sure you use setProject() at the beginning of you code");
    }
    return projectSingleton;
}
