import { Language, uid } from "@jaipuna/shared-external";
import { Exclude, Expose, Type } from "class-transformer";
import "reflect-metadata";
import {
    AbstractDefinition,
    NumberRangeDefinition,
    NumberSetDefinition,
    VariableDefinition,
    VariableSetDefinition,
} from "./ParameterDefinition";

export type Gs = "0G" | "2G" | "3G" | "4G" | "5G" | "6G";

export type SectionTypes =
    | "AR"
    | "UN"
    | "FD"
    | "NG"
    | "DC"
    | "FR"
    | "PC"
    | "MO"
    | "RR"
    | "ADDN"
    | "SUBT"
    | "MULT"
    | "DIVI"
    | "EXPT"
    | "OOOO"
    | "PROP"
    | "NEGF"
    | "IMPF"
    | "CPXF"
    | "MIXD"
    | "PONM"
    | "NPER"
    | "WFPE"
    | "WFPC"
    | "CBPE"
    | "PDIF"
    | "USPE"
    | "ROOT"
    | "NEPO"
    | "FRPO"
    | "FACT"
    | "ABSV"
    | "LOGS"
    | "ARFDADDN"
    | "ARDC"
    | "ARRR"
    | "ARNG"
    | "";

export class Versionable {
    id = `VER${uid()}`;
    firestoreId: string = "";
    version: number = 0;
    changedAt: number = Date.now();
    stable: boolean = false;
    creator: string = "";
    email: string = "";
    archived: boolean = false;
    state: "HEAD" | "STABLE" | "PAST" = "HEAD";
    locked: boolean = false;
    migrationState: "NEW" | "AUTONEW" | "REVIEWED" | "" = ""; // NEW: is used for the migrate done by old abi. AUTONEW: Is used by the new migration script

    @Expose()
    get name() {
        return "Versionable";
    }
    @Exclude()
    get isInstanceOf() {
        return Versionable;
    }
}

export type SectionCategorys =
    | "Country"
    | "Curriculum"
    | "Level"
    | "Course Syllabus"
    | "Module"
    | "Topic"
    | "Sub Topic"
    | "Sub Sub Topic"
    | "";

export class SectionNode extends Versionable {
    id = `SEC${uid()}`;
    @Type(() => Language)
    title: Language = new Language();
    @Type(() => Language)
    subtitle: Language = new Language();
    sectionType: SectionTypes = "";
    sectionCategory: SectionCategorys = "";
    hidden: boolean = false;
    notes: string = "";

    childIds: string[] = [];

    get name() {
        return "SectionNode";
    }
    @Exclude()
    get isInstanceOf() {
        return SectionNode;
    }
}

export type PedagogicalTopics =
    | "Fractions"
    | "Fundamental Operators"
    | "ARTH FD ALT"
    | "Negatives"
    | "Decimals"
    | "Percentages"
    | "More Operators"
    | "UNNU"
    | "Matrix"
    | "Calculus"
    | "Rates and Ratios"
    | "UNBRA"
    | "ABRA FD"
    | "VECT ABRA"
    | "ABRA LINEQ"
    | "FUNKS"
    | "GEOM"
    | "SAT"
    | "PROBS"
    | "MEAS"
    | "Coordinate Geometry"
    | "STAT"
    | "SETS"
    | "ABRA QUAD"
    | "ABRA POLY"
    | "ABRA EXP"
    | "MOAP"
    | "Simultaneous Equations"
    | "TRIG"
    | "";
export class PedagogicalNotes {
    topic: PedagogicalTopics = "";
    subTopic: string[] = [];
    subSubTopic: string[] = [];
    questionType: string[] = [];
    terms: string[] = [];
    term: string[] = [];
    operation: string[] = [];
    solvingMethod: string[] = [];
    wholeNumber: string[] = [];
    part: string[] = [];
    percentage: string[] = [];
    number: string[] = [];
    convertFrom: string[] = [];
    convertTo: string[] = [];
    operand: string[] = [];
    operandOne: string[] = [];
    operandTwo: string[] = [];
    operandThree: string[] = [];
    operandFour: string[] = [];
    result: string[] = [];
    type: string[] = [];
    simplification: string[] = [];
    canceling: string[] = [];
    level: string[] = [];
    scaffolding: string[] = [];
    technique: string[] = [];
    rule: string[] = [];
    function: string[] = [];
    order: string[] = [];
    coefficient: string[] = [];
    constants: string[] = [];
    vectorDimension: string[] = [];
    notation: string[] = [];
    equationType: string[] = [];
    dimensionOfVectors: string[] = [];
    originalAmount: string[] = [];
    coefficients: string[] = [];
    functionType: string[] = [];
    featuresOfFunctions: string[] = [];
    functionsInGraphs: string[] = [];
    shapes: string[] = [];
    typesOfShapes: string[] = [];
    featureOfShapes: string[] = [];
    measuringShapes: string[] = [];
    angles: string[] = [];
    networkPropertiesAndMethods: string[] = [];
    symmetry: string[] = [];
    similarity: string[] = [];
    pythagorasTheorem: string[] = [];
    heartOfAlgebra: string[] = [];
    problemSolvingAndDataAnalysis: string[] = [];
    passportToAdvancedMath: string[] = [];
    additionalTopicsInMath: string[] = [];
    calculator: string[] = [];
    sampleSpaces: string[] = [];
    events: string[] = [];
    theorems: string[] = [];
    distributions: string[] = [];
    features: string[] = [];
    tablesInProbability: string[] = [];
    measureUnits: string[] = [];
    questionFormat: string[] = [];
    extra: string[] = [];
    data: string[] = [];
    tables: string[] = [];
    graphs: string[] = [];
    statisticalMeasures: string[] = [];
    distributionOfData: string[] = [];
    bivariateData: string[] = [];
    sampling: string[] = [];
    timeSeriesData: string[] = [];
    statisticalTests: string[] = [];
    chiSquared: string[] = [];
    statisticalInference: string[] = [];
    statisticalInvestigation: string[] = [];
    confidenceIntervals: string[] = [];
    twoSampleInferenceForTheDifferenceBetweenGroups: string[] = [];
    regression: string[] = [];
    multipleRegressions: string[] = [];
    typesOfElements: string[] = [];
    buildingASet: string[] = [];
    comparingSets: string[] = [];
    intervals: string[] = [];
    intersectionAndUnionOfSets: string[] = [];
    universalSetAndAbsoluteComplement: string[] = [];
    vennDiagram: string[] = [];
    equationForm: string[] = [];
    includes: string[] = [];
    variables: string[] = [];
    trigonometricFunctions: string[] = [];
    notes: string = "";
}

export type QAStates = "" | "BUILT" | "TESTED";
export type ArchQAStates = "" | "PREPOPED" | "BUILT" | "TESTED";

export class Diff {
    sourceExpression: string = "";
    targetExpression: string = "";
    sourceDiff: string = "";
    targetDiff: string = "";
    sourceDiffRelabeled: string = "";
    targetDiffRelabeled: string = "";
    sourceDiffSimple: string = "";
    targetDiffSimple: string = "";

    @Type(() => Map)
    paramMap: Map<string, string> = new Map();

    @Type(() => Map)
    simpleMap: Map<string, string> = new Map();
}

export class Archetype extends Versionable {
    id = `ARC${uid()}`;
    contentType: "DEFAULT" | "MATRIX" = "DEFAULT";

    @Type(() => Step)
    steps: Step[] = [];
    @Type(() => BetweenStep)
    betweenSteps: BetweenStep[] = [];

    @Type(() => Diff)
    firstLastDiff: Diff = new Diff();

    // @Type(() => ParameterDefinition)
    // parameterDefinitions: ParameterDefinition[] = [];

    @Type(() => AbstractDefinition, {
        discriminator: {
            property: "type",
            subTypes: [
                { value: NumberRangeDefinition, name: "NumberRange" },
                { value: NumberSetDefinition, name: "NumberSet" },
                { value: VariableDefinition, name: "Variable" },
                { value: VariableSetDefinition, name: "VariableSet" },
            ],
        },
    })
    parameterDefinitions: (NumberRangeDefinition | NumberSetDefinition | VariableDefinition | VariableSetDefinition)[] =
        [];

    @Type(() => Rule)
    parameterRules: Rule[] = [];

    // parameterSets are the final parameters which are used by exercises
    @Type(() => ParameterSet)
    parameterSets: ParameterSet[] = [];

    @Expose()
    get betweenStepsDiffRelabeled() {
        return this.betweenSteps.map((e) => {
            return `${e.diff.sourceDiffRelabeled}->${e.diff.targetDiffRelabeled}`;
        });
    }

    public getDefaultParameterSet() {
        return this.parameterSets[0]?.parameters || [];
    }

    get name() {
        return "Archetype";
    }

    @Exclude()
    get isInstanceOf() {
        return Archetype;
    }

    // old archetype node
    titlePhraseId: string = "";
    hidden: boolean = false;
    coreArchetype: boolean = false;
    teachingArchetype: boolean = false;
    oldArchetype: string = "";
    notes: string = "";

    // references
    reference: string = "";
    @Type(() => PedagogicalNotes)
    pedagogicalNotes: PedagogicalNotes = new PedagogicalNotes();
    textBook: string = "";
    page: string = "";
    level: string = "";
    mathProcess: string = "";
    testingNotes: string = "";

    // student related
    masteryCoefficient: number = 0.0;

    // QA
    archetypeQuality: ArchQAStates = "";
    archetypeBroken: boolean = false;
    archetypeRecheck: boolean = false;
    instructionQuality: ArchQAStates = "";
    instructionBroken: boolean = false;
    instructionRecheck: boolean = false;
    distractorQuality: ArchQAStates = "";
    distractorBroken: boolean = false;
    distractorRecheck: boolean = false;
    feedbackQuality: ArchQAStates = "";
    feedbackBroken: boolean = false;
    feedbackRecheck: boolean = false;
    transitionQuality: ArchQAStates = "";
    transitionBroken: boolean = false;
    transitionRecheck: boolean = false;

    reviewed: boolean = false;
    colors: boolean = false;
}
export class Option {
    id: string = uid();
    correct: boolean = false;
    expression: string = "";
    generator: "HUMAN" | string = "";
    appoval: "HARDFORCED" | "FORCED" | "APPROVED" | "DISAPPROVED" | "PENDING" = "PENDING";
    flags: string[] = []; // Flags define what type of option this is. Mostly used for distrators to indicate if it's a BADMAS distrators, ...
    feedbackId: string = "";
    transitionId: string = "";
    /**
     * The ID of the arch we have auto assigned to this BetwenStep,
     * will be ignored if there is a locked Transition
     */
    autoTransitionID: string = "";
}

export class OptionRow {
    id: string = uid();
    @Type(() => Option)
    options: Option[] = [];
    hide: boolean = false;
    autoSelect: boolean = false;
    allowDistractors: boolean = true;
    allowTransitions: boolean = true;
    allowFeedbacks: boolean = true;
}

export class Step {
    id: string = uid();
    instructionId: string = "";
    teachingPhraseId: string = "";
    @Type(() => OptionRow)
    optionRow = new OptionRow();

    /**
     * Returns the correct options expression from this step
     * @return {*}
     * @memberof Step
     */
    getCorrectExp() {
        return this.optionRow.options.find((val) => val.correct).expression;
    }

    getCorrectOption() {
        return this.optionRow.options.find((val) => val.correct);
    }
}

export class BetweenStep {
    id = uid();
    sourcePosition = 0;
    targetPosition = 0;
    autoDiff = true;

    @Type(() => Diff)
    diff = new Diff();
}

export type ParameterType = "NUMBER" | "VARIABLE" | "COLOR";
export abstract class Parameter {
    name = "";
    abstract type: ParameterType;
    abstract value: string;
}

export class NumberParameter extends Parameter {
    type: ParameterType = "NUMBER";
    value: string = "0";
}

export class VariableParameter extends Parameter {
    type: ParameterType = "VARIABLE";
    value = "";
}

type ColorTypes = "blue" | "orange" | "green" | "red" | "purple" | "brown" | "pink" | "gray" | "yellow" | "cyan";
export const COLORS: ColorTypes[] = [
    "blue",
    "orange",
    "green",
    "red",
    "purple",
    "brown",
    "pink",
    "gray",
    "yellow",
    "cyan",
];
export class ColorParameter extends Parameter {
    type: ParameterType = "COLOR";
    value: ColorTypes = "blue";
}

export class ParameterSet {
    id = uid();

    @Type(() => Parameter, {
        discriminator: {
            property: "type",
            subTypes: [
                { value: NumberParameter, name: "NUMBER" },
                { value: VariableParameter, name: "VARIABLE" },
                { value: ColorParameter, name: "COLOR" },
            ],
        },
    })
    parameters: (NumberParameter | VariableParameter | ColorParameter)[] = [];
    generator: "HUMAN" | "COMPUTER" = "COMPUTER";
}

export class Rule {
    id = uid();
    rule = "";
    comment = "";
}

export type PhraseType = "FEEDBACK" | "INSTRUCTION" | "OPTION";
export const PhraseTypeData: PhraseType[] = ["FEEDBACK", "INSTRUCTION", "OPTION"];

export class Phrase extends Versionable {
    id = `PHR${uid()}`;
    @Type(() => Language)
    phrase: Language = new Language();
    type: PhraseType = "INSTRUCTION";

    // QA
    quality: QAStates = "";
    broken: boolean = false;
    reviewed: boolean = false;
    recheck: boolean = false;

    get name() {
        return "Phrase";
    }
    @Exclude()
    get isInstanceOf() {
        return Phrase;
    }
}

export class Link extends Versionable {
    id = `LIN${uid()}`;
    @Type(() => Language)
    title: Language = new Language();
    linkId: string = "";

    // QA
    quality: QAStates = "";
    broken: boolean = false;
    reviewed: boolean = false;
    recheck: boolean = false;

    get name() {
        return "Link";
    }
    @Exclude()
    get isInstanceOf() {
        return Link;
    }
}

export class InstructionPhrase {
    id = uid();
    phraseId: string = "";
    hideExpression: boolean = false;
}

export class Instruction extends Versionable {
    id = `INS${uid()}`;
    @Type(() => Language)
    title: Language = new Language();
    @Type(() => InstructionPhrase)
    instructionPhrases: InstructionPhrase[] = [];

    // QA
    quality: QAStates = "";
    broken: boolean = false;
    reviewed: boolean = false;
    recheck: boolean = false;

    get name() {
        return "Instruction";
    }
    @Exclude()
    get isInstanceOf() {
        return Instruction;
    }
}
export type FeedbackType = "SHOULDDO" | "MISTAKE" | "COMBO";
export const FeedbackTypeData: FeedbackType[] = ["SHOULDDO", "MISTAKE", "COMBO"];

export class Feedback extends Versionable {
    id = `FBK${uid()}`;
    @Type(() => Language)
    title: Language = new Language();
    phraseIds: string[] = [];
    type: "" | FeedbackType = "";

    @Type(() => Diff)
    diff: Diff = new Diff();

    @Expose()
    get sourceDiff() {
        return this.diff.sourceDiff;
    }

    @Expose()
    get targetDiff() {
        return this.diff.targetDiff;
    }

    @Expose()
    get sourceDiffRelabeled() {
        return this.diff.sourceDiffRelabeled;
    }

    @Expose()
    get targetDiffRelabeled() {
        return this.diff.targetDiffRelabeled;
    }

    // QA
    quality: QAStates = "";
    broken: boolean = false;
    reviewed: boolean = false;
    recheck: boolean = false;

    get name() {
        return "Feedback";
    }
    @Exclude()
    get isInstanceOf() {
        return Feedback;
    }
}

export class Concatenation extends Versionable {
    id = `CON${uid()}`;
    @Type(() => Language)
    title: Language = new Language();
    instructionIds: string[] = [];

    // QA
    quality: QAStates = "";
    broken: boolean = false;
    reviewed: boolean = false;
    recheck: boolean = false;

    get name() {
        return "Concatenation";
    }
    @Exclude()
    get isInstanceOf() {
        return Concatenation;
    }
}

export class Template {
    id = uid();
    expression: string = ""; // this is the distractor expression
    FBFlag: string = ""; // this is the feedback flag for this distractor
    sectionIds: SectionTypes[] = []; // these are the sections where this distractor/flag combo appears
    feedbackId: string = "";
    transitionId: string = "";
}
export class DistractorTemplate extends Versionable {
    id = `DIS${uid()}`;

    diff: string = "";

    @Type(() => Template)
    templates: Template[] = [];

    get name() {
        return "DistractorTemplate";
    }
    @Exclude()
    get isInstanceOf() {
        return DistractorTemplate;
    }
}

export class AssignmentExercise {
    id = uid();
    archetypeId: string = "";
    note: string = "";
}

export class Assignment extends Versionable {
    id = `ASS${uid()}`;
    @Type(() => Language)
    title: Language = new Language(); // This can be in any language we support
    @Type(() => Language)
    subtitle: Language = new Language();
    @Type(() => AssignmentExercise)
    exercises: AssignmentExercise[] = [];
    teachingType: "AMY" | "AMY_KEEPING_ORIGINS" | "CLASSIC" = "AMY";
    teachingSetupType: "AMY" | "ARRAY" = "ARRAY";
    maxQuestions: number = 0;
    once: boolean = false;
    hidden: boolean = false;

    get name() {
        return "Assignment";
    }
    @Exclude()
    get isInstanceOf() {
        return Assignment;
    }
}
