import { shuffleArray } from "@jaipuna/common-modules/dist/src/GeneralUtils";
import * as mathjs from "mathjs";
import { parse } from "../parser/parse";
import { cleanFloatingPoint, fixDecimalPlaces, removeLeadingZeros } from "../utilities";
import math = require("mathjs");

export const customFunctionsEval: { [name: string]: (...params: any) => any } = {
    // Modulo but handles JS precision error
    f_modulo: function (a: number, b: number): number {
        // a % b
        let num = +a;
        let mod = +b;
        let ans = 0;
        if (num.toString().includes(".") || mod.toString().includes(".")) {
            const numSplitted = num.toString().split(".");
            const modSplitted = mod.toString().split(".");
            const numToMultBy = Math.max(numSplitted[1]?.length || 0, modSplitted[1]?.length || 0);
            const powerOfTen = 10 ** numToMultBy;
            ans = ((num * powerOfTen) % (mod * powerOfTen)) / powerOfTen;
        } else {
            ans = num % mod;
        }
        return ans;
    },

    // Multiplication but handles with JS precision error
    f_multi: function (a: number, b: number): number {
        const ans = a * b;
        return +cleanFloatingPoint(ans);
    },

    // Divides a by b
    f_div: function (a: number, b: number): number {
        const ans = a / b;
        return +cleanFloatingPoint(ans);
    },

    det: function (matrix: mathjs.Matrix): number {
        return +cleanFloatingPoint(mathjs.det(matrix));
    },

    // DO NOT USE THIS IS FOR PARSING AROUND MATHJS
    f_Eval: function (a: number): number {
        return a;
    },

    // Returns a / 100, representing the mathematical version of a%
    f_percent: function (a: number): number {
        return a / 100;
    },

    f_mixedNum: function (a: any, b: any): number {
        return parseFloat(a) + parseFloat(b);
    },

    // An array using curly brackets eg: {a, b, c, d}
    f_bArray: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    // An array using parenthesis eg: (a, b, c, d)
    f_pArray: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    // An array using square brackets eg: a, b, c, d
    f_sArray: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    // An array using no brackets eg: [a, b, c, d]
    f_Array: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    // An array using no spacing or commas eg: abcd
    f_gArray: function (_array: any[] | mathjs.Matrix): any {
        return mathjs.matrix(_array);
    },

    /*
    Given the input number n, this function returns all factor pairs of n (each written as a product).
    For example
    f_factorPairs(4) -> 1 \times 4, -1 \times -4, 2 \times 2, -2 \times -2
    f_factorPairs(-4) -> -1 \times 4, 1 \times -4, -2 \times 2
    Notice that order isn't important in that if we have 1 \times 4 we don't display 4 \times 1
    */
    f_factorPairs: function (num: number, positiveOnly: boolean = false): string {
        const factorPairs: any[] = [];
        num = +num;
        // If n is positive
        if (num > 0) {
            for (let f1: number = 1; f1 < Math.floor(Math.sqrt(num)) + 1; f1++) {
                if (num % f1 === 0) {
                    const f2: number = num / f1;
                    factorPairs.push(String(f1) + " * " + String(f2));
                    factorPairs.push(-1 * f1 + " * " + -1 * f2);
                }
            }
        }

        // If n is negative
        if (num < 0) {
            for (let f1: number = 1; f1 < Math.floor(Math.sqrt(-1 * num)) + 1; f1++) {
                if (num % f1 === 0) {
                    const f2 = num / f1;
                    factorPairs.push(String(f1) + " * " + String(f2));
                    if (f1 !== -1 * f2) {
                        factorPairs.push(-1 * f1 + " * " + -1 * f2);
                    }
                }
            }
        }

        // If n is zero
        if (num === 0) {
            factorPairs.push("0 * 0");
        }

        if (positiveOnly) {
            return `[${factorPairs.filter((val) => !val.includes("-")).join(",")}]`;
            // return mathjs.matrix(factorPairs.filter((val) => !val.includes("-")));
        } else {
            return `[${factorPairs.join(",")}]`;
            // return mathjs.matrix(factorPairs);
        }
    },

    // Returns the sorted array with the optional ascending param
    f_sort: function (array: mathjs.Matrix, ascending?: boolean): mathjs.Matrix {
        let arrayData: any[] = [];
        mathjs.forEach(array, (value) => {
            arrayData.push(parseFloat(value.toString()));
        });
        if (ascending || ascending === undefined) {
            arrayData = arrayData.sort((a, b) => {
                return a - b;
            });
        } else {
            arrayData = arrayData
                .sort((a, b) => {
                    return a - b;
                })
                .reverse();
        }
        return mathjs.matrix(arrayData);
    },

    f_dotplotHeights: function (array: mathjs.Matrix, separation = 1, offset = 1): mathjs.Matrix {
        let arrayData: any[] = [];
        mathjs.forEach(array, (value) => {
            arrayData.push(parseFloat(value.toString()));
        });

        if (separation < 0) {
            throw new Error("separation must be nonnegative");
        }

        const _ret: number[] = [];
        const values = new Map();
        for (const x of arrayData) {
            values.has(x) ? values.set(x, values.get(x) + separation) : values.set(x, offset);
            _ret.push(values.get(x));
        }
        return mathjs.matrix(_ret);
    },

    // Returns the factorial of a given number
    f_factorial: function (a: number): number {
        // let rval: number = 1;
        // for (let index: number = 2; index <= a; index++) {
        //     rval = rval * index;
        // }
        // return rval;
        return mathjs.eval(`${a}!`);
    },

    // Returns the number formatted with the given params
    f_format: function (num: any, arg1?: any, arg2?: any, truncate?: boolean, latexMode?: boolean): string {
        const possibleModes: string[] = ["auto", "exponential", "fixed", "engineering"];
        let mode: "auto" | "exponential" | "fixed" | "engineering", precision: number;
        // 1) Both Precision and Mode are passed
        if (possibleModes.includes(arg1) && arg2 !== undefined) {
            precision = mathjs.eval(arg2);
            mode = arg1;
        }
        // 2) Only Precision is passed
        else if (!possibleModes.includes(arg1) && arg1 !== undefined && arg2 === undefined) {
            precision = mathjs.eval(arg1);
        }
        // 3) Only Mode is passed
        else if (possibleModes.includes(arg1) && arg2 === undefined) {
            mode = arg1;
        }

        const formatOptions: mathjs.FormatOptions = {
            notation: possibleModes.includes(mode) ? mode : "auto",
            precision: precision,
            lowerExp: -Infinity,
            upperExp: Infinity,
        };

        let _toReturn: string;
        const evaluatedNum = parse(num.toString()).eval();
        _toReturn = mathjs.format(isNaN(+evaluatedNum) ? evaluatedNum : +evaluatedNum, formatOptions);
        if (mode === "exponential" && latexMode) {
            // convert to number to remove leading "+" but keep leading "-"
            const exponent = +_toReturn.split("e")[1].toString();
            const rest = _toReturn.split("e")[0];
            _toReturn = `${rest} \\times 10^{${exponent}}`;
        }

        // Adding Trailing Zeros
        let precisionDigits: number;
        if (mode === "fixed") {
            precisionDigits = _toReturn.replace(".", "").length;
        } else {
            precisionDigits = removeLeadingZeros(_toReturn).replace(".", "").length; // remove decimal from num digits
        }
        if (precision > precisionDigits && !_toReturn.includes("e-")) {
            // add a decimal point to _toReturn if there is none
            if (_toReturn.indexOf(".") < 0) _toReturn += ".";
            _toReturn += "0".repeat(precision - precisionDigits);
        }

        if (truncate) {
            return parseFloat(_toReturn.toString()).toString();
        } else {
            return _toReturn.toString();
        }
    },

    // Returns a new complex number generated from the given Polar
    complexFromPolar: function (r: number, phi: number): number {
        return (mathjs as any).type.Complex.fromPolar(r, phi);
    },

    // Returns a matrix of both possibilities, + and -
    plusMinus: function (a: number): mathjs.Matrix {
        return mathjs.matrix([a, a * -1]);
    },

    // Returns a matrix of both possibilities, + and -
    f_plusMinus: function (a: number, b: number): mathjs.Matrix {
        return mathjs.matrix([a + b, a - b]);
    },

    // A catcher to handle any ghost brackets not caught by the parser
    f_Ghost: function (a: number): number {
        return a;
    },

    // Returns an array representing the simultaneous equation
    f_simul: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    // Returns an array representing the simultaneous equation
    f_simulNumbered: function (array: any[] | mathjs.Matrix): any[] | mathjs.Matrix {
        return mathjs.matrix(array);
    },

    f_piecewise: function (
        functionName: string,
        functionsList: Array<[function: string, conditionText: string, fromTo: string]>,
    ) {
        return "error";
    },

    f_lim: function (expression: string, lim: string): string {
        // throw SyntaxError(
        //     `Error in f_lim: Should not be evaluated`,
        // );
        return "error";
    },

    f_leftLim: function (expression: string, lim: string): string {
        // throw SyntaxError(
        //     `Error in f_leftLim: Should not be evaluated`,
        // );
        return "error";
    },

    f_rightLim: function (expression: string, lim: string): string {
        // throw SyntaxError(
        //     `Error in f_rightLim: Should not be evaluated`,
        // );
        return "error";
    },

    // Returns the closest value to num in the array
    f_closestVal: function (num: string, array: mathjs.Matrix): number {
        const numVal: any = mathjs.eval(num);

        let closest: number = (array as any)._data[0];
        let bestDiff: number = Math.abs(numVal - closest);

        for (const node of (array as any)._data) {
            const val: any = mathjs.eval(node);
            const difference: number = Math.abs(numVal - val);

            if (difference < bestDiff) {
                closest = val;
                bestDiff = difference;
            }
        }

        return closest;
    },

    // Returns the Quadratic Solution of a, b, c with an optional precision where x is the variable
    f_quadSolve: function (_x: string, a: number, b: number, c: number, _prec?: number): mathjs.Matrix {
        const left: number = mathjs.eval(`(-1 * ${b} + sqrt(${b}^2 - (4 * ${a} * ${c}))) / (2 * ${a})`);
        const right: number = mathjs.eval(`(-1 * ${b} - sqrt(${b}^2 - (4 * ${a} * ${c}))) / (2 * ${a})`);
        // const discriminant: number = mathjs.eval(`${b}^2 - (4 * ${a} * ${c})`);

        // console.log("b^2 - (4 * a * c) is: " + discriminant);

        // Debugging.
        // console.log("the left number is: " + left);
        // console.log("the right number is: " + right);

        const _ret: mathjs.Matrix = mathjs.matrix([left, right]);

        // console.log(mathjs.matrix([left, right]));
        return _ret;
    },

    // Returns the division represented by the ratio
    f_ratio: function (a: number, b: number): number {
        // return a / b;
        return mathjs.eval(`${a}/${b}`);
    },

    // Returns the evaluated power
    f_pow: function (a: number, b: number): number {
        // return a ** b;
        return mathjs.eval(`(${a})^(${b})`);
    },

    // Returns multiplication
    f_implicitMulti: function (...args: number[]): number {
        let product = 1;
        for (const num of args) {
            product = product * num;
        }
        return product;
    },

    // Returns multiplication
    f_dotMulti: function (...args: number[]): number {
        let product: number = 1;
        for (const num of args) {
            product = product * num;
        }
        return product;
    },

    // Returns multiplication
    f_explicitMulti: function (...args: number[]): number {
        let product: number = 1;
        for (const num of args) {
            product = product * num;
        }
        return product;
    },

    // Returns addition
    f_explicitPlus: function (...args: number[]): number {
        let sum: number = 0;
        for (const num of args) {
            sum = sum + num;
        }
        return sum;
    },

    // Returns subtraction
    f_explicitMinus: function (...args: number[]): number {
        return args[0] - args[1];
    },

    // Returns the definite integral
    f_intDef: function (..._args: number[]): number {
        return 1;
    },

    // Returns the indefinite integral
    f_intIndef: function (..._args: number[]): number {
        return 1;
    },

    // returns the next prime in the given direction
    f_nextPrime: function (a: number, direction: string): number {
        // we cant take negatives
        if (a < 0) {
            return a;
        } else if (a === 1) {
            return 2;
        }
        // the number of fails before we just quit
        let watchDog: number = 1000;

        let num: number = mathjs.eval(a.toString());
        // the change is how much we move +ve or -ve in the direction
        const change: number = direction === "down" ? -1 : 1;

        if (mathjs.isPrime(num)) {
            num += change;
        }
        // round the number
        num = direction === "up" ? Math.ceil(num) : Math.floor(num);
        // make sure we start off on an odd
        if (num % 2 === 0) {
            num += change;
        }
        // keep moving by two in the direction until we hit prime.
        while (!mathjs.isPrime(num)) {
            num += change * 2;
            // check for timeout condition
            watchDog -= 1;
            if (watchDog === 0) {
                return a;
            }
        }

        return num;
    },

    f_shuffleArray: function (array: mathjs.Matrix): mathjs.Matrix {
        // convert eh mathjs matrix into a js array
        let arrayData: any[] = [];
        mathjs.forEach(array, (value) => {
            arrayData.push(value);
        });

        arrayData = shuffleArray(arrayData);

        return mathjs.matrix(arrayData);
    },

    // Returns the number
    f_numToText: function (a: number, over?: string): number {
        return a;
    },

    f_numToPlaceVal: function (a: number): number {
        return a;
    },

    f_stringOption: function (..._args: any): string {
        return "string";
    },

    ln: function (a: number): any {
        if (a < 0) {
            return "undefined";
        }
        return mathjs.log(a);
    },

    log: function (a: number, b?: number): any {
        if (a < 0) {
            return "undefined";
        }

        if (b) {
            return mathjs.log(a, b);
        }

        // mathjs precision gets in the way so we cut it if its crazy long so 2.999999999999 -> 3
        let numString = mathjs.log(a, 10).toString();

        if (numString.length > 15) {
            numString = (mathjs.parse(numString) as any).toString({ precision: 14 });
        }
        return numString;
    },

    // cbrt: function (a: number): number {
    //     return mathjs.cbrt(a);
    // },

    f_latex: function (tex: string): string {
        return tex;
    },

    f_underline: function (a: any): string {
        return a;
    },

    f_overline: function (a: any): string {
        return a;
    },

    f_conj: function (a: any, _notation?: string): any {
        return mathjs.conj(a);
    },

    f_factors: function (n: number, nMin?: number, nMax?: number, showNegative?: boolean): mathjs.Matrix {
        // by default nMin and nMax are 1 and n respectivley
        const min: number = nMin !== undefined ? nMin : n * -1;
        const max: number = nMax !== undefined ? nMax : n;
        // find all factors from min to max
        const _ret: number[] = [];
        for (let num = min; num <= max; num++) {
            if (n % num === 0) {
                if (num > 0 || showNegative === true) _ret.push(mathjs.eval(String(num)));
            }
        }
        // return the sorted array
        return mathjs.matrix(
            _ret.sort((a: number, b: number): number => {
                return a - b;
            }),
        );
    },

    f_commonFactors: function (
        n1: number,
        n2: number,
        nMin?: number,
        nMax?: number,
        showNegative?: boolean,
    ): mathjs.Matrix {
        // get the common factors of each
        const factors1 = (mathjs as any).f_factors(n1, nMin, nMax, showNegative);
        const factors2 = (mathjs as any).f_factors(n2, nMin, nMax, showNegative);

        // make real arrays from them
        const array1: number[] = [];
        const array2: number[] = [];

        mathjs.forEach(factors1, (value) => {
            array1.push(value);
        });

        mathjs.forEach(factors2, (value) => {
            array2.push(value);
        });

        const _ret = array1.filter((value) => array2.includes(value));
        return mathjs.matrix(
            _ret.sort((a: number, b: number): number => {
                return a - b;
            }),
        );
    },

    f_multiples: function (n: number, arg1?: number, arg2?: number): any {
        // usage:
        // f_multiples(n): returns 10 multiples of n
        // f_multiples(n,num) -> returns num multiples of n
        // f_multiples(n,min,max) -> returns multiples of n between min (inclusive) and max (inclusive)

        // default (n only)
        let min: number = +n;
        let max: number = +n * 10;

        const _ret: number[] = [];
        if (arg2) {
            // specify arg1 = min and arg2 = max
            min = Math.ceil(+arg1 / n) * n;
            max = +arg2 - (arg2 % n);
            if (max < min) {
                // nothing
                return mathjs.matrix(_ret);
            }
        } else if (arg1) {
            // specify arg1 = num of multiples, starting from n
            if (+arg1 <= 0) {
                // nothing
                return mathjs.matrix(_ret);
            }
            max = n * +arg1;
        }

        // get all the multiples
        let saveGuard = 0;
        for (let i = min; i <= max; i += n) {
            saveGuard = saveGuard + 1;
            i = +cleanFloatingPoint(i);
            _ret.push(i);

            if (saveGuard > 100) {
                break;
            }
        }

        return mathjs.matrix(_ret);
    },

    f_commonMultiples: function (m: number, n: number, arg1?: number, arg2?: number): any {
        // usage:
        // f_commonMultiples(m, n) -> returns 10 multiples of m and n
        // f_commonMultiples(m, n, num) -> returns num multiples of m and n
        // f_commonMultiples(m, n, min, max) -> returns multiples of m and n between min and max
        const mMultiples = (mathjs as any).f_multiples(m, 100); // should be enough
        const nMultiples = (mathjs as any).f_multiples(n, 100);

        // make real arrays from them
        const mMultiplesArr: number[] = [];
        const nMultiplesArr: number[] = [];
        mathjs.forEach(mMultiples, (value) => {
            mMultiplesArr.push(value);
        });
        mathjs.forEach(nMultiples, (value) => {
            nMultiplesArr.push(value);
        });

        // find union
        const multiples: number[] = [];
        for (const mElement of mMultiplesArr) {
            if (nMultiplesArr.includes(mElement)) {
                multiples.push(mElement);
            }
        }

        const _ret: number[] = [];
        if (arg2) {
            // specify arg1 = min and arg2 = max
            for (const multiple of multiples) {
                if (multiple >= arg1 && multiple <= arg2) {
                    _ret.push(multiple);
                }
            }
        } else {
            // specify arg1 = num of multiples, starting from n
            // or default (10 entries)
            const numMultiples = arg1 ? arg1 : 10;
            for (const multiple of multiples) {
                _ret.push(multiple);
                if (_ret.length >= numMultiples) {
                    break;
                }
            }
        }
        return mathjs.matrix(_ret);
    },

    f_strike: function (n: any): any {
        return n;
    },

    f_bstrike: function (n: any): any {
        return n;
    },

    it: function (n: any): any {
        return n;
    },

    rm: function (n: any): any {
        return n;
    },

    f_divR: function (a: number, b: number): any {
        return a / b;
    },

    f_exp: function (a: number, exp: number): any {
        if (a === 0 && exp < 0) {
            return mathjs.eval(`"undefined"`);
        }
        return mathjs.eval(`(${a}) ^ (${exp})`);
    },

    // inverse error function (https://en.wikipedia.org/wiki/Error_function#Inverse_functions)
    inverf: function (num: number, accuracy: number = 6, numTerms: number = -1): any {
        // https://www.wolframalpha.com/input/?i=inverf%280.95%29
        function nextCoeff(k: number): number {
            // calculate next term in sum
            let sum = 0;
            for (let m = 0; m <= k - 1; ++m) {
                sum += (coeffs[m] * coeffs[k - 1 - m]) / ((m + 1) * (2 * m + 1)); // coeffs if found via lexical scope
            }
            return sum;
        }
        // only defined (single valued) in (-1, 1)
        if (num > 1 || num < -1) {
            return "error";
        }
        // infinite in the limit x -> 1 or x -> -1
        if (num === 1 || num === -1) {
            return num * Infinity;
        }

        let maxTerms: number; // total number of terms
        if (numTerms === -1) {
            maxTerms = 200; // default is 200
        } else {
            maxTerms = numTerms;
        }

        // series calculation
        let ans: number = 0;
        let prev: number = 0;
        let k: number = 0; // sum variable
        const coeffs: number[] = new Array(1).fill(1); // coeffs[0] = 1
        do {
            const c = 2 * k + 1; // this number pops up twice
            prev = ans;
            ans += (coeffs[k] * ((Math.sqrt(Math.PI) * num) / 2) ** c) / c;
            k += 1;

            coeffs.push(nextCoeff(k)); // calculate next term in sum
        } while (k < maxTerms && Math.abs(ans - prev) > 10 ** (-1 * accuracy - 2));
        return ans;
    },

    // Returns a so it can be used in the tree as the insides of the box
    f_box: function (a: any): any {
        return a;
    },

    f_boxbox: function (a: any): any {
        return a;
    },

    // Returns the evaluated given expression so it can be used in the tree
    f_boxOp: function (left: any, right: number, operator: string): any {
        return mathjs.eval(`${left} ${operator} ${right}`);
    },

    f_boxplus: function (a: number, _exp: number): any {
        return a;
    },

    f_boxtimes: function (a: number, _exp: number): any {
        return a;
    },

    f_superscript: function (tex: string, subTex: string): string {
        return tex + "^" + subTex;
    },

    f_evalWSymbol: function (a: number, symbol: string, b: number): any {
        let symbolStr = symbol.toString();
        if (symbolStr[0] === `"` && symbolStr[symbolStr.length - 1] === `"`) {
            symbolStr = symbolStr.slice(1, symbolStr.length - 1);
        }
        // build the xpression
        const expStr: string = `${a.toString()} ${symbolStr} ${b.toString()}`;
        // console.log(expStr);
        return mathjs.eval(expStr);
    },

    f_deriv: function (func: string, ivar: string, order?: string, flag?: string): any {
        return "error";
    },

    f_d: function (func: string): any {
        return "error";
    },

    f_sigma: function (eq: string, from: number, to: number, indexSymbol: string): any {
        let sum = 0;

        for (let i = parseInt(from.toString()); i < parseInt(to.toString()) + 1; i++) {
            let tree = parse(eq);
            tree = tree.findAndReplace(
                (node) => node.name === indexSymbol,
                () => parse(i.toString()),
            );
            // sum += mathjs.eval(tree.toString());
            sum += +parse(tree.solve().toString()).eval();
        }

        return sum;
    },

    f_sum: function (eq: string, from: number, to: number, indexSymbol: string): any {
        // console.log(`f_sum running ${eq}, ${from}, ${to}, ${indexSymbol}`);
        let sum = 0;

        for (let i = parseInt(from.toString()); i < parseInt(to.toString()) + 1; i++) {
            let tree = parse(eq.replace(/"/g, ""), [indexSymbol]);
            tree = tree.replaceNodeByName(indexSymbol, parse(i.toString()));
            // sum += mathjs.eval(tree.toString());
            sum += +parse(tree.solve().toString()).eval();
        }

        return sum;
    },

    f_recurring: function (num: number, mode: string): number {
        return num;
    },

    f_deg: function (): string {
        return "deg";
    },
    f_degF: function (): string {
        return "degF";
    },
    f_degC: function (): string {
        return "degC";
    },
    f_unit: function (unit: string): string {
        return unit;
    },
    f_symbol: function (symbol: string): string {
        return symbol;
    },
    f_op: function (symbol: string): string {
        return symbol;
    },
    f_string: function (symbol: string): string {
        return symbol;
    },
    f_hat: function (symbol: string): string {
        return symbol;
    },
    f_widehat: function (symbol: string): string {
        return symbol;
    },
    f_angle: function (symbol: string): string {
        return symbol;
    },
    f_tally: function (number: number): number {
        return number;
    },

    cis: function (angle: number, radius?: number, _mode?: string): number {
        return (mathjs as any).type.Complex.fromPolar(radius ? radius : 1, angle);
    },

    f_datasetFromFreqs: function (rawValues: mathjs.Matrix, rawFreqs: mathjs.Matrix): mathjs.Matrix {
        const values = [];
        const freqs = [];
        rawValues.forEach((val) => values.push(val));
        rawFreqs.forEach((val) => freqs.push(val));

        const output: number[] = [];
        for (const [index, val] of values.entries()) {
            for (let i = 0; i < freqs[index]; i++) {
                output.push(val);
            }
        }
        return mathjs.matrix(output);
    },

    getElement: function (array: mathjs.Matrix, index: number): any {
        const regArray = [];
        array.forEach((item) => {
            regArray.push(item);
        });

        return regArray[index - 1];
    },

    f_filter: function (array: mathjs.Matrix, boolCheck: string): mathjs.Matrix {
        // example of use: {f_filter([1.65,x2+2,x1,4,1], "x>{x1}")} where x1 and x2 are params
        // only want to replace "x" and not the x in "x1". also need to remove the {...} that are
        // required to insert the value for "x1"
        const newArray = [];
        array.forEach((item) => {
            if (mathjs.eval(boolCheck.replace(/x(?!\S)/g, item.toString()).replace(/\{|\}/g, "")) === true) {
                newArray.push(item);
            }
        });

        return mathjs.matrix(newArray);
    },

    length: function (array: any[]): number {
        const regArray = [];
        array.forEach((item) => {
            regArray.push(item);
        });

        return regArray.length;
    },

    trunc: function (num, perc: number = 0) {
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            const imaginary = mathjs.complex(num.re, num.im);

            imaginary.im = Math.trunc(num.im * 10 ** perc) / 10 ** perc;
            return imaginary.toString();
        }
        let _toReturn = (Math.trunc(num * 10 ** perc) / 10 ** perc).toString();

        // Keep trailing zeros, if needed
        if (num.toString().includes(".") && num.toString().split(".")[1].length >= perc) {
            _toReturn = (mathjs as any).toDP(_toReturn, perc);
        }
        return _toReturn;
    },

    f_allTuplesDiffer: function (...args: mathjs.Matrix[]): boolean {
        let arrayData: string[] = [];
        // for each array of args
        args.forEach((matrix: mathjs.Matrix) => {
            arrayData.push(matrix.toString());
        });

        let allDiffer = true;
        for (const [index, a] of arrayData.entries()) {
            for (let i = index + 1; i < arrayData.length; ++i) {
                const b = arrayData[i];
                allDiffer &&= a !== b;
            }
        }
        return allDiffer;
    },

    f_quantileSeq: function (array: mathjs.Matrix, quartile: number, method: number = 1): number {
        if (method !== 1 && method !== 2) {
            throw SyntaxError(`Error in f_quantile: Method must be either 1 or 2. Received ${method}.`);
        }
        if (![0, 0.25, 0.5, 0.75, 1].includes(quartile)) {
            throw SyntaxError(
                `Error in f_quantile: Quartile must be either in [0, 0.25, 0.5, 0.75, 1]. Received ${quartile}.`,
            );
        }
        const regArray = [];
        array.forEach((item) => {
            regArray.push(+item); // convert to number if string
        });

        let nums = regArray.slice().sort((a, b) => a - b);

        if (quartile === 0) {
            return nums[0];
        } else if (quartile === 0.5) {
            return findMedian(nums);
        } else if (quartile === 1) {
            return nums[nums.length - 1];
        }

        const n = nums.length;
        let half: number[] = [];
        if (method === 1) {
            // Use the median to divide the ordered data set into two-halves.
            // If there is an odd number of data points in the original ordered data set,
            // do not include the median (the central value in the ordered list) in either half.
            if (quartile === 0.25) {
                half = nums.slice().splice(0, Math.floor(n / 2));
            }
            if (quartile === 0.75) {
                half = nums.slice().splice(Math.ceil(n / 2));
            }
        } else {
            // method === 2
            // Use the median to divide the ordered data set into two-halves.
            // If there are an odd number of data points in the original ordered data set,
            // include the median (the central value in the ordered list) in both halves.
            if (quartile === 0.25) {
                half = nums.slice().splice(0, Math.ceil(n / 2));
            }
            if (quartile === 0.75) {
                half = nums.slice().splice(Math.floor(n / 2));
            }
        }
        return findMedian(half);

        function findMedian(numArray: number[]) {
            const n = numArray.length;
            return n % 2 === 0 ? (numArray[n / 2] + numArray[n / 2 - 1]) / 2 : numArray[Math.floor(n / 2)];
        }
    },
    f_simplifyFraction: function (a: number, b: number): number {
        return +a / +b;
    },
    f_decomposeFraction: function (a: number, b: number, strike: string = ""): number {
        return +a / +b;
    },
    f_improperToMixed: function (a: number, b: number, flag: string = ""): number {
        const aNum = +a; // a and b get passed in as strings if not evaluated explicitly
        const bNum = +b;
        const numerator: number = aNum % bNum;
        const gcdenom: number = mathjs.gcd(numerator, bNum);
        if (flag === "wholeNumber") {
            if (aNum < 0 === bNum < 0) {
                return Math.floor(aNum / bNum);
            } else {
                return -Math.floor(mathjs.abs(aNum) / mathjs.abs(bNum));
            }
        } else if (flag === "numeratorSimplified") {
            return mathjs.abs(numerator / gcdenom);
        } else if (flag === "denominatorSimplified") {
            return mathjs.abs(bNum / gcdenom);
        }
        return aNum / bNum;
    },
    f_trueToYes: function (a: boolean): boolean {
        return a;
    },
    f_relation: function (a: number, b: number, c: number): boolean {
        switch (c) {
            case 1:
                return a === b;
            case 2:
                return a !== b;
            case 3:
                return a < b;
            case 4:
                return a > b;
            case 5:
                return a <= b;
            case 6:
                return a >= b;
        }
    },

    f_faceValue: function (num: number, index: number) {
        if (index === 0) {
            return "undefined";
        }
        const decPos = num.toString().split(".")[0].length;
        const idx = index >= 0 ? decPos - index : Math.abs(index) + decPos;
        return num.toString()[idx] || 0;
    },

    f_placeValue: function (num: number, index: number) {
        if (index === 0) {
            return "error";
        }

        const decPos = num.toString().split(".")[0].length;
        const idx = index > 0 ? decPos - index : Math.abs(index) + decPos;

        if (num.toString()[idx] === undefined) {
            return "0";
        } else {
            if (index > 0) {
                return 10 ** (index - 1);
            } else {
                return 10 ** index;
            }
        }
    },
    f_decimalPlaces: function (num: number) {
        const numString = cleanFloatingPoint(num);
        if (numString.includes(".")) {
            return numString.split(".")[1].length;
        } else {
            return 0;
        }
    },
    f_significantFigures: function (num: number) {
        const numString = cleanFloatingPoint(num);
        let [whole, dec] = numString.split(".");
        // remove leading zero from whole
        whole = whole.replace(/^0+/, "");
        whole = whole.replace("-", "");

        // if whole is zero, then we also remove leading 0 on dec
        if ((whole === "0" || whole === "") && dec) {
            dec = dec.replace(/^0+/, "") || "0";
        }

        if (!dec && whole[whole.length - 1] === "0") {
            return "error";
        } else {
            return (whole + (dec || "")).length;
        }
    },
    f_digits: function (num, nonzero?: string) {
        // Handle e notation
        const numStr = cleanFloatingPoint(num);

        if (numStr.includes("e-")) {
            const eIdx = numStr.indexOf("e-");

            let digits = numStr.includes(".") ? numStr.substring(numStr.indexOf(".") + 1, eIdx).length : 0;
            // +1 compensates for the 0 in the whole part: *0*.2 -> 2e-1
            digits += parseInt(numStr.substring(eIdx + 2)) + 1;

            return digits;
        } else if (numStr.includes("e+")) {
            const wholePart = numStr.slice(0, numStr.includes(".") ? numStr.indexOf(".") : undefined);
            return parseInt(numStr.substring(numStr.indexOf("e+") + 2)) + wholePart.length;
        }

        let onlyDigits = cleanFloatingPoint(num).replace(/\.|\-/g, "");

        if (nonzero === "nonzero") {
            onlyDigits = onlyDigits.replace(/0/g, "");
        }
        return onlyDigits.length;
    },
    f_int: function (integrand: string, intVar: string, lowerLim?: string, upperLim?: string) {
        return "error";
    },

    f_matrix: function (arr: any[]) {
        return arr;
    },

    f_not: function (input: boolean, mode?: string) {
        return !input;
    },

    nand: function (left: boolean, right: boolean, mode?: string) {
        return !(left && right);
    },

    nor: function (left: boolean, right: boolean, mode?: string) {
        return !(left || right);
    },

    f_vect: function (x: string) {
        return x;
    },

    f_uVect: function (x: string) {
        return x;
    },

    f_blank: function () {
        return 1;
    },

    and: function (left: boolean, right: boolean, mode?: string) {
        return left && right;
    },
    or: function (left: boolean, right: boolean, mode?: string) {
        return left || right;
    },
    xor: function (left: boolean, right: boolean, mode?: string) {
        return left !== right;
    },

    f_expandedForm: function (val: number, mode1?: string, mode2?: string) {
        return val;
    },
    f_subscript: function (val: any, nums: number | number[], mode?: string) {
        return val;
    },

    f_evalAt: function (exp: number, lowerBound: number, mode?: string) {
        return exp;
    },
    f_evalDiffAt: function (exp: number, lowerBound: number, upperBound: number, mode?: string) {
        return exp;
    },

    sin: function (exp: number) {
        return mathjs.sin(exp);
    },
    cos: function (exp: number) {
        return mathjs.cos(exp);
    },
    tan: function (exp: number) {
        return mathjs.tan(exp);
    },
    sec: function (exp: number) {
        return mathjs.sec(exp);
    },
    csc: function (exp: number) {
        return mathjs.csc(exp);
    },
    cot: function (exp: number) {
        return mathjs.cot(exp);
    },

    asin: function (exp: number) {
        return mathjs.asin(exp);
    },
    acos: function (exp: number) {
        return mathjs.acos(exp);
    },
    atan: function (exp: number) {
        return mathjs.atan(exp);
    },
    asec: function (exp: number) {
        return mathjs.asec(exp);
    },
    acsc: function (exp: number) {
        return mathjs.acsc(exp);
    },
    acot: function (exp: number) {
        return mathjs.acot(exp);
    },
    cotan: function (exp: number) {
        return mathjs.cot(exp);
    },
    cosec: function (exp: number) {
        return mathjs.csc(exp);
    },
    f_clock: function (hour: number, minute: number) {
        return mathjs.matrix([hour, minute]);
    },
    f_stopwatch: function (minute: number = 0, second: number = 0, milliseconds: number = 0) {
        return mathjs.matrix([minute, second, milliseconds]);
    },

    toDP: function (num, places: number, mode?: "trimZeros") {
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            let result: string = (mathjs as any).toDP(num.im, places, mode);
            return `${result}i`;
        }
        num = cleanFloatingPoint(num);

        if (!places && places !== 0 && num.toString().includes(".")) {
            places = num.toString().split(".")[1].length;
        }

        let formattedNum = (mathjs as any).f_format(num, "fixed", places);
        if (formattedNum.startsWith('"') && formattedNum.endsWith('"') && !isNaN(+formattedNum.slice(1, -1))) {
            formattedNum = formattedNum.slice(1, -1);
        }

        let _toReturn = formattedNum;
        if (mode === "trimZeros") {
            _toReturn = `${+formattedNum}`;
        }

        return _toReturn;
    },

    toSigFig: function (num, places: number, mode?: "trimZeros") {
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            let result: string = (mathjs as any).toSigFig(num.im, places, mode);
            return `${result}i`;
        }

        if (!places && places !== 0 && num.toString().includes(".")) {
            places = (mathjs as any).getSigFig(num.toString());
        }

        let formattedNum = (mathjs as any).f_format(num, "auto", places);
        if (mode == "trimZeros") {
            formattedNum = `${+formattedNum}`;
        }

        return formattedNum;
    },

    toSci: function (num, places: number, mode?: "trimZeros"): string {
        const separator = " \\times 10^";
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            let result: string = (mathjs as any).toSci(num.im, places, mode);
            const splitted = result.split(separator);
            splitted[0] = `${splitted[0]}i`;
            result = splitted.join(separator);
            return result;
        }
        if (!places && places !== 0 && num.toString().includes(".")) {
            places = (mathjs as any).getSigFig(num.toString());
        }
        let _toReturn = (mathjs as any).f_format(num, "exponential", places, false, true);

        if (mode === "trimZeros") {
            const splitted = _toReturn.split(separator);
            splitted[0] = `${+splitted[0]}`;
            _toReturn = splitted.join(separator);
        }
        return _toReturn;
    },

    toEng: function (num, places: number, mode?: "trimZeros") {
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            const separator = " \\times 10^";
            let result: string = (mathjs as any).toEng(num.im, places, mode);
            const splitted = result.split(separator);
            splitted[0] = `${splitted[0]}i`;
            result = splitted.join(separator);
            return result;
        }
        if (!places && places !== 0 && num.toString().includes(".")) {
            places = (mathjs as any).getSigFig(num.toString());
        }

        let _toReturn = (mathjs as any).f_format(num, "engineering", places);

        const splitted: string[] = _toReturn.split("e");
        if (mode === "trimZeros") {
            splitted[0] = `${+splitted[0]}`;
            _toReturn = splitted.join("e");
        }
        const exponent = +splitted[1];
        const rest = mode === "trimZeros" ? +splitted[0] : splitted[0];
        _toReturn = `${rest} \\times 10^{${exponent}}`;
        return _toReturn;
    },

    trimZeros: function (num) {
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            let imaginary = mathjs.complex(0, +cleanFloatingPoint(+num.im));
            return imaginary.toString();
        } else if (typeof num === "string" && num.endsWith("i") && !isNaN(+num.slice(0, -1))) {
            let imaginary = mathjs.complex(0, +cleanFloatingPoint(+num.slice(0, -1)));
            return `${imaginary.toString()}i`;
        }
        if (!isNaN(+num)) {
            num = cleanFloatingPoint(+num);
        }
        return num;
    },

    // Temporary duplicates: gitSigFig, getDP and getDigits
    // Content team will start using these instead, as this syntax is more intuitive
    getSigFig: function (num) {
        let real = num.toString();
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            real = num.im;
        } else if (real.endsWith("i") && !isNaN(+real.slice(0, -1))) {
            real = real.slice(0, -1);
        }

        return (mathjs as any).f_significantFigures(real);
    },

    getDP: function (num) {
        let real = num.toString();
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            real = num.im;
        } else if (real.endsWith("i") && !isNaN(+real.slice(0, -1))) {
            real = real.slice(0, -1);
        }
        return (mathjs as any).f_decimalPlaces(real);
    },

    getDigits: function (num, mode?: "nonzero" | "displayed" | "nonzero,displayed") {
        let real = num.toString();
        if (typeof num === "object" && num.hasOwnProperty("im")) {
            real = num.im;
        } else if (real.endsWith("i") && !isNaN(+real.slice(0, -1))) {
            real = real.slice(0, -1);
        }

        if (mode?.includes("displayed")) {
            real = parse(`{${num}}`).solve().eval();
        }

        const _toReturn = (mathjs as any).f_digits(real, mode?.includes("nonzero") ? "nonzero" : "");
        return _toReturn;
    },

    del: function () {
        return "error";
    },

    grad: function (arg: any) {
        return "error";
    },

    divergence: function (arg: any) {
        return "error";
    },

    curl: function (arg: any) {
        return "error";
    },

    f_pDeriv: function (func: string, ivar: string[], flag?: string): any {
        return "error";
    },

    laplace: function (arg: any): any {
        return "error";
    },

    laplaceInv: function (arg: any): any {
        return "error";
    },

    round: function (num: number, multiple: number): any {
        if (multiple) {
            if (multiple.toString().includes(".")) {
                // find how many decimal places multiple has so we can round to this
                const numDecimalPlaces = multiple.toString().split(".")[1].length;
                return mathjs.round(+mathjs.round(num / multiple) * multiple, numDecimalPlaces);
            }
            return mathjs.round(num, multiple);
        }
        return mathjs.round(num);
    },

    f_yValueOfLocalMaxima: function (func: string, lowerLimit: number, upperLimit: number): any {
        return findMaxOrMin(func, lowerLimit, upperLimit, "max");
    },

    f_yValueOfLocalMinima: function (func: string, lowerLimit: number, upperLimit: number): any {
        return findMaxOrMin(func, lowerLimit, upperLimit, "min");
    },
    evalUsingSigFigRules: function (expression: string, num, mode?: "trunc") {
        let minSigFig = -1;
        for (const node of parse(expression).getNodesByType("ConstantNode")) {
            const currentSigFig = (mathjs as any).getSigFig(node.name);
            if (currentSigFig < minSigFig || minSigFig === -1) {
                minSigFig = currentSigFig;
            }
        }
        const _toReturn = fixDecimalPlaces(num.toString(), minSigFig);
        return mode === "trunc" ? `${+_toReturn}` : _toReturn;
    },
};

function findMaxOrMin(func: string, lowerLimit: number, upperLimit: number, flag: string) {
    if (!["max", "min"].includes(flag)) {
        return "error";
    }
    const funcWithParens = func.replace(/x/g, "(x)"); // put (...) around "x"
    const incre = (upperLimit - lowerLimit) / 1000; // divide the domain into 1000 pieces

    // find the prev, curr and next func values
    let prevX = lowerLimit;
    let prevY = mathjs.eval(funcWithParens.replace(/[\{\}]/g, "").replace(/x/g, `${prevX}`));
    let currX = lowerLimit + incre;
    let currY = mathjs.eval(funcWithParens.replace(/[\{\}]/g, "").replace(/x/g, `${currX}`));
    let nextX;
    let nextY;

    let yDiff = 0.1; // difference between two consecutive func values. want this to be very close to zero
    let xVal; // x value the max/min y value occurs at
    // set the yVal value to be the larger/smaller of the endpoints
    let yVal;
    if (flag === "min") {
        yVal = Math.min(prevY, mathjs.eval(funcWithParens.replace(/[\{\}]/g, "").replace(/x/g, `${upperLimit}`)));
    } else if (flag === "max") {
        yVal = Math.max(prevY, mathjs.eval(funcWithParens.replace(/[\{\}]/g, "").replace(/x/g, `${upperLimit}`)));
    }
    for (let i = 2; i <= 1000; ++i) {
        nextX = lowerLimit + i * incre;
        nextY = mathjs.eval(funcWithParens.replace(/[\{\}]/g, "").replace(/x/g, `${currX}`));
        // do calc here
        if (
            (flag === "min" && Math.abs(currY - prevY) < yDiff && prevY > currY && currY < nextY) ||
            (flag === "max" && Math.abs(currY - prevY) < yDiff && prevY < currY && currY > nextY)
        ) {
            yDiff = Math.abs(currY - prevY);
            xVal = currX;
            yVal = currY;
        }
        // update prev values
        prevX = currX;
        prevY = currY;
        currX = nextX;
        currY = nextY;
    }
    return yVal;
}
