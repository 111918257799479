import { useEffect, useState } from "react";
import * as ReactDOM from "react-dom";
import { AmyRender } from "./AmyRender";
import { AmyRenderStyle } from "./latexUtils";

export interface DiagramProps extends AmyRenderStyle {
    data: string;
    svgId: string;
}

export function AmyDiagram(props: DiagramProps) {
    let amySvgDiagramRef: HTMLDivElement;
    const color = props?.color;
    const [svgContainerDimensions, setSvgContainerDimensions] = useState<{
        x: number;
        y: number;
        width: number;
        height: number;
    }>();
    // console.log(`Rendering AmyDIagram`);

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);

        updateDimensions();

        return () => {
            return window.removeEventListener("resize", updateDimensions);
        };
    }, []);

    function updateDimensions(): void {
        console.log("updateDimensions", props.svgId);
        // save the current svg containher dimensions
        const svgContainer = ReactDOM.findDOMNode(amySvgDiagramRef) as any;
        if (svgContainer) {
            // console.log(`updateDimensions found amySVG_diagram and is updating the dimensions`);
            const obj: { x: number; y: number; width: number; height: number } = svgContainer.getBoundingClientRect(); // outputs <h3> coordinates

            if (svgContainerDimensions) {
                if (
                    obj.x !== (svgContainerDimensions.x || -1) ||
                    obj.y !== (svgContainerDimensions.y || -1) ||
                    obj.width !== svgContainerDimensions.width ||
                    obj.height !== svgContainerDimensions.height
                ) {
                    setSvgContainerDimensions({ x: obj.x, y: obj.y, width: obj.width, height: obj.height });
                }
            } else {
                setSvgContainerDimensions({ x: obj.x, y: obj.y, width: obj.width, height: obj.height });
            }
        }
    }

    const mode = color === "black" ? "lightMode" : "darkMode";

    const SVGsrc = `/__/diagrams/${props.svgId}_${mode}.svg`;
    console.log("SVGsrc", SVGsrc);

    let labelsObj: any[] = [];
    let svgScale = 1;
    let margins = { top: 0, bottom: 0, left: 0, right: 0 };
    try {
        const dataJson = JSON.parse(props.data);
        if (!dataJson.format.scale || !dataJson.labels) {
            console.error("Parsing error in Diagram JSON: Missing scale or labels \n");
        } else {
            if (dataJson.format.margins) {
                margins = dataJson.format.margins;
            }
            if (dataJson.format.scale) {
                svgScale = dataJson.format.scale;
            }
            if (dataJson.labels) {
                labelsObj = dataJson.labels;
            }
        }
    } catch (e) {
        console.error("Parsing error in Diagram JSON: \n" + e.message);
    }

    // console.log(svgContainerDimensions, labelsObj);

    const labelElems: JSX.Element[] = [];
    const dimens = svgContainerDimensions;
    if (dimens && dimens.width !== undefined && dimens?.height !== undefined) {
        labelsObj.forEach((label, index) => {
            const posX = label.x * dimens.width + 30;
            const posY = label.y * dimens.height - 22;
            labelElems.push(
                <span
                    style={{
                        top: 0,
                        left: 0,
                        position: "absolute",
                        transform: `translate(${posX}px, ${posY}px)`,
                    }}
                    key={index}
                >
                    <AmyRender
                        color={props.color === "white" ? "white" : "black"}
                        text={labelsObj[index].rawText ? labelsObj[index].val : `$[${labelsObj[index].val}]$`}
                    />
                </span>,
            );
        });
    }

    let svgStyle: React.CSSProperties = {};

    return (
        <div>
            <div
                style={{
                    position: "relative",
                    width: 800 * svgScale + margins.left + margins.right,
                    paddingTop: margins.top,
                    paddingBottom: margins.bottom,
                    paddingLeft: margins.left,
                    paddingRight: margins.right,
                }}
                ref={(el) => {
                    if (el) {
                        amySvgDiagramRef = el;
                    }
                }}
            >
                <img
                    alt="AmyDiagram"
                    onLoad={() => updateDimensions()}
                    src={SVGsrc}
                    width={800 * svgScale}
                    style={svgStyle}
                />
                {labelElems}
            </div>
        </div>
    );
}
