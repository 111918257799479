import pluralize = require("pluralize");
import { Node } from "./Node";

export class UnitNode extends Node {
    constructor(args: Node[], parent: Node = undefined) {
        super("Unit", args, parent);
        this.type = "UnitNode";
    }

    toTex(options?: any) {
        if (this.args[0].name === "degree") {
            return this.args[0].toTex(options);
        } else if (
            this.parent &&
            this.parent.name === "*" &&
            this.parent.args.length === 2 &&
            this.parent.args[0].name === "1" &&
            this.args[0].toTex().length > 2 // not s, m, cm or kg etc
        ) {
            // single unit
            const unit = this.args[0].toTex(options);
            if (unit.split("~").length > 1) {
                // unit has multiple words, for example "meters per second" etc
                // therefore pluralise the first word
                const subunits = unit.split("~");
                const firstUnit = pluralize(subunits[0], 1);
                return `\\mathrm{${firstUnit}~${subunits.slice(1).join("~")}}`;
            }
            return `\\mathrm{${pluralize(unit, 1)}}`;
        } else {
            // normal plural unit
            return `\\mathrm{${this.args[0].toTex(options)}}`;
        }
    }

    toString(options?: any) {
        return `f_unit(${this.args[0].toString(options)})`;
    }

    getLatexNeighbour(_direction: "left" | "right"): Node {
        return this;
    }

    cloneDeep(parent?: Node) {
        const args = this.args.map((arg) => arg.cloneDeep(this));
        return new UnitNode(args, parent);
    }
}
