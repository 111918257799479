import { AmyRender } from "@amy-app/react-syntax-renderer";
import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { parse } from "@jaipuna/js-internal/dist/src/parser/parse";
import {
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useTheme,
} from "@mui/material";

export function DataCard({ page, funcDef }: { page?: WikiPage; funcDef: WikiFunc }) {
    const theme = useTheme();

    function evalExp(exp: string) {
        try {
            return parse(exp.replace(/\{|\}/g, "")).eval().toString();
        } catch (e) {
            console.error(e.message);
            return "Error";
        }
    }
    function toTexExp(exp: string) {
        try {
            return parse(exp).toTex();
        } catch {
            return "Error";
        }
    }

    return (
        <Card>
            <CardContent>
                <Typography variant="h6" color="textPrimary">
                    Parameters
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Parameter</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Optional?</TableCell>
                            <TableCell>Default Value</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {funcDef.params.map((val) => (
                            <TableRow key={val.name}>
                                <TableCell>{val.name}</TableCell>
                                <TableCell>{val.type}</TableCell>
                                <TableCell>{val.optional}?</TableCell>
                                <TableCell>{val.default}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <Typography variant="h6" color="textPrimary" sx={{ marginTop: "40px" }}>
                    Examples
                </Typography>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Example</TableCell>
                            <TableCell>Evaluated</TableCell>
                            <TableCell>LaTeX?</TableCell>
                            <TableCell>Notes</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {page?.examples?.map((val) => (
                            <TableRow key={val.exp}>
                                <TableCell>{val.exp}</TableCell>
                                <TableCell>
                                    <AmyRender
                                        text={`$[${2}]$`}
                                        color={theme.palette.mode === "dark" ? "white" : "black"}
                                    />
                                </TableCell>
                                <TableCell>
                                    <AmyRender
                                        text={`$[${toTexExp(val.exp)}]$`}
                                        color={theme.palette.mode === "dark" ? "white" : "black"}
                                    />
                                </TableCell>
                                <TableCell>{val.descr}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
}
