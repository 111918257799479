import { compareDiffs } from "../DiffFinder/DiffFinder";
import { evaluateExpression, flags, ITuple, Node, PArchetype, PDiff, POption, toTex } from "../modules";
// check if the current step has no latex change from the last step
function noLatexChange(parametricSolutions: Map<string, string>, step: number, params: ITuple): boolean {
    // if (!parametricSolutions.get(String(step + 1))) {
    //     return false;
    // }

    const latexString = toTex(evaluateExpression(parametricSolutions.get(String(step - 1)), params)).replace(/ /g, "");

    // console.log(
    //     `Comapring: ${latexString} to ${parse(
    //         parametricSolutions.get(String(step)).toString(params),
    //     ).toTex()}`,
    // );
    return latexString === toTex(evaluateExpression(parametricSolutions.get(String(step)), params)).replace(/ /g, "");
}

// checks if the given line adds / removes ghost brackets
function checkghostBrackets(diff: PDiff): boolean {
    // because (Z1) and $(Z1)$ are the same in the compareTransitions function we have to check we are looking at ghost brackets
    // if neither include a "$" then we cant be looking at ghost brackets
    if (!diff.source.toString().includes("$") && !diff.target.toString().includes("$")) {
        return false;
    }

    return (
        compareDiffs([diff.source.toString(), diff.target.toString()], ["Z1", "$(Z1)$"]) ||
        compareDiffs([diff.source.toString(), diff.target.toString()], ["$(Z1)$", "Z1"])
    );
}

// function for checking if a diff is a recognised bracket diff, including special case #1 diffs
function isBracketDiff(source: Node, target: Node): "add" | "remove" | undefined {
    if (source.toString().includes("$") || target.toString().includes("$")) {
        return undefined;
    }

    const possibleDiffsLeft: string[][] = [
        ["Z1 + Z2 - Z3", "Z1 + (Z2 - Z3)"],
        ["Z1 * Z2 / Z3", "Z1 * (Z2 / Z3)"],
        ["Z1", "(Z1)"],
    ];

    const possibleDiffsRight: string[][] = [
        ["Z1 * (Z2 / Z3)", "Z1 * Z2 / Z3"],
        ["Z1 + (Z2 - Z3)", "Z1 + Z2 - Z3"],
        ["(Z1)", "Z1"],
    ];

    // for each diff check for a match
    for (const diff of possibleDiffsLeft) {
        // if a match is found, return true, we only need one match
        if (compareDiffs([source.toString(), target.toString()], diff)) {
            return "add";
        }
    }
    // for each diff check for a match
    for (const diff of possibleDiffsRight) {
        // if a match is found, return true, we only need one match
        if (compareDiffs([source.toString(), target.toString()], diff)) {
            return "remove";
        }
    }
    // if the function hasnt returned yet, it hasnt found a match
    return undefined;
}

// checks if the line (represented by diffs[step]) is the first in a pair (or more) of bracket steps
function bracketDuplicate(diffs: Map<string, PDiff>, step: number): boolean {
    // the diff for the current step
    const thisDiff: PDiff = diffs.get(Array.from(diffs.keys())[step - 1]);

    // if the current step isnt a bracket add or remove then skip it
    if (!isBracketDiff(thisDiff.source, thisDiff.target)) {
        return false;
    }

    // the direction we are ading brackets either: add|removed
    const thisBracketTransType = isBracketDiff(thisDiff.source, thisDiff.target);

    // just check the next step
    const nextDiff: PDiff = diffs.get(`${step}->${step + 1}`);
    if (nextDiff) {
        const bracketTransType = isBracketDiff(nextDiff.source, nextDiff.target);
        // if this step is a bracket trans and also the same type as this step
        return bracketTransType === thisBracketTransType;
    } else {
        return false;
    }
}

export function getStepsFlags(arch: PArchetype, step: number, currentFlags: flags[] = []): flags[] {
    const retFlags: flags[] = currentFlags;

    // get the diff at the current position - 1 to get the diff where this is the target
    let currentDiff: PDiff;
    let currentLine: POption;
    // console.log(arch);
    try {
        currentDiff = arch.diffs.get(Array.from(arch.diffs.keys())[step - 1]);
        currentLine = arch.options.get(String(step)).getCorrectOption();
    } catch {
        // console.log(`FAILED: ${step}\n${Array.from(arch.diffs.keys())}\n${Array.from(arch.options.keys())}`);
        return currentFlags;
    }

    // if diff is undefined this is the first step of an arch and therefore cannot be skipped
    if (currentDiff === undefined) {
        // if (step === 0) console.log(`Ignored step  ${step}`);
        // if (step !== 0) console.log(`Step: ${step} failed to find diff`);
        return currentFlags;
    } else if (currentLine === undefined) {
        // console.log(`Step: ${step} failed to find line`);
        return currentFlags;
    }

    // console.log(`currentOption:\n${currentLine.expression.toString(arch.parameters[0])}`);
    // console.log(arch);

    // if (checkghostBrackets(currentDiff)) {
    //     retFlags.push(flags.ghostBrackets);
    // }

    if (bracketDuplicate(arch.diffs, step)) {
        retFlags.push(flags.bracketDuplicate);
    }

    // WE NOW HANDLE THIS ON THE CLOUD FUNCTION server/functions/src/Teaching/ExerciseBubbleOnUpdate.ts
    // if (noLatexChange(arch.parametricSolutions, step, arch.parameters[0])) {
    //     retFlags.push(flags.noLatexChange);
    // }

    // remove duplicate flags
    return Array.from(new Set(retFlags));
}
