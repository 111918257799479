import { getRandomInt } from "@jaipuna/common-modules/dist/src/GeneralUtils";
import * as mathjs from "mathjs";
// import { ITuple } from "./modules";
import { Node } from "../parser/Node";
import { parse } from "../parser/parse";

function randomiseArray(
    array: any[],
    ignoreFirst: boolean = false,
    ignoreLast: boolean = false,
    randomSeed: number = 0,
): mathjs.Matrix {
    const mistakeKids = [];
    // we dopnt edit the first and last value to make them more realistic
    array.forEach((val, index) => {
        if (index === 0 && ignoreFirst) {
            mistakeKids.push(val);
        } else if (index === array.length - 1 && ignoreLast) {
            mistakeKids.push(val);
        } else {
            mistakeKids.push(val + getRandomInt(-3, 3) + randomSeed);
        }
    });
    // return the sorted array
    return mathjs.matrix(
        mistakeKids.sort((a: number, b: number): number => {
            return a - b;
        }),
    );
}

export const customMistakeFunctions: any = {
    f_factorsMistake: function (
        seed: number,
        n: number,
        nMin?: number,
        nMax?: number,
        showNegative?: boolean,
    ): mathjs.Matrix {
        const factors = (mathjs as any).f_factors(n, nMin, nMax, showNegative);
        return randomiseArray(factors, true, true, seed);
    },

    ImMistake: function (seed: number, _: any): number {
        return seed + 4;
    },

    f_multiplesMistake: function (seed: number, n: number, nMin?: number, nMax?: number, count?: number): any {
        const multiples = (mathjs as any).f_multiples(n, nMin, nMax, count);
        return randomiseArray(multiples, true, false, seed);
    },
    f_commonMultiplesMistake: function (
        seed: number,
        n1: number,
        n2: number,
        nMin?: number,
        nMax?: number,
        count?: number,
    ): any {
        const multiples = (mathjs as any).d_commonMultiples(n1, n2, nMin, nMax, count);
        return randomiseArray(multiples, true, false, seed);
    },
    d_misalignedAdditionDistractor: function (left: string, right: string): any {
        // make sure these are numbers
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        const negative = xor(+left < 0, +right < 0);
        let leftArr = String(left).replace(/-/g, "").split("");
        let rightArr = String(right).replace(/-/g, "").split("");

        // this distractor works if one number has more digits that the other
        if (leftArr.length === rightArr.length) {
            return "notApplicable";
        }

        const ans: string[] = [];
        while (leftArr.length > 0 && rightArr.length > 0) {
            const num: number = +leftArr.shift() + +rightArr.shift();
            ans.push(num.toString());
        }
        ans.push(...leftArr, ...rightArr);

        if (negative) {
            return String(-1 * +ans.join(""));
        }
        return ans.join("");
    },
    d_noCarryAdditionDistractor: function (left: string, right: string): any {
        // make sure these are numbers
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        const negative = xor(+left < 0, +right < 0);

        const leftArray = String(Math.abs(+left))
            .split("")
            .reverse();
        const rightArray = String(Math.abs(+right))
            .split("")
            .reverse();

        // pad shorter num with zeros
        const n = Math.max(leftArray.length, rightArray.length);
        while (leftArray.length < n) {
            leftArray.push("0");
        }
        while (rightArray.length < n) {
            rightArray.push("0");
        }
        let ans: number[] = [];
        for (let idx = 0; idx < n; ++idx) {
            ans.push((+leftArray[idx] + +rightArray[idx]) % 10);
        }
        if (negative) {
            return -1 * +ans.reverse().join("");
        }
        return ans.reverse().join("");
    },
    d_zeroOpNumToZero: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        if (+left === 0) {
            return 0;
        }
        return "notApplicable";
    },
    d_zeroOpNumToNumPlusOne: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        if ((+left === 0) === (+right !== 0)) {
            return +left + +right + 1;
        }
        return "notApplicable";
    },
    d_zeroOpNumToNum: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        if (+left === 0) {
            return +left + +right;
        }
        return "notApplicable";
    },
    d_zeroOpNumToOne: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        if (+left === 0) {
            return 1;
        }
        return "notApplicable";
    },
    d_extraZeroDistractor: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }
        if (String(left).slice(-1) === "0" && String(right).slice(-1) === "0") {
            return (+left + +right) * 10;
        }
        return "notApplicable";
    },
    d_addAllNumsTogetherDistractor: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);
        if (String(absLeft).length === 1 && String(absLeft).length === String(absRight).length) {
            return "notApplicable";
        }

        let ans = `${absLeft}${absRight}`.split("").reduce((prev, curr) => +prev + +curr, 0);
        if (negative) {
            return -1 * +ans;
        }
        return ans;
    },
    d_placementDistractor: function (left: string, right: string) {
        if (isNaN(+left) || isNaN(+right)) {
            return "notApplicable";
        }

        const ans = +left + +right;
        const negative = xor(+left < 0, +right < 0);
        const absAns = String(Math.abs(ans));
        const ansReverse: string = absAns.split("").reverse().join("");
        if (absAns.length === 1) {
            return "notApplicable";
        } else if (absAns === ansReverse) {
            // if number is palindrome
            return "notApplicable";
        }

        if (negative) {
            return +ansReverse * -1;
        }
        return +ansReverse;
    },
    d_subtractSmallerDigitFromLargerDistractor: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];

        for (let i = 0; i < len; ++i) {
            if (a[i] >= b[i]) {
                c.push(a[i] - b[i]);
            } else {
                c.push(b[i] - a[i]);
            }
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_borrowWithoutSubtracting: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                if (a[i + 1] > 0) {
                    a[i] += 10;
                } else {
                    return "notApplicable";
                }
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_doesNotKeepBorrowing: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        // this is correct subtraction
        // for (let i = 0; i < len; ++i) {
        //     if (a[i] < b[i]) {
        //         // remember, we know a > b
        //         let j = i + 1;
        //         while (a[j] === 0) {
        //             a[j] = 9;
        //             ++j;
        //         }
        //         a[i] += 10;
        //         a[j] -= 1;
        //     }
        //     c.push(a[i] - b[i]);
        // }
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                a[i] += 10;
                if (i < len - 1 && a[i + 1] !== 0) {
                    a[i + 1] -= 1;
                } else {
                    a[i + 1] = 9;
                }
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_topNumZeroToN: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] === 0) {
                // if a - b and a < b, switch
                [a[i], b[i]] = [b[i], a[i]];
            } else if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 9;
                    ++j;
                }
                a[i] += 10;
                a[j] -= 1;
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_topNumZeroToZero: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] === 0) {
                // if 0 - N, write 0 instead of borrowing
                b[i] = 0; // so a[i] - b[i] -> 0
            } else if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 9;
                    ++j;
                }
                a[i] += 10;
                a[j] -= 1;
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_writeTopAsTenWhenZero: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 10; // write 10 here instead of 9
                    ++j;
                }
                a[i] += 10;
                a[j] -= 1;
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_writeTopAsTenInsteadOfEleven: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 9;
                    ++j;
                }
                if (a[i] === 1) {
                    // if a[i] is 1, write 10 instead of 11
                    a[i] = 10;
                } else {
                    a[i] += 10;
                }
                a[j] -= 1;
            }
            c.push(a[i] - b[i]);
        }
        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_continueToBorrowFromEveryCol: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 9;
                    ++j;
                }
                a[i] += 10;

                for (let k = j; k < len; ++k) {
                    // borrow from all rows
                    if (a[k] > 0) {
                        a[k] -= 1;
                    }
                }
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_alwaysBorrowFromLeftDigit: function (left: string, right: string) {
        // a - b -> c, a >= b
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const a: number[] = String(absLeft)
            .split("")
            .reverse()
            .map((num) => +num);
        const b: number[] = String(absRight)
            .split("")
            .reverse()
            .map((num) => +num);

        const len = a.length;
        if (len < b.length) {
            return "notApplicable";
        }

        while (b.length < len) {
            b.push(0);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            if (a[i] < b[i]) {
                // remember, we know a > b
                let j = i + 1;
                while (a[j] === 0) {
                    a[j] = 9;
                    ++j;
                }
                a[i] += 10;
                a[len - 1] -= 1; // always subtract all borrows from the leftmost digit in the top number
            }
            c.push(a[i] - b[i]);
        }

        const ans = +c.reverse().join("");
        if (negative) {
            return -1 * ans;
        }
        return ans;
    },
    d_dontShiftColDistractor: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const absLeft = String(Math.abs(+left));
        const absRight = String(Math.abs(+right));

        // no decimals
        if (containsDecimals(absLeft, absRight)) {
            return "notApplicable";
        }

        let {
            multiplicand, // longer / second number / right
            multiplier, // shorter / first number / left
        } = findMultiplicandAndMultiplier(absLeft, absRight);

        const len = multiplicand.length;

        while (multiplier.length < len) {
            multiplier.push(0);
        }

        let ans = 0;
        // // correct multiplication
        // for (let i = 0; i < b.length; ++i) {
        //     let term = 0;

        //     for (let j = 0; j < a.length; ++j) {
        //         term += b[i] * a[j] * 10 ** (i + j);
        //     }
        //     ans += term;
        // }
        for (let i = 0; i < multiplier.length; ++i) {
            let term = 0;

            for (let j = 0; j < multiplicand.length; ++j) {
                term += multiplier[i] * multiplicand[j] * 10 ** j; // dont shift
            }
            ans += term;
        }
        if (negative) {
            return -1 * +ans;
        }
        return +ans;
    },
    d_multiplyDownDistractor: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const absLeft = String(Math.abs(+left));
        const absRight = String(Math.abs(+right));

        // no decimals
        if (containsDecimals(left, right)) {
            return "notApplicable";
        }

        let {
            multiplicand, // longer / second number / right
            multiplier, // shorter / first number / left
        } = findMultiplicandAndMultiplier(absLeft, absRight);

        const len = multiplicand.length;

        while (multiplier.length < len) {
            multiplier.push(1);
        }

        const c: number[] = [];
        for (let i = 0; i < len; ++i) {
            c.push(multiplier[i] * multiplicand[i]);
        }

        if (negative) {
            return -1 * +c.reverse().join("");
        }
        return +c.reverse().join("");
    },
    d_dontCarryMultiplication: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const absLeft = String(Math.abs(+left));
        const absRight = String(Math.abs(+right));

        // no decimals
        if (containsDecimals(absLeft, absRight)) {
            return "notApplicable";
        }

        let {
            multiplicand, // longer / second number / right
            multiplier, // shorter / first number / left
        } = findMultiplicandAndMultiplier(absLeft, absRight);

        const len = multiplicand.length;

        while (multiplier.length < len) {
            multiplier.push(0);
        }

        let ans = 0;
        for (let i = 0; i < multiplier.length; ++i) {
            let term = 0;

            for (let j = 0; j < multiplicand.length; ++j) {
                if (j !== multiplicand.length - 1) {
                    term += ((multiplier[i] * multiplicand[j]) % 10) * 10 ** (i + j); // no carry
                } else {
                    term += multiplier[i] * multiplicand[j] * 10 ** (i + j); // normal for last j
                }
            }
            ans += term;
        }

        if (negative) {
            return -1 * +ans;
        }
        return +ans;
    },
    d_dontShiftIfMultOfTen: function (left: string, right: string) {
        const a: number = +left;
        const b: number = +right;

        if (a % 10 === 0 || b % 10 === 0) {
            return (a * b) / 10;
        }
        return "notApplicable";
    },
    d_useOnesColOnly: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const absLeft = String(Math.abs(+left));
        const absRight = String(Math.abs(+right));
        let multiplier: string;
        let multiplicand: string;
        if (+absLeft > +absRight) {
            multiplicand = absLeft;
            multiplier = absRight;
        } else {
            multiplicand = absRight;
            multiplier = absLeft;
        }

        // no decimals
        if ([...multiplier.split(""), ...multiplicand.split("")].includes(".")) {
            return "notApplicable";
        }

        if (multiplier.length === 1) {
            // multiplier (bottom num) is not big enough for this distractor
            return "notApplicable";
        }

        const onesCol = multiplier.split("")[multiplier.length - 1];
        if (negative) {
            return -1 * +onesCol * +multiplicand;
        }
        return +onesCol * +multiplicand;
    },
    d_divisionIdentity: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        if (absRight !== 1) {
            // demoninator has to be 1: a / 1 -> 1
            return "notApplicable";
        } else if (absRight === absLeft) {
            return "notApplicable"; // the answer here is 1
        }
        return negative === false ? 1 : -1;
    },
    d_divisionMultiplesOfTen: function (left: string, right: string, mult: string) {
        const noDecimals: boolean = true;
        const numerator = +left;
        const denominator = +right;

        const n = numerator / denominator;
        if (n * +mult - Math.floor(n * +mult) !== 0 && noDecimals) {
            // distractor has decimals
            return "notApplicable";
        }

        return n * +mult;
    },
    d_singleDigitDivisorAlternated: function (left: string, right: string) {
        // const noDecimals: boolean = true;
        if (isNaN(+left) || isNaN(+right) || !isFinite(1 / +left)) {
            return "notApplicable";
        }

        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const numerator = absLeft.toString().split("");
        const denominator = absRight.toString().split("");
        if (denominator.length > 1) {
            // here, we only want single digit divisors
            return "notApplicable";
        }
        const ans: string[] = [];
        for (const [idx, num] of numerator.entries()) {
            if (num === ".") {
                ans.push(num);
                continue;
            }
            const max = +num > +denominator ? +num : +denominator;
            const min = +num <= +denominator ? +num : +denominator;
            const res = (max / min).toString().split("");

            if (idx === numerator.length - 1) {
                if (ans.includes(".")) {
                    const index = res.indexOf(".");
                    if (index >= 0) {
                        res.splice(index, 1);
                    }
                    ans.push(...res);
                } else {
                    ans.push(...res);
                }
            } else {
                ans.push(res[0]);
            }
        }
        // if (noDecimals) {
        //     return Math.floor(+ans.join(""));
        // }
        if (negative) {
            return -1 * +ans.join("");
        }
        return +ans.join("");
    },
    d_singleDigitDivisor: function (left: string, right: string) {
        // const noDecimals: boolean = true;
        if (isNaN(+left) || isNaN(+right) || !isFinite(1 / +right)) {
            return "notApplicable";
        }

        const negative = xor(+left < 0, +right < 0);
        const absLeft = Math.abs(+left);
        const absRight = Math.abs(+right);

        const numerator = absLeft.toString().split("");
        const denominator = absRight.toString().split("");
        if (denominator.length > 1) {
            // here, we only want single digit divisors
            return "notApplicable";
        }
        const ans: string[] = [];
        for (const [idx, num] of numerator.entries()) {
            if (num === ".") {
                ans.push(num);
                continue;
            }
            const res = Math.floor(+num / +denominator)
                .toString()
                .split("");

            ans.push(...res);
        }
        // if (noDecimals) {
        //     return Math.floor(+ans.join(""));
        // }
        if (negative) {
            return -1 * +ans.join("");
        }
        return +ans.join("");
    },
    d_perservationDivision: function (left: string, right: string) {
        const negative = xor(+left < 0, +right < 0);
        const numerator = Math.abs(+left);
        const denominator = Math.abs(+right);

        if (numerator !== denominator) {
            return "notApplicable";
        }
        if (negative) {
            return -1 * numerator;
        }
        return numerator;
    },
    d_zeroAsIdentityElementExponentiation: function (left: string, right: string) {
        const base: number = +left;
        const exponent: number = +right;

        if (exponent !== 0) {
            return "notApplicable";
        }
        return base;
    },
    d_zeroAsAbsorbingElementExponentiation: function (left: string, right: string) {
        const base: number = +left;
        const exponent: number = +right;

        if (exponent !== 0) {
            return "notApplicable";
        }
        return 0;
    },
    d_zeroAsBaseToBaseExponentiation: function (left: string, right: string) {
        const base: number = +left;
        const exponent: number = +right;

        if (base !== 0) {
            return "notApplicable";
        }
        return exponent;
    },
    d_zeroAsBaseToOneExponentiation: function (left: string, right: string) {
        const base: number = +left;

        if (base !== 0) {
            return "notApplicable";
        }
        return 1;
    },
    d_oneExponentGoesToOneExponentiation: function (left: string, right: string) {
        const base: number = +left;
        const exponent: number = +right;

        if (exponent !== 1) {
            return "notApplicable";
        } else if (base === 1) {
            return "notApplicable";
        }

        return 1;
    },
    d_oneExponentGoesToZeroExponentiation: function (left: string, right: string) {
        const exponent: number = +right;

        if (exponent !== 1) {
            return "notApplicable";
        }
        return 0;
    },
    d_concatenateDistractor: function (args: mathjs.Matrix) {
        let ans: string = "";
        let negative = false;
        mathjs.forEach(args, (arg) => {
            if (+arg < 0) {
                negative = !negative;
            }
            ans += String(Math.abs(+arg));
        });
        if (negative) {
            return -1 * +ans;
        }
        return +ans;
    },
    d_equivalentFractionsMisconception: function (a: number, b: number, c: number, d: number) {
        // b > d: a / b + c / d -> a / b + {c + b - d}/b
        // d > b: a / b + c / d -> {a + d - b} / b + c / d
        let ret = "notApplicable";
        try {
            if (+b > +d) {
                ret = `${a} / ${b} + ${c + b - d} / ${b}`;
            } else if (+d > +b) {
                ret = `${a + d - b} / ${b} + ${c} / ${d}`;
            }
        } catch (e) {
            return "notApplicable";
        }
        return ret;
    },
    d_zeroIfEqualNumerators: function (a: number, b: number, c: number, d: number) {
        // a / b - c / d -> 0 if a === c
        if (+b === +d || +a !== +c) {
            return "notApplicable";
        }
        return `0 / ${b}`;
    },
    d_areEqual: function (a: number, b: number) {
        if (+a === +b) {
            return `${a}`;
        }
        return "notApplicable";
    },
    d_multiplesRemoval: function (n: number, nMin?: number, nMax?: number, quad?: number, count?: number): any {
        // by default nMin and nMax are 1 and 10 respectivley
        const numRet: number = count !== undefined ? count : 10;
        const min: number = nMin !== undefined ? nMin : n;
        const max: number = nMax !== undefined ? nMax : n * numRet;

        // get all the multiples
        const _ret: number[] = [];
        for (let i = 1; i <= numRet; i++) {
            const multiple = parseFloat((n * i).toPrecision(12));
            if (multiple <= max && multiple >= min) {
                _ret.push(multiple);
            }
        }

        const indexToRemove = Math.floor((_ret.length / 3) * quad);
        _ret.splice(indexToRemove, 1);

        return mathjs.matrix(_ret);
    },
    // specify no decimals distractors
    d_noDecimals: function (expression: string): any {
        if (expression === "notApplicable") {
            return "notApplicable";
        }
        if (isNaN(+expression)) {
            return "notApplicable";
        }
        if (`${expression}`.split("").includes(".")) {
            return "notApplicable";
        }
        return expression;
    },
    // specify no negative distractors
    d_noNegatives: function (expression: string) {
        if (expression === "notApplicable") {
            return "notApplicable";
        }
        if (isNaN(+expression)) {
            return "notApplicable";
        }

        if (+expression < 0) {
            return "notApplicable";
        }
        return expression;
    },
    d_incorrectPowers: function (num: string, pow: string, op: string) {
        if (isNaN(+num) || isNaN(+pow)) {
            return "notApplicable";
        } else if (+num <= 0 || +pow <= 0) {
            return "notApplicable";
        }
        const ansArr: string[] = [];
        for (let i = 0; i < +pow; ++i) {
            ansArr.push(num);
        }

        return ansArr.join(op);
    },
    d_wrongRelation: function (a: string, b: string, op: string, occurrence: string, type: string) {
        if (isNaN(+a) || isNaN(+b)) {
            return "notApplicable";
        } else if (![1, 2, 3, 4, 5, 6].includes(+op)) {
            return "notApplicable";
        } else if (![1, 2].includes(+type)) {
            return "notApplicable";
        }
        const equiv = +op;
        let wrongOps: number[] = [];
        let numbersSwitched: number[] = [];
        switch (equiv) {
            // https://gitlab.com/jaipuna/content/-/wikis/syntaxAndOperators/Functions/f_relation
            case 1:
                // case 1: ==
                wrongOps = [2, 3, 4]; // distractors: !=, <, >
                numbersSwitched = [2, 3, 4]; // distractors with numbers switched: !=, <, >
                break;
            case 2:
                // case 2: !=
                wrongOps = [1]; // ==
                numbersSwitched = [1]; // ==
                break;
            case 3:
                // case 3: <
                wrongOps = [1, 4, 6]; // ==, >, >=
                numbersSwitched = [1, 3, 5]; // ==, <, <=
                break;
            case 4:
                // case 4: >
                wrongOps = [1, 3, 5]; // ==, <, <=
                numbersSwitched = [1, 4, 6]; // ==, >, >=
                break;
            case 5:
                // case 5: <=
                wrongOps = [4]; // >
                numbersSwitched = [5]; // <=
                break;
            //
            case 6:
                // case 6: >=
                wrongOps = [3]; // <
                numbersSwitched = [6]; // >=
                break;
        }
        if (+type === 1 && +occurrence >= wrongOps.length) {
            return "notApplicable";
        } else if (+type === 2 && +occurrence >= numbersSwitched.length) {
            return "notApplicable";
        }
        if (+type === 1) {
            return `f_relation(${a}, ${b}, ${wrongOps[+occurrence]})`;
        } else if (+type === 2) {
            return `f_relation(${b}, ${a}, ${numbersSwitched[+occurrence]})`;
        }
        return "error";
    },
    /**
     * old implementation of f_multiples, still used in distractors
     * @param n
     * @param nMin
     * @param nMax
     * @param count
     * @returns
     */
    d_multiples: function (n: number, nMin?: number, nMax?: number, count?: number): any {
        // by default nMin and nMax are 1 and 10 respectivley
        const numRet: number = count !== undefined ? count : 10;
        const min: number = nMin !== undefined ? nMin : n;
        const max: number = nMax !== undefined ? nMax : n * numRet;

        // get all the multiples
        const _ret: number[] = [];
        for (let i = 1; i <= numRet; i++) {
            const multiple = parseFloat((n * i).toPrecision(12));
            if (multiple <= max && multiple >= min) {
                _ret.push(multiple);
            }
        }

        return mathjs.matrix(_ret);
    },
    /**
     * old implementation of f_commonMultiples, still used in distractors
     * @param n1
     * @param n2
     * @param nMin
     * @param nMax
     * @param count
     * @returns
     */
    d_commonMultiples: function (n1: number, n2: number, nMin?: number, nMax?: number, count?: number): any {
        // get the common factors of each
        const factors1 = (mathjs as any).f_multiples(n1, nMin, nMax, 100);
        const factors2 = (mathjs as any).f_multiples(n2, nMin, nMax, 100);
        const numRet: number = count !== undefined ? count : 10;

        // make real arrays from them
        const array1: number[] = [];
        const array2: number[] = [];

        mathjs.forEach(factors1, (value) => {
            array1.push(value);
        });

        mathjs.forEach(factors2, (value) => {
            array2.push(value);
        });

        let _ret = array1.filter((value) => array2.includes(value));
        _ret = _ret.sort((a: number, b: number): number => {
            return a - b;
        });

        _ret = _ret.slice(0, numRet);

        return mathjs.matrix(_ret);
    },
    d_carelessTens: function (sum: number, op: string): any {
        return carelessPowersOfTen(sum, op, 1);
    },
    d_carelessHundreds: function (sum: number, op: string): any {
        return carelessPowersOfTen(sum, op, 2);
    },
    d_carelessThousands: function (sum: number, op: string): any {
        return carelessPowersOfTen(sum, op, 3);
    },
    d_carelessTenths: function (sum: number, op: string): any {
        return carelessNegPowersOfTen(sum, op, -1);
    },
    d_carelessHundredths: function (sum: number, op: string): any {
        return carelessNegPowersOfTen(sum, op, -2);
    },
    d_carelessThousandths: function (sum: number, op: string): any {
        return carelessNegPowersOfTen(sum, op, -3);
    },
    d_firstDigitOnlyCorrect: function (sum: number) {
        const isNeg = +sum < 0 ? true : false;

        const positiveSum = Math.abs(sum);
        const correctArray = positiveSum.toString().split("");
        if (correctArray.length < 2) {
            return "notApplicable";
        }
        const primeNumber = 27644437;
        const prod = (positiveSum * primeNumber).toString().split("");
        const ans = [correctArray[0], ...prod.slice(-(correctArray.length - 1))]; // take only first correct digit, then "randomness"

        if (isNeg) {
            ans.unshift("-");
        }
        return ans.join("");
    },
    d_decomposeFractionStrikeDistractor: function (a: string, b: string, numerStikes: any, denomStikes: any): any {
        return +a / +b;
    },
    d_simplifyFraction: function (a: string, b: string): any {
        return +a / +b;
    },
};

export const customMistakeToTexFunctions: any = {
    f_factorsMistake: function (node: Node, options: any): string {
        const n = node.args[1];
        // by default nMin and nMax are 1 and n respectivley
        const min: string = node.args[2] !== undefined ? node.args[1].toTex(options) : "1";
        const max: string = node.args[3] !== undefined ? node.args[2].toTex(options) : n.toTex(options);

        return `\\mathrm{factors(${n}, ${min}, ${max})}`;
    },

    ImMistake: function (node: Node, _options: any): string {
        const innerTex = node.args[1] ? node.args[1].toTex() : "";
        return `\\mathrm{Im}\\left(${innerTex}\\right)`;
    },
    d_decomposeFractionStrikeDistractor: function (node: Node, options: any): string {
        // node.args[0] is the numer
        // node.args[1] is the denom
        // node.args[2] is numer strike placement array
        // node.args[3] is denom strike placement array

        let numer: number = parseInt(node.args[0].toTex(options).replace(/\s/g, ""));
        let denom: number = parseInt(node.args[1].toTex(options).replace(/\s/g, ""));
        if (isNaN(numer) || isNaN(denom)) {
            return "error";
        }
        const numerStrikesFirst = JSON.parse(node.args[2].args[0].name);
        const numerStrikesSecond = JSON.parse(node.args[2].args[1].name);
        const denomStrikesFirst = JSON.parse(node.args[3].args[0].name);
        const denomStrikesSecond = JSON.parse(node.args[3].args[1].name);
        const gcd = mathjs.gcd(numer, denom);

        let neg = "";
        if (numer < 0 !== denom < 0) {
            // XOR
            neg = "-";
            numer = Math.abs(numer);
            denom = Math.abs(denom);
        }

        const sortFunc = (a: number, b: number) => a - b;
        const numerArr = [gcd, numer / gcd].sort(sortFunc);
        const denomArr = [gcd, denom / gcd].sort(sortFunc);

        // don't duplicate the correct answer if both nums equal
        if (denomArr[0] === denomArr[1] && denomStrikesSecond) {
            // if both numbers in the denom are the same, and the second is crossed out,
            // dont cross out that number in the numer
            if (
                (numerArr[0] === denomArr[0] && numerStrikesFirst) ||
                (numerArr[1] === denomArr[0] && numerStrikesSecond)
            ) {
                return "notApplicable";
            }
        } else if (numerArr[0] === numerArr[1] && numerStrikesSecond) {
            // if both numbers in the numer are the same, and the second is crossed
            // out, dont cross out that number in the denom
            if (
                (denomArr[0] === numerArr[0] && denomStrikesFirst) ||
                (denomArr[1] === numerArr[0] && denomStrikesSecond)
            ) {
                return "notApplicable";
            }
        }

        const newNumer = `${numerStrikesFirst ? "\\cancel{" + numerArr[0] + "}" : numerArr[0]} {\\times} ${
            numerStrikesSecond ? "\\cancel{" + numerArr[1] + "}" : numerArr[1]
        }`;
        const newDenom = `${denomStrikesFirst ? "\\cancel{" + denomArr[0] + "}" : denomArr[0]} {\\times} ${
            denomStrikesSecond ? "\\cancel{" + denomArr[1] + "}" : denomArr[1]
        }`;
        const ans = `\\frac{${neg}${newNumer}}{${newDenom}}`;

        // check if distractor is equal to correct ans
        if (parse(`f_decomposeFraction(${numer},${denom},"strike")`, [], "USEIMPLICIT").toTex() === ans) {
            return "notApplicable";
        }
        return ans;
    },
    /**
     * this is just like f_simplifyFraction, except it does not simplify. it does however get rid of ones,
     * and sort out negatives
     * @param node
     * @param options
     * @returns
     */
    d_simplifyFraction: function (node: Node, options: any): string {
        // node.args[0] is a
        // node.args[1] is b
        const regex = /\s/g;
        const numer: number = +node.args[0].toTex(options).replace(regex, "");
        const denom: number = +node.args[1].toTex(options).replace(regex, "");

        if (isNaN(numer) || isNaN(denom)) {
            return "error";
        }

        // if denom is 1 (or -1), don't show it
        if (denom === 1) {
            return `${numer}`;
        } else if (denom === -1) {
            return `${-1 * numer}`;
        } else if (numer < 0 !== denom < 0) {
            // XOR
            return `\\frac{-${Math.abs(numer)}}{${Math.abs(denom)}}`;
        }
        return `\\frac{${numer}}{${denom}}`;
    },
};

// export function importMistakeFuncs(math: any): void {
//     try {
//         math.import(customMistakeFunctions);
//     } catch (e) {
//         console.error(`MathJS Custom Mistake Functions importer failed: ${e.message}`);
//         // console.log("this should only happen when running a test framework in watch mode");
//     }
// }

function findMultiplicandAndMultiplier(left: string, right: string): { multiplicand: number[]; multiplier: number[] } {
    // normally:
    //      3     x       4      =  12
    // multiplier x multiplicand = whatevs
    // or:
    //   216 <- multiplicand
    // x   6 <- multiplier
    // -----
    //  1266
    // however, we chose the multiplicand to be the bigger (longer?) number, and the multiplier to be the smaller number

    const leftStr = left
        .toString()
        .split("")
        .reverse()
        .map((num) => +num);
    const rightStr = right
        .toString()
        .split("")
        .reverse()
        .map((num) => +num);
    if (+left > +right) {
        return { multiplicand: leftStr, multiplier: rightStr };
    }
    return { multiplicand: rightStr, multiplier: leftStr };
}

// some distractors don't work on decimal numbers
function containsDecimals(left: string, right: string) {
    const leftStr = left.toString().split("");
    const rightStr = right.toString().split("");
    if ([...leftStr, ...rightStr].includes(".")) {
        // no decimals
        return true;
    }
    return false;
}

function xor(op1: boolean, op2: boolean) {
    return (op1 && !op2) || (!op1 && op2);
}

/**
 * add or subtract from the power of tens column.
 * @param sum
 * @param op
 * @param power
 * @returns
 */
function carelessPowersOfTen(sum: number, op: string, power: number): any {
    // power === 1 for tens column
    // power === 2 for hundreds column
    // power === 3 for thousands column
    const col = power + 1;
    const isNeg = +sum < 0 ? true : false;
    const sumStr = `${sum}`;

    let leftOfDecimal = sumStr;
    let rightOfDecimal = "";
    if (`${sum}`.includes(".")) {
        leftOfDecimal = sumStr.split(".")[0];
        rightOfDecimal = sumStr.split(".")[1];
    }

    let sumArr: string[] = [];
    if (isNeg) {
        sumArr = Math.abs(+leftOfDecimal)
            .toString()
            .split("");
    } else {
        sumArr = (+leftOfDecimal).toString().split("");
    }
    if (sumArr.length < col) {
        return "notApplicable";
    }
    const len = sumArr.length;
    let newDigit = 0;
    if (op === "add") {
        newDigit = (+sumArr[len - col] + 11) % 10;
    } else if (op === "sub") {
        newDigit = (+sumArr[len - col] + 9) % 10;
    }
    sumArr[len - col] = newDigit.toString();
    if (isNeg) {
        sumArr.unshift("-");
    }
    if (rightOfDecimal) {
        return [...sumArr, ".", ...rightOfDecimal.split("")].join("");
    }
    return sumArr.join("");
}

function carelessNegPowersOfTen(sum: number, op: string, power: number): any {
    // power === -1 for tenths column
    // power === -2 for hundredths column
    // power === -3 for thousandths column
    const sumStr = `${sum}`;
    if (!sumStr.includes(".")) {
        return "notApplicable";
    }
    const col = Math.abs(power) - 1;

    const leftOfDecimal = sumStr.split(".")[0];
    const rightOfDecimal = sumStr.split(".")[1];

    let sumArr: string[] = [];
    sumArr = (+rightOfDecimal).toString().split("");
    if (sumArr.length < col) {
        return "notApplicable";
    }
    const len = sumArr.length;
    let newDigit = 0;
    if (op === "add") {
        newDigit = (+sumArr[col] + 11) % 10;
    } else if (op === "sub") {
        newDigit = (+sumArr[col] + 9) % 10;
    }
    sumArr[col] = newDigit.toString();
    return [...leftOfDecimal.split(""), ".", ...sumArr].join("");
}
