import { symbolTexTable } from "../modules";
import { Node } from "./Node";

// This Node is used to represent both Parameters and Variables.
// you tell the difference using node.isParam() and node.isVar()

export class SymbolNode extends Node {
    constructor(name: string, parent: Node = undefined) {
        super(name, [], parent);
        this.type = "SymbolNode";
    }

    toTex(_options?: any) {
        // because params arent ever in the tree when we toTex, we can always use italics
        const predefinedTex = symbolTexTable.get(this.name);

        if (predefinedTex) {
            return predefinedTex;
        } else {
            return this.name;
        }
    }

    cloneDeep(parent?: Node) {
        return new SymbolNode(this.name, parent);
    }
}
