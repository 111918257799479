import { createTheme } from "@mui/material";

function generateTheme(mode: "light" | "dark") {
    const darkMode = mode === "dark";
    const amyRed = "rgb(255, 49, 98)";
    const amyRedDark = "#FF3162"; // redish pink #FF3162
    const amyBlue = "rgb(108, 148, 247)";
    const amyNavy = "rgb(23, 27, 45)";

    return createTheme({
        palette: {
            mode: mode,
            primary: {
                main: amyBlue,
                dark: amyNavy,
                contrastText: "#ffffff",
            },
            secondary: {
                main: amyRed,
                dark: amyRedDark,
                contrastText: "#FFFFFF",
            },
            info: {
                main: darkMode ? "#FFFFFFF" : "#000000",
                dark: darkMode ? "#dddddd" : "#222222",
                light: darkMode ? "#FFFFFFF" : "#000000",
                contrastText: darkMode ? "#000000" : "#FFFFFF",
            },
            action: {
                hover: "rgba(255, 255, 255, 0.06)",
                selected: "rgba(255, 255, 255, 0.1)",
            },
        },

        components: {
            MuiButton: {
                defaultProps: {
                    color: "inherit",
                    variant: "outlined",
                },
                styleOverrides: {},
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        background: darkMode ? "#121212" : "#FFFFFF",
                    },
                },
            },
        },
    });
}

export const darkTheme = generateTheme("dark");
export const lightTheme = generateTheme("light");
