import { Grid, Typography, useTheme } from "@mui/material";

export function FourOhFour({ path }: { path: string }) {
    const theme = useTheme();

    return (
        <Grid container spacing={3} alignItems={"center"} direction="column" style={{ marginTop: "100px" }}>
            <Grid item>
                <img
                    src="/resources/pixel404.png"
                    style={{
                        filter: theme.palette.mode === "dark" ? "invert()" : undefined,
                        borderRadius: 0,
                    }}
                    width="100px"
                />
            </Grid>
            <Grid item>
                <Typography variant="h3" color="textPrimary">
                    404 - Page not Found
                </Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1" color="textPrimary">
                    "{path}" not found, try searching or looking in the list on your left
                </Typography>
            </Grid>
        </Grid>
    );
}
