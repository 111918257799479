import * as draw_tree from "asciitree";
import { relabeler } from "../DiffFinder/DiffFinder";
import { mathjs, nodeOverrides } from "../compiler/customFunctions";
import { ITuple } from "../modules";
import { addQuotesToNums, fixNegativeZero, getSingularUnit, keepTrailingZeros } from "../utilities";
import { ParenthesisNode } from "./ParenthesisNode";
import { parse } from "./parse";
import _ = require("lodash");
import math = require("mathjs");

export class Node {
    private _name: string;
    private _parent: Node;
    private _args: Node[];
    private _type: string;

    constructor(name: string, args: Node[] = [], parent: Node = null) {
        this.name = name;
        this.args = args;
        this.parent = parent;
    }

    //=======================================  Checkers =======================================//
    /**
     * Checks if this node exists below a {...} node in its tree
     * @returns {boolean}
     * @memberof Node
     */
    isInEval(): boolean {
        // navigate up parents to root, if {} is founds, we are in eval
        if (this.type === "EvalNode") {
            return true;
        } else if (this.parent) {
            return this.parent.isInEval();
        }
        return false;
    }

    /**
     * Checks if this node is a variable, (A symbol node outside of {...} so not replaced with a number)
     * @returns {boolean}
     * @memberof Node
     */
    isVar(): boolean {
        const ancestorTypes = this.getAncestors().map((val) => val.type);
        return this.type === "SymbolNode" && !this.isInEval() && !ancestorTypes.includes("UnitNode");
    }

    /**
     * Checks if this node is a parameter, (A symbol node inside {...} so replaced with a number later)
     * @returns {boolean}
     * @memberof Node
     */
    isParam(): boolean {
        // we allow e to be a param for DiffFinder etc, is still evaluated to a constant
        const isSymbolConstant = ["e"].includes(this.name);
        return (this.type === "SymbolNode" || isSymbolConstant) && this.isInEval();
    }

    /**
     * Checks if this node could have more than 2 kids (An associative node)
     * @returns {boolean}
     * @memberof Node
     */
    isAssociative(): boolean {
        const associativeNames = ["*", "+"];
        return associativeNames.includes(this.name);
    }

    isZNode(): boolean {
        return (this.name.match(/Z\d+/g) || []).length > 0;
    }

    isStrikeNode(): boolean {
        return this.name === "f_strike";
    }

    isEvalNode(): boolean {
        return this.type === "EvalNode";
    }

    isLeaf(): boolean {
        return this.args.length === 0;
    }

    /**
     * Check if the node is a Unit node
     * @returns
     */
    isUnit(): boolean {
        return this.type === "UnitNode";
    }

    /**
     * Check if the node is a Function node
     * @returns
     */
    isFunction(): boolean {
        return this.type === "FunctionNode";
    }

    //======================================  Comparisons ======================================//
    /**
     * Checks if this node (and only this node, not kids) is equal to the given node
     * @param {Node} node2
     * @returns {boolean}
     * @memberof Node
     */
    shallowEquals(node2: Node): boolean {
        // deal with Ghost Brackets
        if ((this.name === "$()$" && node2.name === "()") || (this.name === "()" && node2.name === "$()$")) {
            return true;
        } else if (node2.name === "*" && this.name === "*") {
            return (this as unknown as any).mode === (node2 as any).mode;
        } else {
            return getSingularUnit(this.name) === getSingularUnit(node2.name);
        }
    }

    /**
     * Checks if this node and all its args are equal to the given node and all its args
     * @param {Node} node2
     * @returns {boolean}
     * @memberof Node
     */
    deepEquals(node2: Node): boolean {
        let exp1 = this.toString();
        let exp2 = node2.toString();

        // deal with ghost bracket
        exp1 = exp1.replace(/\$\(/g, "(");
        exp1 = exp1.replace(/\)\$/g, ")");

        exp2 = exp2.replace(/\$\(/g, "(");
        exp2 = exp2.replace(/\)\$/g, ")");

        return exp1 === exp2;
    }

    /**
     * Once relabled does this node and node2 match. This will only relabel symbolNodes
     * @param {Node} node2
     * @memberof Node
     */
    relabledEquals(node2: Node) {
        const thisRelabled = relabeler([this.toString()]);
        const node2Relabled = relabeler([node2.toString()]);
        return thisRelabled[0].replace(/\s/g, "") === node2Relabled[0].replace(/\s/g, "");
    }

    //===================================  Getters / Setters ===================================//
    /**
     * Replaces this in parent with the newNode
     * @param {Node} newNode
     * @returns {Node}
     * @memberof Node
     */
    replaceInTree(newNode: Node, root?: Node): Node {
        // get the root first as we cut the link in the replacement
        const ogRoot = root ? root : this.getRoot();
        if (this.parent) {
            this.parent.replaceArg(this, newNode);
            return ogRoot;
        } else {
            newNode.parent = null;
            return newNode;
        }
    }

    /**
     * Replaces this in parent with the newNode`
     * @param {Node} newNode
     * @returns {Node}
     * @memberof Node
     */
    replaceArg(toReplace: Node, replacement: Node): void {
        const replaceIndex = this.args.indexOf(toReplace);
        if (replaceIndex > -1) {
            const newArgs = [...this.args];
            newArgs.splice(replaceIndex, 1, replacement);
            toReplace.parent = null;
            this.args = newArgs;
        }
    }

    /**
     * reverse the children of a node
     * @return {*}  {void}
     * @memberof Node
     */
    reverseChildren(): void {
        if (this.args.length < 2) {
            return;
        }
        const leftTemp = this.args[0];
        this.args[0] = this.args[1];
        this.args[1] = leftTemp;
    }

    /**
     * Removes node from the args array
     * @param {Node} newNode
     * @returns {Node}
     * @memberof Node
     */
    removeArg(toRemove: Node): void {
        const newArgs = this.args.filter((val) => val !== toRemove);
        this.args = newArgs;
        toRemove.parent = null;
    }

    removeNodeByName(nodeName: string) {
        return this.findAndReplace(
            (node) => node.name === nodeName,
            (node) => node.args[0],
        );
    }

    /**
     * Remove nodes with type UnitNode from tree.
     * @return {*}  {Node}
     * @memberof Node
     */
    removeUnits(): Node {
        let _ret: Node = this;
        for (const node of this.getBFS()) {
            if (node.isUnit()) {
                _ret = node.removeFromTree();
            }
        }

        return _ret;
    }

    /**
     * Removes this from tree and backtraces up the tree until the tree is valid.
     * @return {*}  {Node}
     * @memberof Node
     */
    removeFromTree(): Node {
        if (!this.parent) return null;

        const root = this.getRoot();
        let validParent = false;
        const ogKids = this.parent.args;
        let siblings = ogKids.map((val) => val);
        siblings.splice(siblings.indexOf(this), 1);

        // before we do any swapping, we should just remove the node and see if parent is still valid
        const oldParent = this.parent;
        this.parent.removeArg(this);
        // we dont allow the creation of unary minuses
        if (!(oldParent.name === "-" && siblings.length === 1)) {
            try {
                parse(root.toString(), null, "USEIMPLICIT");
                return root;
            } catch {
                oldParent.args = ogKids;
            }
        }
        oldParent.args = ogKids;

        // at this point, we know parent is invalid, so we have to remove it
        let nodeToRemove = this.parent;

        // if just removing node is invalid, we backtrace up the tree, testing each parent
        while (!validParent) {
            if (!nodeToRemove) return null;
            if (!nodeToRemove.parent) return siblings[0];

            // remove the node from parent
            const oldKids = nodeToRemove.parent.args;
            const newKids = oldKids.map((val) => val);
            // if we have siblings, move them up a layer
            if (siblings[0]) {
                newKids[newKids.indexOf(nodeToRemove)] = siblings[0];
            } else {
                newKids.splice(newKids.indexOf(nodeToRemove), 1);
                siblings = newKids.map((val) => val);
            }
            nodeToRemove.parent.args = newKids;

            // we dont allow the creation of unary minuses
            if (!(nodeToRemove.parent.name === "-" && newKids.length === 1)) {
                try {
                    parse(root.toString(), null, "USEIMPLICIT");
                    return root;
                } catch {
                    validParent = false;
                }
            }
            if (!validParent) {
                nodeToRemove.parent.args = oldKids;
                nodeToRemove = nodeToRemove.parent;
            }
        }

        if (!validParent) {
            return null;
        } else {
            return root;
        }
    }

    /**
     * Returns the first node in the tree BFS which matches the search callback
     * @param searchCallback
     * @returns
     */
    find(searchCallback: (node: Node) => boolean): Node | undefined {
        for (const node of this.getBFS()) {
            if (searchCallback(node)) {
                return node;
            }
        }
        return undefined;
    }

    /**
     * Takes a search callback, and replaces nodes using the replaceCallback
     * eg: To replace all instances of f_format(...) with its 0th arg, so `1 + f_format(2, "fixed", 1)` ---> `1 + 2`
     *      findAndReplace(node => node.name === "f_format", node => node.args[0])
     * @param searchCallback
     * @param replaceCallback
     */
    findAndReplace(searchCallback: (node: Node) => boolean, replaceCallback: (node: Node) => Node): Node {
        let root = this.getRoot();
        const nodes = this.getNodes(searchCallback);
        for (const toReplace of nodes) {
            const replacement = replaceCallback(toReplace);
            root = toReplace.replaceInTree(replacement, root);
        }
        return root;
    }

    /**
     * Takes a search callback, and renames the nodes with the given replacement string
     * eg: To rename all SymbolNodes with X1, so `1 + a` ---> `1 + X1`
     *      findAndRename(node => node.type === "SymbolNode", "X1")
     * @param searchCallback
     * @param replaceCallback
     */
    findAndRename(searchCallback: (node: Node) => boolean, newName: string): Node {
        return this.findAndReplace(searchCallback, (node) => {
            node.name = newName;
            return node;
        });
    }

    /**
     * Substitutes all instances of toReplace with replacement
     * @param {Node} newNode
     * @returns {Node}
     * @memberof Node
     */
    replaceNodeByName(toReplace: string, replacement: Node): Node {
        return this.findAndReplace(
            (node) => node.name === toReplace,
            () => replacement.cloneDeep(),
        );
    }

    /**
     * Replaces all nodes where nodename === name with a clone of replacement, keeping kids
     * @param {string} name
     * @param {Node} replacement
     * @memberof Node
     */
    replaceShallowNode(name: string, replacement: Node) {
        return this.findAndReplace(
            (node) => node.name === name,
            (node) => {
                const newNode = replacement.cloneDeep();
                newNode.args = node.args;
                return newNode;
            },
        );
    }

    /**
     * Returns name
     * @returns {string}
     * @memberof Node
     */
    get name(): string {
        return this._name;
    }

    /**
     * Sets name
     * @param {string} newName
     * @memberof Node
     */
    set name(newName: string) {
        this._name = newName;
    }

    /**
     * Returns args
     * @returns {Node[]}
     * @memberof Node
     */
    get args(): Node[] {
        return this._args;
    }

    /**
     * Sets args
     * @param {Node[]} newArgs
     * @memberof Node
     */
    set args(newArgs: Node[]) {
        for (const arg of newArgs) {
            arg.parent = this;
        }
        this._args = newArgs;
    }

    /**
     * Returns parent
     * @returns {Node}
     * @memberof Node
     */
    get parent(): Node {
        return this._parent;
    }

    /**
     * Sets parent
     * @param {Node} newParent
     * @memberof Node
     */
    set parent(newParent: Node) {
        this._parent = newParent;
    }

    /**
     * Returns type
     * @returns {string}
     * @memberof string
     */
    get type(): string {
        return this._type;
    }

    /**
     * Sets type
     * @param {string} newType
     * @memberof string
     */
    set type(newType: string) {
        this._type = newType;
    }

    //======================================  Traversals ======================================//
    /**
     * Finds the root of this tree
     * @returns {Node}
     * @memberof Node
     */
    getRoot(): Node {
        if (this.parent) {
            return this.parent.getRoot();
        } else {
            return this;
        }
    }

    /**
     * Finds the path of nodes from this root -> node
     * @returns {Node}
     * @memberof Node
     */
    getAncestors(): Node[] {
        if (this.parent && this.parent.getAncestors) {
            try {
                return this.parent.getAncestors().concat([this]);
            } catch {
                //
            }
            return this.parent.getAncestors().concat([this]);
        } else {
            return [this];
        }
    }

    /**
     * Returns all operators in direct connection with node
     * @returns {Node[]}
     * @memberof Node
     */
    getAllImmediateOps(): Node[] {
        let _ret: Node[] = [this];

        for (const arg of this.args) {
            if (arg.type === "OperatorNode") {
                _ret = _ret.concat(arg.getAllImmediateOps());
            }
        }

        return _ret;
    }

    /**
     * Returns all leaves in order
     * ```
     *     +
     *    / \      =>   2, 3
     *   2   3
     * ```
     * @returns {Node[]}
     * @memberof Node
     */
    getLeaves(): Node[] {
        return this.getIOT().filter((node) => node.args.length === 0);
    }

    /**
     * Returns an array of all parameter nodes below this
     * @returns {Node[]}
     * @memberof Node
     */
    getParams(): Node[] {
        return this.getIOT().filter((node) => node.isParam());
    }

    /**
     * Generates a BFS of this node (Breadth First Traversal)
     * @returns {Node[]}
     * @memberof Node
     */
    getBFS(): Node[] {
        const queue: Node[] = [this];
        for (const item of queue) {
            for (const child of item.args) {
                queue.push(child);
            }
        }
        return queue;
    }

    /**
     * Searches the path to a given descendent
     * @returns {Node[]}
     * @memberof Node
     */
    getPathToNode(searching: Node): Node[] {
        if (searching === this) {
            return [this];
        }

        for (const node of this.args) {
            const search = node.getPathToNode(searching);
            if (search) {
                return search;
            }
        }

        return null;
    }

    /**
     * Generates a DFS of this node (Depth First Traversal)
     * @param {("left" | "right")} [direction="left"]
     * @returns {Node[]}
     * @memberof Node
     */
    getDFS(direction: "left" | "right" = "left") {
        const stack: Node[] = [this];
        const visited: Node[] = [];

        while (stack.length > 0) {
            const item: Node = stack.pop();

            visited.push(item);

            let directionIt = 1;
            let index = 0;
            if (direction === "left") {
                directionIt = -1;
                index = item.args.length - 1;
            }

            while (index > -1 && index < item.args.length) {
                stack.push(item.args[index]);
                index += directionIt;
            }
        }
        return visited;
    }

    /**
     * Generates an IOT of this node (In Order Traversal)
     * @returns {Node[]}
     * @memberof Node
     */
    getIOT(): Node[] {
        let leaves: Node[] = [];
        // 0 kids means we only return this node
        if (this.args.length === 0) {
            return [this];
        }
        // 1 kid means unary minus case etc so this is added first
        else if (this.args.length === 1) {
            leaves.push(this);
            leaves = leaves.concat(this.args[0].getIOT());
        }
        // more than 1 kid means we act normal, left subtree then this
        else {
            leaves = leaves.concat(this.args[0].getIOT());
            leaves.push(this);
        }
        // now any other nodes (Right subtree)
        for (const child of this.args.slice(1)) {
            leaves = leaves.concat(child.getIOT());
        }
        return leaves;
    }

    /**
     *Generates an PreOT of this node (Pre Order Traversal)
     * @returns {Node[]}
     * @memberof Node
     */
    getPreOT(): Node[] {
        if (this.args.length === 0) {
            return [this];
        }
        let array: Node[] = [];
        for (const arg of this.args) {
            array = array.concat(arg.getPreOT());
        }
        array.push(this);
        return array;
    }

    /**
     * Returns the nearest neighbour in the latex in given direction.
     * @param {("left" | "right")} direction
     * @return {*}  {Node}
     * @memberof Node
     */
    getLatexNeighbour(_direction: "left" | "right"): Node {
        return this;
    }

    /**
     * Returns a simple output of the tree given by its root node.
     * (Currently only used by Node.toAscii)
     * eg:
     * parse("a + b").toBasicTree();
     * >>> ['+', [ 'a' ], [ 'b' ] ]
     * @returns {(any[] | string)}
     * @memberof Node
     */
    getBasicTree(): any[] | string {
        let name = this.name;
        if (name === "*") {
            name = (this as unknown as any).mode === "implicit" ? "\\*" : "*";
            name = (this as unknown as any).mode === "explicit" ? "\\times" : "*";
        }
        const nodeArray: any[] = [name];
        for (const child of this.args) {
            nodeArray.push(child.getBasicTree());
        }
        return nodeArray;
    }

    /**
     * Returns all descendants matching the callback (where the callback operates on a given Node)
     * Operates just like array.filter()
     *
     * Takes an optional traversal function like node.getDFS etc
     *
     * @param {(Node: Node) => boolean} [filter]
     * @param {() => Node[]} [traversal]
     * @return {*}  {Node[]}
     * @memberof Node
     */
    getNodes(filter?: (Node: Node) => boolean, nodes?: Node[]): Node[] {
        // first get a BFS
        const bfs = nodes ? nodes : this.getBFS();
        // return it filtereed by
        if (filter !== undefined) {
            return bfs.filter(filter);
        } else {
            return bfs;
        }
    }

    /**
     * Returns all nodes from this tree which match the given name, in BFS order
     * @param {string} name
     * @returns {Node[]}
     * @memberof Node
     */
    getNodesByName(name: string): Node[] {
        return this.getNodes((node) => node.name === name);
    }

    /**
     * Returns all nodes from this tree which match the given type, in BFS order
     * @param {string} type
     * @returns {Node[]}
     * @memberof Node
     */
    getNodesByType(type: string): Node[] {
        return this.getNodes((node) => node.type === type);
    }

    //====================================  Number Outputs ====================================//
    /**
     * Evaluates this node
     * @returns {any}
     * @memberof Node
     */
    eval(params: ITuple = {}): any {
        const ancestors = this.getAncestors().map((node) => node.name);
        if (!ancestors.includes("f_lim") && !ancestors.includes("f_leftLim") && !ancestors.includes("f_rightLim")) {
            // if we have a division of zero, we want "undefined", rather than infinity (which
            // mathjs.eval returns) so deal with that here (except when in limits custom funcs)
            let divNode = this.getNodes((node) => node.name === "/" || node.name === "f_div")[0];
            if (divNode) {
                // if a division node has been found, check for / 0
                // const right = +parse(`{${divNode.args[1].toString()}}`).solve(params).toString();
                const right = divNode.args[1].eval(params);

                if (+right === 0) {
                    // e.g. 5/0 and 0/0 and inf/0
                    return "undefined";
                }
                // 5/9, 5/inf, inf/5, inf/inf and 0/inf are handled correctly below by mathjs.eval
            }
        }

        // Adds the source expression as the first argument for evalUsingSigFigRules
        if (this.name === "evalUsingSigFigRules") {
            this.args.unshift(new Node(`"${this.args[0].toString()}"`, [], this));
            this.args[0].type = "StringNode"; // This avoids importing StringNode
        }

        // replace "*", "%" and "/" nodes with "f_multi", "f_modulo" and "f_div" to handle JS precision error
        let simpleTree = parse(this.toString(), [], "NOTFLAT");
        simpleTree = simpleTree.findAndReplace(
            (node) => node.name === "*" || node.name === "/" || node.name === "%",
            (node) => {
                if (node.name === "*") {
                    // don't do this for units or variables
                    const nonParamSymbolNodes = node
                        .getBFS()
                        .filter((node) => node.type === "SymbolNode" && !params[node.name]);
                    if (nonParamSymbolNodes.length === 0) {
                        const newNode = parse(`f_multi()`);
                        newNode.args = node.args;
                        return newNode;
                    }
                    return node;
                } else if (node.name === "%") {
                    const newNode = parse(`f_modulo()`);
                    newNode.args = node.args;
                    return newNode;
                } else {
                    // node.name === "/"
                    const newNode = parse(`f_div()`);
                    newNode.args = node.args;
                    return newNode;
                }
            },
        );

        // replace any overwritten funcs
        simpleTree = simpleTree.findAndReplace(
            (node) => nodeOverrides.has(node.name),
            (node) => {
                const newNode = parse(`${nodeOverrides.get(node.name)}()`);
                newNode.args = node.args;
                return newNode;
            },
        );

        // replace any ghost brackets with regular brackets
        simpleTree = simpleTree.findAndReplace(
            (node) => node.type === "ParenthesisNode",
            (node) => {
                let pNode = node as ParenthesisNode;

                pNode.name = "()";
                pNode.isGhost = false;

                return pNode;
            },
        );

        // Calling eval on getDigits(1.00000000000) makes mathjs turn the parameter to a number
        // We need to make it stay a string
        const expWithQuotes = addQuotesToNums(simpleTree);
        let _toReturn = mathjs.eval(expWithQuotes, params);

        if (
            typeof _toReturn === "string" &&
            _toReturn.startsWith('"') &&
            _toReturn.endsWith('"') &&
            !isNaN(+_toReturn.slice(1, -1))
        ) {
            _toReturn = _toReturn.slice(1, -1);
        }
        _toReturn = keepTrailingZeros(this.toString(), _toReturn);
        _toReturn = fixNegativeZero(_toReturn);
        return _toReturn;
    }

    /**
     * Returns the numerical value of a node. Redefined by ConstantNode
     * @return {*}  {number}
     * @memberof Node
     */
    getNumberVal(): number {
        return 1;
    }

    //====================================  Misc  ====================================//

    /**
     * Replaces subtrees inside EvalNodes with the evaluated expression
     * @return {*}  {Node}
     * @memberof Node
     */
    solve(parameters: ITuple = {}): Node {
        // solve subtrees first
        const newargs = this.args.map((arg) => arg.solve(parameters));
        this.args = newargs;
        return this;
    }

    /**
     * Flattens all cases of identical associative operators into a single associative node.
     * @memberof Node
     */
    flatten(): void {
        const queue: Node[] = [this];

        for (const node of queue) {
            let args: Node[] = node.args;
            if (node.isAssociative()) {
                let kidsLength: number = args.length;
                if (node.name === "*" && (node as any).mode !== "norm") continue;

                for (let childPosition: number = 0; childPosition < kidsLength; childPosition++) {
                    const child: Node = node.args[childPosition];
                    if (child.name === node.name) {
                        // we dont flatten non-norm mode multiplication
                        if (child.name === "*" && (child as any).mode !== "norm") continue;

                        const childIndex: number = args.indexOf(child);
                        args = args
                            .slice(0, childIndex)
                            .concat(child.args)
                            .concat(args.slice(childIndex + 1));
                        node.args = args;
                        childPosition = -1;
                        kidsLength = node.args.length;
                    }
                }
            }
            for (const arg of args) {
                queue.push(arg);
            }
        }
    }

    /**
     * Returns clone of node where all arguments are recursively cloned.
     * @param {Node} [parent]
     * @return {*}  {Node}
     * @memberof Node
     */
    cloneDeep(parent?: Node): Node {
        return new Node(
            this.name,
            this.args.map((arg) => arg.cloneDeep()),
            parent,
        );
    }

    /**
     * Returns an array of this cloned num times.
     * @param {number} num
     * @return {*}  {Node[]}
     * @memberof Node
     */
    repeat(num: number): Node[] {
        const _ret: Node[] = [];

        for (let i = 0; i < num; i++) {
            _ret.push(this.cloneDeep());
        }
        return _ret;
    }

    /**
     * Checks that all nodes are doubly linked.
     * @return {*}  {string}
     * @memberof Node
     */
    areAllLinksComplete(): string {
        for (const arg of this.args) {
            if (arg.parent !== this) {
                return `Node ${arg.name} has incorrect parent, should be: ${this.name}, is: ${arg.parent.name}`;
            } else {
                const out = arg.areAllLinksComplete();
                if (out !== "All Links Complete") {
                    return out;
                }
            }
        }
        return "All Links Complete";
    }

    //====================================  String Outputs ====================================//
    /**
     * Generates to LATEX output of this node
     * @param {*} [options]
     * @returns {string}
     * @memberof Node
     */
    toTex(options?: any): string {
        const argsLatex = this.args.map((val) => val.toTex(options));
        return `${this.name}(${argsLatex.join(",")})`;
    }

    /**
     * Generates to word output of this node eg: 123 => One hundered and twenty three
     * @param {*} [options]
     * @returns {string}
     * @memberof Node
     */
    toWord(options?: any): string {
        return "NaN";
    }

    /**
     * Generates the string representation of this node
     * @param {*} [options]
     * @returns {string}
     * @memberof Node
     */
    toString(_options?: any): string {
        return this.name;
    }

    /**
     * Generates the ASCII representation of this node (Looks like a tree structure)
     * @returns {string}
     * @memberof Node
     */
    toAscii(hideColors = false, hideHelp = true): string {
        const typeColors = new Map([
            ["ArrayNode", "\x1b[35m"], // Magenta
            ["ConstantNode", "\x1b[93m"], // Yellow
            ["EvalNode", "\x1b[31m"], // red
            ["FunctionNode", "\x1b[33m"], // orange
            ["OperatorNode", ""], // normal
            ["ParenthesisNode", "\x1b[37m"], // light grey
            ["StringNode", "\x1b[32m"], // green
            ["SymbolNode", "\x1b[34m"], // blue
            ["UnitNode", "\x1b[36m"], // cyan
            ["RelationalNode", ""], // cyan
        ]);

        const simpleTree: string[] | string = this.getBasicTree();
        let asciiTree = draw_tree(simpleTree);

        if (!hideColors) {
            // replace each name in BFS order with its color version
            for (const node of this.getBFS()) {
                const color = typeColors.get(node.type); // get hex color code
                const name = node.name.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&"); // excapes regex chars like "+"

                // replace the name with the new color wrapped name (including resetting it to default afterwards)
                asciiTree = asciiTree.replace(
                    new RegExp(`(^|\\s|_)${name}(\\s|_|$)`),
                    `$1${color}${node.name}\x1b[39m$2`,
                );
            }
        }

        let types = "";
        if (!hideHelp) {
            types = `\x1b[34mSymbol \x1b[93mConstant \x1b[36mUnit \x1b[32mString \x1b[39mOperator \x1b[33mFunction \x1b[37mParenthesis \x1b[31mEval \x1b[35mArray\x1b[39m\n`;
        }

        return types + asciiTree;
    }
}
