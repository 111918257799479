import { Node } from "../parser/Node";

/**
 *
 * All the functions normally included in MathJS which we need to include in our new latex engine
 * Date: November 2020
 * Author: Henry Seed
 *
 */

export const basicToTexFunctions: any = {
    // Latex for round(a, b)                    =>      round(a,b)
    round: function (node: Node, options: any): string {
        if (node.args.length === 1) {
            return `\\left\\lfloor${node.args[0].toTex(options)}\\right\\rceil`;
        } else {
            return `\\mathrm{round}\\left(${node.args[0].toTex()},${node.args[1].toTex(options)}\\right)`;
        }
    },

    sqrt: function (node: Node, options: any): string {
        return `\\sqrt {${node.args[0].toTex(options)}}`;
    },

    abs: function (node: Node, options: any): string {
        return `\\left|${node.args[0].toTex(options)}\\right|`;
    },

    arg: function (node: Node, options: any): string {
        return `\\arg \\left(${node.args[0].toTex(options)}\\right)`;
    },

    nthRoot: function (node: Node, options: any): string {
        return `\\sqrt[${node.args[1].toTex(options)}] {${node.args[0].toTex(options)}}`;
    },

    cbrt: function (node: Node, options: any): string {
        // if we have a fraction inside the cbrt, increase the size of the 3
        if (node.getNodesByName("/").length > 0) {
            return `\\sqrt[\\scriptstyle 3]{${node.args[0].toTex(options)}}`;
        } else {
            return `\\sqrt[3]{${node.args[0].toTex(options)}}`;
        }
    },

    cis: function (node: Node, options: any): string {
        const angle = node.args[0];
        const angleTex = angle.toTex(options);
        let radiusTex = node.args[1] ? node.args[1].toTex(options) : "";
        if (radiusTex == "1") {
            radiusTex = "";
        }
        const mode = (node.args[2] ? node.args[2].name : "cis").replace(/\"/g, "");
        const symbolFuncs = ["f_unit", "f_deg", "f_degC", "f_degF", "f_latex", "f_symbol"];

        let _toRet = "";

        if (mode === "e") {
            if (symbolFuncs.includes(angle.name) || angle.type === "SymbolNode") {
                _toRet = `${radiusTex} e^{i ${angleTex}}`;
            } else {
                _toRet = `${radiusTex} e^{${angleTex} i}`;
            }
        } else if (mode === "angle") {
            _toRet = `${radiusTex} \\angle ~ ${angleTex}`;
        } else if (mode === "cis") {
            _toRet = `${radiusTex ? radiusTex + "~" : ""} \\mathrm{cis} ~ ${angleTex}`;
        }

        return _toRet;
    },
};
