import { Button, Divider, Typography } from "@mui/material";
import { Auth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { useNavigate } from "react-router-dom";

const provider = new GoogleAuthProvider();

export default function LoginView({ auth }: { auth: Auth }) {
    const nav = useNavigate();

    return (
        <div
            style={{
                width: "fit-content",
                margin: "100px auto",
            }}
        >
            <Typography color="textPrimary" variant="h4" style={{ margin: "10px 0" }}>
                Login
            </Typography>
            <Divider />
            <Typography color="textPrimary" variant="body1" style={{ marginTop: "20px" }}>
                Sign in with your Amy account.
            </Typography>
            <Button
                fullWidth
                style={{ marginTop: "30px" }}
                onClick={() =>
                    signInWithPopup(auth, provider)
                        .then((result) => {
                            const user = result.user;
                            console.log(user.uid);
                            nav("/");
                        })
                        .catch((error) => {
                            console.error(error.message);
                        })
                }
                color="primary"
            >
                Login
            </Button>
        </div>
    );
}
