import { MathNode } from "mathjs";
import { mathjs, toTexMap } from "../compiler/customFunctions";
import { defaultLatex } from "../compiler/customFunctionsLatex";
import { fixDecimalPlaces, getExpressionDecimalPlaces, getSingularUnit } from "../utilities";
import { ArrayNode } from "./ArrayNode";
import { ConstantNode, symbolicConstants } from "./ConstantNode";
import { EvalNode } from "./EvalNode";
import { FunctionNode } from "./FunctionNode";
import { Node } from "./Node";
import { OperatorNode } from "./OperatorNode";
import { ParenthesisNode } from "./ParenthesisNode";
import { RelationalNode } from "./RelationalNode";
import { StringNode } from "./StringNode";
import { SymbolNode } from "./SymbolNode";
import { UnitNode } from "./UnitNode";

const unitFuncs = {
    f_deg: "degree",
    f_degC: "degreeC",
    f_degF: "degreeF",
};

const arrays = new Map([
    ["f_bArray", "{}"],
    ["f_pArray", "()"],
    ["f_Array", ""],
    ["f_sArray", "[]"],
]);

function getMathJSArgs(node: MathNode): MathNode[] {
    if (node === undefined) {
        return [];
    }

    if ((node as any).content) {
        // Used for parenthesis
        return [(node as any).content]; // Returns it as an array because parenthesis will only ever have one content
    } else if (node.args) {
        // Used for Operators
        return node.args;
    } else if ((node as any).items) {
        // Used for Operators
        return (node as any).items;
    } else if ((node as any).params) {
        // Used for Parameters
        return (node as any).params;
    } else {
        // Used for Symbols
        return [];
    }
}

function geMathJSName(node: MathNode): string {
    if (node === undefined) {
        return "";
    }

    if (node.type === "EvalNode") {
        return "{}";
    } else if (node.type === "EqualsNode") {
        return "=";
    } else if (node.type === "GhostBracketNode") {
        return "$()$";
    } else if (node.type === "ArrayNode") {
        return "[]";
    } else if (node.type === "FunctionNode") {
        return (node.fn as any).name;
    } else if (node.type === "ParenthesisNode") {
        return "()";
    } else if (node.type === "ConstantNode") {
        return String(node.value);
    } else if (node.op) {
        // Used for Operators
        return node.op;
    } else if (node.name) {
        // Used for Letters
        return node.name;
    } else {
        return "";
    }
}

export function translateMathJSTree(
    mathJSnode: MathNode,
    vars: string[],
    _parent: Node = undefined,
    decimalPlaces: number[] = [],
): Node {
    let _toRet: any;
    const args: Node[] = [];
    const nodeName = geMathJSName(mathJSnode);

    for (const mathjsarg of getMathJSArgs(mathJSnode)) {
        args.push(translateMathJSTree(mathjsarg, vars, undefined, decimalPlaces));
    }

    if (mathJSnode.type === "OperatorNode") {
        _toRet = new OperatorNode(mathJSnode.op, args);
    } else if (mathJSnode.type === "SymbolNode") {
        if (symbolicConstants.get(mathJSnode.name)) {
            // check if "e" is a variable of math constant
            if (vars.includes("e") && mathJSnode.name === "e") {
                _toRet = new SymbolNode(mathJSnode.name);
            } else {
                _toRet = new ConstantNode(mathJSnode.name, undefined, "SymbolicNum");
            }
        } else {
            _toRet = new SymbolNode(mathJSnode.name);
        }
    } else if (mathJSnode.type === "ConstantNode") {
        if (typeof mathJSnode.value === "string") {
            const contents = mathJSnode.toString().replace(/"/g, "");
            _toRet = new StringNode(`"${contents}"`);
        } else if (symbolicConstants.get(mathJSnode.toString())) {
            _toRet = new ConstantNode(mathJSnode.toString(), undefined, "SymbolicNum");
        } else {
            const decimalPlace = decimalPlaces.splice(0, 1)[0];
            _toRet = new ConstantNode(fixDecimalPlaces(String(mathJSnode.value), decimalPlace));
        }
    } else if (mathJSnode.type === "EvalNode" || nodeName === "f_Eval") {
        _toRet = new EvalNode(args);
    } else if (mathJSnode.type === "ParenthesisNode") {
        _toRet = new ParenthesisNode(args);
    } else if (mathJSnode.type === "GhostBracketNode") {
        _toRet = new ParenthesisNode(args, undefined, true);
    } else if (mathJSnode.type === "ArrayNode") {
        _toRet = new ArrayNode(args);
    } else if (mathJSnode.type === "FunctionNode") {
        // use UnitNode instead of f_unit
        if (nodeName === "f_unit" && args[0]) {
            if (symbolicConstants.get(args[0].name)) {
                _toRet = new ConstantNode(args[0].name, undefined, "SymbolicNum");
            } else {
                _toRet = new UnitNode(args);
            }
        } else if (unitFuncs[nodeName]) {
            _toRet = new UnitNode([new SymbolNode(unitFuncs[nodeName])]);
        }
        // use ArrayNode for arrays
        else if (arrays.get(nodeName)) {
            let arrayArgs = args;
            // only skip the [...] if there is a [...] to skip
            if (args[0].type === "ArrayNode") {
                arrayArgs = args[0].args;
            }
            _toRet = new ArrayNode(arrayArgs);
            _toRet.setBrackets(arrays.get(nodeName));
        }
        // use OperatorNode instead of f_implicitMulti and f_explicitMulti
        else if (nodeName === "f_implicitMulti") {
            _toRet = new OperatorNode("*", args);
            _toRet.mode = "implicit";
        } else if (nodeName === "f_explicitMulti") {
            _toRet = new OperatorNode("*", args);
            _toRet.mode = "explicit";
        } else if (nodeName === "f_dotMulti") {
            _toRet = new OperatorNode("*", args);
            _toRet.mode = "dot";
        }
        // regular function
        else if (toTexMap.get(nodeName)) {
            _toRet = new FunctionNode(nodeName, args, undefined, toTexMap.get(nodeName));
        } else {
            _toRet = new FunctionNode(nodeName, args, undefined, defaultLatex);
        }
    } else if (mathJSnode.type === "RelationalNode") {
        const condLatex = {
            equal: "==",
            unequal: "!=",
            smaller: "<",
            larger: ">",
            smallerEq: "<=",
            largerEq: ">=",
        };
        const ourConds = (mathJSnode as any).conditionals.map((node: any) => condLatex[node]);
        _toRet = new RelationalNode(args, ourConds);
    } else if (mathJSnode.type === "RangeNode") {
        _toRet = new FunctionNode("f_ratio", args, undefined, toTexMap.get("f_ratio"));
    } else {
        throw SyntaxError(`MATHJS -> AMY Translation failed\nNODE ${mathJSnode.type} NOT RECOGNISED`);
    }
    return _toRet;
}

/**
 * Takes a string and returns a MathJs tree with our custom EvalNode and equalNodes used.
 *
 * @export
 * @param {string} expression
 * @param {string[]} [variables]
 * @param {("NOTFLAT" | "DIFF" | "DIFFNOTFLAT" | "USEIMPLICIT")} [mode]
 * @return {*}  {Node}
 */
export function parse(
    expression: string,
    variables?: string[],
    mode?: "NOTFLAT" | "DIFF" | "DIFFNOTFLAT" | "USEIMPLICIT",
): Node {
    // console.log(`RUNNING PARSER`, expression);
    if (!expression) {
        return null;
    }
    if (typeof expression !== "string") {
        expression = `${expression}`;
    }
    const notFlat = ["NOTFLAT", "DIFFNOTFLAT"].includes(mode);
    const singularUnits = ["DIFF", "DIFFNOTFLAT"].includes(mode);
    const useImplicit = ["USEIMPLICIT"].includes(mode);

    let exp = expression;

    // We need to replace all "..." with EXP_STRINGX so we dont replace \\times inside "..."
    let thisString = "";
    const stringSections: string[] = [];
    for (let expChar of expression) {
        if (thisString !== "") {
            // if we are currently working through a string
            thisString += expChar;
            if (expChar === `"`) {
                if (!thisString.includes("EXP_STRING_")) {
                    exp = exp.replace(thisString, `"EXP_STRING_${stringSections.length}"`);
                    stringSections.push(thisString.replace(/"/g, ""));
                }
                thisString = "";
            }
        } else {
            // if we are outside a string
            if (expChar === `"`) {
                thisString = `"`;
            }
        }
    }

    // Replace {} and $()$ with f_eval and f_ghost so the mathjs parse can read them
    exp = replaceCustomBrackets(exp); // exp is the expression with every {...} replaces with EvalNode eg: {a + b} + c => EvalNode + c

    // replaces \times, \* and \plus with functions and parses with mathjs
    let expRoot = parseImplicit(exp, useImplicit, stringSections, variables ? variables : []);

    // replace the f_eval and f_ghost with custom functions
    for (const node of expRoot.getDFS()) {
        // Go through generated tree and replace f_eval and f_ghost with actual nodes
        if (node.name !== "f_Eval" && node.name !== "f_Ghost" && node.name !== "f_gArray") {
            // handle units case
            if (node.type === "SymbolNode" && singularUnits) {
                node.name = getSingularUnit(node.name);
            }
        } else {
            // set a new node of
            let newNode: any;
            if (node.name === "f_Eval") {
                if (!node.args[0]) {
                    console.error(`Found {} or $()$ node with no kids`);
                    throw new TypeError('Node expected for parameter "content"');
                }
                newNode = new EvalNode(node.args, undefined);
            } else if (node.name === "f_Ghost") {
                if (!node.args[0]) {
                    console.error(`Found {} or $()$ node with no kids`);
                    throw new TypeError('Node expected for parameter "content"');
                }
                newNode = new ParenthesisNode(node.args, undefined, true);
            }
            // Unsure why we would want to do this
            // if (useImplicit === true) {
            //     if (node.name === "f_gArray") {
            //         // make a fake node
            //         newNode = new ConstantNode("X");
            //         // get all the leaves from the original node
            //         const leaves: Node[] = node.getLeaves();
            //         // get all the leaves names
            //         const leafNames = [];
            //         for (const leaf of leaves) {
            //             leafNames.push(leaf.toString());
            //         }
            //         // make these names joined together the new node
            //         newNode.name = leafNames.join("");
            //     }
            // }

            if (newNode) {
                expRoot = node.replaceInTree(newNode, expRoot);
            }
        }
    }
    // replace units with a unit wrapper and unig symbols with units eg: deg => f_deg()
    if (variables !== undefined) {
        expRoot = replaceUnits(expRoot, variables);
    }

    // Flattens tree to merge all common associative nodes
    if (!notFlat) {
        expRoot.flatten();
    }
    return expRoot;
}

/**
 * Replaces all units in a given tree, with units surrounded with the f_unit()  function wrapper
 * @static
 * @param {Node} tree
 * @param {string[]} [variables]
 * @returns {Node}
 */
function replaceUnits(tree: Node, variables?: string[]): Node {
    let _retTree = tree;
    const knownUnits = ["deg", "degC", "degF"];

    let seenUnitNodes = []; // we dont want to insert units inside units

    for (const node of _retTree.getBFS()) {
        // either we know its a unit or we can infer it is by checking its not a variable or parmeter
        if (isUnitSymbol(node, _retTree, variables, knownUnits) && !seenUnitNodes.includes(node)) {
            seenUnitNodes = seenUnitNodes.concat(node.getBFS());
            const nodeName = node.name;
            let newNode: Node;
            if (knownUnits.includes(nodeName)) {
                if (!node.isInEval()) {
                    newNode = parse(`f_${nodeName}()`);
                } else {
                    newNode = parse(nodeName);
                }
                _retTree = node.replaceInTree(newNode);
            } else {
                newNode = new UnitNode([]);
                _retTree = node.replaceInTree(newNode);
                newNode.args = [node];
            }
        }
    }

    return _retTree;
}

/**
 * NOT TO BE USED OUTSIDE PARSER. Checks if the given node is a unit
 * Outside parser, to check if a node is a unit, just check if it's inside a f_unit()
 * @static
 * @param {Node} node
 * @param {Node} tree
 * @param {string[]} vars
 * @returns {boolean}
 */
function isUnitSymbol(node: Node, tree: Node, vars: string[], knownUnits: string[]): boolean {
    // Ensures we don't replace i18n f_string keys with f_unit
    if (node.toString().includes("fString")) {
        return false;
    } else if (node.name.match(/[XZ]\d+/)) {
        // diff nodes (Z1) and relabelled diff nodes (X1) aren't units and won't appear in vars
        return false;
    }
    // make sure we arent already inside a unit
    const ancestors = node.getAncestors();
    if (ancestors !== undefined) {
        if (ancestors.filter((val) => val.isUnit()).length > 0) {
            return false;
        }
    }

    const allowedUnitOps = [
        "^",
        "/",
        "*",
        "()",
        "$()$",
        "f_exp",
        "sqrt",
        "f_div",
        "nthRoot",
        "f_pow",
        "f_multi",
        "f_Ghost",
        "f_implicitMulti",
        "f_explicitMulti",
    ]; // a list of nodeNames allowed inside a unit

    const nonUnit = [
        "alpha",
        "Alpha",
        "beta",
        "Beta",
        "gamma",
        "Gamma",
        "delta",
        "Delta",
        "epsilon",
        "varepsilon",
        "Epsilon",
        "zeta",
        "Zeta",
        "eta",
        "Eta",
        "theta",
        "Theta",
        "iota",
        "Iota",
        "kappa",
        "Kappa",
        "lambda",
        "Lambda",
        "mu",
        "Mu",
        "nu",
        "Nu",
        "xi",
        "Xi",
        "omnicron",
        "Omnicron",
        "pi",
        "Pi",
        "rho",
        "Rho",
        "varrho",
        "sigma",
        "Sigma",
        "tau",
        "Tau",
        "upsilon",
        "Upsilon",
        "phi",
        "Phi",
        "chi",
        "Chi",
        "psi",
        "Psi",
        "omega",
        "Omega",
        "i",
        "$",
    ]; // an array of symbols which cant be units

    if (node.type === "SymbolNode" && !nonUnit.includes(node.name)) {
        if (knownUnits.includes(node.name) || (vars && !vars.includes(node.name) && !node.isInEval())) {
            return true;
        } else {
            return false;
        }
    } else if (allowedUnitOps.includes(node.name)) {
        // check every child is a unit
        const kids = node.args;
        return kids.every((kid) => isUnitSymbol(kid, tree, vars, knownUnits));
    }

    return false;
}

/**
 * Takes a string with our custom nodes like \*  \times \plus and returns a tree correctly parsed
 * @static
 * @param {string} exp
 * @returns {Node}
 */
function parseImplicit(inputExp: string, useImplicit: boolean, stringSections: string[], variables: string[]): Node {
    let exp = inputExp;

    // before we parse it with mathjs, we need to catch all our implicit multiplication nodes
    // first we need an array of the order of the multiplications and their state: {implicit | normal}
    const multiplicationStates: ("implicitMulti" | "normalMulti" | "explicitMulti" | "dotMulti")[] = [];
    const multiReg: RegExp = /\*|\\\*|\\times|\\dot/g;

    const additionStates: ("normalPlus" | "explicitPlus" | "normalMinus" | "explicitMinus")[] = [];
    const additionReg: RegExp = /\+|\\plus|\-|\\minus/g;

    const statesMap = new Map<
        string,
        | "implicitMulti"
        | "normalMulti"
        | "explicitMulti"
        | "dotMulti"
        | "normalPlus"
        | "explicitPlus"
        | "normalMinus"
        | "explicitMinus"
    >([
        ["\\times", "explicitMulti"],
        ["\\*", "implicitMulti"],
        ["*", "normalMulti"],
        ["+", "normalPlus"],
        ["\\plus", "explicitPlus"],
        ["-", "normalMinus"],
        ["\\minus", "explicitMinus"],
        ["\\dot", "dotMulti"],
    ]);

    // we define a string for use with the regex, it just gets every match replaced with "xxxxxxxx" for keeping the index the same or less than before.
    let regexStr = exp;

    // replace and *, \* or \times with a standard *
    let result1: RegExpExecArray = multiReg.exec(regexStr);
    while (result1) {
        multiplicationStates.push(statesMap.get(result1[0]) as any);
        exp = exp.replace(result1[0], "*");
        regexStr = regexStr.replace(result1[0], "xxxxxxx");
        result1 = multiReg.exec(regexStr);
    }
    // we define a string for use with the regex, it just gets every match replaced with "xxxxxxxx" for keeping the index the same or less than before.
    let regexStr2 = exp;

    // replace and +, -, \minus or \plus with a standard + or -
    let result2: RegExpExecArray = additionReg.exec(regexStr2);
    while (result2) {
        additionStates.push(statesMap.get(result2[0]) as any);
        if (result2[0] === "\\minus" || result2[0] === "-") {
            regexStr2 = regexStr2.replace(result2[0], "xxxxxxx");
            exp = exp.replace(result2[0], "-");
        } else if (result2[0] === "\\plus" || result2[0] === "+") {
            regexStr2 = regexStr2.replace(result2[0], "xxxxxxx");
            exp = exp.replace(result2[0], "+");
        }

        result2 = additionReg.exec(regexStr2);
    }

    // replace not operator with custo function f_not
    exp = exp.replace(/([^A-z_]|^)not\(/g, "$1f_not(");

    const decimalPlaces = getExpressionDecimalPlaces(exp);

    let expRoot = translateMathJSTree(mathjs.parse(exp), variables, undefined, decimalPlaces);

    let strReplaceIndex = 0;
    for (const stringSection of stringSections) {
        const node = expRoot.getNodesByName(`"EXP_STRING_${strReplaceIndex}"`);
        node[0].name = `"${stringSection}"`;
        strReplaceIndex++;
    }

    // make a list of nodes who are under f_stringOption so we can ignore them
    const f_stringOptionKids: Node[] = [].concat.apply(
        [],
        expRoot.getNodesByName("f_stringOption").map((val) => val.getAllImmediateOps()),
    );

    let dfs = expRoot.getIOT();

    for (const node of dfs) {
        // if node is a multi node
        if (useImplicit) {
            if (node.type === "OperatorNode") {
                let newNode: Node;

                // implicit multiplication check
                if (node.name === "*") {
                    const thisMultiState = multiplicationStates.shift();
                    // if the node is supposed to be implicit
                    if (thisMultiState === "implicitMulti") {
                        (node as OperatorNode).mode = "implicit";
                    }
                    // if the node is supossed to be explicit
                    else if (thisMultiState === "explicitMulti") {
                        (node as OperatorNode).mode = "explicit";
                    }
                    // if the node is supossed to be dotMulti
                    else if (thisMultiState === "dotMulti") {
                        (node as OperatorNode).mode = "dot";
                    }
                }

                // implicit addition / subtraction check   eg: 1 + - 2   => 1 - 2   and  eg: 1 - - 2   => 1 + 2
                else if (node.name === "+" || node.name === "-") {
                    const thisAddState = additionStates.shift();

                    // skip any nodes who are directly under f_stringOption so we dont remove "+" before "-"
                    if (f_stringOptionKids.indexOf(node) > -1 && thisAddState !== "explicitMinus") {
                        continue;
                    }

                    if (thisAddState === "explicitPlus") {
                        newNode = parse("f_explicitPlus()"); //     make an implicit node
                        newNode.args = node.args; //          store the current kids on the implicit node
                    } else if (thisAddState === "explicitMinus") {
                        newNode = parse("f_explicitMinus()"); //     make an implicit node
                        newNode.args = node.args; //          store the current kids on the implicit node
                    } else if (
                        (thisAddState === "normalPlus" || thisAddState === "normalMinus") &&
                        node.args.length > 1
                    ) {
                        const rightKid = node.args[1].getLatexNeighbour("left");

                        if (f_stringOptionKids.indexOf(rightKid) > -1) {
                            continue;
                        }

                        if (rightKid.name === "-") {
                            const exceptions = ["f_decomposeFraction"]; // there are some exceptions where we don't want this to happen
                            if (!node.find((node) => exceptions.includes(node.name))) {
                                // replace rightKid with its kids
                                expRoot = rightKid.replaceInTree(rightKid.args[0]);

                                // Change the parents op since we are removing the "-"
                                if (node.name === "+") {
                                    node.name = "-";
                                } else if (node.name === "-") {
                                    node.name = "+";
                                }
                            }
                        }
                    }
                }

                if (newNode !== undefined) {
                    expRoot = node.replaceInTree(newNode, expRoot);
                    dfs = expRoot.getIOT();
                }
            }
        }
        if (node.type === "ConstantNode" && node.toString().includes(`"`)) {
            // if we are looking at a string we need to escape any escape characters so they arent lost in parsing
            node.name = node.name.replace(/([^\\])\\([^\\])/g, "$1\\\\$2");
        }
    }
    return expRoot;
}

function replaceCustomBrackets(exp: string): string {
    let _toRet: string = exp;
    let masterExpression: string = exp; // expression that is only altered after loops to stop loop editing problem

    // Replaces {} and $()$ with EvalNode and GhostBracketNode functions
    const toReplace: { start: string; end: string; func: string }[] = [
        { start: "{", end: "}", func: "f_Eval" },
        { start: "$(", end: ")$", func: "f_Ghost" },
    ];
    for (const run of toReplace) {
        const subExpressions: string[] = [""];

        let insideQuote = false;

        for (let expindex = 0; expindex < masterExpression.length; expindex++) {
            // start a new bracketing session
            const char: string = masterExpression[expindex];
            const char2: string = masterExpression[expindex] + masterExpression[expindex + 1];

            if ((char2[1] === '"' && char2[0] !== "\\") || (char === '"' && expindex === 0)) {
                insideQuote = !insideQuote;
            }

            // checks if this char is the start of a new bracketted section
            if ((char === run.start || char2 === run.start) && !insideQuote) {
                // check for 2 char match
                if (char2 === run.start) {
                    expindex++;
                }
                subExpressions.push("");
            }
            // checks if this char is the end of a bracketted section
            else if ((char === run.end || char2 === run.end) && !insideQuote) {
                // check for 2 char match
                if (char2 === run.end) {
                    expindex++;
                }
                const insideBrackets: string = subExpressions.pop();
                _toRet = _toRet.replace(`${run.start}${insideBrackets}${run.end}`, `${run.func}(${insideBrackets})`);
                subExpressions[subExpressions.length - 1] += `${run.func}(${insideBrackets})`;
            }
            // continue adding to string
            else {
                subExpressions[subExpressions.length - 1] += char;
            }
        }

        masterExpression = _toRet;
    }

    return _toRet;
}
