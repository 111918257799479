import {
    getProjectSettingsFromHostname,
    getSettings,
    setSettings,
} from "@jaipuna/common-modules/dist/src/ProjectSettings";
import { compileAmySyntax } from "@jaipuna/js-internal/dist/src/compiler/compileAmySyntax";
import { Parameter } from "@jaipuna/js-internal/dist/src/modules/Archetype";
import { getAllRegexMatchStrings } from "@jaipuna/js-internal/dist/src/utilities";
import { getApp, getApps, initializeApp } from "firebase/app";
import { getDownloadURL, getStorage, ref } from "firebase/storage";

const settings = getProjectSettingsFromHostname(window.location.hostname);
if (settings) {
    setSettings(settings);
}

// init firebase
if (getApps().length === 0) {
    initializeApp(getSettings().firebaseConfig);
}

const storage = getStorage(getApp());

export function timeAgo(time: Date) {
    let daysOldFloat = (Date.now() - time.getTime()) / 1000 / 60 / 60 / 24;
    let hoursOldFloat = (daysOldFloat % 1) * 24;

    const daysOld = Math.floor(daysOldFloat);
    const hoursOld = Math.floor(hoursOldFloat);
    const minsOld = Math.floor((hoursOldFloat % 1) * 60);

    const dayStr = `${daysOld} day${daysOld !== 1 ? "s" : ""}`;
    const hourStr = `${hoursOld} hour${hoursOld !== 1 ? "s" : ""}`;
    const minStr = `${minsOld} min${minsOld !== 1 ? "s" : ""}`;

    if (daysOld > 0) {
        return `${dayStr}, ${hourStr} ago`;
    } else if (hoursOld > 0) {
        return `${hourStr}, ${minStr} ago`;
    } else {
        return `${minStr} ago`;
    }
}

export function formatPath(path: string) {
    let newPath = path;

    for (const match of getAllRegexMatchStrings(newPath, /f_[A-Za-z]+/g)) {
        newPath = newPath.replace(match, match.toLowerCase());
    }

    newPath = newPath.replace(/([a-zA-Z])([A-Z])([a-z])/g, "$1 $2$3");
    newPath = newPath.replace(/([a-z])([A-Z])/g, "$1 $2");
    newPath = newPath.replace(/ /g, "-");
    newPath = newPath.toLowerCase();

    // the first char of all paths is a "/"
    if (!newPath.startsWith("/")) newPath = "/" + newPath;

    return newPath;
}

export async function compileWikiArticle(text: string) {
    let md = text;

    const vars: Parameter[] = [
        { type: "VARIABLE", name: "x", value: "x" },
        { type: "VARIABLE", name: "y", value: "y" },
        { type: "VARIABLE", name: "z", value: "z" },
        { type: "VARIABLE", name: "a", value: "a" },
        { type: "VARIABLE", name: "b", value: "b" },
        { type: "VARIABLE", name: "c", value: "c" },
        { type: "VARIABLE", name: "p", value: "p" },
        { type: "VARIABLE", name: "q", value: "q" },
    ];

    try {
        md = compileAmySyntax({ text: md, archExps: [], parameters: vars, stepNum: 0 });
    } catch {
        md = "COMPILER_ERROR " + md;
    }

    md = md.replace(/https:\/\/gitlab.com\/jaipuna\/content\/-\/wikis\//g, "");
    md = md.replace(/\]\(/g, `](/#`);
    md = md.replace(/\]\(\/#https/g, `](https`);
    md = md.replace(/\]\(\/#uploads/g, `](uploads`);

    const imagePaths = [...md.matchAll(/\]\(uploads\/([^)]+)\)/g)].map((val) => val[1]);

    await Promise.all(imagePaths.map((val) => getDownloadURL(ref(storage, `WikiImages/${val}`)))).then((urls) => {
        for (const [index, url] of urls.entries()) {
            const imagePath = imagePaths[index];
            md = md.replace(`uploads/${imagePath}`, url);
        }
    });

    return md;
}

export function cleanImgPath(imgPath: string) {
    return encodeURIComponent(imgPath.replace(/\(|\)/g, ""));
}
