import { Exclude, Type } from "class-transformer";
import "reflect-metadata";
import { relabeler, replaceEvalSubtrees } from "../DiffFinder/DiffFinder";
import { Node, PDiff } from "../modules";
import { parse } from "../parser/parse";

/**
 * Contains data on a `unique diff`. A unique diff is a relabled diff that multiple archetypes share.
 * For example, if a + a -> 2a is one archetypes diff and b + b -> 2b is anothers, they will share the same unique diff.
 *
 * @export
 * @class UniqueDiff
 */
export class UniqueDiff {
    id: string;
    source: string;
    target: string;

    possibleArchetypes: string[];
    sourceNodesSerialized: string[] = [];
    targetNodesSerialized: string[] = [];
    noEvalSourceBFSSerialized: string[] = [];
    noEvalTargetBFSSerialized: string[] = [];

    @Exclude()
    private _sourceNodesCache: Node[] = [];

    @Exclude()
    private _targetNodesCache: Node[] = [];

    @Exclude()
    private _noEvalSourceBFSCache: Node[] = [];

    @Exclude()
    private _noEvalTargetBFSCache: Node[] = [];

    @Exclude()
    get sourceNodes(): Node[] {
        if (this._sourceNodesCache.length === 0) {
            this._sourceNodesCache = this.sourceNodesSerialized.map((e) => parse(e));
        }

        return this._sourceNodesCache;
    }

    set sourceNodes(Nodes: Node[]) {
        this.sourceNodesSerialized = Nodes.map((e) => e.toString());
        this._sourceNodesCache = Nodes;
    }

    @Exclude()
    get targetNodes(): Node[] {
        if (this._targetNodesCache.length === 0) {
            this._targetNodesCache = this.targetNodesSerialized.map((e) => parse(e));
        }

        return this._targetNodesCache;
    }

    set targetNodes(Nodes: Node[]) {
        this.targetNodesSerialized = Nodes.map((e) => e.toString());
        this._targetNodesCache = Nodes;
    }

    @Exclude()
    get noEvalSourceBFS(): Node[] {
        if (this._noEvalSourceBFSCache.length === 0) {
            this._noEvalSourceBFSCache = this.noEvalSourceBFSSerialized.map((e) => parse(e));
        }

        return this._noEvalSourceBFSCache;
    }

    set noEvalSourceBFS(Nodes: Node[]) {
        this.noEvalSourceBFSSerialized = Nodes.map((e) => e.toString());
        this._noEvalSourceBFSCache = Nodes;
    }

    @Exclude()
    get noEvalTargetBFS(): Node[] {
        if (this._noEvalTargetBFSCache.length === 0) {
            this._noEvalTargetBFSCache = this.noEvalTargetBFSSerialized.map((e) => parse(e));
        }

        return this._noEvalTargetBFSCache;
    }

    set noEvalTargetBFS(Nodes: Node[]) {
        this.noEvalTargetBFSSerialized = Nodes.map((e) => e.toString());
        this._noEvalTargetBFSCache = Nodes;
    }

    @Type(() => PDiff)
    matchingDiffs: PDiff[];

    constructor(source: string, target: string) {
        if (!source || !target) {
            return;
        }
        this.possibleArchetypes = [];
        this.source = relabeler([source])[0];
        this.target = relabeler([target])[0];
        this.id = `${this.source}->${this.target}`;

        const S_diff: Node = parse(this.source, null);
        const T_diff: Node = parse(this.target, null);
        this.sourceNodes = S_diff.getBFS();
        this.targetNodes = T_diff.getBFS();

        this.noEvalSourceBFS = replaceEvalSubtrees(this.sourceNodes[0], this.sourceNodes, this.targetNodes);
        this.noEvalTargetBFS = replaceEvalSubtrees(this.targetNodes[0], this.targetNodes, this.sourceNodes);

        this.matchingDiffs = [];
    }
}
