import { Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";

export function Breadcrumbs({ path }: { path: string }) {
    const theme = useTheme();
    return (
        <>
            <Link style={{ color: theme.palette.mode === "dark" ? "#5dc0ff" : "" }} to="/#home">
                Wiki
            </Link>
            {path !== "home" &&
                path
                    .split("/")
                    .filter((val) => val.trim() !== "")
                    .map((val) => (
                        <Typography
                            color="textPrimary"
                            key={val}
                            style={{ display: "inline-block" }}
                            variant="subtitle1"
                        >
                            <span
                                style={{
                                    margin: "0px 10px",
                                    fontWeight: "bold",
                                    fontSize: "18pt",
                                    verticalAlign: "middle",
                                    opacity: 0.3,
                                }}
                            >
                                {"/"}
                            </span>
                            {val}
                        </Typography>
                    ))}
        </>
    );
}
