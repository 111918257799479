import KaTeX, { KatexOptions, ParseError } from "katex";
import React, { ComponentPropsWithoutRef, ElementType, ReactElement, memo } from "react";

const TeX: React.FC<TeXProps> = ({ math, block, errorColor, renderError, settings, ...props }) => {
    try {
        const innerHtml = KaTeX.renderToString(math as string, {
            displayMode: block,
            errorColor,
            throwOnError: !!renderError,
            output: "html",
            ...settings,
        });
        if (block) {
            return (
                <div
                    {...props}
                    style={{ ...props?.style, width: "fit-content" }}
                    dangerouslySetInnerHTML={{ __html: innerHtml }}
                />
            );
        }
        return <span {...props} dangerouslySetInnerHTML={{ __html: innerHtml }} />;
    } catch (e) {
        return <>Latex error: {e.message}</>;
    }
};

export default memo(TeX);

type TeXProps = ComponentPropsWithoutRef<"div"> &
    Partial<{
        as: ElementType;
        math: string | number;
        block: boolean;
        errorColor: string;
        renderError: (error: ParseError | TypeError) => ReactElement;
        settings: KatexOptions;
    }>;
