import { AmyRender } from "@amy-app/react-syntax-renderer";
import { WikiFunc, WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { parse } from "@jaipuna/js-internal/dist/src/parser/parse";
import {
    Alert,
    Button,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    useTheme,
} from "@mui/material";
import { deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { compileWikiArticle, formatPath } from "../utils";
import { Breadcrumbs } from "./Breadcrumbs";
import { ConfirmButton } from "./ConfirmButton";
import { DragzoneTextfield } from "./DragzoneTextfield";
import { ProgressButton } from "./ProgressButton";
import { RenderWikiMarkdown } from "./RenderWikiMarkdown";

export function PageEditor({
    page,
    pages,
    funcDef,
    onCloseEditor,
    isNewPage,
}: {
    page: WikiPage;
    pages: WikiPage[];
    funcDef: WikiFunc | null;
    onCloseEditor: () => void;
    isNewPage?: boolean;
}) {
    const theme = useTheme();
    const nav = useNavigate();
    const user = useUser();

    const [name, setName] = useState("");
    const [path, setPath] = useState("");
    const [pathError, setPathError] = useState(false);
    const [text, setText] = useState("");
    const [compiledMD, setCompiledMD] = useState("");
    const [examples, setExamples] = useState<{ exp: string; descr: string }[]>([]);

    const [updateWarning, setUpdateWarning] = useState(false);
    const [updateAuthor, setUpdateAuthor] = useState(page?.editedBy);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const newPage = pages.find((val) => val.id === page.id);
        if (!isSaving && newPage && newPage.lastUpdated != page.lastUpdated) {
            setUpdateWarning(true);
            setUpdateAuthor(newPage.editedBy);
        }
    }, [pages, isSaving]);

    useEffect(() => {
        setName(page.name);
        setPath(page.path);
        setText(page.description);
        setExamples(page.examples);
    }, [page]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => compileWikiArticle(text).then((val) => setCompiledMD(val)), 300);
        return () => clearTimeout(delayDebounceFn);
    }, [text]);

    function evalExp(exp: string) {
        if (exp.trim() === "") return "";
        try {
            return parse(exp.replace(/\{|\}/g, "")).eval().toString();
        } catch (e) {
            console.error(e.message);
            return "Error";
        }
    }
    function toTexExp(exp: string) {
        if (exp.trim() === "") return "";
        try {
            return parse(exp).toTex();
        } catch {
            return "Error";
        }
    }

    async function save() {
        const dbPage = pages.find((val) => val.id === page.id);
        if (dbPage || isNewPage) {
            setIsSaving(true);
            const newPage: WikiPage = { ...page };
            newPage.name = name.trim();
            newPage.path = path.startsWith("/") ? path.trim() : "/" + path.trim();
            newPage.description = text.trim();
            newPage.editedBy = user?.email || "";
            newPage.renderedDescription = await compileWikiArticle(text);

            if (funcDef) {
                newPage.examples = examples.map((ex) => ({ exp: ex.exp.trim(), descr: ex.descr.trim() }));
            }

            // Only save and backup to history if there is actually a change
            if (JSON.stringify(newPage) !== JSON.stringify(dbPage)) {
                newPage.lastUpdated = Date.now();

                if (!isNewPage) {
                    // save the old page to history collection
                    setDoc(doc(getFirestore(), "WikiHistory", `${dbPage?.id}-${dbPage?.lastUpdated}`), dbPage);
                }
                await setDoc(doc(getFirestore(), "WikiPages", page.id), newPage);
            } else {
                console.log("Saving with no change, skipping");
            }

            nav(`/#${newPage.path.slice(1)}`.toLowerCase());
        }
    }

    async function trash() {
        if (page) {
            await deleteDoc(doc(getFirestore(), "WikiPages", page.id));
        }
    }

    function handlePathChange(newPath: string) {
        const cleanPath = formatPath(newPath);
        if (cleanPath !== page.path) {
            setPathError(pages.find((p) => p.path === cleanPath) !== undefined);
        }
        setPath(cleanPath);
    }

    return (
        <span style={{ width: "100%" }}>
            <Grid xs={12} item container spacing={2} justifyContent="flex-end">
                <Grid item xs={12}>
                    {updateWarning && (
                        <Alert variant="filled" severity="error">
                            {updateAuthor} has just edited this page. It is recommended you save. Their changes will be
                            kept in history and you can merge them by hand.
                        </Alert>
                    )}
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item>
                            <Button
                                color="primary"
                                onClick={() => {
                                    onCloseEditor();
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                        <Grid item>
                            <ConfirmButton
                                buttonName={"Delete"}
                                buttonColor={"error"}
                                dialogTitle={`Are you sure you want to delete "${page?.name}"?`}
                                dialogContents={"This cannot be reversed"}
                                onClick={async () => {
                                    await trash();
                                    onCloseEditor();
                                    nav("/");
                                }}
                                progressButton
                                buttonStyle={{ marginRight: "20px" }}
                            />
                            <ProgressButton
                                onClick={async () => {
                                    await save();
                                    onCloseEditor();
                                }}
                                buttonProps={{ color: "primary" }}
                            >
                                Save
                            </ProgressButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {funcDef && (
                <>
                    <Typography variant="h6" color="textPrimary" sx={{ marginTop: "40px" }}>
                        Examples
                    </Typography>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Example</TableCell>
                                <TableCell>Evaluated</TableCell>
                                <TableCell>LaTeX?</TableCell>
                                <TableCell>Notes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {examples.map((example, i) => (
                                <TableRow>
                                    <TableCell>
                                        <TextField
                                            autoComplete="off"
                                            fullWidth
                                            value={example.exp}
                                            onChange={(e) =>
                                                setExamples([
                                                    ...examples.slice(0, i),
                                                    { ...example, exp: e.target.value },
                                                    ...examples.slice(i + 1),
                                                ])
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{evalExp(example.exp)}</TableCell>
                                    <TableCell>
                                        <AmyRender
                                            text={`$[${toTexExp(example.exp)}]$`}
                                            color={theme.palette.mode === "dark" ? "white" : "black"}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            autoComplete="off"
                                            fullWidth
                                            value={example.descr}
                                            onChange={(e) =>
                                                setExamples([
                                                    ...examples.slice(0, i),
                                                    { ...example, descr: e.target.value },
                                                    ...examples.slice(i + 1),
                                                ])
                                            }
                                        />
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <Button
                        color="info"
                        variant="text"
                        sx={{ marginTop: "10px", color: "white" }}
                        fullWidth
                        onClick={() => setExamples([...examples, { exp: "", descr: "" }])}
                    >
                        Add Example
                    </Button>
                </>
            )}
            <Grid container spacing={2} style={{ marginTop: "20px" }}>
                <Grid item xs={6}>
                    <TextField
                        label="Title"
                        onChange={(e) => setName(e.target.value)}
                        onBlur={() => path === "/" && handlePathChange(name)}
                        fullWidth
                        value={name}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ marginTop: "10px" }} variant="h4" color="textPrimary">
                        {name}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={pathError ? "Path already exists" : "Path"}
                        onChange={(e) => handlePathChange(e.target.value)}
                        fullWidth
                        error={pathError}
                        value={path}
                    />
                </Grid>
                <Grid item xs={6}>
                    <div style={{ marginTop: "10px" }}>
                        <Breadcrumbs path={path} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <DragzoneTextfield label="Description" onChange={(val) => setText(val)} value={text} />
                </Grid>
                <Grid item xs={6}>
                    {page ? (
                        <RenderWikiMarkdown md={compiledMD} color={theme.palette.mode === "dark" ? "white" : "black"} />
                    ) : (
                        <p>404</p>
                    )}
                </Grid>
            </Grid>
        </span>
    );
}
