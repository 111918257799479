import { WikiPage } from "@jaipuna/common-modules/dist/src/WikiTypes";
import { uid } from "@jaipuna/shared-external";
import { Home, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Button, useTheme } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

type PageNode = { id: string; name: string; isDir: boolean; fullPath: string; kids: PageNode[] };

function WikiTreeNode({
    node,
    pages,
    onClick,
    path,
}: {
    node: PageNode;
    pages: WikiPage[];
    onClick: (path: string) => void;
    path: string;
}) {
    const theme = useTheme();
    const [collapsed, setCollapsed] = useState(true);

    const thisPage = pages.find((page) => page.id === node.id);
    const isLeaf = node.kids.length === 0;
    const name = (thisPage?.name || node.name).replace(/-/g, " ");
    const selected = node.fullPath
        .toLowerCase()
        .split("/")
        .every((val) => path.toLowerCase().split("/").includes(val));

    let icon: JSX.Element | undefined = undefined;
    if (name === "Home") icon = <Home />;
    if (!isLeaf && !collapsed) icon = <KeyboardArrowUp />;
    if (!isLeaf && collapsed) icon = <KeyboardArrowDown />;

    useEffect(() => {
        if (collapsed) {
            setCollapsed(!path.toLowerCase().split("/").includes(node.name.toLowerCase()));
        }
    }, [path, node.name]);

    return (
        <>
            <div style={{ margin: "0 10px" }}>
                <Button
                    fullWidth
                    variant="text"
                    style={{
                        borderRadius: "10px",
                        padding: "10px 5px",
                        paddingLeft: !icon ? "40px" : undefined,
                        justifyContent: "flex-start",
                        textAlign: "left",
                        textTransform: "none",
                        fontSize: "12pt",
                        background: selected ? theme.palette.action.selected : undefined,
                    }}
                    onClick={() => (isLeaf ? onClick(node.fullPath) : setCollapsed(!collapsed))}
                >
                    {icon && <span style={{ height: "20px", width: "30px", marginTop: "-4px" }}>{icon}</span>}
                    {name}
                    {!isLeaf && <span style={{ marginLeft: "10px", opacity: 0.4 }}>({node.kids.length})</span>}
                </Button>
            </div>
            {!isLeaf && (
                <div
                    style={{
                        marginLeft: "30px",
                        height: collapsed ? "0px" : "inherit",
                        overflow: "hidden",
                        borderLeft: `1px solid ${theme.palette.action.disabled}`,
                    }}
                >
                    {node.kids
                        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
                        .map((kid) => (
                            <span key={kid.id}>
                                <WikiTreeNode node={kid} pages={pages} onClick={onClick} path={path} />
                            </span>
                        ))}
                </div>
            )}
        </>
    );
}

export function WikiTree({ pages, onClick }: { pages: WikiPage[]; onClick: (path: string) => void }) {
    const path = "/" + useLocation().hash.replace("#", "").toLowerCase();

    const pageTree = useMemo(() => {
        const sortedPages = pages.sort((a, b) => (b.path < a.path ? 1 : -1));

        const pageTree: PageNode = { id: "ROOT", isDir: true, name: "wiki", fullPath: "wiki", kids: [] };
        for (const page of sortedPages) {
            const ancestorDirs = page.path.split("/").filter((val) => val !== "");

            // populate tree to support page
            let treeNode = pageTree;
            for (const dir of ancestorDirs.slice(0, ancestorDirs.length - 1)) {
                let dirTreeNode = treeNode.kids.find(
                    (val) => val.isDir && val.fullPath === page.path.split(dir)[0] + dir,
                );
                if (!dirTreeNode) {
                    dirTreeNode = {
                        id: uid(),
                        isDir: true,
                        name: dir,
                        fullPath: page.path.split(dir)[0] + dir,
                        kids: [],
                    };
                    treeNode.kids.push(dirTreeNode);
                }
                treeNode = dirTreeNode;
            }
            // insert page
            treeNode.kids.push({ id: page.id, isDir: false, name: page.name, fullPath: page.path, kids: [] });
        }

        // move the Home page to the top
        const homeNode = pageTree.kids.find((val) => val.name === "Home");
        if (homeNode) {
            pageTree.kids.splice(pageTree.kids.indexOf(homeNode), 1);
            pageTree.kids = [homeNode, ...pageTree.kids];
        }
        return pageTree;
    }, [pages]);

    return (
        <>
            {pageTree.kids.map((kid) => (
                <span key={kid.id}>
                    <WikiTreeNode node={kid} pages={pages} onClick={onClick} path={path} />
                </span>
            ))}
        </>
    );
}
