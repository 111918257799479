/**
 * Fast and simple PRNG taken from: https://stackoverflow.com/a/47593316
 * @param {*} a
 * @return {*}
 */
export function mulberry32(a: number): () => number {
    return () => {
        // eslint-disable-line no-param-reassign
        let t = (a += 0x6d2b79f5);
        t = Math.imul(t ^ (t >>> 15), t | 1);
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    };
}

/**
 *
 * @param seed Creates a seed number based on a string
 * @returns
 */
export function seedFromString(seed: string) {
    return seed
        .split("")
        .map((e) => e.charCodeAt(0))
        .reduce((a, b) => a + b, 0);
}

/**
 * Uses a string as seed for a random number
 * @param seed
 * @returns
 */
export function stringToRandomNr(seed: string) {
    return mulberry32(seedFromString(seed))();
}

/**
 * Returns a random integer between min-max (inclusive)
 * @param min
 * @param max
 * @returns {number}
 */
export function getRandomInt(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * Retunred a shuffled array
 * @param input
 * @returns
 */
export function shuffleArray<Type>(input: Type[]): Type[] {
    if (!input) {
        throw new Error("Input is undefined");
    }

    if (input.length === 0) {
        return input;
    }

    if (input.length === 1) {
        return input;
    }

    let copy = [...input];
    const rand: Type[] = [];

    while (copy.length > 0) {
        const nextPos = Math.floor(Math.random() * copy.length);
        rand.push(...copy.splice(nextPos, 1));
    }

    return rand;
}

/**
 * Psuedo-random version of the above shuffleArray. Copies and shuffles the input according to the seeded RNG random().
 * @param {any[]} array
 * @param {number} seed
 * @return {*}
 */
export function PRShuffleArray<Type>(input: Type[], seed: number = Math.random()): Type[] {
    const array = input.slice();
    let currentIndex: number = array.length,
        randomIndex: number;
    const random = mulberry32(seed);
    // While there remain elements to shuffle ...
    while (currentIndex) {
        // Pick a remaining element…
        randomIndex = Math.floor(random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }
    return array;
}

/**
 * The return type for getRandomPermutation, next.value is a array of the input type
 * @interface PermIterator
 * @template T
 */
interface PermIterator<T> {
    next(value?: any): IteratorResult<T, T>;
    return(value?: any): IteratorResult<T, T>;
}
/**
 * Returns the next unique permutation in the sequence described by the Sawada-Williams' alg.
 * The input is first psuedo-randomly shuffled using the seed.
 * SW alg: http://www.cis.uoguelph.ca/~sawada/papers/sigmaTauPath.pdf
 * Other permutation algs: http://combos.org/perm
 * @export
 * @param {any[]} input
 * @param {number} seed
 * @return {*}  {Generator<any[]>}
 */
export function* getRandomPermutation<Type>(input: Type[], seed: number): PermIterator<Type[]> {
    const randPerm = shuffleArray(input); // shuffle input
    const n = input.length;
    // pi is a permutation of n ints. we use these as the indices for randPerm
    const pi = Array(n + 1).fill(0); // entry 0 is not used (fix this?)
    let totalPerms = 1;
    for (let i = 2; i <= n; i++) {
        totalPerms = totalPerms * i; // compute n!
    }
    let count = 0; // current perm no.
    let idx = 0;
    if (n === 1) {
        pi[1] = 1;
        idx = 1;
    } else {
        pi[1] = n - 1;
        pi[2] = n;
        idx = 2;
        for (let i = 3; i <= n; i++) {
            pi[i] = n - i + 1;
        }
    }

    // loop while count < n!
    while (count < totalPerms) {
        // console.log(pi.slice(1));
        yield pi.slice(1).map((index) => randPerm[index - 1]);
        let r: number;
        if (idx === 1) {
            r = pi[3];
        } else if (idx === n) {
            r = pi[1];
        } else {
            r = pi[idx + 1];
        }
        if (((r < n - 1 && pi[2] === r + 1) || (r === n - 1 && pi[2] === 1)) && !specialPerm()) {
            [pi[1], pi[2]] = [pi[2], pi[1]]; // this is the tau() function
            if (idx <= 2) idx = 3 - idx;
        } else {
            sigma();
            if (idx-- === 1) idx = n;
        }
        count = count + 1;
    }

    /**
     * Sigma helper function. Shifts elements of pi to the left (wrapping at the end).
     */
    function sigma(): void {
        const tmp = pi[1];
        for (let i = 1; i < n; i++) {
            pi[i] = pi[i + 1];
        }
        pi[n] = tmp;
    }

    /**
     * Special permutation helper function. Returns true if pi[1..n] = n(n-1)...1
     * @return {*}  {boolean}
     */
    function specialPerm(): boolean {
        for (let i = 1; i <= n; i++) {
            if (pi[i] !== n - i + 1) {
                return false;
            }
        }
        return true;
    }
}

/**
 * Flattens multi-dimensional array.
 * Copied from: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
 * @param {any[]} array
 * @param {number} depth
 * @return {*}
 */
export function flatDeep<Type>(arr: any, depth: number = 1): Type[] {
    return depth > 0
        ? arr.reduce((acc: any, val: any) => acc.concat(Array.isArray(val) ? flatDeep(val, depth - 1) : val), [])
        : arr.slice();
}
